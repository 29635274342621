import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import {
  IActiveProduct,
  IProduct,
  IProductDetail,
  ISeo,
} from "../../../shared/models";
import { Gallery, GalleryItem, ImageItem } from "@ngx-gallery/core";
import { Lightbox } from "@ngx-gallery/lightbox";
import { PixelService } from "../../../shared/services/facebook-pixel/pixel.service";
import { WishlistSnackbarService } from "../../../shared/services/wishlist-service/wishlist-snackbar.service";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IReviewsData,
  IUpgradeProduct,
} from "../../../shared/models/product-detail-payload.interface";
import { ApiService } from "../../../shared/services/api/api.service";
import { UtilsService } from "../../../shared/services/utils/utils.service";
import { MatDialogUtilsService } from "../../../shared/services/matDialogUtils/matDialogUtils.service";
import { SeoService } from "../../../shared/services/seo/seo.service";
import { EventEmitterService } from "../../../shared/services/events/event-emitter.service";
import { CookieService } from "ngx-cookie-service";
import { ReviewDialogService } from "../../../shared/services/matDialogUtils/review-dialog.service";
import { EnvService } from "../../../shared/services/env.service";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.less"],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  @ViewChild("topContainer", { static: false }) topContainer: ElementRef;
  @ViewChild("gallery", { static: false }) galleryContainer: ElementRef;
  @ViewChild("itemTemplate", { static: true }) itemTemplate: TemplateRef<any>;

  product: IProduct;
  seoData: ISeo;
  productSubscription: Subscription;
  eventSubscription: Subscription;
  routeSubscription: Subscription;
  Math: Math = Math;
  selectedIndex: number;
  descriptionExist: boolean;
  isSwatchExist: boolean;
  isVariationExist: boolean;
  galleryId = "myLightBox";
  items: GalleryItem[] | any;
  isProductFetching = true;
  showDetails = false;
  spinner = "assets/image/spinner.gif";
  description: any;
  productPrice: any;
  productWasPrice: any;
  variations = [];
  topHeight = { "max-height": "0" };
  swatches = [];
  errorMessage = "";

  activeTab = "mr";
  priceData = {
    price: "",
    wasPrice: "",
  };
  selectedSwatch = {
    swatch_image: null,
    price: "",
    wasPrice: "",
  };
  hasValidWasPrice = true;
  quantity = 1;
  quantityArray = [];
  galleryRef = this.gallery.ref(this.galleryId);
  isSetItemInInventory = false;
  localStorageUser = {};
  activeProduct: IActiveProduct;
  hasSelection: boolean;
  beforeSelection: boolean;
  checkSelection: boolean;
  schema = {};
  invalidLinkImageSrc = "assets/image/invalid_link.png";
  invalidLink: boolean;
  starIcons = [];
  recentProducts: any[] = [];
  recentReviews: IReviewsData;

  upgradeProducts: IUpgradeProduct[];
  otherPeopleProducts = [];

  mainProductImage = [];
  serverProductImagesWithIndex = [];

  showSku = false;
  productSku = "";

  paramSku = "";
  pKey: string;
  updatedPrice: any;
  slideIndex = 0;
  newVariationFound: boolean;
  newVariationData: any;
  dupProduct: IProduct;
  prodBoardList: any[] = [];
  perRowCount: number;
  isPriceUpdate: boolean;
  brandCoverImg: any;
  newSku: string = "";
  salePriceStrike: number;
  onlySkuDetails: boolean;
  appUrl = '';
  serverProductGalleryImagesWithIndex: { image: string; index: number; }[];
  nos: number;
  constructor(
    private apiService: ApiService,
    public utils: UtilsService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private location: Location,
    private matDialogUtils: MatDialogUtilsService,
    private seoService: SeoService,
    private pixelService: PixelService,
    private snackBarService: WishlistSnackbarService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private cookie: CookieService,
    private reviewDialog: ReviewDialogService,
    public env: EnvService,
    private _renderer2: Renderer2
  ) {
    this.appUrl = this.env.appUrl;
    this.utils.showCatNav =false;
  }
getmsg(d){
 return this.utils.compileMarkdownText(d) 
}
  ngOnInit(): void {
    if(window.innerWidth >1500){
      this.nos = 10
    } else if(window.innerWidth>1200){
      this.nos = 8
    } else {
      this.nos= 6
    }
    this.isProductFetching = true;
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe(
        (user) => {
          if (user.email !== "") {
            this.loadProduct();
          } else {
            this.loadProduct();
          }
        },
        (err) => {
          this.loadProduct();
        }
      );
  }
  getProdImgBoard() {
    this.apiService.getProdImgBoard(this.paramSku).subscribe((data: any) => {
      this.prodBoardList = data.sort((a, b) => a.serial - b.serial);
      this.perRowCount = Math.ceil(this.prodBoardList.length / 4);
    });
  }
  getBrandCoverImage(prd) {
    this.apiService.getBrandData(prd.brand).subscribe((data: any) => {
      if (data.length > 0) { 
        this.brandCoverImg = data[0].cover_image;
      }
    });
  }

  private loadProduct() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.pKey = params.pkey;
    });
    this.routeSubscription = this.activeRoute.params.subscribe((params) => {
      if (params && params.product) {
        this.paramSku = params.product;
        this.isProductFetching = true;

        this.getProdImgBoard();
        this.productSubscription = this.apiService
          .getProduct(this.paramSku)
          .subscribe(
            (payload: IProductDetail) => {
              if (payload.product) {
                this.getBrandCoverImage(payload.product);
                const user = JSON.parse(localStorage.getItem("user"));
                if (
                  payload.product.displayable == "false" &&
                  user &&
                  user.user_type !== 90 &&
                  user.user_type !== 70
                ) {
                  this.router.navigate(["product/contact"]);
                  return;
                } else if (
                  payload.product.displayable == "restricted" &&
                  user &&
                  user.user_type !== 2 &&
                  user.user_type !== 90 &&
                  user.user_type !== 70
                ) {
                  this.router.navigate(["product/contact"]);
                  return;
                } else if (
                  payload.product.displayable == "preview" &&
                  user &&
                  user.user_type == 0
                ) {
                  this.router.navigate([`product/${this.paramSku}/preview`]);
                  return;
                }
                this.processProduct(payload);
                this.loadProductReviews(this.paramSku);
                this.loadRecentProducts();
              }
            },
            (error) => {
              this.invalidLink = true;
              this.isProductFetching = false;
            }
          );
        this.apiService
          .getProductSelection(this.paramSku)
          .subscribe((data: any) => {
            this.onlySkuDetails = false;
            if (data && data.selection && data.selection.length > 0) {
              this.newVariationFound = true;
              this.newVariationData = data;
              this.activeProduct.in_inventory = true;
              if (!this.activeProduct.inventory_product_details.count) {
                this.activeProduct.inventory_product_details.count = 1;
              }

            } else {
              let activeRecords = data.Skudetails?.find(f=>f.is_active==='1');
              if (activeRecords) {
                this.onlySkuDetails = true;
                this.newVariationFound = true;
                this.newVariationData = data;
                this.newSku = activeRecords.sku;
              } else {
                this.newVariationFound = false;
              }
            }
          });
      }
    }); 
  }

  goBack() {
    this.location.back();
  }

  loadRecentProducts() {
    this.apiService
      .getRecentProducts()
      .pipe(first())
      .subscribe((response: any[]) => {
        this.recentProducts = response;
      });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  loadOtherPeopleProducts() {
    this.apiService
      .getOtherPeopleProducts(this.product.sku)
      .subscribe((response: any[]) => {
        this.otherPeopleProducts = response;
      });
  }

  openAllReviewsModal(reviewsData) {
    this.matDialogUtils.openAllReviewsModal(this.product, reviewsData);
  }

  loadProductReviews(sku) {
    const limit = 10;
    this.apiService
      .getProductReviews(sku, limit)
      .subscribe((response: IReviewsData) => {
        this.recentReviews = response;
      });
  }

  setRating(): void {
    this.starIcons = [];
    let starCount = Math.round(this.product.rating * 2) / 2;
    while (starCount > 0.5) {
      this.starIcons.push("star");
      starCount -= 1;
    }
    if (starCount && this.starIcons.length < 5) {
      this.starIcons.push("star_half");
    } else if (this.starIcons.length < 5) {
      while (this.starIcons.length < 5) {
        this.starIcons.push("star_outline");
      }
    }
  }

  private processProduct(payload: IProductDetail) {
    this.product = payload.product;
    this.dupProduct = JSON.parse(JSON.stringify(payload.product));
    if (
      this.product.variations.length === 0 &&
      this.product.product_status === "active"
    ) {
      this.isSetItemInInventory = true;
    }
    this.seoData = payload.seo_data;
    this.pixelService.trackViewContent(this.product);
    if (this.product.sku !== this.paramSku) {
      this.router.navigateByUrl("/product/" + this.product.sku);
    }
    this.upgradeProducts = payload.upgrades || [];
    this.loadOtherPeopleProducts();
    if (payload.product) {
      this.setProduct(payload);
      this.setRating();
    } else {
      this.invalidLink = true;
    }
    this.isProductFetching = false;
    if (
      Math.floor(this.product.min_price) ==
        Math.floor(this.product.max_price) ||
      Math.floor(this.product.min_price) >=
        Math.floor(this.product.min_was_price)
    ) {
      this.updatedPrice = JSON.parse(JSON.stringify(this.product.min_price));
    } else {
      this.updatedPrice = JSON.parse(JSON.stringify(this.product.max_price));
    }
    setTimeout(() => {
      this.currentSlide(1);
    }, 0);
  }

  private setProduct(payload: IProductDetail) {
    this.setSeoData(payload);
    this.updateActiveProduct(this.product);
    if (this.product.on_server_images.length > 0) {
      this.createGalleryItems(this.product.on_server_images);
      this.serverProductImagesWithIndex = this.product.on_server_images.map(
        (image, index) => {
          return {
            image,
            index,
          };
        }
      );
    } else {
      this.createGalleryItems(this.product.product_images_gallery);
      this.serverProductImagesWithIndex =
        this.product.product_images_gallery.map((image, index) => {
          return {
            image,
            index,
          };
        });
    }
    this.mainProductImage = [];
    this.mainProductImage.push(this.product.main_image);
    if (this.product.product_images_gallery_thumb.length >= 0) {
      this.product.product_images_gallery_thumb.unshift(
        this.product.main_image
      );

      this.serverProductGalleryImagesWithIndex = this.product.product_images_gallery_thumb.map(
        (image, index) => {
          return {
            image,
            index,
          };
        }
      );
    }
    if (this.product.product_images_gallery.length > 0) {
      this.product.product_images_gallery.unshift(this.product.main_image);
    }
    this.description = this.utils.compileMarkdown(
      this.product.description,
      true
    );
    this.descriptionExist = this.utils.checkDataLength(
      this.product.description
    );
    this.isSwatchExist = this.utils.checkDataLength(
      this.product.variations &&
        this.product.variations.filter(
          (variation) => variation.swatch_image !== null
        )
    );
    if (this.product.in_inventory) {
      this.productPrice = this.utils.formatPrice(
        this.product.inventory_product_details.price
      );
      this.productWasPrice = this.utils.formatPrice(
        this.product.inventory_product_details.was_price
      );
    } else {
      this.productPrice = this.product.min_price;

      this.productWasPrice = this.product.max_was_price;
    }
    if (typeof this.productPrice == "string") {
      const minPrice = Number(this.productPrice.split("-")[0]);
      const wasMinPrice = Number(this.productWasPrice.split("-")[0]);
      if (wasMinPrice <= minPrice) {
        this.hasValidWasPrice = false;
      }
    } else {
      const minPrice = Number(this.productPrice);
      const wasMinPrice = Number(this.productWasPrice);
      if (wasMinPrice <= minPrice) {
        this.hasValidWasPrice = false;
      }
    }

    this.isVariationExist = this.utils.checkDataLength(this.product.variations);

    if (!this.isVariationExist) {
      this.beforeSelection = true;
      this.checkSelection = true;
    }

    this.hasVariationsInventory();
    this.variations = this.product.variations.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    if (this.product.set) {
      this.checkSetInventory(this.product.set);
    }
    if (!this.isVariationExist) {
      this.showSku = true;
      this.productSku = this.product.sku;
    }
    if (this.isVariationExist && this.product.variations.length === 1) {
      this.beforeSelection = true;
      this.checkSelection = true;
      this.showSku = true;
      this.productSku = this.activeProduct.sku;
    }
    if (this.newVariationFound && this.newSku) {
      this.productSku = this.newSku;
    }

    const self = this;
    setTimeout(() => {
      self.getMaxHeight();
    }, 1000);
    this.invalidLink = false;
  }

  createGalleryItems(items: any[]) {
    this.items = items.map((item) => new ImageItem({ src: item, thumb: item }));
    this.galleryRef.setConfig({
      imageSize: "contain",
      gestures: false,
      thumb: true,
      thumbWidth: 90,
    });
    this.galleryRef.load(this.items);
  }

  setSkuOfCurrentSelectedProduct(variations) {
    if (variations.length === 1) {
      this.showSku = true;
      this.productSku = variations[0].variation_sku;
    } else {
      this.showSku = false;
      this.productSku = "";
    }
  }

  private setSeoData(payload: any) { 
    this.schema = payload.seo_data.json_ld;  
    const seoData: any = payload.seo_data;
    const metaData = {
      title: `${seoData.brand} ${seoData.product_name} | ${this.env.appName}`,
      description: seoData.description,
      image: seoData.image_url,
    };
    payload.seo_data.title = `${seoData.brand} ${seoData.product_name} | ${this.env.appName}`;
    this.seoService.setMetaTags(payload.seo_data,this._renderer2);
    this.seoService.setJsonLd(this._renderer2,this.schema);
  }

  onDestroy(): void {
    this.productSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  openLightbox(index: number) {
    this.lightbox.open(index, this.galleryId, {
      panelClass: "fullscreen",
    });
  }

  checkSetInventory(product) {
    for (const item of product) {
      if (item.in_inventory) {
        this.isSetItemInInventory = true;
      }
    }
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  wishlistProduct(sku, mark) {
    this.product.wishlisted = mark;
    const user = JSON.parse(localStorage.getItem("user"));
    const neverShow = localStorage.getItem("login-prompt-never-show");
    const token = this.cookie.get("token");
    if (!token) {
      // trigger signup window
      this.utils.openSignupDialog(false, true);
      return;
    }
    this.apiService.wishlistProduct(sku, mark, false).subscribe(
      (_) => {
        if (mark) {
          if (user.user_type === 0 && !neverShow) {
            this.matDialogUtils.openWishlistLoginPromptModal(this.product);
          } else {
            this.snackBarService.addToWishlist(sku);
          }
        } else {
          this.snackBarService.removeIfExistsProduct(sku);
        }
      },
      (error) => {
        this.product.wishlisted = !mark;
      }
    );
  }

  openLink(event, url) {
    event.preventDefault();
    if (typeof vglnk) {
      vglnk.open(url, "_blank");
    }
  }

  getMaxHeight() {
    const topHeight =
      this.topContainer && (this.topContainer.nativeElement.offsetHeight || 0);
    this.topHeight = { "max-height": `calc(100vh - ${topHeight + 51}px)` };
  }

  onSetImage(variation): void {
    this.mainProductImage = []; 
    if (variation && variation.image) {
      const src = variation.image.split(",");
      let images = [];
      if (this.isArray(src)) {
        images = [...src];
      } else {
        images[0] = new ImageItem({ src, thumb: src });
      }
      this.mainProductImage = [...images];
      this.updateActiveProduct(variation);
      this.hasSelection = true; 
    } else {
      this.updateActiveProduct(this.product);
      if(variation && variation.inventory_product_details){
      this.activeProduct.inventory_product_details = variation.inventory_product_details;
      }
      this.mainProductImage[0] = this.product.main_image;
      this.hasVariationsInventory();
    }
    this.galleryRef.load(this.items);
  }

  onSetPrice(priceData): void {
    if (!this.productPrice.includes("-")) {
      this.salePriceStrike = 0;
      this.productPrice = this.utils.formatPrice(
        priceData.price || this.product.min_price
      );
      this.productWasPrice = this.utils.formatPrice(
        priceData.min_was_price || this.product.max_was_price
      ); 
      this.isPriceUpdate = true;

      // this.showSku = true;
      this.updatedPrice = this.utils.formatPrice(
        priceData.price || this.product.min_price
      );
      if (priceData.price != priceData.wasPrice) {
        this.salePriceStrike = priceData.wasPrice;
      }
    }
    this.isSetItemInInventory = this.activeProduct.inventory_product_details.is_active == '1' ? true : false;
    this.activeProduct.in_inventory = this.activeProduct.inventory_product_details.is_active == '1' ? true : false;
    this.showSku = this.activeProduct.sku ? true : false;
  }

  scrollTo(elementId) {
    const el: Element = document.getElementById(elementId);
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  openCartModal() {
    if (this.newVariationFound) {
      if (this.newVariationData.Skudetails.length > 0) {
        if (this.newSku) {
          this.saveCart();
        } else {
          this.hasSelection = false;
        }
      } else {
        if (
          this.newVariationData.selection.some((s) => s.requiredSelection == 0)
        ) {
          if (
            this.newSku.split("-").length - 1 ==
              this.newVariationData.selection.filter(
                (f) => f.requiredSelection !== 0
              ).length ||
            this.newSku.split("-").length - 1 ==
              this.newVariationData.selection.length
          ) {
            this.saveCart();
          } else {
            this.hasSelection = false;
          }
        } else {
          if (
            this.newSku.split("-").length - 1 !==
            this.newVariationData.selection.length
          ) {
            this.hasSelection = false;
          } else {
            this.saveCart();
          }
        }
      }
    } else {
      if (
        (!this.activeProduct.in_inventory &&
          !this.activeProduct.inventory_product_details.price) ||
        !this.beforeSelection
      ) {
        this.hasSelection = false;
      } else {
        this.saveCart();
      }
    }
  }

  saveCart() {
    this.hasSelection = true;
    const data = {
      sku: this.activeProduct.sku,
      brand: this.product.brand_name,
      image: this.mainProductImage[0],
      name:
        this.activeProduct.sku === this.product.sku
          ? this.activeProduct.name
          : this.product.name + " " + this.activeProduct.name,
      price: this.productPrice,
      quantity: this.quantity,
    };
    const postData = {
      product_sku:
        this.newVariationFound && this.newSku
          ? this.newSku
          : this.activeProduct.sku,
      count: this.quantity,
      parent_sku: this.product.sku,
      isServiceRepOrder: 0,
      is_virtual: this.newVariationFound && this.newSku ? 1 : 0,
    };
    this.apiService.addCartProduct(postData).subscribe(
      (payload: any) => {
        if (payload.status) {
          this.errorMessage = "";
          this.matDialogUtils.openAddToCartDialog(data);
          this.pixelService.trackAddToCart(data);
        } else {
          this.errorMessage = payload.msg;
        }
      },
      (error: any) => {
        this.errorMessage = "Cannot add this product at the moment.";
      }
    );
  }

  addUpgradeToCart(product: IUpgradeProduct) {
    const data = {
      sku: product.sku,
      brand: product.brand_name,
      image: product.image,
      name: product.name,
      price: product.price,
      quantity: 1,
    };
    const postData = {
      product_sku: product.sku,
      count: 1,
      parent_sku: product.parent_sku,
      isServiceRepOrder: 0,
      is_virtual: 0,
    };
    this.apiService.addCartProduct(postData).subscribe(
      (payload: any) => {
        if (payload.status) {
          this.errorMessage = "";
          this.matDialogUtils.openAddToCartDialog(data);
          this.pixelService.trackAddToCart(data);
        } else {
          this.errorMessage = payload.msg;
        }
      },
      (error: any) => {
        this.errorMessage = "Cannot add this product at the moment.";
      }
    );
  }
  updatePrice(e) { 
    this.salePriceStrike = 0;
    this.updatedPrice = JSON.parse(JSON.stringify(this.product.min_price));
    let scenario1 =
      Math.floor(this.product.min_price) + Math.floor(e.sellingPrice);
    let scenario2 =
      Math.floor(this.product.min_was_price) + Math.floor(e.retailPrice);
    if (this.newVariationData.Skudetails.length > 0) {
      this.updatedPrice = +e.sellingPrice;
      if (Math.floor(e.retailPrice) != Math.floor(e.sellingPrice)) {
        this.salePriceStrike = Math.floor(e.retailPrice);
      }
    } else {
      this.updatedPrice = this.updatedPrice + +e.sellingPrice;
      if (scenario1 != scenario2) {
        this.salePriceStrike = scenario2;
      }
    }
    this.productPrice = this.utils.formatPrice(this.updatedPrice);
 
    this.isPriceUpdate = true;
  }
  getNewSku(event) {
    if (event === undefined) {
      this.isSetItemInInventory = false;
      this.activeProduct.in_inventory =   false;
      this.product.in_inventory =   false;
      return; 
    } 
    if (this.newVariationData.Skudetails.length > 0 && event ) {

      this.hasSelection = true;
      this.newSku = event;
      this.productSku = event;
      this.activeProduct.inventory_product_details.is_drop =
        this.newVariationData.Skudetails.find((f) => f.sku == event).is_drop;
        if(this.newVariationData.Skudetails.length>1){
      this.mainProductImage[0] = this.newVariationData.Skudetails.find(
        (f) => f.sku == event
      ).imagePath
        ? this.newVariationData.Skudetails.find((f) => f.sku == event).imagePath
        : this.mainProductImage[0];
      }
      this.activeProduct.in_inventory = (this.newVariationData.Skudetails.find(
        (f) => f.sku == event
      ).is_active) === "1" ? true : false;
      this.product.in_inventory = (this.newVariationData.Skudetails.find(
        (f) => f.sku == event
      ).is_active) === "1" ? true : false; 

      this.isSetItemInInventory = (this.newVariationData.Skudetails.find(
        (f) => f.sku == event
      ).is_active) === "1" ? true : false; 
      if(this.activeProduct.inventory_product_details && this.activeProduct.inventory_product_details.count &&
        this.activeProduct.inventory_product_details.count >1
      ){

      }else {
      this.activeProduct.inventory_product_details.count = 1; 
      }
    } else {
      this.hasSelection = true;
      this.isSetItemInInventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
      this.activeProduct.in_inventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
      this.product.in_inventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
      this.newSku = this.product.sku + "-" + event;
    }
    // this.activeProduct.inventory_product_details = 
  }

  updateActiveProduct(product) { 
    if (
     
      this.product.variations.length === 1
    ) {
      this.activeProduct = {
        sku: product.variations[0].variation_sku,
        in_inventory: product.variations[0].in_inventory,
        name: product.variations[0].name,
        inventory_product_details:
          product.variations[0].inventory_product_details,
      };
    } else {
      this.activeProduct = {
        sku: product.variation_sku ? product.variation_sku : product.sku,
        in_inventory: product.in_inventory,
        name: product.name,
        inventory_product_details: product.inventory_product_details
          ? product.inventory_product_details
          : [],
      };
    } 
    this.updatedPrice = Number(product.price);
  }
  quantityLimit(count) {
    const maxNumber = count < 10 ? count : 10;
    return Array.from({ length: maxNumber }, Number.call, (i) => i + 1);
  }

  hasVariationsInventory() {
    if (
      this.isVariationExist &&
      this.product.inventory_product_details === null
    ) {
      if (this.product.variations.find((item) => item.in_inventory === true)) {
        this.activeProduct.in_inventory = true;
        if (!this.activeProduct.inventory_product_details.count) {
          this.activeProduct.inventory_product_details.count = 1;
        }
      }
    }
  }

  onSetSelectionChecked(e: boolean) {
    this.beforeSelection = e;
  }

  onClearSelection(e: boolean) {
    this.checkSelection = e;
  }

  onSetSelection(e: boolean) {
    this.hasSelection = e;
  }
 

  goToReview(sku) {
    window.location.href = "./product/review/" + sku; 
  }

  openMyReviewModal() {
    this.hasSelection = true;
    const data = {
      sku: this.product.sku,
      brand: this.product.brand_name,
      image: this.product.main_image,
      name:
        this.activeProduct.sku === this.product.sku
          ? this.activeProduct.name
          : this.product.name + " " + this.activeProduct.name,
      price: this.productPrice,
      quantity: this.quantity,
    };
    this.reviewDialog.openMyReviewDialog(data);
  }

  isDiscounted(product): boolean {
    product.is_price = product.price;
    const price = this.utils.getPriceObject(product);
    return price.isDiscounted;
  }

  toCollectionProduct(sku) {
    this.matDialogUtils.closeDialogs();
    setTimeout(() => this.matDialogUtils.openMatDialog(sku), 250);
  }

  selectTab(tab) {
    this.activeTab = tab;
  }
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      "Hi there! I see you would like to chat with a design consultant."
    );
  }
  updateImg(ind) { 
    if (ind) {
      if (!this.mainProductImage) {
        this.mainProductImage.push(
          `${this.product.product_images_gallery[ind]}`
        );
      } else {
        this.mainProductImage[0] = `${
          this.product.product_images_gallery[ind]
        }`;
      }
    } else {
      this.mainProductImage[0] = this.product.main_image;
    }
  }
  plusSlides(n: number) {
    this.showSlides((this.slideIndex += n));
  }
  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }
  showSlides(n) {
    let i;
    let slides = document.getElementsByClassName(
      "pSlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (slides.length > 0) {
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active-dot", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active-dot";
    }
  }
  getProductImage(init: number): string[] { 
    let finalProductImage = [];
    let counter = JSON.parse(JSON.stringify(init));
    for (let i = 0; i < this.perRowCount; i++) {
      if (this.prodBoardList[counter] && this.prodBoardList[counter].image) {
        finalProductImage.push(this.prodBoardList[counter].image);
        counter = +4;
      }
    }
    return finalProductImage;
  }
  clearSelection() {
    this.isPriceUpdate = false;
    this.newSku = "";
  }
}