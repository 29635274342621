import { Component, OnInit } from "@angular/core";
import { EnvService } from "../../../shared/services/env.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer-mobile",
  templateUrl: "./footer-mobile.component.html",
  styleUrls: ["./footer-mobile.component.less"],
})
export class FooterMobileComponent implements OnInit {
  currentYear = new Date().getFullYear();
  constructor(public env: EnvService) {}

  ngOnInit() {}
  goBlog() {
    window.open("https://blog.seededhome.com", "_self");
  }
}
