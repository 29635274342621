import { Options } from "@angular-slider/ngx-slider";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.less"],
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {
  currentPage = 10;
  isHandset = false;
  logoPath: any;
  homeList: { name: string; image: string; value: string }[];
  stageList: { name: string; image: string; value: string }[];
  location = "";
  home_type = "";
  people = "";
  household = "";
  priority = "";
  stage = "";
  budgetValue: number = 2;

  budgetOptions: Options = {
    showTicksValues: true,
    stepsArray: [
      {
        value: 1,
        legend: `
        <div class="c-grey">|</div>
        
        <div class="f-20">$2,500 <br/> or less</div>`,
      },

      {
        value: 2,
        legend: `
        <div class="c-grey">|</div>
      
      <div class="f-20">$5,000</div>`,
      },

      {
        value: 3,
        legend: `
        <div class="c-grey">|</div>
      
      <div class="f-20">$7,500</div>`,
      },
      {
        value: 4,
        legend: `
        <div class="c-grey">|</div>
        
        <div class="f-20">$10,000 <br/> or more</div>`,
      },
    ],
  };
  adults_count: number;
  children_count: number;
  pets: string[] = [];

  petsList: any[] = [
    { name: "None", disable: false },
    { name: "Dogs", disable: false },
    { name: "Cats", disable: false },
    { name: "Other", disable: false },
  ];

  projectId: any;
  quizId: string;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private env: EnvService,
    private router: Router,
    private api: ApiService,
    private actRouter: ActivatedRoute
  ) {
    this.quizId = this.api.quizId;
    this.logoPath = this.env.logoPathBlack;
  }

  ngOnInit(): void {
    this.projectId = this.actRouter.snapshot.params.bookingId;

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
        if (this.isHandset) {
          this.budgetOptions = {
            vertical: true,
            showTicksValues: true,
            stepsArray: [
              {
                value: 1,
                legend: `
            
            <div class="f-20-mob">$2,500 <br/> or less</div>`,
              },

              {
                value: 2,
                legend: `
          
          <div class="f-20-mob">$5,000</div>`,
              },

              {
                value: 3,
                legend: `
          
          <div class="f-20-mob">$7,500</div>`,
              },
              {
                value: 4,
                legend: `
            
            <div class="f-20-mob">$10,000 <br/> or more</div>`,
              },
            ],
          };
        }
      });
    this.loadList();
  }
  loadList() {
    this.homeList = [
      {
        name: "Urban House",
        image: this.env.assetBaseUrl + "site/quiz/034-duplex.png",
        value: "urban_home",
      },
      {
        name: "Apartment",
        value: "apartment",
        image: this.env.assetBaseUrl + "site/quiz/035-apartment.png",
      },
      {
        name: "Suburban House",
        value: "suburban_home",
        image: this.env.assetBaseUrl + "site/quiz/036-house.png",
      },
      {
        name: "Farmhouse/Lodge",
        image: this.env.assetBaseUrl + "site/quiz/037-farmhouse.png",
        value: "country",
      },
    ];
    this.stageList = [
      {
        name: "Empty Room",
        image: this.env.assetBaseUrl + "site/quiz/043-floor.png",
        value: "early",
      },
      {
        name: "Partially There",
        image: this.env.assetBaseUrl + "site/quiz/045-hotel.png",
        value: "midway",
      },
      {
        name: "Touch Ups/Refresh",
        image: this.env.assetBaseUrl + "site/quiz/044-room.png",
        value: "late",
      },
    ];
  }
  get deviceHeight() {
    return window.innerHeight;
  }

  ngAfterViewInit(): void {
    this.hideIntercomIcon();
  }

  petChange(e:any){
    console.log(this.pets,'event')
    if(e.value.includes('None')){
      this.pets = ['None'];
      this.petsList.forEach(f=>{
        if(f.name!=='None')
        {
          f.disable = true;
        }
      })
    } else {
      this.petsList.forEach(f=>{
        if(f.name=='None')
        {
          f.disable = true;
        } else {
          f.disable = false;

        }
      })
    }
    if(this.pets.length===0){
      this.petsList.forEach(f=>{
       f.disable = false
      })
    }
    // if(this.pets)
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
  }

  goToPage13() {
    this.currentPage = 13;
  }
  goToPage12() {
    this.currentPage = 12;
  }
  goToPage14() {
    this.finalSubmit();
  }

  selectLivingStyle(name, i) {
    if (this.currentPage === 10) {
      this.home_type = name;
      this.currentPage = 11;
    } else if (this.currentPage === 12) {
      this.stage = name;
      this.currentPage = 13;
    }
    window.scrollTo(0, 0);
  }

  finalSubmit() {
    let obj = {
      quiz_id: this.quizId,
      user_responses: {
        home_type: this.home_type,
        children_count: this.children_count,
        adults_count: this.adults_count,
        pets: this.pets ? this.pets.join(",") : "",
        stage: this.stage,
        budget:
          this.budgetValue === 1
            ? "2500"
            : this.budgetValue === 2
            ? "5000"
            : this.budgetValue === 3
            ? "7500"
            : this.budgetValue === 4
            ? "10000"
            : "0",
      },
    };
    this.api.saveQuizLifestyle(obj).subscribe((res: any) => {
      // const url = `products/custom/${this.quizId}`;
      // this.router.navigateByUrl(url);
      this.router.navigate([`/design/book/payment/${this.projectId}`]);
    });
  }
  Back() {
    if (this.currentPage === 10) {
      this.router.navigate([`/style-quiz/my-style/${this.quizId}`]);
      // if (this.isLoggedIn) {
      //   this.currentPage = 7;
      // } else {
      //   this.currentPage = 8;
      // }
    } else {
      this.currentPage = this.currentPage - 1;
    }
  }
  get isLoggedIn(): boolean {
    return (
      JSON.parse(localStorage.getItem("user")) &&
      JSON.parse(localStorage.getItem("user")).email.length > 0
    );
  }
}
