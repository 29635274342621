import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { AuthModule } from '../core/components/auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatProductsComponent } from './chat-products/chat-products.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatGenomesComponent } from './chat-genomes/chat-genomes.component';
import { ChatGenomesMobileComponent } from './chat-genomes-mobile/chat-genomes-mobile.component'; 
import { MarkdownModule } from 'ngx-markdown';


@NgModule({
  declarations: [
    ChatComponent,
    ChatProductsComponent,
    ChatGenomesComponent,
    ChatGenomesMobileComponent, 
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MarkdownModule.forRoot()
  ]
})
export class ChatModule { }
