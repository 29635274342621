import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'; 
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialogUtilsService } from 'src/app/shared/services/matDialogUtils/matDialogUtils.service';

@Component({
    selector: 'app-wishlist-login-prompt-modal',
    templateUrl: './wishlist-login-prompt-modal.component.html',
    styleUrls: ['./wishlist-login-prompt-modal.component.less']
})
export class WishlistLoginPromptModalComponent implements OnInit {
    product: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private matDialog: MatDialogUtilsService,
        private dialogRef: MatDialogRef<WishlistLoginPromptModalComponent>,
        // tslint:disable-next-line:variable-name
        private _snackBar: MatSnackBar
    ) {
        this.product = data.product;
    }

    ngOnInit() {
    }

    openSignupModal() {
        this.matDialog.openSignupDialog(this.data.isHandset);
    }

    openSignInModal() {
        const width = this.data.isHandset ? '90%' : '35%';
        this.matDialog.openSigninDialog(width);
    }

    setPermanentClose() {
        this._snackBar.openFromComponent(CustomSnackBarComponent, {
            duration: 1000,
            panelClass: 'snackbar-panel',
        });
        localStorage.setItem('login-prompt-never-show', '1');
        this.dialogRef.close();
    }
}

/**
 * Custom Snackbar Component
 */
@Component({
    selector: 'app-custom-snack-bar',
    templateUrl: 'custom-snack-bar.html',
    styles: [`
        ::ng-deep.mat-snack-bar-container.snackbar-panel{
            background: #0c1c31;
            color: #ffcc00;
            min-width: 0;
        }
    `],
})
export class CustomSnackBarComponent {
}

