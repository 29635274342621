import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-eligible-dialog',
  templateUrl: './not-eligible-dialog.component.html',
  styleUrls: ['./not-eligible-dialog.component.less']
})
export class NotEligibleDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
