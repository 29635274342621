import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "./../../../shared/services";
import AOS from "aos";
import { EnvService } from "src/app/shared/services/env.service";
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import { ChatService } from "src/app/shared/services/api/chat.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.less"],
})
export class BannerComponent implements OnInit, AfterViewInit {
  bannerData: any[] = [];
  isBanner = true;
  banner = new Banner();
  @Input() isHandset = false;
  @Input() page = "home";
  brands: any[];
  featuredBrands: any[];
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  email: string;

  EMAIL_REGEX =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
  invalidEmail: boolean;
  message: any;
  currentPage: string;
  blackSaleBanner: any[] = [];
  faqList: any[] = [
    {
      ques: "What can I expect from the Seeded design process?",
      ans: `With our Full Service design process, you will be paired with a professional interior designer to design your living space.
     Through the design process, you will receive:`,
      showAns: false,
    },
    {
      ques: "Why do I have to take a quiz to shop products at Seeded?",
      ans: `Our AI powered style quiz is designed to extract your personal 
  style tastes and generate your personalized shopping feed. 
  Once you have completed the quiz, all shopping lists on our site are curated to 
  rank products matching your style the highest.`,
      showAns: false,
    },
    {
      ques: "How much do your design services cost?",
      ans: `Our design service is offered completely complimentary without any fees 
  specifically associated to the design service.`,
      showAns: false,
    },
    {
      ques: "How do I get help with my furniture order after I have made a purchase?",
      ans: `Orders placed with Seeded are routed to sellers immediately and fulfilled directly 
  by the seller using in-house or outsourced logistics partners.
   Our concierge team monitors your orders throughout
   its journey and makes sure everything runs smoothly in the background.`,
      showAns: false,
    },
  ];
  isToggleChecked = true;
  @ViewChild("target", { static: false }) targetElement: any;
  query: any;
  promptSearchList: any[]=[];
  categoriesList:any[]=[
    
  ]
  constructor(
    private apiService: ApiService,
    private router: Router,
    public env: EnvService,
    private chatService: ChatService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
    this.currentPage = location.pathname;
  }
  get elementHeight(): any {
    if (this.targetElement && this.targetElement.nativeElement) {
      const height = this.targetElement.nativeElement.offsetHeight;
      return { height: height + "px" };
    }
    return { height: "auto" };
  }

  get height():number{
    return window.innerHeight-60;
  }
  ngOnInit() {
    AOS.init();
    if (
      location.href.includes(this.env.siteUrl) ||
      location.href.includes("localhost")
    ) {
      this.banner.image =
        this.env.assetBaseUrl + "site/banners/test/landing-d-light.jpeg";
    } else {
      this.banner.image =
        this.env.assetBaseUrl + "site/banners/landing-d-light.jpeg";
    }
    if (this.page === "shop" || this.currentPage === "/") {
      this.apiService.bannerData().subscribe((res: any[]) => {
        this.bannerData = res.filter((value) => value.position === "primary");
        this.blackSaleBanner = res.filter((value) => value.position === "hero");
        this.apiService.blackSaleBanner = this.blackSaleBanner;
      });
    }

  }
  get envirnoment(): boolean {
    return (
      location.href.includes(this.env.siteUrl) ||
      location.href.includes("localhost")
    );
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      // this.getbrands();
      this.getPromptsSearch();
    }, 2500);
    document.addEventListener("DOMContentLoaded", function() {
      const textarea = document.getElementById("chatInput");
  
      textarea.addEventListener("input", function() {
          this.style.height = "auto";
          this.style.height = (this.scrollHeight - 20) + "px";
      });
  });
  }
  getbrands() {
    this.apiService.getBrands().subscribe((res: any) => {
      this.brands = res;
      this.brands = this.brands.filter((val) => {
        if (val.value !== "potterybarn") {
          return val;
        }
      });
      this.featuredBrands = this.brands.filter((_) => _.is_home_feature === 1);
    });
  }
  routeToSale() {
    // this.router.navigate(['/products/all'], { queryParams: { sale: true } });
    this.router.navigate(["/products/sale/cyber-week"]);
  }
  navTo(url) {
    window.open(url, "_self");
  }
  submit(data) {
    if (this.EMAIL_REGEX.test(data)) {
      this.invalidEmail = false;
      const url = window.location.href.split("?")[0];
      this.apiService.subscription(url, data).subscribe((response: any) => {
        this.message = response.message;
        if (!response.subscribed) {
          this.apiService
            .newsLetterSubmit(this.email)
            .subscribe((response: any) => {});
        }
        // if (payload.status === 'We already have your email. Thanks!') {
        //   this.subscriberExists = true;
        // } else if (payload.status === 'success') {
        //   this.showSuccessMsg = true;
        // }
      });
    } else {
      this.invalidEmail = true;
    }
  }
  goToQuiz() {
    this.router.navigate(["/style-quiz"]);
  }
  goToAppointments() {
    this.router.navigate(["/design"]);
  }
  getPromptsSearch(){
    this.chatService.getPromptSearch().subscribe((resp:any[])=>{
      this.promptSearchList = resp.find(f=>f.type==='primary').prompt_details;
      this.categoriesList = resp.find(f=>f.type==='secondary').prompt_details;
    })
  }
  assignQuery() {
    if (this.isToggleChecked) { 
      this.chatService.query = this.query;
      this.router.navigate(["/chat"]);
    } else { 
      const data = `?query=${this.query}`
      this.router.navigateByUrl(`/search${data}`).then(() => {
        this.query = "";
      });
    }
  }
  sampleChat(data) {
    localStorage.removeItem('recentChat');
    this.chatService.query = data;
    this.router.navigate(["/chat"]);
  }
}
export class Banner {
  "name": string;
  "image": string;
  "link": string;
  "link_label": string;
  "banner_label": string;
  "font_hex": string;
  "position": string;
  "rank": number;
  "link_type": string;
}
