import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs'; 
import { EnvService } from '../../../../shared/services/env.service';

@Component({
  selector: 'app-not-available-dialog',
  templateUrl: './not-available-dialog.component.html',
  styleUrls: ['./not-available-dialog.component.less']
})
export class NotAvailableDialogComponent implements OnInit {
  naItemsList:any[]=[];
  isHandSet: boolean;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
); 
bpSubscription: Subscription;
  constructor(public dialogRef: MatDialogRef<NotAvailableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private router:Router,
    public env:EnvService,
    private breakpointObserver: BreakpointObserver,) { }

  ngOnInit() {
    this.naItemsList=this.data;
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
          this.isHandSet = handset.matches;
      }
  );
  }
  decodeHtml(text: string): string {
    const htmlComponent = document.createElement('textarea');
    htmlComponent.innerHTML = text;
    return htmlComponent.value;
  }
  cancel(){
    this.router.navigate(['/cart']);
    this.dialogRef.close();
  }
}
