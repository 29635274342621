import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { environment as env } from "src/environments/environment";
import { of } from "rxjs";
import { delay, retry } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  query = "";
  quizCategory: string;
  shuffledImages: any[] = [];
  constructor(private cookie: CookieService, private httpService: HttpService) {
    // env.API_BASE_HREF = 'https://seededhome.com/'
  }

  chatRequest(data: any) {
    const url = `${env.API_BASE_HREF}chat`;
    return this.httpService.post(url, data);
    // return this.httpService.get("/assets/chat.json");
    // return this.httpService.get('/assets/products.json')
  }
  viewAllConversations() {
    const url = `${env.API_BASE_HREF}conversations/user_conv_history_detail`;
    return this.httpService.get(url);
  }
  getUserConvSummary() {
    const url = `${env.API_BASE_HREF}conversations/user_conv_history_summary`;
    return this.httpService.get(url);
  }
  viewConversationsById(id) {
    const url = `${env.API_BASE_HREF}conversations/conv_id_history?conversation_id=${id}`;
    return this.httpService.get(url);
  }
  deleteConversationsById(id) {
    const url = `${env.API_BASE_HREF}conversation?conversation_id=${id}`;
    return this.httpService.delete(url);
  }
  getPromptSearch() {
    const url = `${env.API_BASE_HREF}prompts_search`;
    return this.httpService.get(url);
  }
  getPinboardList(id: string) {
    const url = `${env.API_BASE_HREF}get_pinboardlist?conversation_id=${id}`;
    return this.httpService.get(url);
  }
  getProductBySku(sku: string): Promise<any> {
    const url = `${env.API_BASE_HREF}product-microdata/${sku}`;
    return this.httpService.get(url).toPromise();
  }
}
