import { Pipe, PipeTransform } from '@angular/core';
import {formatCurrency, getLocaleCurrencySymbol} from '@angular/common';

@Pipe({
  name: 'americanCurrencyCustomFormat'
})
export class AmericanCurrencyCustomFormatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const quotient = Math.floor(value);
    const remainder = Number((value - quotient).toPrecision(2));
    if (remainder === 0) {
      return this.formatUSCurrency(quotient, '1.0-0');
    } else {
      return this.formatUSCurrency(value, '1.2-2');
    }
  }
  formatUSCurrency(price: number, decimals) {
    return formatCurrency(price, 'en-US', getLocaleCurrencySymbol('en-US'), 'en-US', decimals);
  }

}
