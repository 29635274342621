import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";
import { EnvService } from "../../shared/services/env.service";

@Component({
  selector: "app-design-mobile",
  templateUrl: "./design-mobile.component.html",
  styleUrls: ["./design-mobile.component.less"],
})
export class DesignMobileComponent implements OnInit {
  designProcess = [
    {
      id: 1,
      header: "Discovery Call",
      subHeader: "We want to get to know you!",
      content: [
        "We’ll ask a few basic questions to learn more about you.",
        "We'll walk you through the process so you know what to expect.",
        " We will match you with the perfect designer that fits your needs.",
      ],
    },

    {
      id: 2,
      header: "Design Proposal",
      subHeader: "Meet your design professional!",
      content: [
        "Develop concept to next level of detail",
        "Ideal specifications of each furniture piece",
        "Detail information on finishes, fabrics and products",
        "Visualize your designs with sample 2D boards",
      ],
    },

    {
      id: 3,
      header: "Design Development",
      subHeader: "Style your perfect space together!",
      content: [
        "Visualize your space with 3D Renderings",
        " Detail oriented information of finishes, fabrics and products by room",
        "Coordination with vendors/contractors",
      ],
    },

    {
      id: 4,
      header: "Make it Yours",
      subHeader: "Bring it all home",
      content: [
        "Complete checkout for full set in one place",
        "Enjoy up to 15% off your orders with your design credits",
        "Let us take care of coordinating order tracking and delivery",
        "Post installation walkthrough to help with finishing touches",
      ],
    },
  ];
  lsData = [
    { benefit: "Accredited Design Professionals", ls: 1, discount: 0 },
    { benefit: "Curated luxury desginer brands", ls: 1, discount: 0 },
    { benefit: "Realistic 3D models to scale", ls: 1, discount: 0 },
    { benefit: "Seamless integrated ordering ", ls: 1, discount: 0 },
    { benefit: "Discounted pricing on furniture", ls: 1, discount: 0 },
    { benefit: "Complimentary white glove delivery", ls: 1, discount: 0 },
  ];
  slideIndex = 0;
  profileList: any[]=[];
  designImagesList: any[]=[];
  slideIndex2: number;
  carousalOptions={
    autoWidth: false,
    loop: true,
    margin: 10,
    items: 1.3,
    center: false,
    dots: false,
    pagination: false,
    responsiveRefreshRate: 200};
carousalOptions1:any;
  isLoggedIn: boolean;
  public images = [
    'assets/image/c1.jpg',
    'assets/image/c2.jpg'
  ]
  constructor(private apiService:ApiService,private router:Router,public env: EnvService) {}

  ngOnInit() {
    this.getDesigners();
    // this.getDesignImages();
    this.isLoggedIn = (JSON.parse(localStorage.getItem('user')).email).length >0;
  }
  getDesigners() {
    this.apiService.getDesignProfile().subscribe((res: any) => { 
      this.carousalOptions = {
        autoWidth: false,
        loop: true,
        margin: 10,
        items: 1.3,
        center: false,
        dots: false,
        pagination: false,
        responsiveRefreshRate: 200,
        // responsiveClass:true,
    // responsive:{
    //     0:{
    //         items:1,
    //         nav:true
    //     },
    //     600:{
    //         items:3,
    //         nav:false
    //     },
    //     1000:{
    //         items:5,
    //         nav:true,
    //         loop:false
    //     }
      // }
    };
    this.profileList = res;
      // setTimeout(() => {
      //   // this.showSlides(0);
      // }, 0);
    });
  }
  // getDesignImages() {
  //   this.apiService.getDesignImages().subscribe((res: any) => {
  //     this.carousalOptions1={
  //         autoWidth: false,
  //         loop: true,
  //         margin: 10,
  //         items: 1.3,
  //         center: false,
  //         dots: false,
  //         pagination: false,
  //     }
  //     this.designImagesList = res;
  //     setTimeout(() => {
  //       // this.showSlides2(0);
  //     }, 0);
  //   });
  // } 
  plusSlides(n: number) {
    this.showSlides((this.slideIndex += n));
  }
  plusSlides2(n: number) {
    this.showSlides2((this.slideIndex2 += n));
  }
  goTOCons() {

    this.router.navigate(['/design/book/SydneyLedden']);
    // var my_element = document.getElementById("consult");

    // my_element.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    //   inline: "nearest",
    // });
  }
  showSlides(n) {
    let i;
    let slides = document.getElementsByClassName(
      "mySlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this.slideIndex - 1].style.display = "block";
  }
  showSlides2(n) {
    let i;
    let slides = document.getElementsByClassName(
      "mySlidesx"
    ) as HTMLCollectionOf<HTMLElement>;
    if (n > slides.length) {
      this.slideIndex2 = 1;
    }
    if (n < 1) {
      this.slideIndex2 = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this.slideIndex2 - 1].style.display = "block";
  }

  goToDesigner(p){
    this.router.navigate(['/design/book/'+p.designerId]);
  }
  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      "Hi there! I see you would like to chat with a design consultant."
    );
  }
  
}