import { LabelType, Options } from '@angular-slider/ngx-slider'; 
import { Component, DoCheck, HostListener, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService, EventEmitterService } from '../../shared/services';
import { ComponentCanDeactivate } from '../../shared/services/seller/seller.guard';
import { NoQuizDataDialogComponent } from '../no-quiz-data-dialog/no-quiz-data-dialog.component'; 
import { EnvService } from '../../shared/services/env.service';
@Component({
  selector: 'app-quiz-desktop',
  templateUrl: './quiz-desktop.component.html',
  styleUrls: ['./quiz-desktop.component.less']
})
export class QuizDesktopComponent implements OnInit, DoCheck, ComponentCanDeactivate {
  pageNo = 1;
  logoPath = '';
  seatsCount = [2, 3];
  value: number = 2;
  highValue: number = 8;
  options: Options = {
    showTicksValues: true,
    floor: 2,
    ceil: 8
  };

  comfortCount = [1, 5];
  comfortValue: number = 1;
  comfortHighValue: number = 5;
  comfortOptions: Options = {
    showTicksValues: true,
    floor: 1,
    ceil: 5
  };
  minWidth: number = 0;
  maxWidth: number = 0;
  widthoptions: Options = {
    floor: 0,
    ceil: 0, translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "''";
        case LabelType.High:
          return value + "''";
        default:
          return value + "''";
      }
    }
  };

  minDepth: number = 0;
  maxDepth: number = 0;
  depthoptions: Options = {
    floor: 0,
    ceil: 0, translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "''";
        case LabelType.High:
          return value + "''";
        default:
          return value + "''";
      }
    }
  };

  minHeight: number = 0;
  maxHeight: number = 0;
  heightoptions: Options = {
    floor: 0,
    ceil: 0, translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "''";
        case LabelType.High:
          return value + "''";
        default:
          return value + "''";
      }
    }
  };


  sectionalShapes: any[] = [];
  colorsList: any[] = [];
  stylesList: any[] = [];
  upholsteryList: any[] = [];
  upholsterySelected: boolean;
  selectedCards: any[] = [];
  sizingType: string;
  skipPage2: boolean;
  skipPage3: boolean;
  width: any[] = [];
  depth: any[] = [];
  height: any[] = [];
  pageErrorMsg: string;
  minPrice: number;
  maxPrice: number;
  originalPrice: any[] = [];
  price: any[] = [];
  priceOpt: any[] = [];
  sizingSkipped: boolean;
  productsCount: number = 0;
  email=new FormControl('',Validators.required);
  password=new FormControl('',Validators.required);
  error: boolean;
  errorMsg: string; 
  
  constructor(private api: ApiService, private router: Router, private dialog: MatDialog,
    public env: EnvService,
    private eventService: EventEmitterService) {
    this.logoPath=  this.env.logoPathBlack;

  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue = true
  }
  canDeactivate() {
    return true;

  }

  ngOnInit() {
    [1, 2, 3, 4, 5, 6].forEach(f => {
      this.selectedCards.push([])
    })
    setInterval(() => {

      this.hide();
    }, 1000);
    this.getPrice();
  }
  hide() {
    const intercom = document.getElementsByClassName('intercom-lightweight-app')[0];
    if (intercom) {
      intercom.classList.add('dn');
    }

    const intr = document.getElementsByClassName('intercom-namespace')[0];
    if (intr) {
      intr.classList.add('dn');
    }
  }
  ngDoCheck(): void {
    this.hide();
  }
  async goToPage() {
    if (this.pageNo == 2) {
      if (this.selectedCards[1].length == 0) {
        this.pageErrorMsg = 'Select at least one option to continue';
        return false;
      } else {
        this.pageErrorMsg = '';
      }
    }
    if (this.pageNo == 3) {
      if (this.selectedCards[2].length == 0) {
        this.pageErrorMsg = 'Select at least one option to continue';
        return false;
      } else {
        this.pageErrorMsg = '';
      }
    }
    if (this.pageNo == 5) {
      if (this.selectedCards[4].length == 0) {
        this.pageErrorMsg = 'Select at least one option to continue';
        return false;
      } else {
        this.pageErrorMsg = '';
      }
    }
    if (this.pageNo == 6) {
      if (this.selectedCards[5].length == 0) {
        this.pageErrorMsg = 'Select at least one option to continue';
        return false;
      } else {
        this.pageErrorMsg = '';
      }
    }
   
    if (this.pageNo == 1 && !this.seatsCount.find(f => f == 2 || f == 3)) {
      this.pageNo = 2;
      this.skipPage2 = false;   // this.skipPage2 = true;
      this.selectedCards[1] = [];
    } 
    else {
      this.skipPage2 = false;
      this.pageNo++;
    } 
    this.getShapeData(this.selectedCards[1]);
    this.getSizingData(this.selectedCards[1]);
    if (this.pageNo == 5 || this.pageNo == 6) {
      this.getProductFinder(6);
      this.getProductFinder(7);
    }
  }
  backToPage() {
    if (this.skipPage2 && this.pageNo == 3) {
      this.pageNo = 1
    } else if (this.skipPage3 && this.pageNo == 4) {
      this.pageNo = 2;
    } else {
      this.pageNo--;
    }
  }
  async getProductFinder(no: number) {
    await this.api.getProductFinder(no).subscribe((resp: any) => {
      switch (no) {

        case 6:
          this.stylesList = resp.sort((a, b) => +a.rank - +b.rank);
          break;
        case 7:
          this.colorsList = resp.sort((a, b) => +a.rank - +b.rank);
          break;
        default:
          break;
      }
    })
  }
  clearFabric() {
    this.upholsterySelected = false;
    this.selectedCards[3] = [];
  }
  submit() {
    if (this.pageNo == 8) {
      if (this.priceOpt.length == 0) {
        this.pageErrorMsg = 'Select at least one option to continue';
        return false;
      } else {
        this.pageErrorMsg = '';
      }
    }
    let data = new QuizData();
    const num = [];
    for (let i = +this.seatsCount[0]; i <= +this.seatsCount[1]; i++) {
      num.push(i);
    }
    data.seats = num.join(',');
    const comNum = [];
    for (let i = +this.comfortCount[0]; i <= +this.comfortCount[1]; i++) {
      comNum.push(i);
    }
    data.firmness = comNum.join(',');
    let brwsData = '';
    // if (this.selectedCards[1].length > 0) {
    //   let brwsData = '';
    //   let type = this.selectedCards[1];
    //   if (type.length == 2) {
    //     brwsData = '201,202,203,204,205,206'
    //   } else if (type.length == 1 && type[0] == 'sofas') {
    //     brwsData = '201,202,203'
    //   } else if (type.length == 1 && type[0] == 'sectionals') {
    //     brwsData = '204,205,206'
    //   }
    //   brwsData = '201,202,203,204,205,206'
    //   data.browse = brwsData.split(',');
    // }
    brwsData = '201,202,203,204,205,206'
    data.browse = brwsData.split(',');
    data.shapes = this.selectedCards[1];
    data.fabric = this.selectedCards[2];
    data.style = this.selectedCards[4];
    data.colors = this.selectedCards[5]; 
    if(!this.sizingSkipped){
    data.sizing = {
      "min-width": this.width[0], "max-width": this.width[1],
      "min-depth": this.depth[0], "max-depth": this.depth[1],
      "min-height": this.height[0], "max-height": this.height[1]
    }
  } else {
    data.sizing = {
     "min-width": 27.5,
    "max-width": 247,
    "min-depth": 21.5,
    "max-depth": 166,
    "min-height": 16.5,
    "max-height": 42
    }
  }
    if (this.priceOpt.length == 3) {
      data.price = {
        "max_price": this.price[1],
        "min_price": this.price[0]
      }
    } else if (this.priceOpt.length == 1) {
      // if (this.priceOpt[0] == 'budget')
        data.price = {
          "max_price": this.priceOpt[0] == 'budget' ? 2000 : this.priceOpt[0] == 'standard' ? 5000 : this.priceOpt[0] == 'premium' ? this.price[1] : this.price[1],
          "min_price": this.priceOpt[0] == 'budget' ? this.price[0] : this.priceOpt[0] == 'standard' ? 2000 : this.priceOpt[0] == 'premium' ? 5000 : this.price[0],
        }
    } else if (this.priceOpt.length == 2) {
      if ((this.priceOpt[0] == 'budget' && this.priceOpt[1] == 'standard')|| (this.priceOpt[1] == 'budget' && this.priceOpt[0] == 'standard')) {
        data.price = {
          "max_price": 5000,
          "min_price": this.price[0]
        }
      } else if ((this.priceOpt[0] == 'premium' && this.priceOpt[1] == 'standard')|| (this.priceOpt[1] == 'premium' && this.priceOpt[0] == 'standard')) {
        data.price = {
          "max_price": this.price[1],
          "min_price": 2000
        }
      } else {
        data.price = {
          "max_price": this.price[1],
          "min_price": this.price[0]
        }
      }
    }
    

    this.saveQuizData(data);
    let fData = JSON.parse(JSON.stringify(data));
    delete fData.style;

    this.api.searchProductFinder(fData).then((resp: any) => {
      if (resp.products && resp.products.length > 0) {
        sessionStorage.setItem('quiz-result', JSON.stringify(data)); 
        if(JSON.parse(localStorage.getItem('user')).email){
          this.router.navigate(['/product/quiz-result']);
        } else{
        this.pageNo = -1;
        this.productsCount = resp.total;
        }
      } else {
        this.dialog.open(NoQuizDataDialogComponent, {
          disableClose: true,
          width: '700px',
        }).afterClosed().subscribe(resp => {
          if (resp === 'retake') {
            sessionStorage.removeItem('quiz-result');
            this.pageNo = 1;
            this.selectedCards = [];
            [1, 2, 3, 4, 5, 6].forEach(f => {
              this.selectedCards.push([])
            });
            this.minWidth = 0;
            this.maxWidth = 0;
            this.widthoptions = {
              floor: 0,
              ceil: 0, translate: (value: number, label: LabelType): string => {
                switch (label) {
                  case LabelType.Low:
                    return value + "''";
                  case LabelType.High:
                    return value + "''";
                  default:
                    return value + "''";
                }
              }
            };

            this.minDepth = 0;
            this.maxDepth = 0;
            this.depthoptions = {
              floor: 0,
              ceil: 0, translate: (value: number, label: LabelType): string => {
                switch (label) {
                  case LabelType.Low:
                    return value + "''";
                  case LabelType.High:
                    return value + "''";
                  default:
                    return value + "''";
                }
              }
            };

            this.minHeight = 0;
            this.maxHeight = 0;
            this.heightoptions = {
              floor: 0,
              ceil: 0, translate: (value: number, label: LabelType): string => {
                switch (label) {
                  case LabelType.Low:
                    return value + "''";
                  case LabelType.High:
                    return value + "''";
                  default:
                    return value + "''";
                }
              }
            };
            this.seatsCount = [2, 3];
            this.comfortCount = [1, 5]
          } else {
            const allData = {
              "browse": [
                "201",
                "202",
                "203",
                "204",
                "205",
                "206"
              ],
              "pageno": 0,
              "firmness": "1,2,3,4,5",
              "sort_type": "recommended",
              "seats": "2,3,4,5,6,7,8",
              "shapes": [
                "Rectangular",
                "Armless",
                "Curved",
                "Chaise: Left-Arm",
                "Reversible",
                "U-shape",
                "L-shape",
                "Chaise: Right-Arm"
              ],
              "fabric": [
                "fabric",
                "leather"
              ],
              "colors": [
                "Beige",
                "Black",
                "Blue",
                "Brown",
                "Gold",
                "Gray",
                "Green",
                "Natural",
                "Orange",
                "Pink",
                "Red",
                "Silver",
                "Tan",
                "White",
                "Yellow"
              ],
              "style": [
                "Minimalist",
                "Contemporary",
                "Mid-Century",
                "Coastal",
                "Scandinavian",
                "Art Deco",
                "Country & Farmhouse",
                "Industrial",
                "Vintage 70s",
                "French",
                "Italian",
                "Classic",
                "Bohemian",
                "Safari",
                "Maximalist"
              ],
              "sizing": {
                "min-width": 27.5,
                "max-width": 247,
                "min-depth": 21.5,
                "max-depth": 166,
                "min-height": 16.5,
                "max-height": 42
              },
              "price":{
                "min_price":this.price[0],
                "max_price":this.price[1]
              }
            }
            sessionStorage.setItem('quiz-result', JSON.stringify(allData));
            this.router.navigate(['/product/quiz-result']);
          }
        })
      }
    })


  }
  submitFinal(){
    this.router.navigate(['/product/quiz-result']);
  }
  notify(){
    if( this.email.valid && this.password.valid){
      const formData: any = new FormData();
      formData.append('name', '');
      formData.append('email', this.email.value);
      formData.append('password', this.password.value);
      formData.append('c_password', this.password.value);
      this.api.signup(formData).subscribe(
        (payload: any) => {
          if (payload.success) { 
            this.error = false;
            this.eventService.fetchUser(
              payload.success.token,
              payload.success.user
            ); 
            this.router.navigate(['/product/quiz-result']);
          }
        },
        (error: any) => {
          if (error.error.error.email) {
            this.error = true;
            const formData: any = new FormData();
            formData.append('email', this.email.value);
            formData.append('password', this.password.value);
            this.api.login(formData).subscribe(
                (payload: any) => {
                    if (payload.success) {
                        this.error = false;
                        this.eventService.fetchUser(payload.success.token, payload.user); 
                        this.router.navigate(['/product/quiz-result']);
                    } else {
                        this.handleError(payload);
                    }
                },
                (payload: any) => this.handleError(payload.error)
            ); 
            return false;
          }
        }
      );
    }
   
  }
  handleError(payload: any) {
    this.error = true;
    this.errorMsg = payload.error;
}
  assignPrice(e: any, type: string) {
    if (e.checked) {
      this.priceOpt.push(type);
    } else {
      const pType = this.priceOpt.findIndex(f => f == type);
      if (pType > -1) {
        this.priceOpt.splice(pType, 1);
      }
    }
  }

  saveQuizData(data: any) {
    let apiData = {
      "min_seat": data.seats.charAt(0),
      "max_seat": data.seats.charAt(data.seats.length - 1),
      "shapes": data.shapes.join(','),
      "upholstery": data.fabric.join(','),
      "min_comfort": data.firmness.charAt(0),
      "max_comfort": data.firmness.charAt(data.firmness.length - 1),
      "styles": data.style.join(','),
      "colors": data.colors.join(','),
      "min_width": data.sizing['min-width'],
      "max_width": data.sizing['max-width'],
      "min_depth": data.sizing['min-depth'],
      "max_depth": data.sizing['max-depth'],
      "min_height": data.sizing['min-height'],
      "max_height": data.sizing['max-height'],
      "min_price":data.price.min_price,
      "max_price":data.price.max_price
    }
    this.api.saveQuizData(apiData).subscribe(resp => {

    })
  }

  showUpholstery() {
    this.upholsterySelected = !this.upholsterySelected;
    this.selectedCards[3] = [];
    if (this.upholsterySelected) {
      this.api.getProductFinder(4).subscribe((resp: any) => {
        this.upholsteryList = resp;
      });
    }
  }
  getShapeData(type: any[]) {
    let data = '201,202,203,204,205,206';
    let final = { ls_id: data }
    this.api.getShapeData(final).subscribe((resp: any) => {
      this.sectionalShapes = resp.sort((a, b) => +a.rank - +b.rank);
    });
  }
  getSizingData(type: any[]) {
    let data = '201,202,203,204,205,206';
    let final = { ls_id: data }
    this.api.getSizingData(final).subscribe((resp: any) => {
      const dims = resp;
      if (dims.dim_width) {
        this.minWidth = dims.dim_width.from > 0 ? dims.dim_width.from : dims.dim_width.min;
        this.maxWidth = dims.dim_width.to ? dims.dim_width.to : dims.dim_width.max;
        this.widthoptions.floor = dims.dim_width.min;
        this.widthoptions.ceil = dims.dim_width.max;
      } else {
        this.minWidth = 0;
        this.maxWidth = 0;
        this.widthoptions.floor = 0;
        this.widthoptions.ceil = 0;
      }

      if (dims.dim_height) {
        this.minHeight = dims.dim_height.from > 0 ? dims.dim_height.from : dims.dim_height.min;
        this.maxHeight = dims.dim_height.to ? dims.dim_height.to : dims.dim_height.max;
        this.heightoptions.floor = dims.dim_height.min;
        this.heightoptions.ceil = Math.floor(dims.dim_height.max);
      } else {
        this.minHeight = 0;
        this.maxHeight = 0;
        this.heightoptions.floor = 0;
        this.heightoptions.ceil = 0;
      }

      if (dims.dim_depth) {
        this.minDepth = dims.dim_depth.from > 0 ? dims.dim_depth.from : dims.dim_depth.min;
        this.maxDepth = dims.dim_depth.to > 0 ? dims.dim_depth.to : dims.dim_depth.max;
        this.depthoptions.floor = dims.dim_depth.min
        this.depthoptions.ceil = dims.dim_depth.max
      } else {
        this.minDepth = 0;
        this.maxDepth = 0;
        this.depthoptions.floor = 0;
        this.depthoptions.ceil = 0;
      }
      this.width.push(this.minWidth);
      this.width.push(this.maxWidth);

      this.depth.push(this.minDepth);
      this.depth.push(this.maxDepth);

      this.height.push(this.minHeight);
      this.height.push(this.maxHeight);
      localStorage.setItem('dims', JSON.stringify({ width: this.width, depth: this.depth, height: this.height }))
    });
  }
  getPreSizingData(type: string) {
    this.sizingType = type;
    this.sizingSkipped = false;
    if (type == 'apartment') {
      this.minWidth = 60;
      this.maxWidth = 80;
      this.minHeight = this.heightoptions.floor;
      this.maxHeight = this.heightoptions.ceil;
      this.minDepth = 34;
      this.maxDepth = 38;

    } else if (type == 'livingRoom') {
      this.minWidth = 80;
      this.maxWidth = 110;
      this.minHeight = this.heightoptions.floor;
      this.maxHeight = this.heightoptions.ceil;
      this.minDepth = this.depthoptions.floor;
      this.maxDepth = this.depthoptions.ceil;
    } else if (type == 'familyRoom') {
      this.minWidth = 100;
      this.maxWidth = this.widthoptions.ceil;
      this.minHeight = this.heightoptions.floor;
      this.maxHeight = this.heightoptions.ceil;
      this.minDepth = this.depthoptions.floor;
      this.maxDepth = this.depthoptions.ceil;
    }
    this.width=[];
    this.depth=[];
    this.height=[];
    this.width.push(this.minWidth); 
    this.width.push(this.maxWidth);
    this.depth.push(this.minDepth);
    this.depth.push(this.maxDepth);
    this.height.push(this.minHeight);
    this.height.push(this.maxHeight);
  }
  selectCard(type: string) {
    const index = this.selectedCards[this.pageNo - 1].findIndex(f => f == type);
    if (index > -1) {
      this.selectedCards[this.pageNo - 1].splice(index, 1)
    } else {
      this.selectedCards[this.pageNo - 1].push(type);
    }
  }
  selectAll(type: string) {
    this.selectedCards[this.pageNo - 1] = [];
    if (type == 'styles') {
      for (let i = 0; i < this.stylesList.length; i++) {
        this.selectedCards[this.pageNo - 1].push(this.stylesList[i].filter_value)
      }
    }
    if (type == 'colors') {
      for (let i = 0; i < this.colorsList.length; i++) {
        this.selectedCards[this.pageNo - 1].push(this.colorsList[i].filter_value)
      }
    }
  }
  clearAll() {
    this.selectedCards[this.pageNo - 1] = [];
  }
  getSelectionHighlight(type: string): string {
    if (this.selectedCards[this.pageNo - 1]) {
      const index = this.selectedCards[this.pageNo - 1].findIndex(f => f == type);
      if (index > -1) {
        return 'selected';
      } else {
        if (type == 'fabric' || type == 'leather') {
          return 'circle mr-25';
        } else {
          return 'circle circle-x mr-25';
        }

      }
    }
  }
  getColorSelectionHighlight(type: string): string {
    if (this.selectedCards[this.pageNo - 1]) {
      const index = this.selectedCards[this.pageNo - 1].findIndex(f => f == type);
      if (index > -1) {
        return 'highlight-border highlight-border-x';
      } else {
        return 'circle mr-25 circle-x unselected';
      }
    }
  }
  showEverything(){
    this.sizingSkipped =true;
    this.goToPage();
  }
  getPrice() {
    this.api.getPricingData({ "ls_id": "201,202,203,204,205,206" }).subscribe((resp: any) => {
      // const dims = resp.dimension;
      const price = resp;
      if (price) {
        this.minPrice = Math.round(price.min / 10) * 10; //Math.round(price.min);
        this.maxPrice = Math.round(price.max / 10) * 10;// Math.round(price.max); 
        this.price.push(this.minPrice);
        this.price.push(this.maxPrice);
        this.originalPrice = JSON.parse(JSON.stringify(this.price));
      }
    });
    this.priceOpt.push('budget','standard','premium');
  }
}
export class QuizData {
  seats: string;
  browse: string[] = ['201,202,203,204,205,206'];// need to have is_id value
  shapes: string[];
  fabric: string[];
  style: string[];
  sizing: any;
  price: Price;
  colors: string[];
  pageno: number = 0;
  firmness: string = '';
  sort_type: string = 'recommended';
  price_from: number;
  price_to: number;
}
export class Price {
  min_price: number;
  max_price: number
}