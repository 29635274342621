import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  AfterViewInit,
  HostListener,
  AfterViewChecked,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import imageResponseMock from "./imageResponseMock.json";
import { Observable, Subscription } from "rxjs";
import { ApiService, MatDialogUtilsService, UtilsService } from "../../shared/services";
import { EnvService } from "../../shared/services/env.service";
import { Options } from "ng5-slider";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { PixelService } from "src/app/shared/services/facebook-pixel/pixel.service";

@Component({
  selector: "app-shoppable-image",
  templateUrl: "./shoppable-image.component.html",
  styleUrls: ["./shoppable-image.component.less"],
})
export class ShoppableImageComponent implements OnInit, AfterViewChecked {
  @ViewChild("shoppableImage", { static: false }) shoppableImage: ElementRef;
  // shoppableImage: any;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset = false;
  showMarker = true;
  creditsRemain = 5;
  imageResponse: any = [];
  imageHeight: number = 0;
  imageWidth: number = 0;
  itemCoords: number[][] = [];
  dotCoords: number[][] = [];
  dotSize: number = 16;
  items: string[] = [];
  currHeight: number;
  currWidth: number;
  shoppableImageUrl: string;
  editBoard: boolean;
  productList: any[] = [];
  pinBoardList: any[] = [];
  style: any;
  latestPins: any[];
  pinExpanded: boolean;
  throttle = 700;
  scrollDistance = 1;
  scrollUpDistance = 2;
  item: any;
  pageNo: number;
  imageId: string;
  isLoggedIn: boolean;
  boardName: any;
  showLoader: boolean = true;
  progress: number;
  imgPredictions: any;
  color: string;
  shape: string;
  filterData: any;
  sliderOptions: Options = {
    step: 1,
  };
  priceFilter = {
    name: "price",
    from: 100,
    to: 600,
    min: 100,
    max: 600,
  };
  widthFilter = {};
  heightFilter = {};
  lengthFilter = {};
  depthFilter = {};
  diameterFilter = {};

  diameter_from: any;
  diameter_to: any;
  width_from: any;
  width_to: any;
  height_from: any;
  height_to: any;
  length_from: any;
  length_to: any;
  depth_from: any;
  depth_to: any;
  price_from: any;
  price_to: any;
  originalFilterData: any;
  showBottomPanel = true;
  filteredElements: string[] = [];
  defaultFilters = ["color", "shape", "style"];
  cartProduct: number;
  errorMessage: string;
  oldBoardName: any;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private matUtils: UtilsService,
    public api: ApiService,
    private breakpointObserver: BreakpointObserver,
    public env: EnvService,
    private snackBar: MatSnackBar,
    private matDialogUtils: MatDialogUtilsService,
    private pixelService: PixelService
  ) {
    setInterval(() => {
      if (this.progress < 100) {
        this.progress = this.progress + 1;
      }
    }, 1000);
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }

  ngOnInit() {
    this.cartProduct = parseInt(localStorage.getItem("cart"));
    if (JSON.parse(localStorage.getItem("user"))) {
      this.isLoggedIn =
        JSON.parse(localStorage.getItem("user")).email.length > 0;
    }
    // this.getFilters();
    const url: string = (window.location as any).pathname;
    this.style = url
      .split("gpt/")[1]
      .split("-")
      .splice(0, url.split("gpt/")[1].split("-").length - 1)
      .join(" ");
    this.boardName = this.style;
    this.oldBoardName = this.style;
    this.getShopImage();
  }

 get isUserLoggedIn():boolean {
    return JSON.parse(localStorage.getItem("user")).email && JSON.parse(localStorage.getItem("user")).email.length > 0;
  }

  ngAfterViewChecked() {}

  @HostListener("window:resize")
  onWindowResize() {
    this.handleResize();
  }

  handleResize() {
    if (this.shoppableImageUrl) {
      this.updateImageDimensions(); // Update on window resize
      this.processImageResponse("resize");
    }
  }
  getFilters() {
    this.api.getFilteredData("all").subscribe((res: any) => {
      console.log(res, "res");
      this.filterData = res.filterData;
      this.originalFilterData = JSON.parse(JSON.stringify(this.filterData));
      this.filterData["size"] = [{ name: "size" }];
      this.priceFilter = this.filterData["price"];
      this.priceFilter["name"] = "price";
      this.diameterFilter = this.filterData["diameter"][0];
      this.diameterFilter["name"] = "diameter";
      this.widthFilter = this.filterData["width"][0];
      this.heightFilter = this.filterData["height"][0];
      this.lengthFilter = this.filterData["length"][0];
      this.depthFilter = this.filterData["depth"][0];
    });
  }
  assignSliderData(event, name) { 
    if (name == "diameter") {
      this.diameter_from = event.minValue;
      this.diameter_to = event.maxValue;
    } else if (name == "width") {
      this.width_from = event.minValue;
      this.width_to = event.maxValue;
    } else if (name == "height") {
      this.height_from = event.minValue;
      this.height_to = event.maxValue;
    } else if (name == "length") {
      this.length_from = event.minValue;
      this.length_to = event.maxValue;
    } else if (name == "depth") {
      this.depth_from = event.minValue;
      this.depth_to = event.maxValue;
    } else if (name == "price") {
      this.price_from = event.minValue;
      this.price_to = event.maxValue;
    }
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }
  }

  resetFilter() {
    this.filterData = JSON.parse(JSON.stringify(this.originalFilterData));
    this.filterData["size"] = [{ name: "size" }];
    this.priceFilter = this.filterData["price"];
    this.priceFilter["name"] = "price";
    this.diameterFilter = this.filterData["diameter"][0];
    this.diameterFilter["name"] = "diameter";
    this.widthFilter = this.filterData["width"][0];
    this.heightFilter = this.filterData["height"][0];
    this.lengthFilter = this.filterData["length"][0];
    this.depthFilter = this.filterData["depth"][0];
    this.diameter_from = null;
    this.diameter_to = null;
    this.width_from = null;
    this.width_to = null;
    this.height_from = null;
    this.height_to = null;
    this.length_from = null;
    this.length_to = null;
    this.depth_from = null;
    this.depth_to = null;
    this.price_from = null;
    this.price_to = null;
    this.filteredElements = [];
  }
  getShopImage() {
    const url: string = (window.location as any).pathname;
    this.api.getImageRetrive(url.split("gpt/")[1]).subscribe((res: any) => {
      this.shoppableImageUrl = res[0].image_path;
      this.progress = 50;
      const imageElement: HTMLImageElement = this.shoppableImage.nativeElement;
      this.renderer.listen(imageElement, "load", () => {
        console.info("Image loaded!");
        this.handleResize();
      });
      // Update on window resize
      this.getImgClassification();
    });
  }
  getImgClassification() {
    const url: string = (window.location as any).pathname;
    this.imageId = url.split("gpt/")[1].split("-").pop();
    this.getPinBoardList();
    this.api.getImageClassification(url.split("gpt/")[1]).subscribe(
      (res: any) => {
        console.log(res, "RESPS");
        this.progress = 75;
        this.imageResponse = res;
        this.updateImageDimensions();
        this.processImageResponse();
      },
      (err) => {
        this.updateImageDimensions();
        this.processImageResponse();
      }
    );
  }
  updateImageDimensions() {
    const imageElement: HTMLImageElement = this.shoppableImage.nativeElement;
    this.currWidth = imageElement.offsetWidth;
    this.currHeight = imageElement.offsetHeight;
  }

  processImageResponse(resize: string = "") {
    this.dotCoords = [];
    this.itemCoords = [];
    this.items = [];
    if (document.getElementById("shoppableImage")) {
      var img: any = document.getElementById("shoppableImage");
      var width = img.clientWidth || img.width;
      var height = img.clientHeight || img.height;
      if (width !== 0) {
        this.imageHeight = height;
        this.imageWidth = width;
      } else {
        this.imageHeight = this.imageResponse.original_height;
        this.imageWidth = this.imageResponse.original_width;
      }
    } else {
      this.imageHeight = this.imageResponse.original_height;
      this.imageWidth = this.imageResponse.original_width;
    }

    let imagePredictions = this.imageResponse.predictions;
    this.imgPredictions = imagePredictions;
    let widthScaleValue = this.currWidth / this.imageWidth;
    let heightScaleValue = this.currHeight / this.imageHeight;
    if (imagePredictions && imagePredictions.length > 0) {
      if (resize == "") {
        this.progress = 90;
      }
      for (let i = 0; i < imagePredictions.length; i++) {
        this.items.push(imagePredictions[i].label);
        const scaledCoords = this.getScaledCoords(
          imagePredictions[i].box_xyxy,
          widthScaleValue == 0 ? 1 : widthScaleValue,
          heightScaleValue == 0 ? 1 : heightScaleValue
        );
        this.itemCoords.push(scaledCoords);
        this.dotCoords.push(this.getItemCenter(scaledCoords));
        this.dotSize * widthScaleValue;
      }
    }
    if (resize == "") {
      this.progress = 100;
      this.showLoader = false;
    }
  }

  getScaledCoords(
    coords: number[],
    widthScaleValue: number,
    heightScaleValue: number
  ): number[] {
    const x1 = coords[0] * widthScaleValue;
    const y1 = coords[1] * heightScaleValue;
    const x2 = coords[2] * widthScaleValue;
    const y2 = coords[3] * heightScaleValue;
    return [x1, y1, x2, y2];
  }

  getItemCenter(coords: number[]): number[] {
    const x = (coords[0] + coords[2]) / 2;
    const y = (coords[1] + coords[3]) / 2;
    return [x, y];
  }

  onScrollDown() {
    const data = {
      style: this.style.split(" ")[0],
      class: this.item,
      room: this.style.split(" ")[1],
      color: this.color,
      shape: this.shape,
    };
    this.pageNo++;
    let filterData = this.formFilterURL();
    this.api
      .getPredictionProducts(data, filterData, this.pageNo)
      .subscribe((response: any) => {
        if (this.productList.length === 0) {
          this.productList = [...response.products];
        } else {
          this.productList = [...this.productList, ...response.products];
        }
      });
  }

  formFilterURL(): string {
    let filterUrl = "";
    let data = {
      style: this.style.split(" ")[0],
      class: this.item,
      room: this.style.split(" ")[1],
      color: this.color,
      shape: this.shape,
      diameter_from: this.diameter_from,
      diameter_to: this.diameter_to,
      width_from: this.width_from,
      width_to: this.width_to,
      height_from: this.height_from,
      height_to: this.height_to,
      length_from: this.length_from,
      length_to: this.length_to,
      depth_from: this.depth_from,
      depth_to: this.depth_to,
      price_from: this.price_from,
      price_to: this.price_to,
    };

    if (this.filterData) {
      this.filteredElements.forEach((f) => {
        if (
          f != "price" &&
          f != "diameter" &&
          f != "width" &&
          f != "height" &&
          f != "length" &&
          f != "depth"
        ) {
          let checked = [];
          this.filterData[f].forEach((element) => {
            if (element.checked) {
              checked.push(element.name);
            }
          });
          filterUrl = filterUrl + `${f}:${checked.join(",")};`;
        } else if (f == "price") {
          filterUrl =
            filterUrl +
            `${"price_from"}:${this.price_from};${"price_to"}:${
              this.price_to
            };`;
        } else if (f == "diameter") {
          filterUrl =
            filterUrl +
            `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${
              this.diameter_to
            };`;
        } else if (f == "width") {
          filterUrl =
            filterUrl +
            `${"width_from"}:${this.width_from};${"width_to"}:${
              this.width_to
            };`;
        } else if (f == "height") {
          filterUrl =
            filterUrl +
            `${"height_from"}:${this.height_from};${"height_to"}:${
              this.height_to
            };`;
        } else if (f == "length") {
          filterUrl =
            filterUrl +
            `${"length_from"}:${this.length_from};${"length_to"}:${
              this.length_to
            };`;
        } else if (f == "depth") {
          filterUrl =
            filterUrl +
            `${"depth_from"}:${this.depth_from};${"depth_to"}:${
              this.depth_to
            };`;
        }
      });
    }
    return filterUrl;
  }

  checkData(d){
    // d.checked = !d.checked;
    setTimeout(() => {
      this.applyFilter();
    }, 1000);
  }

  loadProducts(item, i) {
    this.color = "";
    this.shape = "";
    Object.keys(this.imgPredictions[i]).forEach((f) => {
      if (f == "color") {
        this.color = this.imgPredictions[i][f];
      }
      if (f === "shape") {
        this.shape = this.imgPredictions[i][f];
      }
    });
    this.item = item;
    const data = {
      style: this.style.split(" ")[0],
      class: item,
      room: this.style.split(" ")[1],
      color: this.color,
      shape: this.shape,
    };
    this.pageNo = 0;
    let filterData = "";
    if (this.color) {
      filterData = filterData + `${"color"}:${this.color};`;
    }
    if (this.shape) {
      filterData = filterData + `${"shape"}:${this.shape};`;
    }
    if (this.style) {
      filterData = filterData + `${"style"}:${this.style.split(" ")[0]};`;
    }
    this.api
      .getPredictionProducts(data, filterData, this.pageNo)
      .subscribe((response: any) => {
        if (response && response.products) {
          this.productList = response.products;
          this.pinExpanded = false;
          if (this.color || this.shape) {
            if (!this.filteredElements.includes("color")) {
              this.filteredElements.push("color");
            }
            if (!this.filteredElements.includes("shape")) {
              this.filteredElements.push("shape");
            }
            if (!this.filteredElements.includes("style")) {
              this.filteredElements.push("style");
            }
            this.getFilterData();
          }
        }
      });
  }

  applyFilter() {
    const data = {
      style: this.style.split(" ")[0],
      class: this.item,
      room: this.style.split(" ")[1],
      color: this.color,
      shape: this.shape,
    };
    let filterData = this.formFilterURL();
    this.api
      .getPredictionProducts(data, filterData, this.pageNo)
      .subscribe((response: any) => {
        this.productList =  response.products;
        // if (this.productList.length === 0) {
        //   this.productList = [...response.products];
        // } else {
        //   this.productList = [...this.productList, ...response.products];
        // }
      });
  }

  selectFilter(d, name) {
    d.checked = !d.checked;
    if (this.filterData[name].filter((f) => f.checked).length > 0) {
      if (!this.filteredElements.includes(name)) {
        this.filteredElements.push(name);
      }
    } else {
      this.filteredElements = this.filteredElements.filter((f) => f != name);
    }
  }
  selectAll(name) {
    this.filterData[name].forEach((element) => {
      element.checked = true;
    });
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }
  }
  unSelectAll(name) {
    this.filterData[name].forEach((element) => {
      element.checked = false;
    });
    this.filteredElements = this.filteredElements.filter((f) => f != name);
  }
  getFilterData() {
    let filterUrl = "";
    if (this.style) {
      filterUrl = filterUrl + `${"style"}:${this.style.split(" ")[0]};`;
    }
    if (this.color) {
      filterUrl = filterUrl + `${"color"}:${this.color};`;
    }
    if (this.shape) {
      filterUrl = filterUrl + `${"shape"}:${this.shape};`;
    }

    const data = {
      class: this.item,
      room: this.style.split(" ")[1],
    };

    this.api.getFilteredProducts(data, filterUrl).subscribe((res: any) => {
      // this.productList = res.products;
      // this.pinExpanded = false;
      this.filterData = res.filterData;
      this.originalFilterData = JSON.parse(JSON.stringify(this.filterData));
      this.filterData["size"] = [{ name: "size" }];
      this.priceFilter = this.filterData["price"];
      this.priceFilter["name"] = "price";
      this.diameterFilter = this.filterData["diameter"][0];
      this.diameterFilter["name"] = "diameter";
      this.widthFilter = this.filterData["width"][0];
      this.heightFilter = this.filterData["height"][0];
      this.lengthFilter = this.filterData["length"][0];
      this.depthFilter = this.filterData["depth"][0];

      this.filteredElements.forEach((f) => {
        if (
          f != "price" &&
          f != "diameter" &&
          f != "width" &&
          f != "height" &&
          f != "length" &&
          f != "depth"
        ) {
          let checked = [];
          this.filterData[f].forEach((element) => {
            if (f === "color") {
              let ind = this.filterData[f].findIndex(
                (f) => f.name.toLowerCase() === this.color.toLowerCase()
              );
              if (ind != -1) {
                this.filterData[f][ind].checked = true;
              }
            }
            if (f === "shape") {
              let ind = this.filterData[f].findIndex(
                (f) => f.name.toLowerCase() === this.shape.toLowerCase()
              );
              if (ind != -1) {
                this.filterData[f][ind].checked = true;
              }
            }
            if (f === "style") {
              let ind = this.filterData[f].findIndex(
                (f) =>
                  f.name.toLowerCase() ===
                  this.style.split(" ")[0].toLowerCase()
              );
              if (ind != -1) {
                this.filterData[f][ind].checked = true;
              }
            }
            if (element.checked) {
              checked.push(element.name);
            }
          });
          filterUrl = filterUrl + `${f}:${checked.join(",")};`;
        } else if (f == "price") {
          filterUrl =
            filterUrl +
            `${"price_from"}:${this.price_from};${"price_to"}:${
              this.price_to
            };`;
        } else if (f == "diameter") {
          filterUrl =
            filterUrl +
            `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${
              this.diameter_to
            };`;
        } else if (f == "width") {
          filterUrl =
            filterUrl +
            `${"width_from"}:${this.width_from};${"width_to"}:${
              this.width_to
            };`;
        } else if (f == "height") {
          filterUrl =
            filterUrl +
            `${"height_from"}:${this.height_from};${"height_to"}:${
              this.height_to
            };`;
        } else if (f == "length") {
          filterUrl =
            filterUrl +
            `${"length_from"}:${this.length_from};${"length_to"}:${
              this.length_to
            };`;
        } else if (f == "depth") {
          filterUrl =
            filterUrl +
            `${"depth_from"}:${this.depth_from};${"depth_to"}:${
              this.depth_to
            };`;
        }
      });
    });
  }
  getCheckedLength(name): boolean {
    return this.filterData[name].filter((f) => f.checked).length > 0;
  }
  getSelections(name): string {
    return this.filterData[name]
      .filter((f) => f.checked)
      .map((f) => f.name)
      .join(",");
  }
  clearSelections(name) {
    this.filterData[name].forEach((element) => {
      element.checked = false;
    });
    this.filteredElements = this.filteredElements.filter((f) => f != name);
    this.applyFilter();
  }
  pinIt(p) {
    let isLoggedIn = JSON.parse(localStorage.getItem("user")).email.length > 0;
    if (isLoggedIn) { 
      if(this.pinBoardList.filter(f=>f.sku == p.sku).length > 0){
        this.snackBar.open("Product is already pinned", "x", { duration: 3000 });
        return;
      } else{
      this.savePinBoard(p);
      this.pinBoardList.unshift(p);
      this.latestPins = this.pinBoardList;
      this.showBottomPanel = true;
      }
    } else {
      this.matUtils.openSignupDialog();
    }
  }

  savePinBoard(p) {
    const data = {
      image_id: this.imageId,
      board_name: this.boardName,
      board_name_new: this.boardName,
      product_sku: p.sku,
      action:'add'
    };
    this.api.savePinBoard(data).subscribe((res: any) => {
      if (res) {
        this.snackBar.open("Product is pinned successfully",'x',{duration:3000,
         });
      }
    });
  }

  updatePinBoard() {
    const data = {
      image_id: this.imageId,
      board_name: this.oldBoardName,
      board_name_new: this.boardName,
      action:'update'
    };
    this.api.savePinBoard(data).subscribe((res: any) => {
      if (res) {
        this.snackBar.open("Pinboard name is updated successfully",'x',{duration:3000,
         });
      }
    });
  }
removePinBoard(p,index){
  const data = {
    image_id: this.imageId,
    board_name: this.boardName,
    board_name_new: this.boardName,
    product_sku: p.sku,
    action:'remove'
  };
  this.pinBoardList.splice(index, 1);
  this.latestPins = this.pinBoardList;
  this.api.savePinBoard(data).subscribe((res: any) => {
    if (res) {
      this.snackBar.open("Product is removed from pinboard successfully",'x',{duration:3000,
       });
    }
  });
}


  addToCart(p) { 
    const data = {
      sku: p.sku,
      brand: p.brand_name,
      image: p.main_image,
      name:  p.name,
      price: p.min_price,
      quantity: 1,
    };
    const postData = {
      product_sku: p.sku,
      count: 1,
      parent_sku: p.sku,
      isServiceRepOrder: 0,
      is_virtual: 0,
    };
    this.api.addCartProduct(postData).subscribe(
      (payload: any) => {
        if (payload.status) {
          this.errorMessage = "";
          this.matDialogUtils.openAddToCartDialog(data);
          this.pixelService.trackAddToCart(data);
        } else {
          this.errorMessage = payload.msg;
        }
      },
      (error: any) => {
        this.errorMessage = "Cannot add this product at the moment.";
      }
    );
  }

  getPinBoardList() {
    let userId = JSON.parse(localStorage.getItem("user")).id;
    this.api.getPinBoardList(this.imageId, userId).subscribe((res: any) => {
      if (res.status) {
        this.pinBoardList = res.products;
        this.oldBoardName = res.products[0].board_name;
        this.latestPins = this.pinBoardList;
      }
    });
  }

  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      "Hi there! I see you would like to chat with a design consultant."
    );
  }
  expandPin() {
    this.pinExpanded = true;
  } 
  ngOnDestroy() {
    clearInterval(this.progress); 
  }
  openProduct(url) {
    this.router.navigate([]).then(() => {
      window.open(`/product/${url.sku}`);
    });
  }
}
