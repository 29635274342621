import {
  AfterViewInit,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Location } from "@angular/common";
import { EventEmitterService, MatDialogUtilsService } from "../shared/services";
import { ChatService } from "../shared/services/api/chat.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.less"],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  isHandset = false;
  messageList: any[] = [];
  isChatMob = true;
  isProductsMob = false;

  message: string = "";
  queryLoading: boolean;
  userId: any;

  genomeName: string = "";
  genomeDescription: string='';
  genomeImage: string='';
  subStylesList: any[] = [];
  quizId: any;
  conversation_id: string = "";
  historyList: any[] = [];
  currentHistory: any;

  groupedItems: { [key: string]: any[] } = {};
  finalChatHistory: any[] = [];
  currentMonth = new Date().toLocaleString("default", { month: "long" });
  newChatClicked: boolean;
  isLeftPanelOpened = false;
  promptSearchList: any[] = [];
  assignData: boolean;
  hasProducts: boolean = false;
  pinBoardList: any[] = [];
  showPinBoard = false;
  isRightPanelOpened = false;
  title: any;
  cartProduct: number;
conversationStage = '';
  showTabs = false;
  noMoreProducts: boolean =false;

  constructor(
    public chatService: ChatService,
    private location: Location,
    private router:Router,
    private matDialogUtils: MatDialogUtilsService,
    private activatedRouter: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private eventEmitterService:EventEmitterService
  ) {}

  ngOnInit(): void { 
    this.cartProduct = parseInt(localStorage.getItem("cart"));

    this.eventEmitterService.updateCart.subscribe((payload) => {
      // tslint:disable-next-line: radix
      this.cartProduct = parseInt(localStorage.getItem("cart"));
    });
    this.getPromptsSearch();
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
      });
    this.conversation_id = this.activatedRouter.snapshot.params.id;
    if (this.isLoggedIn) {
      this.userId = JSON.parse(localStorage.getItem("user")).id;
      this.getAllConverstations();
    }

    if (this.chatService.query && !this.conversation_id) {
      this.chat(this.chatService.query);
    } else {
      this.newChatClicked = true;
    }
  }

  get deviceWidth(): number {
    return window.outerWidth;
  }

  get innerWidth(): number {
    return window.innerWidth;
  }

  get deviceHeight(): number {
    return window.innerHeight;
  }

  get msgBoxWidth(): string {
    // if (this.deviceWidth >= 600 && this.deviceWidth <= 1399) {
    //   return this.msgContainWidth - 70 + "px";
    // } else if (this.deviceWidth >= 1400 && this.deviceWidth <= 1500) {
    //   return this.msgContainWidth - 200 + "px";
    // } else if (this.deviceWidth >= 1500 && this.deviceWidth <= 1680) {
    //   return this.msgContainWidth - 240 + "px";
    // } else if (this.deviceWidth >= 1680 && this.deviceWidth <= 2000) {
    //   return this.msgContainWidth - 250 + "px";
    // } else if (this.deviceWidth >= 2000 && this.deviceWidth <= 4500) {
    //   return this.msgContainWidth - 350 + "px";
    // }
    return this.msgContainWidth + "px";
  }

  get msgContainWidth(): number {
    const element = document.getElementById("headContain");
    const rect = element.getBoundingClientRect();
    return rect.width;
  }

  get searchContainWidth(): number {
    const element = document.getElementById("searchContainer");
    const rect = element.getBoundingClientRect();
    return rect.width;
  }

  get msgIdContainWidth(): number {
    const element = document.getElementById("messageIdentifier0");
    if (element) {
      const rect = element.getBoundingClientRect();
      return rect.width;
    } else {
      return 600;
    }
  }

  getPromptsSearch() {
    this.chatService.getPromptSearch().subscribe((resp: any[]) => {
      this.promptSearchList = resp.find(
        (f) => f.type === "primary"
      ).prompt_details;
    });
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }

  sampleChat(data) {
    this.newChatClicked = false;
    this.chatService.query = data;
    this.chat(data);
  }

  loadMoreProducts(event) {
    let obj = {
      conversation_id: this.conversation_id,
      message: event.message.replace("\n", ""),
      is_new_conversation: this.messageList.length > 0 ? false : true,
      only_retrieve: true, // for load more
      products: event.sub_product, // for load more
    };
    this.messageList.push({
      role: "user",
      message: event.message,
      conversation_stage: "",
      room: "",
      product_details: [],
      only_retrieve: true, // for load more
      sub_product: event.sub_product, // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: false,
    });
    this.chatRequest(obj);
  }

  chat(data) {
    let obj = {
      conversation_id: this.conversation_id,
      message: data.replace("\n", ""),
      is_new_conversation: this.messageList.length > 0 ? false : true,
    };
    this.messageList.push({
      role: "user",
      message: data,
      conversation_stage: "",
      room: "",
      product_details: [],
      only_retrieve: false, // for load more
      sub_product: [], // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: false,
    });
    this.chatRequest(obj);
  }

  chatRequest(obj) {
    this.message = "";

    this.scrollToQuery();
    this.queryLoading = true;
    this.messageList.push({
      role: "assistant",
      message: ``,
      conversation_stage: "",
      room: "",
      product_details: [],
      only_retrieve: false, // for load more
      sub_product: [], // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: true,
    });
    let input: HTMLInputElement = document.getElementById(
      "chatInput"
    ) as HTMLInputElement;
    if (input) {
      input.value = null;
    }

    this.chatService.chatRequest(obj).subscribe(
      (resp: any) => {
        if (resp) {
          setTimeout(() => {
            this.conversation_id = resp.conversation_id;
            this.conversationStage = resp.conversation_stage;
            this.title = resp.title;
            this.location.replaceState(`/c/${this.conversation_id}`);
            let room = "living";
            this.messageList[this.messageList.length - 1].message =
              resp.message[0];
            this.messageList[this.messageList.length - 1].showLoader = false;
            this.messageList[this.messageList.length - 1].conversation_stage =
              resp.conversation_stage;
            if (resp.room) {
              room = resp.room;
            }
            this.messageList[this.messageList.length - 1].room = room;
            this.messageList[this.messageList.length - 1].quiz_id =
              resp.quiz_id;

            if (resp.jsondata && resp.jsondata.product) {
              let sIndex = this.messageList.findIndex(
                (f) => f.conversation_stage.toLocaleLowerCase() === "products"
              );
              let count = this.messageList.filter(
                (f) => f.conversation_stage.toLocaleLowerCase() === "products"
              ).length;

              // console.log(
              //   count,
              //   this,
              //   this.messageList,
              //   sIndex,
              //   resp.jsondata.product
              // );
              this.messageList[this.messageList.length - 1].sub_product = [];

              if (count > 1) {
                resp.jsondata.product.forEach((f) => {
                  this.messageList[sIndex].product_details.push(f);
                });
                this.messageList[sIndex].product_details = JSON.parse(
                  JSON.stringify(this.messageList[sIndex].product_details)
                );
                this.messageList[sIndex].product_count =
                  this.messageList[sIndex].product_count + 1;

                if (resp.jsondata.sub_product) {
                  this.messageList[sIndex].sub_product =
                    resp.jsondata.sub_product;
                }
              } else {
                this.messageList[this.messageList.length - 1].product_details =
                  resp.jsondata.product;
                this.messageList[this.messageList.length - 1].product_count =
                  this.messageList[this.messageList.length - 1].product_count +
                  1;
                if (resp.jsondata.sub_product) {
                  this.messageList[this.messageList.length - 1].sub_product =
                    resp.jsondata.sub_product;
                }
              }
            }
            if (resp.conversation_stage.toLowerCase() === "products") {
              resp.jsondata.product.forEach((f) => {
                f["pinned"] = false;
              });

              this.hasProducts = true;
            }
            if (
              resp.conversation_stage.toLowerCase() === "style" ||
              resp.conversation_stage.toLowerCase() === "products"
            ) {
              this.isRightPanelOpened = true;
            }
            if (resp.conversation_stage.toLowerCase() === "products") {
              this.showTabs = true;
              this.isProductsMob = true;
              window.scrollTo(0, 0);
            }
            this.quizId = resp.quiz_id;
            this.chatService.quizCategory = room;
            this.scrollToResponse();
            this.queryLoading = false;
            if(!resp.jsondata.product || resp.jsondata.product.length===0){
              this.noMoreProducts = true;
            }  else{
              this.noMoreProducts = false;

            }
          }, 0);
          this.getAllConverstations(false);
        }
      },
      (err) => {
        this.messageList[this.messageList.length - 1].showLoader = false;
        this.queryLoading = false;
      }
    );
  }

  assignSubProducts(resp) {
    if (resp.jsondata && resp.jsondata.sub_product) {
    }
  }

  scrollToQuery() {
    setTimeout(() => {
      let name = document.getElementById("msgContainer");
      name.scrollTo({
        left: 0,
        top: name.scrollHeight - 400,
        behavior: "smooth",
      });
    }, 0);
  }

  scrollToResponse() {
    setTimeout(() => {
      let name = document.getElementById("msgContainer");
      if (
        this.messageList[this.messageList.length - 1].conversation_stage ===
        "style"
      ) {
        let he = name.scrollHeight - (this.deviceHeight - 100) - 490;
        name.scrollTo({
          left: 0,
          top: he,
          behavior: "smooth",
        });
      } else if (
        this.messageList[
          this.messageList.length - 1
        ].conversation_stage.toLowerCase() === "products"
      ) {
        let he = name.scrollHeight - (this.deviceHeight - 100) - 490;
        name.scrollTo({
          left: 0,
          top: he,
          behavior: "smooth",
        });
      } else {
        name.scrollTo({
          left: 0,
          top: name.scrollHeight - 400,
          behavior: "smooth",
        });
      }
      let input: HTMLInputElement = document.getElementById(
        "chatInput"
      ) as HTMLInputElement;
      if(input){
        input.focus();
      }
    }, 0);
  }

  checkQueryLoading(e) {
    this.messageList.push({
      role: "assistant",
      message: ``,
      conversation_stage: "",
      room: "",
      product_details: [],
      only_retrieve: false, // for load more
      sub_product: [], // for load more
      product_count: 0,
      quiz_id: "",
      showStyle: false,
      showLoader: true,
    });
    this.queryLoading = true;
    this.isRightPanelOpened = false;
  }
  getMessage(data) {
    if (data.chat && data.status) {
      setTimeout(() => {
        this.assignData = true;
      }, 3000);
      this.queryLoading = false;
      this.conversationStage = '';
      this.messageList[this.messageList.length - 1].showLoader = false;
      this.messageList[this.messageList.length - 1].message =
        data.chat.message[0];
      this.messageList[this.messageList.length - 1].showStyle = true;

      // this.messageList[this.messageList.length-1].conversation_stage= data.chat.conversation_stage;
    }
  }
  sendQuery() {
    this.chat(this.message);
    const textarea = document.getElementById("chatInput");
    textarea.style.height = "72px";
  }
  getAllConverstations(assign = true) {
    // this.assignData = false;

    this.chatService.getUserConvSummary().subscribe((resp: any) => {
      this.historyList = resp;
      this.groupByDate();
    });

    let data = JSON.parse(localStorage.getItem("recentChat"));
    if (data && data.conversation_id === this.conversation_id) {
      if (assign) {
        this.assignData = true;
      }
      this.formatChat(data);
      // this.assignChatData(data);
    } else {
      if (this.conversation_id) {
        this.chatService
          .viewConversationsById(this.conversation_id)
          .subscribe((resp: any) => {
            if (assign) {
              this.assignData = true;
              if (resp && resp.conversation) {
                this.formatChat(resp.conversation);
              }
            }
          });
      }
    }
  }

  formatChat(conv: any) {
    if (conv) {
      this.assignData = true;
      localStorage.setItem("recentChat", JSON.stringify(conv));
      let convC = JSON.parse(JSON.stringify(conv));
      let chats =convC.chats;

      chats
        .filter((f) => f.conversation_stage.toLowerCase() === "products")
        .forEach((f) => {
          f["product_count"] = 0;
          f["product_details"] = [];
        });

      let sIndex = chats.findIndex(
        (f) => f.conversation_stage.toLocaleLowerCase() === "products"
      );
      let productChat = chats.filter(
        (f) => f.conversation_stage.toLocaleLowerCase() === "products"
      );
      let count = chats.filter(
        (f) => f.conversation_stage.toLocaleLowerCase() === "products"
      ).length;

      if (count > 1) {
        let executed = false;
        productChat.forEach((chat) => {
          if (chat.products) {
            chat.products.forEach((f) => {
              productChat[0].product_details.push(f);
            });
            if (chat[sIndex]) {
              chat[sIndex].product_details = JSON.parse(
                JSON.stringify(productChat[0].product_details)
              );
            } else {
              if (!executed) {
                chat.product_details = JSON.parse(
                  JSON.stringify(productChat[0].product_details)
                );
              }
              executed = true;
            }
            productChat[0].product_count = productChat[0].product_count + 1;
          }
        });
      } else {
        productChat.forEach((chat) => {
          productChat[productChat.length - 1].product_details = chat.products;
          productChat[productChat.length - 1].product_count =
            productChat[productChat.length - 1].product_count + 1;
        });
      }
      this.assignChatData(convC);
    }
  }

  getConv(h) {
    if (h.conversation_id) {
      this.isRightPanelOpened = false;
      this.isProductsMob = false;
      this.showTabs = false;
      this.chatService
        .viewConversationsById(h.conversation_id)
        .subscribe((resp: any) => {
          this.hasProducts = false;

          if (resp && resp.conversation) {
            this.formatChat(resp.conversation);
          }
        });
    }
  }

  assignChatData(h) {
    this.currentHistory = h;
    this.title = h.title ? h.title : h.conversation_id;
    this.messageList = h.chats;
    if (
      this.messageList.some(
        (s) => s.conversation_stage.toLowerCase() === "products"
      )
    ) {
      this.isRightPanelOpened = true; 
      this.showTabs = true
      this.getPinBoardList(); 

      this.hasProducts = true; 
    }
    this.chatService.query = h.title;
    this.location.replaceState(`/c/${h.conversation_id}`);
    this.assignData = true;
    console.log(this.messageList, "ml");
  }

  groupByDate() {
    this.groupedItems = {};
    this.historyList.forEach((item) => {
      const date = new Date(item.created_date);
      const monthKey = `${date.toLocaleString("default", { month: "long" })}`;
      if (!this.groupedItems[monthKey]) {
        this.groupedItems[monthKey] = [];
      }
      this.groupedItems[monthKey].push(item);
    });
    this.sortGroupedItemsByDate();
  }
  sortGroupedItemsByDate() {
    this.finalChatHistory = [];
    // Map month names to their corresponding numerical values
    const monthMap: { [key: string]: number } = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    // Convert groupedItems to an array of [monthName, items[]] pairs
    const pairs = Object.entries(this.groupedItems);

    // Sort the pairs based on the numerical value of the month
    pairs.sort((a, b) => {
      return monthMap[b[0]] - monthMap[a[0]];
    });

    // Convert the sorted pairs back to the groupedItems object
    this.groupedItems = {};
    pairs.forEach((pair) => {
      this.finalChatHistory.push({
        key: pair[0],
        value: pair[1],
      });
      this.groupedItems[pair[0]] = pair[1];
    });
  }
  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);

    const intervalx = setInterval(() => {
      const intercomx = document.getElementsByClassName("intercom-app")[0];
      if (intercomx) {
        intercomx.classList.add("dn");
        clearInterval(intervalx);
      }
    }, 500);
  }
  ngAfterViewInit(): void {
    this.hideIntercomIcon();

    document.addEventListener("DOMContentLoaded", function () {
      const textarea = document.getElementById("chatInput");

      console.log(textarea);
      textarea.addEventListener("input", function () {
        this.style.height = "auto";
        this.style.height = this.scrollHeight - 10 + "px";
      });
    });
  }
  openSignupDialog() {
    this.matDialogUtils.openSignupDialog(this.isHandset);
  }

  openSignInDialog() {
    this.matDialogUtils.openSigninDialog(this.isHandset ? "90%" : "35%");
  }
  getStyleReport(res) {
    this.genomeName = res.genome[0].genome_name;
    this.genomeDescription = res.genome[0].genome_description;
    this.genomeImage = res.genome[0].genome_image;
    this.subStylesList = res.substyles;
  }
  startNewChat() {
    this.newChatClicked = true;
    this.messageList = [];
    this.conversation_id = "";
    this.chatService.query = "";
    this.isLeftPanelOpened = false;
    this.location.replaceState(`/chat`);
    this.assignData = false;
    this.title = "";
    this.showPinBoard = false;
    this.hasProducts = false;
    this.pinBoardList = [];
    this.isRightPanelOpened = false;
    this.showTabs = false; 
    this.chatService.shuffledImages = [];
  }

  goToStyle(){
    this.router.navigate([]).then(() => {
      window.open(`/style-quiz/my-style/${this.quizId}`);
    });
    
  }
  getPinBoardList() {
    this.chatService
      .getPinboardList(this.conversation_id)
      .subscribe((res: any) => {
        if (res.status) {
          this.pinBoardList = res.products;
        }
      });
  }
  ngOnDestroy(): void {
    this.chatService.shuffledImages = [];
  }
  goToConv(h){
    this.chatService.shuffledImages = [];
    this.router.navigate([`/c/${h.conversation_id}`]);
    this.ngOnInit();
  }
}
 
