import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IActiveProduct, IProduct, IProductDetail, ISeo } from '../../../shared/models'; 
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { VariationsComponent } from '../variations/variations.component';
import { PixelService } from '../../../shared/services/facebook-pixel/pixel.service';
import { WishlistSnackbarService } from '../../../shared/services/wishlist-service/wishlist-snackbar.service';
import { first } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../../shared/services/api/api.service';
import { UtilsService } from '../../../shared/services/utils/utils.service';
import { CacheService } from '../../../shared/services/cache/cache.service';
import { EventEmitterService } from '../../../shared/services/events/event-emitter.service';
import { MatDialogUtilsService } from '../../../shared/services/matDialogUtils/matDialogUtils.service';
import { SeoService } from '../../../shared/services/seo/seo.service';
import { ReviewDialogService } from '../../../shared/services/matDialogUtils/review-dialog.service';
import { environment } from 'src/environments/environment.staging';
import { EnvService } from '../../../shared/services/env.service';

@Component({
    selector: 'app-product-details-mobile',
    templateUrl: './product-details-mobile.component.html',
    styleUrls: ['./product-details-mobile.component.less'],
})
export class ProductDetailsMobileComponent implements OnInit {
    @ViewChild(VariationsComponent, { static: false }) child: VariationsComponent;
    @ViewChild('gallery', { static: false }) galleryContainer: ElementRef<any>;
    @ViewChild('itemTemplate', { static: true }) itemTemplate: TemplateRef<any>;
    @ViewChild('thumbTemplate', { static: true }) thumbTemplate: TemplateRef<any>;

    productSku: any;
    routeSubscription: any;
    product: IProduct;
    seoData: ISeo;
    productSubscription: Subscription;
    activeTab = 'features';
    dimensionExist = false;
    featuresExist = false;
    descriptionExist = false;
    assemblyExist = false;
    careExist = false;
    spinner = 'assets/image/spinner.gif';
    bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
        Breakpoints.Handset
    );
    galleryId = 'myLightbox';
    items: GalleryItem[];
    bpSubscription: Subscription;
    isHandset: boolean;
    isVariationExist: boolean;
    selectedIndex: any;
    isSwatchExist: boolean;
    isProductFetching = true;
    description: any;
    features: any;
    productPrice: any;
    productWasPrice: any;
    variations = [];
    selectedSwatch = {
        swatch_image: null,
        price: '',
        wasPrice: '',
    };
    errorMessage = '';
    quantity = 1;
    quantityArray = [];
    galleryRef = this.gallery.ref(this.galleryId);
    isSetItemInInventory = false;
    eventSubscription: Subscription;
    activeProduct: IActiveProduct;
    hasSelection: boolean;
    beforeSelection: boolean;
    checkSelection: boolean;
    schema = {};
    invalidLinkImageSrc = 'assets/image/invalid_link.png';
    invalidLink: boolean;
    isSingleDimension: boolean;
    priceObject = {
        is_price: '',
        was_price: '',
    };
    isDiscounted = false;
    isRange = false;
    carousalOptions = {
        autoWidth: false,
        loop: true,
        margin: 10,
        items: 2.3,
        center: false,
        dots: false,
        pagination: false,
    };
    recentProducts = [];
    recentOptions = {
        autoWidth: false,
        loop: true,
        margin: 10,
        items: 2.3,
        center: false,
        dots: false,
        pagination: false,
    };
    otherPeopleOptions = {
        autoWidth: false,
        loop: true,
        margin: 10,
        items: 2.3,
        center: false,
        dots: false,
        pagination: false,
    };
    imageDialogCarouselOptions = {
        margin: 10,
        loop: true,
        items: 1,
        dots: true,
        touchDrag: false,
        pagination: true,
        singleItem: true,
    };
    recentReviews: any;
    allReviews: any;
    totalCount: any;
    lowestReviews: any;
    totalRating: number;
    highestReviews: any;
    totalNo: number;
    currentReviewSelection = 'mr';

    assembly: any;
    care: any;
    otherPeopleProducts = [];

    showSku = false;
    selectedProductSku = '';
    hasValidWasPrice: boolean;
    newVariationFound: boolean;
    newVariationData: any;
    updatedPrice: any;
    isPriceUpdate: boolean;
    newSku: string = '';
    prodBoardList: any[];
    slideIndex = 0;
    mainProductImage: any[] = [];
    salePriceStrike: number;
    user: any;
    appUrl = '';
    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private apiService: ApiService,
        public utils: UtilsService,
        private breakpointObserver: BreakpointObserver,
        public gallery: Gallery,
        public lightbox: Lightbox,
        public cacheService: CacheService,
        private eventEmitterService: EventEmitterService,
        private matDialogUtils: MatDialogUtilsService,
        private seoService: SeoService,
        private pixelService: PixelService,
        private snackBarService: WishlistSnackbarService,
        private cookie: CookieService,
        private reviewDialog:ReviewDialogService,
        public env: EnvService,
        private _renderer2: Renderer2

    ) {
        this.appUrl = this.env.appUrl;
    }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem("user"));  
        this.loadRecentProducts();
        this.eventSubscription = this.eventEmitterService.userChangeEvent
            .asObservable()
            .subscribe((user) => {
                this.bpSubscription = this.bpObserver.subscribe(
                    (handset: BreakpointState) => {
                        this.isHandset = handset.matches;
                    }
                );
                this.loadProduct();
            });
    }
    getProdImgBoard() {
        this.apiService.getProdImgBoard(this.productSku).subscribe((data: any) => {
            this.prodBoardList = data.sort((a, b) => a.serial - b.serial);
        });
    }
    loadProduct() {
        this.routeSubscription = this.activeRoute.params.subscribe(
            (routeParams) => {
                this.isProductFetching = true;
                this.productSku = routeParams.product;
                this.cacheService.data.productSku = this.productSku;
                this.cacheService.data.useCache = true;
                this.getProdImgBoard();
                this.productSubscription = this.apiService
                    .getProduct(this.productSku)
                    .subscribe(
                        (payload: IProductDetail) => {
                            if (payload.product) {
                                const user = JSON.parse(localStorage.getItem('user'));
                                if (payload.product.displayable == 'false' && user && user.user_type !== 90 && user.user_type !== 70) {
                                    this.router.navigate(['product/contact']);
                                    return;
                                } else if (payload.product.displayable == 'restricted' && user && user.user_type !== 2 && user.user_type !== 90 && user.user_type !== 70) {
                                    this.router.navigate(['product/contact']);
                                    return;
                                } else if (
                                    payload.product.displayable == "preview" &&
                                    user &&
                                    user.user_type == 0
                                  ) {
                                    this.router.navigate([`product/${this.productSku}/preview`]);
                                    return;
                                  }
                            }
                            this.product = payload.product;
                            if (this.product.sku !== this.productSku) {
                                this.router.navigateByUrl(
                                    "/product/" + this.product.sku
                                );
                            }
                            this.pixelService.trackViewContent(this.product);
                            if (this.product.collections.length < 3) {
                                this.carousalOptions.loop = false;
                            }
                            if (this.product) {
                                this.schema = payload.seo_data.json_ld; //this.seoService.setSchema(this.product);
                                this.setSeoData(payload);
                                this.updateActiveProduct(this.product);
                                this.description = this.utils.compileMarkdown(
                                    this.product.description,
                                    true
                                );
                                this.features = this.utils.compileMarkdown(
                                    this.product.features
                                );
                                this.dimensionExist = this.utils.checkDimensionsLength(
                                    this.product.dimension
                                );
                                this.isSingleDimension = (this.product.dimension.length === 1);
                                this.featuresExist = this.utils.checkDataLength(
                                    this.product.features
                                );
                                if (this.product.product_assembly != null) {
                                    this.assemblyExist = this.utils.checkDataLength(
                                        this.product.product_assembly
                                    );
                                }
                                if (this.product.product_care != null) {
                                    this.careExist = this.utils.checkDataLength(
                                        this.product.product_care
                                    );
                                }
                                this.assembly = this.utils.compileMarkdownText(this.product.product_assembly);
                                this.care = this.utils.compileMarkdownText(this.product.product_care);
                                this.descriptionExist = this.utils.checkDataLength(
                                    this.product.description
                                );
                                this.isSwatchExist = this.utils.checkDataLength(
                                    this.product.variations.filter(
                                        (variation) => variation.swatch_image !== null
                                    )
                                );
                                this.isVariationExist = this.utils.checkDataLength(
                                    this.product.variations
                                );
                                if (!this.isVariationExist) {
                                    this.beforeSelection = true;
                                    this.checkSelection = true;
                                    this.showSku = true;
                                    this.selectedProductSku = this.activeProduct.sku;
                                }
                                if (
                                    this.isVariationExist &&
                                    this.product.variations.length === 1
                                ) {
                                    this.beforeSelection = true;
                                    this.checkSelection = true;
                                    this.showSku = true;
                                    this.selectedProductSku = this.activeProduct.sku;
                                    
                                }
                                if(this.newVariationFound && this.newSku){
                                    this.selectedProductSku = this.newSku;
                                  } 
                                this.hasVariationsInventory();

                                this.variations = this.product.variations.sort((a, b) =>
                                    a.name > b.name ? 1 : -1
                                );
                                if (this.product.in_inventory) {
                                    this.productPrice = this.utils.formatPrice(
                                        this.product.inventory_product_details.price
                                    );
                                    this.productWasPrice = this.utils.formatPrice(
                                        this.product.inventory_product_details.was_price
                                    );
                                } else {
                                    this.productPrice = this.utils.formatPrice(
                                        this.product.min_price
                                    );

                                    this.productWasPrice = this.utils.formatPrice(
                                        this.product.max_was_price
                                    );
                                }

                                const {
                                    isPriceString,
                                    isRanged,
                                    isDiscounted,
                                    wasPriceString,
                                } = this.utils.getPriceObject(this.product);
                                this.priceObject.is_price = isPriceString;
                                this.priceObject.was_price = wasPriceString;
                                this.isRange = isRanged;
                                this.isDiscounted = isDiscounted;
                                this.mainProductImage = [];
                                this.mainProductImage.push(this.product.main_image);
                                this.mainProductImage.unshift(...this.product.product_images_gallery);
                                this.product.on_server_images.unshift(...this.mainProductImage)
                                // this.createGalleryItems(this.product.on_server_images);
                                if (this.product.on_server_images.length > 0) {
                                    this.createGalleryItems(this.product.on_server_images);
                                } else {
                                    this.createGalleryItems(this.product.product_images_gallery);
                                }
                                if (this.product.set) {
                                    this.checkSetInventory(this.product.set);
                                }
                                this.loadOtherPeopleProducts();
                                if(this.product.variations.length===0 && this.product.product_status === 'active'){
                                    this.isSetItemInInventory= true;
                                  }
                                this.invalidLink = false;
                            } else {
                                this.invalidLink = true;
                            }
                            this.loadProductReviews(this.productSku);
                            this.isProductFetching = false;
                            setTimeout(() => {
                                this.currentSlide(1);
                            }, 0);
                        },
                        (error) => {
                            this.invalidLink = true;
                            this.isProductFetching = false;
                        }
                    );
                this.apiService
                    .getProductSelection(this.productSku)
                    .subscribe((data: any) => {
                        if (data && data.selection && data.selection.length > 0) {
                            this.newVariationFound = true;
                            this.newVariationData = data;
                          } else {
                            if(data.Skudetails.length===1){
                              this.newVariationFound = true;
                              this.newVariationData = data;
                              this.newSku = data.Skudetails[0].sku;
                            } else {
                            this.newVariationFound = false;
                            }
                          }
                    });
            }
        );
    }

    private setSeoData(payload: any) {
        this.schema =payload.seo_data.json_ld;// this.seoService.setSchema(this.product);
        const seoData: any = payload.seo_data;
        const metaData = {
            title: `${seoData.brand} ${seoData.product_name} | ${this.env.appName}`,
            description: seoData.description,
            image: seoData.image_url,
        };
        payload.seo_data.title =  `${seoData.brand} ${seoData.product_name} | ${this.env.appName}`;
        this.seoService.setMetaTags(payload.seo_data,this._renderer2);
        this.seoService.setJsonLd(this._renderer2,this.schema);
    }

    setSkuOfCurrentSelectedProduct(variations) {
        if (variations.length === 1) {
            this.showSku = true;
            this.selectedProductSku = variations[0].variation_sku;
        } else {
            this.showSku = false;
            this.selectedProductSku = '';
        }
    }

    loadProductReviews(sku) {
        const limit = 10;
        this.apiService.getProductReviews(sku, limit).subscribe((response: any) => {
            this.recentReviews = response;
            this.allReviews = this.recentReviews.all_reviews;
            this.totalCount = this.product.reviews;
            this.totalNo = Number(this.product.reviews) * 5;
            this.totalRating =
                (Number(this.recentReviews.tot_rating) / this.totalNo) * 100;
            this.highestReviews = this.recentReviews.highest_reviews;
            this.lowestReviews = this.recentReviews.lowest_reviews;
        });
    }

    objKeys(anObject) {
        if (anObject) {
            return Object.keys(anObject);
        }
        return [];
    }

    changeReview(event) {
        this.currentReviewSelection = event.target.value;
    }

    createGalleryItems(items: any[]) {
        this.items = items.map((item) => new ImageItem({ src: item, thumb: item }));
        this.galleryRef.setConfig({
            imageSize: 'contain',
            // itemTemplate: this.itemTemplate,
            // thumbTemplate: this.thumbTemplate,
            gestures: false,
            thumb: true,
            thumbWidth: 90,
        });
        this.galleryRef.load(this.items);
    }

    onDestroy(): void {
        this.productSubscription.unsubscribe();
        this.bpSubscription.unsubscribe();
        this.eventSubscription.unsubscribe();
    }

    loadRecentProducts() {
        this.apiService
            .getRecentProducts()
            .pipe(first())
            .subscribe((response: any[]) => {
                this.recentProducts = response;
                if (this.recentProducts.length <= 2) {
                    this.recentOptions.loop = false;
                }
            });
    }

    selectTab(tab) {
        this.activeTab = tab;
    }

    selectedVariation(variation, index, container) {
        if (variation.has_parent_sku) {
            this.router.navigate([`/product/${variation.variation_sku}`]);
        } else {
            this.selectedSwatch = {
                swatch_image: variation.swatch_image,
                price: variation.price,
                wasPrice: variation.was_price,
            };
            this.productPrice = variation.price;
            this.productWasPrice = variation.was_price;
            this.onSetImage(variation.image);
            this.selectedIndex = index;
            container.scrollLeft = 0;
        }
    }

    onVariationChange() {
        this.loadProduct();
    }

    wishlistProduct(sku, mark) {
        const user = JSON.parse(localStorage.getItem('user'));
        const neverShow = localStorage.getItem('login-prompt-never-show');
        const token = this.cookie.get('token');
        if (!token) {
            // trigger signup window
            this.utils.openSignupDialog(false, true);
            return;
        }
        this.apiService
            .wishlistProduct(sku, mark, true)
            .subscribe((payload: any) => {
                this.product.wishlisted = mark;
                if (mark) {
                    if (user.user_type === 0 && !neverShow) {
                        this.matDialogUtils.openWishlistLoginPromptModal(this.product, true);
                    } else {
                        this.snackBarService.addToWishlist(sku);
                    }
                } else {
                    this.snackBarService.removeIfExistsProduct(sku);
                }
            });
    }

    openLightbox(index: number) {
        this.lightbox.open(index, this.galleryId, {
            panelClass: 'fullscreen',
        });
        const intercom = document.getElementsByClassName(
            'intercom-lightweight-app'
        )[0];
        if (intercom) {
            intercom.classList.add('hidden');
        }
        this.lightbox.closed.pipe(first()).subscribe((_) => {
            intercom.classList.remove('hidden');
        });
    }

    isArray(obj: any) {
        return Array.isArray(obj);
    }

    openLink(event, url) {
        event.preventDefault();
        if (typeof vglnk) {
            vglnk.open(url, '_blank');
        }
    }

    onSetImage(variation): void {
        // this.galleryContainer.nativeElement.scrollTop = 0;
        this.items = this.product.on_server_images.map(
            (item) => new ImageItem({ src: item, thumb: item })
        );
        if (variation) {
            const src = variation.image.split(',');
            let images = [];
            if (this.isArray(src)) {
                images = src.map(item => new ImageItem({ src: item, thumb: item }));
            } else {
                images[0] = new ImageItem({ src, thumb: src });
            }
            this.items = [...images, ...this.items];
            this.updateActiveProduct(variation);
            this.hasSelection = true;
        } else {
            this.updateActiveProduct(this.product);
            this.hasVariationsInventory();
            // this.hasSelection = false;
        }
        this.galleryRef.load(this.items);
    }

    // onSetPrice(priceData): void {
    //     const newPrices = {
    //         is_price: priceData.price,
    //         was_price: priceData.wasPrice,
    //     };
    //     let prices ={}
    //     if(newPrices.is_price.includes('-')){
    //          prices ={
    //             min_price:Number(newPrices.is_price.split[0]),
    //             max_price:Number(newPrices.is_price.split[1]),
    //             min_was_price:Number(newPrices.was_price.split[0]),
    //              max_was_price:Number(newPrices.was_price.split[1])}
    //     }
    //  else {
    //      prices ={
    //         min_price:Number(newPrices.is_price),
    //         max_price:Number(newPrices.is_price),
    //         min_was_price:Number(newPrices.was_price),
    //          max_was_price:Number(newPrices.was_price)}
    //  }
    //     const {
    //         isPriceString,
    //         isRanged,
    //         isDiscounted,
    //         wasPriceString,
    //     } = this.utils.getPriceObject( prices || this.product);
    //     this.priceObject.is_price = isPriceString;
    //     this.priceObject.was_price = wasPriceString;
    //     this.isRange = isRanged;
    //     this.isDiscounted = isDiscounted;
    //     this.galleryContainer.nativeElement.scrollTo({
    //         left: 0,
    //         behavior: 'smooth',
    //     });
    // }

    onSetPrice(priceData): void {
        // this.salePriceStrike =0;
        // this.productPrice = this.utils.formatPrice(
        //     priceData.price || this.product.min_price
        // );
        // this.productWasPrice = this.utils.formatPrice(
        //     priceData.min_was_price || this.product.max_was_price
        // );
        // if (typeof (this.productPrice) == 'string') {
        //     const minPrice = Number(this.productPrice.split('-')[0]);
        //     const wasMinPrice = Number(this.productWasPrice.split('-')[0]);
        //     this.hasValidWasPrice = !(wasMinPrice <= minPrice);
        // } else {
        //     const minPrice = Number(this.productPrice);
        //     const wasMinPrice = Number(this.productWasPrice);
        //     this.hasValidWasPrice = !(wasMinPrice <= minPrice);
        // }
        // this.isPriceUpdate = true;
        // // this.showSku = true;
        // this.updatedPrice = this.utils.formatPrice(
        //   priceData.price || this.product.min_price
        // );
        // if(this.updatedPrice != this.utils.formatPrice(this.product.min_was_price)){
        //     this.salePriceStrike = this.utils.formatPrice(this.product.min_was_price);
        //   }
        if(!this.productPrice.includes('-')){
            this.salePriceStrike = 0;
            this.productPrice = this.utils.formatPrice(
              priceData.price || this.product.min_price
            );
            this.productWasPrice = this.utils.formatPrice(
              priceData.min_was_price || this.product.max_was_price
            );
            // if (typeof this.productPrice == "string") {
            //   const minPrice = Number(this.productPrice.split("-")[0]);
            //   const wasMinPrice = Number(this.productWasPrice.split("-")[0]);
            //   this.hasValidWasPrice = !(wasMinPrice <= minPrice);
            // } else {
            //   const minPrice = Number(this.productPrice);
            //   const wasMinPrice = Number(this.productWasPrice);
            //   this.hasValidWasPrice = !(wasMinPrice <= minPrice);
            // }
            this.isPriceUpdate = true;
        
            // this.showSku = true;
            this.updatedPrice = this.utils.formatPrice(
              priceData.price || this.product.min_price
            );
            if (
              priceData.price != priceData.wasPrice
            ) {
              this.salePriceStrike = priceData.wasPrice;
            } 
          
          }
          this.isSetItemInInventory = this.activeProduct.inventory_product_details.is_active == '1' ? true : false;
          this.activeProduct.in_inventory = this.activeProduct.inventory_product_details.is_active == '1' ? true : false;
          this.showSku = this.activeProduct.sku?true:false;
    }



 
    openCartModal() {
        if (this.newVariationFound) {
          if (this.newVariationData.Skudetails.length > 0) {
            if (this.newSku) {
              this.saveCart();
            } else {
                this.hasSelection = false;
            }
          } else {
            if (
              this.newVariationData.selection.some((s) => s.requiredSelection == 0)
            ) {
              if (
                this.newSku.split("-").length - 1 ==
                  this.newVariationData.selection.filter(
                    (f) => f.requiredSelection !== 0
                  ).length ||
                this.newSku.split("-").length - 1 ==
                  this.newVariationData.selection.length
              ) {
                this.saveCart();
              } else {
                this.hasSelection = false;
              }
            } else {
              if (
                this.newSku.split("-").length - 1 !==
                this.newVariationData.selection.length
              ) {
                this.hasSelection = false;
              } else {
                this.saveCart();
              }
            }
          }
        } else {
          if (
            (!this.activeProduct.in_inventory &&
              !this.activeProduct.inventory_product_details.price) ||
            !this.beforeSelection
          ) {
            this.hasSelection = false;
          } else {
            this.saveCart();
          }
        }
      }

    saveCart() {
        this.hasSelection = true;
        const data = {
            sku: this.activeProduct.sku,
            brand: this.product.brand_name,
            image: this.items[0].data.src,
            name:
                this.activeProduct.sku === this.product.sku
                    ? this.activeProduct.name
                    : this.product.name + " " + this.activeProduct.name,
            price: this.productPrice,
            quantity: this.quantity,
        }; 
        const postData = {
            product_sku:
              this.newVariationFound && this.newSku
                ? this.newSku
                : this.selectedProductSku,
            count: this.quantity,
            parent_sku: this.product.sku,
            isServiceRepOrder: 0,
            is_virtual: this.newVariationFound && this.newSku ? 1 : 0,
          };
        this.apiService.addCartProduct(postData).subscribe(
            (payload: any) => {
                if (payload.status) {
                    this.errorMessage = "";
                    this.matDialogUtils.openAddToCartDialog(data);
                    this.pixelService.trackAddToCart(data);
                } else {
                    this.errorMessage = payload.msg;
                }
            },
            (error: any) => {
                this.errorMessage = "Cannot add this product at the moment.";
            }
        );
    }

    checkSetInventory(product) {
        for (const item of product) {
            if (item.in_inventory) {
                this.isSetItemInInventory = true;
            }
        }
    }

    updateActiveProduct(product) {
        if ((product.site === 'westelm' || product.site === 'nw') && this.product.variations.length === 1) {
            this.activeProduct = {
                sku: product.variations[0].variation_sku,
                in_inventory: product.variations[0].in_inventory,
                name: product.variations[0].name,
                inventory_product_details:
                    product.variations[0].inventory_product_details,
            };
        } else {
            this.activeProduct = {
                sku: product.variation_sku ? product.variation_sku : product.sku,
                in_inventory: product.in_inventory,
                name: product.name,
                inventory_product_details: product.inventory_product_details
                    ? product.inventory_product_details
                    : [],
            };
        }
        if (product.price && product.was_price) {
            this.product.min_price = Number(product.price);
            this.product.max_price = Number(product.price);
            this.product.min_was_price = Number(product.was_price);
            this.product.max_was_price = Number(product.was_price);
        }
    }

    quantityLimit(count) {
        const maxNumber = count < 10 ? count : 10;
        return Array.from({ length: maxNumber }, Number.call, (i) => i + 1);
    }

    hasVariationsInventory() {
        if (
            this.isVariationExist &&
            this.product.inventory_product_details === null
        ) {
            if (this.product.variations.find((item) => item.in_inventory === true)) {
                this.activeProduct.in_inventory = true;
                this.activeProduct.inventory_product_details.count = 1;
            }
        }
    }

    onSetSelectionChecked(e: boolean) {
        this.beforeSelection = e;
    }

    onClearSelection(e: boolean) {
        this.hasSelection = e;
        this.checkSelection = e;
    }

    onSetSelection(e: boolean) {
        this.hasSelection = e;
    }

    renderPrice(price, wasPrice = false) {
        return this.utils.formatPriceMobile(price, wasPrice);
    }

    isDiscountedCollectionPrice(product): boolean {
        product.is_price = product.price;
        const price = this.utils.getPriceObject(product);
        return price.isDiscounted;
    }

    toCollectionProduct(product) {
        this.router.navigate(['/product', product.sku]);
    }

    openMyReviewModal() {
        this.hasSelection = true;
        const data = {
            sku: this.activeProduct.sku,
            brand: this.product.site,
            image: this.product.main_image,
            name:
                this.activeProduct.sku === this.product.sku
                    ? this.activeProduct.name
                    : this.product.name + ' ' + this.activeProduct.name,
            price: this.productPrice,
            quantity: this.quantity,
        };
        const postData = {
            product_sku: this.activeProduct.sku,
            count: this.quantity,
            parent_sku: this.product.sku,
        };
        this.reviewDialog.openMyReviewDialog(data);
    }

    openAllReviewsPage() {
        this.router.navigate(['/product', 'view-reviews', this.productSku]);
    }

    goToReview(sku) { 
        // window.location.href = './product/review/' + sku;
        this.router.navigateByUrl(`/product/review/${sku}`);
    }

    loadOtherPeopleProducts() {
        this.apiService.getOtherPeopleProducts(this.product.sku).subscribe((response: any[]) => {
            this.otherPeopleProducts = response;
            if (this.otherPeopleProducts.length <= 2) {
                this.otherPeopleOptions.loop = false;
            }

        });
    }

    renderCarouselPrice(price) {
        return this.utils.parsePrice(price);
    }

    isRangedPrice(price: string) {
        const priceArray = price.split('-');
        return priceArray.length > 1;
    }

    updatePrice(e) { 
        this.salePriceStrike = 0;
        this.updatedPrice = JSON.parse(JSON.stringify(this.product.min_price));
        let scenario1 =
          Math.floor(this.product.min_price) + Math.floor(e.sellingPrice);
        let scenario2 =
          Math.floor(this.product.min_was_price) + Math.floor(e.retailPrice);
          if (this.newVariationData.Skudetails.length > 0) {
            this.updatedPrice = (+e.sellingPrice);
            if(Math.floor(e.retailPrice) != Math.floor(e.sellingPrice)){
            this.salePriceStrike = Math.floor(e.retailPrice);
            }
          }else{
            this.updatedPrice = this.updatedPrice + +e.sellingPrice;
            if (scenario1 != scenario2) {
              this.salePriceStrike = scenario2;
            }
          } 
        this.productPrice = this.utils.formatPrice(this.updatedPrice);
        this.isPriceUpdate = true;
    }
    getNewSku(event) {
        if (event === undefined) {
            this.isSetItemInInventory = false; 
            this.activeProduct.in_inventory =   false;
            this.product.in_inventory =   false;
            return;
            // this.product.product_status = "inactive";
          } 
        if (this.newVariationData.Skudetails.length > 0 && event) {
            this.hasSelection = true;
            this.newSku = event;
            this.productSku = event;
            this.items[0].data.src = this.newVariationData.Skudetails.find(f=>f.sku == event).imagePath ? this.newVariationData.Skudetails.find(f=>f.sku == event).imagePath:this.items[0].data.src ;
            this.activeProduct.inventory_product_details.is_drop = this.newVariationData.Skudetails.find(f=>f.sku == event).is_drop;
            this.activeProduct.in_inventory = (this.newVariationData.Skudetails.find(
                (f) => f.sku == event
              ).is_active) === "1" ? true : false;
              this.product.in_inventory = (this.newVariationData.Skudetails.find(
                (f) => f.sku == event
              ).is_active) === "1" ? true : false;
        
              // this.product.product_status =
              //   this.newVariationData.Skudetails.find((f) => f.sku == event)
              //     .is_active === "1"
              //     ? "active"
              //     : "inactive";
        
              this.isSetItemInInventory = (this.newVariationData.Skudetails.find(
                (f) => f.sku == event
              ).is_active) === "1" ? true : false;// this.newVariationData.Skudetails.find(f=>f.sku == event).is_active==='1' ? true:false;
            this.activeProduct.inventory_product_details.count = 1
            // this.checkSetInventory(this.product.set)
          } else {
            this.hasSelection = true;
            this.isSetItemInInventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
            this.activeProduct.in_inventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
            this.product.in_inventory = this.activeProduct.inventory_product_details.is_active === "1" ? true : false;
            this.newSku = this.product.sku + "-" + event;
          }
    }

    showInterCom() {
        (<any>window).Intercom(
            "showNewMessage",
            "Hi there! I see you would like to chat with a design consultant."
        );
    }
    plusSlides(n: number) {
        this.showSlides((this.slideIndex += n));
    }
    currentSlide(n) {
        this.showSlides((this.slideIndex = n));
    }
    showSlides(n) {
        let i;
        let slides = document.getElementsByClassName(
            "pSlides"
        ) as HTMLCollectionOf<HTMLElement>;
        if (slides.length > 0) {
            let dots = document.getElementsByClassName("dot");
            if (n > slides.length) {
                this.slideIndex = 1;
            }
            if (n < 1) {
                this.slideIndex = slides.length;
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active-dot", "");
            }
            slides[this.slideIndex - 1].style.display = "block";
            dots[this.slideIndex - 1].className += " active-dot";
        }
    }
    clearSelection(){
        this.isPriceUpdate = false;
        this.newSku ='';
      }
}