import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment.staging';
import { EnvService } from 'src/app/shared/services/env.service';

@Component({
  selector: 'app-quiz-dialog',
  templateUrl: './quiz-dialog.component.html',
  styleUrls: ['./quiz-dialog.component.less']
})
export class QuizDialogComponent implements OnInit {
  email: string;
  errorText: string;
  submitted = false;
  isHandset = false;
  desktopImageUrl = '';
  mobileImageUrl = ''

  constructor(
      private dialogRef: MatDialogRef<QuizDialogComponent>,
      private router: Router,
      private cookie: CookieService,
      @Inject(MAT_DIALOG_DATA) public data,
      public env:EnvService
  ) {
this.desktopImageUrl = this.env.assetBaseUrl+'site/prompts/sofa-prompt.png';
this.mobileImageUrl = this.env.assetBaseUrl+'site/prompts/sofa-prompt-m.png';
  }

  setStyles() {
      return {
          'background-image': `url('${this.isHandset ? this.mobileImageUrl : this.desktopImageUrl}')`,
          'background-size': this.isHandset ? 'contain' : 'cover',
      };
  }

  ngOnInit() { 
    this.cookie.set('quizPopupShow','true')
      this.isHandset = this.data.handset;
  }

  onClose() {
      this.dialogRef.close();
  }

  submit() {
      this.router.navigate(['/product/quiz']) ;
      this.onClose();
  }
}
