import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilsService} from '../../../../shared/services';

@Component({
    selector: 'app-product-items-carousel',
    templateUrl: './product-items-carousel.component.html',
    styleUrls: ['./product-items-carousel.component.less']
})
export class ProductItemsCarouselComponent implements OnInit {

    @Input() items = [];
    @Input() showBrand = true;
    @Output() productClick = new EventEmitter<any>();
Math:Math=Math;
    imagesToShow = 4;

    constructor(
        private utils: UtilsService,
    ) {
    }

    ngOnInit() {
        // This is required so that carousel doesn't crash
        if (this.items.length < this.imagesToShow) {
            this.addDummyData(this.imagesToShow - this.items.length);
        }
    }

    addDummyData(num: number) {
        for (let i = 0; i < num; i++) {
            this.items = [...this.items, {dummy: true}];
        }
    }

    onClick(productSku) {
        this.productClick.emit(productSku);
    }

    renderPrice(price) {
        return this.utils.parsePrice(price);
    }
}
