import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'; 
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { environment } from '../../../../environments/environment.staging';
import { EnvService } from 'src/app/shared/services/env.service';

@Component({
    selector: 'app-newsletter-popup',
    templateUrl: './newsletter-popup.component.html',
    styleUrls: ['./newsletter-popup.component.less']
})
export class NewsletterPopupComponent implements OnInit {
    email: string;
    errorText: string;
    submitted = false;
    isHandset = false;
    message ='';

    assetBaseUrl = '';
    desktopImageUrl = '';
    mobileImageUrl = '';

    constructor(
        private dialogRef: MatDialogRef<NewsletterPopupComponent>,
        private apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data,
        private cookie:CookieService,
        public env:EnvService,
    ) {

        this.assetBaseUrl = this.env.assetBaseUrl;

    this.desktopImageUrl = this.assetBaseUrl+'site/prompts/NewSubSignUpBlank.png';
    this.mobileImageUrl = this.assetBaseUrl+'site/prompts/NewSubSignUpBlankSq.png';
    }

    setStyles() {
        return {
            'background-image': `url('${this.isHandset ? this.mobileImageUrl : this.desktopImageUrl}')`,
            'background-size': this.isHandset ? 'cover' : 'cover',
            'overflow': 'hidden'
        };
    }

    ngOnInit() {
        this.isHandset = this.data.handset;
    }

    onClose() {
        this.dialogRef.close();
        this.cookie.set('popupShow', '1', 1)
    }

    submitEmail() {
        const emailMatch = this.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        if (!emailMatch) {
            this.errorText = 'Please Enter a valid Email address';
            return;
        }
        if (this.email && emailMatch) {
          
            this.apiService.subscription('PopupForm', this.email).subscribe((response: any) => {
                this.submitted = true;
                this.message = response.message;

        this.cookie.delete('popup');
                if(!response.subscribed){
                    this.apiService.newsLetterSubmit(this.email).subscribe((response: any) => {
               
                    });
                }
            });
        }
    }
}
