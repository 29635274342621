import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {FeatureModule} from './feature/feature.module';
import {SharedModule} from './shared/shared.module';
import {HttpClientModule} from '@angular/common/http'; 
import {FormsModule} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {GalleryModule} from '@ngx-gallery/core';
import {LIGHTBOX_CONFIG, LightboxModule} from '@ngx-gallery/lightbox';
import {MarkdownModule} from 'ngx-markdown';
import {EventEmitterService, MatDialogUtilsService} from './shared/services';
import {NgxStripeModule} from 'ngx-stripe';
import {environment as env} from 'src/environments/environment'; 
import { QuizDesktopComponent } from './quiz/quiz-desktop/quiz-desktop.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ResultPageComponent } from './quiz/result-page/result-page.component';
import { ResultFilterComponent } from './quiz/result-filter/result-filter.component';
import { QuizMobileComponent } from './quiz/quiz-mobile/quiz-mobile.component';
import { ResultFilterMobileComponent } from './quiz/result-filter-mobile/result-filter-mobile.component';
import { MaterialModule } from './shared/material-module';
import { NoQuizDataDialogComponent } from './quiz/no-quiz-data-dialog/no-quiz-data-dialog.component';
import { MailListingComponent } from './feature/containers/mail-listing/mail-listing.component';
import { DesignModule } from './design/design.module';
import { OnboardQuizMobileComponent } from './quiz/onboard-quiz-mobile/onboard-quiz-mobile.component';
import { OnboardQuizDesktopComponent } from './quiz/onboard-quiz-desktop/onboard-quiz-desktop.component';
import { SocialLoginModule } from 'angularx-social-login';
import { ChatModule } from './chat/chat.module';
import { CbInventoryComponent } from './feature/containers/cb-inventory/cb-inventory.component';
import { ProjectDetailsComponent } from './quiz/project-details/project-details.component';
import { DesignSignUpComponent } from './quiz/design-sign-up/design-sign-up.component';
; 

// import { NgApexchartsModule } from 'ng-apexcharts';

const MODULES = [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    FeatureModule,
    SharedModule, 
    FormsModule,
    GalleryModule,
    LightboxModule,
    MarkdownModule.forRoot(),
    NgxStripeModule.forRoot(env.STRIPE_API_KEY), 
    NgxSliderModule,
    MaterialModule,
    DesignModule,
    SocialLoginModule,
    ChatModule,
    
];

@NgModule({
    declarations: [AppComponent, QuizDesktopComponent, ResultPageComponent, ResultFilterComponent, QuizMobileComponent, ResultFilterMobileComponent,
       MailListingComponent , NoQuizDataDialogComponent, OnboardQuizMobileComponent, OnboardQuizDesktopComponent,
    CbInventoryComponent,
    ProjectDetailsComponent,
    DesignSignUpComponent,
    ],
    imports: [...MODULES],
    providers: [
        CookieService,
        EventEmitterService,
        MatDialogUtilsService,
        {
            provide: LIGHTBOX_CONFIG,
            useValue: {
                keyboardShortcuts: false
            }
        }
    ],
    entryComponents:[NoQuizDataDialogComponent],
    bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
