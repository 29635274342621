import {AfterViewInit, Component, DoCheck, Input, OnInit} from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils/utils.service'; 
import { EnvService } from '../../../shared/services/env.service';

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.less']
})
export class AboutusComponent implements OnInit, DoCheck,AfterViewInit {
    logoPath = '';
    isHandset = false;
    isLoggedIn: boolean;
    assetBaseUrl = '';
    bgImg= '';
    constructor(private utils: UtilsService,public env:EnvService) {
      this.logoPath = this.env.logoPathWhite;
      this.assetBaseUrl = this.env.assetBaseUrl;
      if(this.isHandset){
      this.bgImg = this.env.assetBaseUrl+'site/about/about_cover.jpg';
      }else{
        this.bgImg = this.env.assetBaseUrl+'site/about/about_cover_mobile.jpg';
      }

    }

    ngOnInit() {
        this.utils.isHandset().subscribe(handset => {
            this.isHandset = handset.matches;
        
        });
      }
      ngAfterViewInit() {
        console.log('this.isHandset', document.getElementsByClassName('our-features'));
        document.getElementById("about-bg").style.backgroundImage = `url(${this.bgImg})`;
      }
    ngDoCheck(): void {
        this.isLoggedIn = (JSON.parse(localStorage.getItem('user')).email).length >0;
      
    }
    showInterCom() {
        (<any>window).Intercom(
          "showNewMessage",
          "Hi there! I see you would like to chat with us."
        );
      }
}
