import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";
import { BuyCreditsModalComponent } from "../buy-credits-modal/buy-credits-modal.component";
import { MatDialog } from '@angular/material/dialog'; 
import {  MatSnackBar } from '@angular/material/snack-bar'; 
import { environment } from "src/environments/environment";
import { BreakpointObserver, BreakpointState, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs/internal/Observable";
import { Subscription } from "rxjs";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-render",
  templateUrl: "./render.component.html",
  styleUrls: ["./render.component.less"],
})
export class RenderComponent implements OnInit {
  gptData: any;
  creditsRemain: number;
  isLoggedIn: boolean;
  baseUrl = environment.ASSET_BASE_URL;
  renderedData: any;
  isAuthUpdate: boolean = true;
  submission_id: any;
  showLoader: boolean;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset = false;
  constructor(
    private api: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private snackBar:MatSnackBar,
    public env:EnvService
  ) {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem("user"))){
    this.isLoggedIn = JSON.parse(localStorage.getItem("user")).email.length > 0;
    }
    if (!this.api.gptData) {
      this.router.navigate(["/design/gpt"]);
    } else {
      this.getRoomDesign();
      this.getAvailableCredits();
      this.gptData = this.api.gptData;
    }
  }
  getAvailableCredits() {
    this.api.getAvailableCredits().subscribe((res: any) => {
      if (res.data.length == 0) {
        this.creditsRemain = 0;
      } else {
        this.creditsRemain = res.data[0].credits_available;
      }
    });
  }
  getRoomDesign() {
    const formData = new FormData();
    formData.append("rimage", this.api.gptData.file);
    formData.append("room", this.api.gptData.roomSelected.toLowerCase());
    formData.append("style", this.api.gptData.styleSelected.filter_value);
    formData.append("style_name", this.api.gptData.styleSelected.filter_name);
    formData.append("style_id", this.api.gptData.styleSelected.filter_value_id);
    formData.append("is_private", this.api.gptData.privacy=='public'?'0':'1');
    formData.append("submission_id", '');
    this.api.saveRoomDesign(formData).subscribe(
      (res: any) => {
        this.renderedData = res.data;
        this.renderedData.forEach((element) => {
          element.status = null});
        if(this.renderedData && this.renderedData.length>0){
        this.submission_id = res.data[0].submission_id; 
        }
        this.getAvailableCredits();
      },
      (err) => {  
      }
    );
  } 
  saveRoomDesignWithSubId(){
    this.showLoader = true;
    const formData = new FormData();
    formData.append("rimage", this.api.gptData.file);
    formData.append("room", this.api.gptData.roomSelected.toLowerCase());
    formData.append("style", this.api.gptData.styleSelected.filter_value);
    formData.append("style_name", this.api.gptData.styleSelected.filter_name);
    formData.append("style_id", this.api.gptData.styleSelected.filter_value_id);
    formData.append("is_private", this.api.gptData.privacy=='public'?'0':'1');
    formData.append("submission_id", this.submission_id);
    this.api.saveRoomDesign(formData).subscribe(
      (res: any) => {  
        this.renderedData = res.data.sort((a, b) => b.id - a.id);
        if(this.renderedData && this.renderedData.length>0){
        this.submission_id = res.data[0].submission_id; 
        }
        this.showLoader = false;
        this.getAvailableCredits();
      },
      (err) => {  
        this.showLoader = false;
      }
    );
  }
  loggedOut(e){
    if(e){
      this.isLoggedIn = false; 
    }
  }

  loggedIn(e) {
    if (e) {
      this.isLoggedIn = true;
      this.getAvailableCredits();
    }
  }
  resetSelections() {
    this.router.navigate(["/design/gpt"]);
  }
  openShopPage(r) {
    this.router.navigate([`design/gpt/${r.url}-${r.id}`]);
    localStorage.setItem("shoppableImage", JSON.stringify(r.image_path));
    // this.router.navigate([]).then(() => {
    //   window.open(`design/shoppable-image`);
    // });
  }
  likeUnlike(r, l,index) {

    const data = {
      id: r.id,
      like: l,
    };
   this.renderedData[index].status=l;
    this.api.userLikeUnlike(data).subscribe((resp) => {
      this.snackBar.open('Thank you for your feedback!', 'x',{duration:5000});
    });
  }
  openBuyCreditsModal() {
    const dialogRef = this.dialog.open(BuyCreditsModalComponent, {
      disableClose: true,
      panelClass: "purchase-credits-container",
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "success") {
        this.getAvailableCredits(); 
      } 
    });
  }
}
