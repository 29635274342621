import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IReviewsData} from '../../../../shared/models/product-detail-payload.interface';

@Component({
    selector: 'app-product-reviews',
    templateUrl: './product-reviews.component.html',
    styleUrls: ['./product-reviews.component.less']
})
export class ProductReviewsComponent implements OnInit, OnChanges {

    @Input() reviews: IReviewsData;
    @Input() productReviewsCount = 0;
    @Input() starIcons = [];
    @Input() ratings = 0;

    @Output() writeReviewClick = new EventEmitter<any>();
    @Output() seeAllReviewClick = new EventEmitter<any>();

    activeTab = 'mr';

    allReviews: any = [];
    highestReviews: any = [];
    lowestReviews: any = [];
    totalCount = 0;
    totalNo = 0;

    constructor() {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reviews || changes.productReviewsCount) {
            if (changes.reviews.currentValue) {
                this.setReviewsData();
            }
        }
    }

    setReviewsData() {
        if (
            this.reviews.all_reviews &&
            this.reviews.all_reviews.length > 0
        ) {
            this.allReviews = this.reviews.all_reviews;
            this.totalCount = this.productReviewsCount;
            this.totalNo = Number(this.productReviewsCount) * 5;
        }
        if (
            this.reviews.highest_reviews &&
            this.reviews.highest_reviews.length > 0
        ) {
            this.highestReviews = this.reviews.highest_reviews;
        }
        if (
            this.reviews.lowest_reviews &&
            this.reviews.lowest_reviews.length > 0
        ) {
            this.lowestReviews = this.reviews.lowest_reviews;
        }
    }

    selectTab(tab) {
        this.activeTab = tab;
    }

    openWriteReviewModal() {
        this.writeReviewClick.emit();
    }

    openAllReviewsModal() {
        const reviewsData = {
            totalReviews: this.totalCount,
            totalRatings: this.ratings,
        };
        this.seeAllReviewClick.emit(reviewsData);
    }

    renderPercentage(num) {
        return Number.parseFloat(num).toPrecision(2);
    }
}
