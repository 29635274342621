import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { EnvService } from "../../services/env.service";

@Component({
  selector: 'app-no-product-found',
  templateUrl: './no-product-found.component.html',
  styleUrls: ['./no-product-found.component.less']
})
export class NoProductFoundComponent implements OnInit {
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );

  bpSubscription: Subscription;
  isHandset = false;
  constructor(private breakpointObserver: BreakpointObserver,public env:EnvService) {}
  ngOnInit() {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  } 

  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      ""
    );
  }
}
