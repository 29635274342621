import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EnvService } from '../../../../shared/services/env.service';

@Component({
  selector: 'app-quiz-offer-banner',
  templateUrl: './quiz-offer-banner.component.html',
  styleUrls: ['./quiz-offer-banner.component.less']
})
export class QuizOfferBannerComponent implements OnInit {
  isHandSet: boolean;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
);
bpSubscription: Subscription;
  constructor(
    private breakpointObserver: BreakpointObserver,public env: EnvService) { }

  ngOnInit() {
    
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
          this.isHandSet = handset.matches;
      }
  );
  } 

  openLink(){
    window.open('https://www.surveymonkey.com/r/lazysuzy','_blank')
  }
}
