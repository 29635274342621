import { F } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-prd-avail-status-dialog',
  templateUrl: './prd-avail-status-dialog.component.html',
  styleUrls: ['./prd-avail-status-dialog.component.less']
})
export class PrdAvailStatusDialogComponent implements OnInit {
  statusList: any[] = [];
  sku: string;
  constructor(private api: ApiService) { }

  ngOnInit() {
  }
  search() {
    this.api.getWareHouseLocations(this.sku).subscribe((resp: any) => {
      resp.map(m => {
        m.status = 'Pending';
        m.retryAttempts = 1;
      })
      this.statusList = resp;
      this.statusList.forEach(f => {
        f.status = 'Updating...'
        this.getStatus(f);
      })
    })
  }
  async getStatus(data: any) {
    await this.api.getProductShippingStatus(data.zipcode, this.sku, data.brand).then(resp => {
      if (resp.onlineAvailableMessage) {
        data.status = resp.onlineAvailableMessage;
      } else {
        if(data.retryAttempts<5){
        this.getStatus(data);
        data.retryAttempts = data.retryAttempts + 1;
        } else {
          data.status = 'Not Found';
        }
      }
    })
  }
}
