import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesignComponent } from './design.component';
import { GptComponent } from './gpt/gpt.component';
import { ShoppableImageComponent } from './shoppable-image/shoppable-image.component';
import { BuyCreditsModalComponent } from './gpt/buy-credits-modal/buy-credits-modal.component';
import { RenderComponent } from './gpt/render/render.component';
import { AdminGuard } from '../shared/services/admin/admin.guard';

const routes: Routes = [
  {
    path: '',
    component: DesignComponent
  },
  {
    path: 'gpt',
    component: GptComponent
  },
  {
    path: 'gpt/renders',
    component: RenderComponent
  },
  {
    path: 'gpt/:room-:style-:id',
    component: ShoppableImageComponent
  },
  {
    path: 'buy-credits',
    component: BuyCreditsModalComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DesignRoutingModule { }
