import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService, EventEmitterService, MatDialogUtilsService } from 'src/app/shared/services';
import { WishlistSnackbarService } from 'src/app/shared/services/wishlist-service/wishlist-snackbar.service';

@Component({
  selector: 'app-inactive-items-dialog',
  templateUrl: './inactive-items-dialog.component.html',
  styleUrls: ['./inactive-items-dialog.component.less']
})
export class InactiveItemsDialogComponent implements OnInit {
  inactiveItemsList = [];
  isGuest: boolean;
  isSignupBtn: boolean;
  email = '';
  password = '';
  error: boolean;
  errorMsg = '';
  isWishlisted;
  successSignup: boolean;
  isHandSet: boolean;
  constructor(public dialogRef: MatDialogRef<InactiveItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private matDialogUtils: MatDialogUtilsService,
    private snackBarService: WishlistSnackbarService,
    private eventService: EventEmitterService) {
    this.inactiveItemsList = data.oos; 
    this.isHandSet = data.handSet;
  }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.isGuest = user.name == '' ? true : false;

  
  }
  decodeHtml(text: string): string {
    const htmlComponent = document.createElement('textarea');
    htmlComponent.innerHTML = text;
    return htmlComponent.value;
  }
  addToWishlist(type?: string) {
    let isCompCount = 0;
    this.inactiveItemsList.forEach(f => {
      let mark = true;
      f.wishlisted = mark;
      this.apiService.wishlistProduct(f.parent_sku, mark, false).subscribe(
        (_) => {
          isCompCount++;
          if (mark) {
            this.snackBarService.addToWishlist(f.parent_sku);
          } else {
            this.snackBarService.removeIfExistsProduct(f.parent_sku);
          }
          if (isCompCount == this.data.oos.length && !type) {

            this.cancel();
          }
        },
        (error) => {
          f.wishlisted = !mark;
          this.cancel();
        }
      );
    })

  }
  closeDialog(){
    this.matDialogUtils.closeDialogs();
  }

  validateForm(email, password) {
    this.error = false;
    if (!email) {
      this.error = true;
      this.errorMsg = 'Email cannot be blank';
    } else if (!password) {
      this.error = true;
      this.errorMsg = 'Password cannot be blank';
    } else if (password.length < 8) {
      this.error = true;
      this.errorMsg = 'Password must contain 8 characters';
    }
    return !this.error;
  }

  handleError(payload: any) {
    this.error = true;
    this.errorMsg = payload.error;
  }

  login() {
    this.successSignup = false;
    if (this.validateForm(this.email, this.password)) {
      const formData: any = new FormData();
      formData.append('name', name);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('c_password', this.password);
      this.apiService.signup(formData).subscribe(
        (payload: any) => {
          if (payload.success) {
            this.successSignup = true;
            this.error = false;
            this.eventService.fetchUser(
              payload.success.token,
              payload.success.user
            );
            this.markWishlist();
            this.addToWishlist('signin');
            // this.matDialogUtils.closeDialogs();
          }
        },
        (error: any) => {
          if (error.error.error.email) {
            this.error = true;
            this.errorMsg = 'This email already exists';
            this.successSignup = false;
            return false;
          }
        }
      );
    }
  }
  markWishlist() {
    // this.wishlist.emit(true);
  }
  cancel() {
    this.matDialogUtils.closeDialogs();
    this.dialogRef.close('NoThanks');
  }
}
