import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import {
  IFilterData,
  IProductPayload,
  IProductsPayload,
  ISortType,
} from "./../../../shared/models";
import {
  ApiService,
  CacheService,
  EventEmitterService,
  MatDialogUtilsService,
  SeoService,
} from "./../../../shared/services";
import { SCROLL_ICON_SHOW_DURATION } from "./../../../shared/constants";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { first } from "rxjs/operators";
import MetaData from "../../../shared/services/seo/meta-data-model";
import { HttpParams } from "@angular/common/http";
import { EnvService } from "../../../shared/services/env.service";
import { Options } from "ng5-slider";
import { isArray } from "util";
import { CookieService } from "ngx-cookie-service";
import { MatDialog } from "@angular/material/dialog";
import { QuizDialogComponent } from "../quiz-dialog/quiz-dialog.component";
import { NoopScrollStrategy } from "@angular/cdk/overlay";

@Component({
  selector: "app-product-keyword-mapping",
  templateUrl: "./product-keyword-mapping.component.html",
  styleUrls: ["./product-keyword-mapping.component.less"],
})
export class ProductKeywordMappingComponent implements OnInit {
  @ViewChild("productFiltersComponent", { static: false }) productFilter: any;
  productsSubscription: Subscription;
  isBrandPageSubscription: Subscription;
  routeSubscription: Subscription;
  products: IProductPayload[];
  productFilters: IFilterData;
  trend: string;
  category: string;
  totalCount = 0;
  filters = "";
  sortType = "";

  sortTypeList: ISortType[];
  pageNo = 0;
  topPosToStartShowing = 300;
  fixFilterBar = 150;
  isIconShow = false;
  showBar = false;
  isProductFetching = false;
  spinner = "assets/image/spinner.gif";
  showMobileFilter = false;
  showMobileSort = false;
  productsInRow = 2;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  timeout: any;
  bpSubscription: Subscription;
  isHandset = false;
  total = 24;
  scrollToProductSKU = "";
  eventSubscription: Subscription;
  brandData: any = {
    cover_image: "",
    description: "",
    logo: "",
    name: "",
    url: "",
    value: "",
  };
  selectedBrandValue: string = "";
  isBrandPage: boolean = false;
  isChangingBrandList: boolean = false;
  showFilters = false;
  pageKey;
  user: any;

  pageTitle: any = "";
  color: string;
  shape: string;
  filterData: any;
  sliderOptions: Options = {
    step: 1,
  };
  priceFilter = {};
  widthFilter = {};
  heightFilter = {};
  lengthFilter = {};
  depthFilter = {};
  diameterFilter = {};

  diameter_from: any;
  diameter_to: any;
  width_from: any;
  width_to: any;
  height_from: any;
  height_to: any;
  length_from: any;
  length_to: any;
  depth_from: any;
  depth_to: any;
  price_from: any;
  price_to: any;
  originalFilterData: any;
  showBottomPanel = true;
  filteredElements: string[] = [];
  defaultFilters = ["color", "shape", "style"];
  style: string = "";
  item: any;
  height: number;
  department_long: any;
  keyword: string;
  filterKeys: any;
  orderedFilterData: any[] = [];
  sizeBackup: any;
  iscurrentFilterApplied: boolean;
  filterURL: any;
  subCategories: any[] = [];
  width: number;
  urlFilter: any;
  activeTab: any;
  showLoader: boolean;
  storedFilterData: any;
  constructor(
    public apiService: ApiService,
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    public cacheService: CacheService,
    private eventEmitterService: EventEmitterService,
    public env: EnvService,
    private cookie: CookieService,
    private seoService: SeoService,
    private matDialogUtils: MatDialogUtilsService
  ) {
    document.addEventListener("click", this.findTopmostParent.bind(this));
    this.height = window.innerHeight;
    this.user = JSON.parse(localStorage.getItem("user"));

    if (
      this.activeRoute.snapshot.routeConfig.path.includes("products/sale") ||
      this.activeRoute.snapshot.routeConfig.path.includes("sale")
    ) {
      this.keyword = "sale";
    } else if (
      this.activeRoute.snapshot.routeConfig.path.includes("products/new")
    ) {
      this.keyword = "new";
    } else {
      this.keyword = this.activeRoute.snapshot.params.keyword;
    }

    this.isBrandPageSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        const checkBrandPage = url.indexOf("/brand");
        if (checkBrandPage > -1) {
          this.isBrandPage = true;
        }
      }
    });
  }
  findTopmostParent(element) {
    let parent = element.target.parentNode;
    let isOk;
    let firstNode = parent.className === "filter-sectionCheck" ? true : false;
    while (parent.parentNode) {
      if (parent.id === "mySidenav") {
        isOk = true;
        break;
      }
      parent = parent.parentNode;
    }
    if (!isOk && !firstNode) {
      this.closeNav();
    }
  }

  @HostListener("window:resize")
  onResize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }

  toggleFilterBar() {
    this.showFilters = !this.showFilters;
  }

  @HostListener("window:wheel", ["$event"])
  onWindowScroll() {
    const fixedSection = document.querySelector(
      ".fixed-prod-head"
    ) as HTMLElement;
    if (fixedSection) {
      const initialOffset = fixedSection.offsetTop;

      if (window.pageYOffset >= 550 || this.cookie.get("popupShow") === "") {
        fixedSection.classList.add("fixed-key");
      } else {
        fixedSection.classList.remove("fixed-key");
      }
      if (window.pageYOffset === 0) {
        fixedSection.classList.remove("fixed-key");
      }
    }
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.width = window.innerWidth;
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        this.getParamsFromQuery();
        this.bpSubscription = this.bpObserver.subscribe(
          (handset: BreakpointState) => {
            this.isHandset = handset.matches;
          }
        );
      });
  }

  clearFilters() {
    this.filters = "";
    this.loadProducts();
  }

  onDestroy(): void {
    this.productsSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.bpSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
    this.isBrandPageSubscription.unsubscribe();
  }

  getParamsFromQuery(): void {
    const routeConfig = this.activeRoute.routeConfig.path;

    this.routeSubscription = this.activeRoute.queryParams.subscribe(
      (params) => {
        this.filters = params.filters || "";
        this.urlFilter = JSON.parse(JSON.stringify(this.filters));
        this.filterURL = JSON.parse(JSON.stringify(this.filters));
        this.getFilteredEle(params.filters);
        const metaData: MetaData = {};
        if (routeConfig.includes("new")) {
          metaData.title = `Shop New Arrivals for your Home | ${this.env.appName}`;
        }
        if (routeConfig.includes("sale")) {
          metaData.title = `Shop Top Deals for your Home | ${this.env.appName}`;
        }
        if (routeConfig.includes("bestseller")) {
          metaData.title = `Shop Best Sellers for your Home | ${this.env.appName}`;
        }

        const checkBrand = this.activeRoute.snapshot.params.brand;
        if (!checkBrand) {
          this.selectedBrandValue = "";
        } else {
          // const restString = this.filters.slice(checkBrand + 6);
          // const endBrand = restString.indexOf(';');
          // this.selectedBrandValue = endBrand >= 0 ? restString.substr(0, endBrand) : restString;
          this.selectedBrandValue = checkBrand;
          if (this.isBrandPage) this.getBrandData(this.selectedBrandValue);
        }

        this.seoService.setMetaTags(metaData);

        this.pageNo = parseInt(params.pageNo) || 0;
        this.sortType = params.sortType || "";
        this.trend = "sale"; // for now need to change later
        Object.keys(params).map((key) => {
          // if (key === 'new' || key === 'bestseller' || key === 'sale' || key === 'trending' || key === 'spacesaver') {
          //   this.trend = key;
          // }
        });
        if (this.isBrandPage === true) {
          this.eventEmitterService.isBrandSubject.next(this.selectedBrandValue);
        } else {
          this.pageKey = this.trend;
        }
        this.getFiltersFromQuery();
        this.checkPage(true);
      }
    );
    this.setTitle(this.brandData.name);
  }

  getFilteredEle(data) {
    if (data) {
      this.filteredElements = [];
      let matches = data.match(/;([^:]+):/g);
      let extractedCharacters = [];
      if (data.split(":")[0]) {
        extractedCharacters.push(data.split(":")[0]);
      }

      if (matches) {
        for (let match of matches) {
          let extracted = match.substring(1, match.length - 1);
          // Removing the ';' and ':'
          if (extracted !== "category") {
            extractedCharacters.push(extracted);
          }
        }
      }
      this.filteredElements = extractedCharacters;
    }
  }

  uncheckAllItems(data: any[]) {
    data.forEach((category: any) => {
      Object.keys(category).forEach((key: string) => {
        if (Array.isArray(category[key])) {
          category[key].forEach((item: any) => {
            item.checked = false;

            Object.keys(item).forEach((it: string) => {
              if (Array.isArray(item[it])) {
                item[it].forEach((em: any) => {
                  em.checked = false;
                });
              }
            });
          });
        }
      });
    });
  }

  getFilterAndLoadProducts(i: number) {
    this.subCategories[i].checked = !this.subCategories[i].checked;

    this.filters = this.formFilterURL();
    this.apiService
      .getFilteredData(this.keyword + "", this.filters, this.sortType, "")
      .subscribe(
        (payload: any) => {
          this.productFilters = payload.filterData;
          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();
          this.filters = "";
          this.checkPage();
        },
        (err) => {
          this.isProductFetching = false;
        }
      );
    this.loadProducts();
  }

  getFilterData(onlyClass = false) {
    let filterData = this.formFilterURL();
    this.filters = filterData;
    this.storedFilterData = JSON.parse(JSON.stringify(this.orderedFilterData));
    this.apiService
      .getFilteredData(this.keyword + "", this.filters, this.sortType, "")
      .subscribe(
        (payload: any) => {
          this.productFilters = payload.filterData;
          if (this.filterURL) {
            const inputString = JSON.parse(JSON.stringify(this.filterURL));
            const pairedData = this.apiService.pairKeyValues(inputString);
            pairedData.forEach((f) => {
              if (
                this.productFilters[f.key] &&
                f.key !== "size" &&
                f.key !== "price"
              ) {
                f.value.forEach((v) => {
                  this.productFilters[f.key].forEach((element) => {
                    if (element.value == v) {
                      element.checked = true;
                    }
                  });
                });
              }
            });
          }
          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();

          if (
            this.subCategories.length == 0 &&
            this.filterURL.includes("class")
          ) {
            this.getAvailableSubCategories();
          }

          if (onlyClass) {
            this.orderedFilterData.find((f) => f.key === "class").opened = true;
          }

          this.showLoader = false;
          this.filters = "";
        },
        (err) => {
          this.isProductFetching = false;
        }
      );
    // }
  }

  getFiltersFromQuery() {
    let filterData = this.formFilterURL();

    if (this.urlFilter) {
      this.filters = this.urlFilter;
    } else {
      this.filters = filterData;
    }
    // }
    this.showLoader = true;
    this.storedFilterData = JSON.parse(JSON.stringify(this.orderedFilterData));
    this.apiService
      .getFilteredData(this.keyword + "", this.filters, this.sortType, "")
      .subscribe(
        (payload: any) => {
          this.productFilters = payload.filterData;
          if (this.filterURL) {
            const inputString = JSON.parse(JSON.stringify(this.filterURL));
            const pairedData = this.apiService.pairKeyValues(inputString);
            pairedData.forEach((f) => {
              if (
                this.productFilters[f.key] &&
                f.key !== "size" &&
                f.key !== "price"
              ) {
                f.value.forEach((v) => {
                  this.productFilters[f.key].forEach((element) => {
                    if (element.value == v) {
                      element.checked = true;
                    }
                  });
                });
              }
            });
          }
          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();

          if (
            this.subCategories.length == 0 &&
            this.filterURL.includes("class")
          ) {
            this.getAvailableSubCategories();
          }
          this.showLoader = false;
          this.filters = "";
        },
        (err) => {
          this.isProductFetching = false;
        }
      );
  }

  checkPage(isFilter?) {
    if (this.pageNo > 0) {
      this.isProductFetching = true;

      let filterData = this.formFilterURL();

      if (isFilter) {
        this.filters = this.urlFilter;
      } else {
        this.filters = filterData;
      }

      this.filterURL = JSON.parse(JSON.stringify(this.filters));

      this.productsSubscription = this.apiService
        .getMultiplePageAllProducts(
          this.trend,
          this.total,
          this.filters,
          this.sortType,
          this.pageNo
        )
        .subscribe((response) => {
          let allProducts = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < response.length; i++) {
            allProducts = [...allProducts, ...response[i].products];
          }
          this.products = allProducts;
          this.updateQueryString();
          this.totalCount = response[0].total;
          // this.productFilters = response[0].filterData;
          // this.sortTypeList = response[0].sortType;
          this.isProductFetching = false;
          if (this.cacheService.data.useCache) {
            this.scrollToProductSKU = this.cacheService.data.productSku;
            this.cacheService.data.useCache = false;
            setTimeout(() => {
              // this.productElement.nativeElement.getElementById
              const el = document.getElementById(this.scrollToProductSKU);
              window.scrollTo(0, el.offsetTop - 200);
            }, 500);
          }
        });
    } else {
      this.loadProducts(isFilter);
    }

    // Code for cached product sku
  }

  loadProducts(isFilter?): void {
    this.pageNo = 0;
    this.isProductFetching = true;

    if (isFilter) {
      this.filters = this.urlFilter;
    } else {
      let filterData = this.formFilterURL();
      this.filters = filterData;
    }

    this.filterURL = JSON.parse(JSON.stringify(this.filters));
    this.productsSubscription = this.apiService
      .getAllProductsUsingKeyword(
        this.keyword,
        this.total,
        this.filters,
        this.sortType,
        0
      )
      .pipe(first())
      .subscribe(
        (payload: IProductsPayload) => {
          if (
            this.keyword !== "sale" &&
            this.keyword !== "new" &&
            this.keyword !== "all"
          ) {
            if (payload.products.length === 0) {
              this.router.navigate(["/"]);
            }
          }
          this.products = payload.products;
          // this.productFilters = payload.filterData;
          // this.sortTypeList = payload.sortType;
          this.totalCount = payload.total;
          this.updateQueryString();
          this.isProductFetching = false;
          this.isChangingBrandList = false;
        },
        (err) => {
          this.products = [];
          this.isProductFetching = false;
          this.isChangingBrandList = false;
        }
      );
  }

  updateQueryString(): void {
    let params = new HttpParams();
    // if (this.trend) {
    //   params = params.set(this.trend, 'true');
    // }

    if (this.filterURL) {
      params = params.set("filters", this.filterURL);
    }
    if (this.sortType) {
      params = params.set("sortType", this.sortType);
    }
    if (this.pageNo) {
      params = params.set("pageNo", this.pageNo.toString());
    }
    setTimeout(() => {
      this.location.replaceState(
        window.location.pathname,
        params.toString(),
        this.location.getState()
      );

      if ((this.pageNo == 1 || this.pageNo == 2) && !this.isLoggedIn) {
        this.matDialogUtils.openNewsLetter(this.isHandset)
      }
    }, 1000);
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }
  
  getProductwithBrandName(brandValue: string) {
    if (this.isBrandPage) {
      if (this.selectedBrandValue !== brandValue) {
        this.isChangingBrandList = true;
        this.selectedBrandValue = brandValue;
        this.getBrandData(brandValue);
      }
    }
  }

  getBrandData(brandValue: string) {
    this.apiService.getBrandData(brandValue).subscribe((brandData: any) => {
      if (brandData.length !== 0) {
        this.brandData = brandData[0];
        this.pageKey = this.brandData.name;
        if (brandValue !== "") {
          this.setTitle(this.brandData);
        }
      } else {
        this.location.replaceState("", "", this.location.getState());
        this.router.navigate(["/"], {
          queryParams: {
            param1: "",
            param2: "",
          },
        });
      }
    });
  }

  setTitle(brand: any) {
    const metaData: MetaData = {};
    if (this.trend == "new") {
      brand = { name: "new" };
    }
    metaData.title = `A personalized discovery and shopping experience for your home| ${this.env.appName}`;
    metaData.description = brand.description;
    metaData.image = brand.logo;
    this.seoService.setMetaTags(metaData);
  }

  ngOnDestroy(): void {
    // this.setTitle();
  }

  setPrdFilters(e) {
    this.productFilter = e.pf;
    this.activeTab = e.tab;
  }
  onSetFilters(e): void {
    if (e || e === "") {
      this.filters = e;
      if (this.filters) {
        this.filters = this.filters.replace(/category/g, "class");
      }
      this.getFilteredEle(e);
      if (e.includes("class") && this.activeTab === "class") {
        this.getFilterData(true);
      }
      this.applyFilter(this.filters, false);
    }
  }

  onSetSortType(e): void {
    this.sortType = e;
    this.loadProducts();
    this.getFilterData();
  }

  onSetMobileToggle($e): void {
    this.toggleMobileFilter();
  }

  onSetSortToggle($e): void {
    this.toggleMobileSort();
  }

  onScroll() {
    if (this.isProductFetching) {
      return;
    }
    this.pageNo += 1;
    this.isProductFetching = true;

    let filterData = this.formFilterURL();

    this.filters = filterData;
    this.productsSubscription = this.apiService
      .getAllProductsUsingKeyword(
        this.keyword,
        this.total,
        this.filters,
        this.sortType,
        this.pageNo
      )
      .subscribe((payload: IProductsPayload) => {
        this.products = [...this.products, ...payload.products];
        this.updateQueryString();
        this.isProductFetching = false;
      });
  }

  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.isIconShow = scrollPosition >= this.topPosToStartShowing;
    this.showBar = scrollPosition >= this.fixFilterBar;
    const self = this;
    if (this.isIconShow) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        self.isIconShow = false;
      }, SCROLL_ICON_SHOW_DURATION);
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  toggleMobileFilter() {
    this.showMobileFilter = !this.showMobileFilter;
    if (this.showMobileFilter) {
      this.hideIntercom();
    } else {
      this.showIntercom();
    }
  }

  hideIntercom() {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    if (intercom && intercom.classList) {
      intercom.classList.add("dn");
    }
  }

  showIntercom() {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    if (intercom && intercom.classList) {
      intercom.classList.remove("dn");
    }
  }

  toggleMobileSort() {
    this.showMobileSort = !this.showMobileSort;
  }

  toggleMobileView() {
    if (this.productsInRow === 3) {
      this.productsInRow = 1;
    } else {
      this.productsInRow += 1;
    }
  }

  assignSliderData(event, name) {
    if (name == "diameter") {
      this.diameter_from = event.minValue;
      this.diameter_to = event.maxValue;
    } else if (name == "width") {
      this.width_from = event.minValue;
      this.width_to = event.maxValue;
    } else if (name == "height") {
      this.height_from = event.minValue;
      this.height_to = event.maxValue;
    } else if (name == "length") {
      this.length_from = event.minValue;
      this.length_to = event.maxValue;
    } else if (name == "depth") {
      this.depth_from = event.minValue;
      this.depth_to = event.maxValue;
    } else if (name == "price") {
      this.price_from = event.minValue;
      this.price_to = event.maxValue;
    }
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }
  }

  resetFilter() {
    this.filterData = JSON.parse(JSON.stringify(this.originalFilterData));
    this.filterData["class"] = JSON.parse(
      JSON.stringify(this.originalFilterData["class"])
    );
    this.subCategories = [];
    this.orderedFilterData.forEach((f) => {
      if (f.value && isArray(f.value) && f.value.length > 0) {
        f.value.forEach((element) => {
          if (element.checked) {
            element.checked = false;
          }
        });
      }
    });

    this.iscurrentFilterApplied = false;

    this.sizeBackup = JSON.parse(
      JSON.stringify(this.originalFilterData["size"])
    );
    this.widthFilter = this.sizeBackup["width"];
    this.heightFilter = this.sizeBackup["height"];
    this.lengthFilter = this.sizeBackup["length"];
    this.depthFilter = this.sizeBackup["depth"];
    // this.productFilters["size"] = [{ name: "size" }];
    this.priceFilter = this.productFilters["price"];
    this.priceFilter["name"] = "price";
    this.diameter_from = null;
    this.diameter_to = null;
    this.width_from = null;
    this.width_to = null;
    this.height_from = null;
    this.height_to = null;
    this.length_from = null;
    this.length_to = null;
    this.depth_from = null;
    this.depth_to = null;
    this.price_from = null;
    this.price_to = null;
    this.filteredElements = [];
    this.uncheckAllItems(this.filterData["class"]);

    let filterData = this.formFilterURL();

    this.filters = filterData;
    this.productsSubscription = this.apiService
      .getAllProductsUsingKeyword(
        this.keyword,
        this.total,
        this.filters,
        this.sortType,
        0
      )
      .pipe(first())
      .subscribe(
        (payload: IProductsPayload) => {
          this.products = payload.products;

          // delete payload.filterData.category;
          // this.productFilters = payload.filterData;
          // this.sortTypeList = payload.sortType;

          this.totalCount = payload.total;
          this.filterURL = "";
          this.updateQueryString();
          this.isProductFetching = false;
          this.closeNav();
        },
        (error) => {
          this.isProductFetching = false;
        }
      );
  }
  formFilterURL(): string {
    let filterUrl = "";
    if (this.productFilters) {
      this.filteredElements.forEach((f) => {
        if (
          !f.includes("price") &&
          !f.includes("diameter") &&
          !f.includes("width") &&
          !f.includes("height") &&
          !f.includes("length") &&
          !f.includes("depth") &&
          f != "class"
        ) {
          let checked = [];
          if(this.productFilters[f]){
          this.productFilters[f].forEach((element) => {
            if (element.checked) {
              checked.push(element.value);
            }
          });
        }
          filterUrl =
            filterUrl +
            (checked.length > 0 ? `${f}:${checked.join(",")};` : "");
        } else if (f == "class") {
          if (this.productFilters[f]) {
            let catchecked = [];
            this.productFilters[f].forEach((element) => {
              if (element.checked) {
                catchecked.push(element.value);
              }
            });
            filterUrl = filterUrl + `${"class"}:${catchecked.join(",")};`;
          }
        } else if (f == "price") {
          filterUrl =
            filterUrl +
            `${"price_from"}:${this.price_from};${"price_to"}:${
              this.price_to
            };`;
        } else if (f == "diameter") {
          filterUrl =
            filterUrl +
            `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${
              this.diameter_to
            };`;
        } else if (f == "width") {
          filterUrl =
            filterUrl +
            `${"width_from"}:${this.width_from};${"width_to"}:${
              this.width_to
            };`;
        } else if (f == "height") {
          filterUrl =
            filterUrl +
            `${"height_from"}:${this.height_from};${"height_to"}:${
              this.height_to
            };`;
        } else if (f == "length") {
          filterUrl =
            filterUrl +
            `${"length_from"}:${this.length_from};${"length_to"}:${
              this.length_to
            };`;
        } else if (f == "depth") {
          filterUrl =
            filterUrl +
            `${"depth_from"}:${this.depth_from};${"depth_to"}:${
              this.depth_to
            };`;
        }
      });
    }
    if (this.subCategories && this.subCategories.some((s) => s.checked)) {
      let category = this.subCategories
        .filter((s) => s.checked)
        .map((s) => s.category_id);
      filterUrl = filterUrl + `category:${category.join(",")};`;
    } else {
      if (!filterUrl) {
        return this.filterURL;
      }
    }
    return filterUrl;
  }
  applyFilter(filterFromMobile, onlyCls) {
    let filterData = this.formFilterURL();

    this.filters = filterFromMobile ? filterFromMobile : filterData;

    if (this.filters) {
      if(this.filters.includes(this.filterURL)){
      this.filterURL = JSON.parse(JSON.stringify(this.filters));
      } else {
        this.filterURL = this.filterURL+ ';'+ JSON.parse(JSON.stringify(this.filters));
        this.filterURL=this.filterURL.replace(/;;/g,';').split(';').reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]).join(';')
      }
    }
    this.iscurrentFilterApplied = false;

    this.productsSubscription = this.apiService
      .getAllProductsUsingKeyword(
        this.keyword,
        this.total,
        this.filters,
        this.sortType,
        0
      )
      .pipe(first())
      .subscribe(
        (payload: IProductsPayload) => {
          this.products = payload.products;

          // delete payload.filterData.category;
          // this.productFilters = payload.filterData;
          // this.sortTypeList = payload.sortType;

          this.totalCount = payload.total;
          this.updateQueryString();
          this.isProductFetching = false;
          if (!onlyCls) {
            this.closeNav();
          }
        },
        (error) => {
          this.isProductFetching = false;
        }
      );
    // this.apiService
    //   .getProduct(data, filterData, this.pageNo)
    //   .subscribe((response: any) => {
    //     // this.productList =  response.products;
    //     // if (this.productList.length === 0) {
    //     //   this.productList = [...response.products];
    //     // } else {
    //     //   this.productList = [...this.productList, ...response.products];
    //     // }
    //   });
  }

  getAvailableSubCategories() {
    let filterData = this.formFilterURL();
    this.filters = filterData;
    if (this.filters) {
      this.filterURL = JSON.parse(JSON.stringify(this.filters));
    }
    if (this.filterURL && this.filterURL.includes("class:")) {
      if (this.filterURL.split("class:")[1] !== "") {
        const category = this.filterURL
          .split("class:")[1]
          .split(";")[0]
          .split(",");
        if (category.length > 0) {
          this.subCategories = [];
          this.filterData["class"].forEach((c) => {
            category.forEach((cat) => {
              if (c.value === cat) {
                c.department.forEach((d) => {
                  let data = d.categories.filter((f) => f.enabled === true);

                  if (this.subCategories.length > 0) {
                    this.subCategories = [
                      ...new Set([...this.subCategories, ...data]),
                    ];
                  } else {
                    this.subCategories = data;
                  }
                });
              }
            });
          });
          this.subCategories = this.subCategories.filter(
            (item, index) => this.subCategories.indexOf(item) === index
          );
          console.log(this.subCategories,'SC')
        } else {
          this.subCategories = [];
        }
      } else {
        this.subCategories = [];
      }
    } else {
      this.subCategories = [];
    }
  }

  selectFilter(d, name) {
    d.checked = !d.checked;

    this.productFilters[name].find((f) => f.name === d.name).checked =
      d.checked;

    if (this.productFilters[name].filter((f) => f.checked).length > 0) {
      if (!this.filteredElements.includes(name)) {
        this.filteredElements.push(name);
      }
    } else {
      this.filteredElements = this.filteredElements.filter((f) => f != name);
    }
    if (name === "class") {
      this.getAvailableSubCategories();
      this.getFilterData(true);
      this.applyFilter("", true);
    } else {
      this.getFilterData(false);
    }
    this.iscurrentFilterApplied = true;
  }
  selectAll(name) {
    this.productFilters[name].forEach((element) => {
      element.checked = true;
    });
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }
    if (name === "class") {
      this.getAvailableSubCategories();
      this.getFilterData(true);
      this.applyFilter("", true);
    } else {
      this.getFilterData(false);
    }

    this.iscurrentFilterApplied = true;
  }
  unSelectAll(name) {
    this.productFilters[name].forEach((element) => {
      element.checked = false;
    });
    this.filteredElements = this.filteredElements.filter((f) => f != name);
    if (name === "class") {
      this.getAvailableSubCategories();
      this.getFilterData(true);
      this.applyFilter("", true);
    } else {
      this.getFilterData(false);
    }

    this.iscurrentFilterApplied = true;
  }
  getFiltersData() {
    let filterUrl = "";

    delete this.productFilters["diameter"];
    delete this.productFilters["square"];

    for (var k in this.productFilters) {
      if (
        this.productFilters[k] &&
        isArray(this.productFilters[k]) &&
        (this.productFilters[k].length == 0 ||
          this.productFilters[k].every((e) => e.enabled == false))
      ) {
        delete this.productFilters[k];
      }
    }
    this.orderedFilterData = [];

    for (let i = 0; i < this.filterKeys.length; i++) {
      if (this.productFilters[this.filterKeys[i]]) {
        this.orderedFilterData.push({
          key: this.filterKeys[i],
          value: this.productFilters[this.filterKeys[i]],
          opened: false,
        });
      }
    }
    if (this.storedFilterData && this.storedFilterData.length > 0) {
      this.orderedFilterData = this.apiService.compareAndUpdate(
        this.storedFilterData,
        this.orderedFilterData
      );
    }

    this.filterData = this.productFilters;
    this.originalFilterData = JSON.parse(JSON.stringify(this.filterData));

    this.sizeBackup = JSON.parse(JSON.stringify(this.productFilters["size"]));
    this.widthFilter = this.sizeBackup["width"];
    this.heightFilter = this.sizeBackup["height"];
    this.lengthFilter = this.sizeBackup["length"];
    this.depthFilter = this.sizeBackup["depth"];
    this.priceFilter = this.productFilters["price"];
    this.priceFilter["name"] = "price";

    this.filteredElements.forEach((f) => {
      if (
        !f.includes("price") &&
        !f.includes("diameter") &&
        !f.includes("width") &&
        !f.includes("height") &&
        !f.includes("length") &&
        !f.includes("depth")
      ) {
        let checked = [];
        if(this.filterData[f]){
        this.filterData[f].forEach((element) => {
          if (element.checked) {
            checked.push(element.name);
          }
        });
      }
        filterUrl = filterUrl + `${f}:${checked.join(",")};`;
      } else if (f == "price") {
        filterUrl =
          filterUrl +
          `${"price_from"}:${this.price_from};${"price_to"}:${this.price_to};`;
      } else if (f == "diameter") {
        filterUrl =
          filterUrl +
          `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${
            this.diameter_to
          };`;
      } else if (f == "width") {
        filterUrl =
          filterUrl +
          `${"width_from"}:${this.width_from};${"width_to"}:${this.width_to};`;
      } else if (f == "height") {
        filterUrl =
          filterUrl +
          `${"height_from"}:${this.height_from};${"height_to"}:${
            this.height_to
          };`;
      } else if (f == "length") {
        filterUrl =
          filterUrl +
          `${"length_from"}:${this.length_from};${"length_to"}:${
            this.length_to
          };`;
      } else if (f == "depth") {
        filterUrl =
          filterUrl +
          `${"depth_from"}:${this.depth_from};${"depth_to"}:${this.depth_to};`;
      }
    });
    // this.urlFilter = filterUrl;
  }

  getCheckedLength(name): boolean {
    return this.filterData[name].filter((f) => f.checked).length > 0;
  }
  getSelections(name): string {
    return this.filterData[name]
      .filter((f) => f.checked)
      .map((f) => f.name)
      .join(",");
  }
  clearSelections(name) {
    this.filterData[name].forEach((element) => {
      element.checked = false;
    });
    this.filteredElements = this.filteredElements.filter((f) => f != name);
    this.applyFilter("", false);
  }

  get getDeptName() {
    if (this.filterURL && this.filterURL.includes("class:")) {
      if (this.filterURL.split("class:")[1].includes(",")) {
        return " - Custom";
      } else if (this.filterURL.split("class:")[1] !== "" && this.filterData) {
        if (
          this.filterURL.split("class:")[1] &&
          this.filterURL.split("class:")[1].split(";")[0]
        ) {
          const name = this.filterData["class"].find(
            (f) => f.value === this.filterURL.split("class:")[1].split(";")[0]
          ).name;
          return " - " + name;
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "650px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    this.apiService.isSideNavOpened = true;
  }

  closeNav() {
    if (document.getElementById("mySidenav")) {
      document.getElementById("mySidenav").style.width = "0";
      document.body.style.backgroundColor = "white";
      this.apiService.isSideNavOpened = false;
    }
  }
  collapSection(o: any) {
    o.opened = !o.opened;
  }
  showClear(key) {
    if (this.filterData[key] && this.filterData[key].length > 0) {
      return this.filterData[key].some((f) => f.checked);
    } else {
      return false;
    }
  }
}
