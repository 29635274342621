import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  ProductFiltersModule,
  ProductModule,
  SortTypeModule
} from './../../components';

import { ProductsComponent } from './products.component';
import { ProductMobileModule } from '../../components/product-mobile/product-mobile.module';
import { ProductFilterMobileModule } from '../../components/product-filter-mobile/product-filter-mobile.module';
import { ProductSortMobileModule } from '../../components/product-sort-mobile/product-sort-mobile.module';
import { BrandListModule } from '../../components/brand-list/brand-list.module';
import {CollectionListModule} from "../../components/collection-list/collection-list.module";
import { ProductCollectionMobileComponent } from '../../components/product-collection-mobile/product-collection-mobile.component';
import { SharedModule } from 'src/app/shared/shared.module';


const DECLARATIONS = [ProductsComponent,ProductCollectionMobileComponent];

const MODULES = [
  CommonModule,
  ProductModule,
  ProductFiltersModule,
  SortTypeModule,
  MatToolbarModule,
  MatIconModule,
  InfiniteScrollModule,
  ProductMobileModule,
  ProductFilterMobileModule,
  ProductSortMobileModule,
  BrandListModule,
  SharedModule
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES, CollectionListModule], 
  exports: [...DECLARATIONS, ...MODULES]
})
export class ProductsModule {}
