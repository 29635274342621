import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProductDetailsComponent } from './../product-details/product-details.component';
import { ConfirmCartProductComponent } from '../confirm-cart-product/confirm-cart-product.component';
import { ProductComponent } from './product.component';
import { MatButtonModule } from '@angular/material/button';
import { VariationsModule } from '../variations/variations.module';
import { RedirectModule } from '../redirect/redirect.module';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms'; 
import { CarouselModule } from 'primeng/carousel';
import { ReviewFormComponent } from './../reviewForm/reviewForm.component';
import { ReviewDesktopComponent } from '../review-desktop/review-desktop.component';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { AllReviewsDesktopComponent } from '../all-reviews-desktop/all-reviews-desktop.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GalleryModule } from '@ngx-gallery/core';
import { OwlModule } from 'ngx-owl-carousel';
import { ProductFeaturesComponent } from '../product-details/product-features/product-features.component';
import { ProductItemsCarouselComponent } from '../product-details/product-items-carousel/product-items-carousel.component';
import { ProductUpgradesComponent } from '../product-details/product-upgrades/product-upgrades.component';
import { ProductReviewsComponent } from '../product-details/product-reviews/product-reviews.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductCollectionComponent } from './product-collection/product-collection.component';
import { ImageArrangePipe } from '../product-details/image-arrange.pipe';
import { MarkdownModule } from 'ngx-markdown';

const DECLARATIONS = [
    ProductComponent,
    ProductDetailsComponent,
    ReviewFormComponent,
    ConfirmCartProductComponent,
    ReviewDesktopComponent,
    AllReviewsDesktopComponent,
    ProductFeaturesComponent,
    ProductItemsCarouselComponent,
    ProductUpgradesComponent,
    ProductReviewsComponent,
    ProductCollectionComponent,
    ImageArrangePipe
];

const MODULES = [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    VariationsModule,
    RedirectModule,
    MatSelectModule,
    FormsModule,
    SharedModule, 
    MarkdownModule.forRoot()

];

const COMPONENTS = [
    ProductDetailsComponent,
    ConfirmCartProductComponent,
    ReviewFormComponent,
    ReviewDesktopComponent,
    AllReviewsDesktopComponent,
    ProductCollectionComponent,

];

@NgModule({
    declarations: [...DECLARATIONS],
    entryComponents: [...COMPONENTS],
    imports: [...MODULES, CarouselModule, LightboxModule, InfiniteScrollModule, GalleryModule, OwlModule],
    exports: [...DECLARATIONS,  CommonModule,
        MatCardModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        VariationsModule,
        RedirectModule,
        MatSelectModule,
        FormsModule,
        SharedModule, ]
})
export class ProductModule {
}
