import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imageArrange",
})
export class ImageArrangePipe implements PipeTransform {
  transform(value: any[], ...args: any[]): any[] {
    let perRowCount = Math.ceil(value.length / 4);
    let finalProductImage = [];
    let counter = args[0];
    for (let i = 0; i < perRowCount; i++) {
      if (value[counter] && value[counter].image) { 
        // const prd = value.find((prd) => prd === value[counter].image);
        finalProductImage.push(value[counter]);
        counter = counter + 4;
      } 
    }
    return finalProductImage;
  }
}
