import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../../../shared/services';

@Component({
    selector: 'app-product-details-container',
    templateUrl: './product-details-container.component.html',
    styleUrls: ['./product-details-container.component.less']
})
export class ProductDetailsContainerComponent implements OnInit {

    isHandset = false;

    constructor(
        private utilsService: UtilsService,
    ) {
    }

    ngOnInit() {
        this.utilsService.isHandset().subscribe(handset => {
            this.isHandset = handset.matches;
        });
    }

}
