import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";
import Chart from "chart.js";
import { FormControl, Validators } from "@angular/forms";
import { EnvService } from "src/app/shared/services/env.service";
import { CookieService } from "ngx-cookie-service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: "app-onboard-quiz-desktop",
  templateUrl: "./onboard-quiz-desktop.component.html",
  styleUrls: ["./onboard-quiz-desktop.component.less"],
})
export class OnboardQuizDesktopComponent implements OnInit {
  // selectedImages = [];
  firstSelectedImages = [];
  secondSelectedImages = [];
  thirdSelectedImages = [];
  genomesList: any[] = [];
  shownImages = [];
  type: string;
  // col1List:any[] = [];
  // col2List:any[] = [];
  // col3List: any[] = [];

  page3Col1List: any[] = [];
  page3Col2List: any[] = [];
  page3Col3List: any[] = [];

  page4Col1List: any[] = [];
  page4Col2List: any[] = [];
  page4Col3List: any[] = [];

  page5Col1List: any[] = [];
  page5Col2List: any[] = [];
  page5Col3List: any[] = [];
  alreadyShownImages: any[] = [];
  isNextClicked = false;
  limitReached = false;
  noOfTimesNextClicked: number = 0;
  randomImageWithoutType: any;
  deviceWidth: number;
  quizId: any;
  page1: any[] = [];
  page2: any[] = [];
  currentPage = 1;
  genomeName: string;
  genomeDescription: string;
  genomeImage: string;
  subStylesList: any[] = [];
  @ViewChild("chart", { static: false })
  private chartRef: ElementRef;
  private chart: Chart;
  currentSetOption: number = 1;
  showLoadMore: boolean;
  bottomScrolled: boolean;
  selectedDept: string;
  selectedCategory: string;
  quizCategories: any[] = [];
  quizDepartments: any[] = [];
  // prd1List: any[] = [];
  // prd2List: any[] = [];
  // prd3List: any[] = [];
  // selectedProductImages: any[] = [];
  selectedProducts: any[] = [];

  isPage5Skipped: boolean;
  isPage4Skipped: boolean;
  logoPath: string;
  showAuth: boolean = true;
  quizCategory: any;
  projectId: any;
  isHandset: boolean;
  userId: any;



  constructor(
    private api: ApiService,
    private router: Router,
    private actRouter: ActivatedRoute,
    private elementRef: ElementRef,
    private env: EnvService,
    private cookie: CookieService,
    private loc: Location,
    private breakpointObserver: BreakpointObserver
  ) {

  }

  get isLoggedIn(): boolean {
    return (
      JSON.parse(localStorage.getItem("user")) &&
      JSON.parse(localStorage.getItem("user")).email.length > 0
    );
  }

  get deviceHeight(){
    return  window.innerHeight;
  }

  ngOnInit() {
    this.breakpointObserver
    .observe([Breakpoints.Handset])
    .subscribe((result) => {
      this.isHandset = result.matches;
    });
    document.body.style.setProperty("background-color", "white", "important");
 

  this.userId =  JSON.parse(localStorage.getItem("user")).id

    if(!this.api.onInitLoaded){
    if (this.loc.path().includes("/design/project")) {
    
      let ids = this.router.url.match(/\d+/g);
        this.projectId = ids[0];
    }

    this.selectedDept = this.api.quizCategory;
    if (this.selectedDept) {
      this.currentPage = 3;
    }
    this.hideIntercomIcon();
      this.getQuizCategories();
    this.deviceWidth = window.innerWidth;
    if (
      this.router.url.includes("my-style") &&
      this.router.url.split("my-style/")[1]
    ) {
      this.quizId = this.router.url.split("my-style/")[1];
      this.getStyleReport(this.router.url.split("my-style/")[1]);
      this.currentPage = 7;
    } else {
      if (this.projectId ) {
        this.api.getAllAppointments("", this.projectId).subscribe((resp) => {
          if (resp && resp.length > 0 && !this.quizId) {
            this.selectedDept = resp[0].room;
            if (resp[0].style_quiz_id  ) {
              this.currentPage = 7;
              this.quizId =  resp[0].style_quiz_id;
                this.getStyleReport(this.quizId)
            } else{
              let obj = {
                department: this.selectedDept,
                category: null,
              };
              this.api.createStyleQuiz(obj).subscribe((x: any) => {
                this.quizId = x.quiz_id;
                this.updateBookingTask();
                this.getStyleReport(this.quizId);
                if (resp[0].style_quiz_id) {
                
                
                } else {
                  this.currentPage = 3;
                  this.getGenomeData();
                
                }
              });
            }
          }
        });
      }
    }
    this.api.onInitLoaded = true
  } else { 
    if (this.loc.path().includes("/design/project")) {
    
      let ids = this.router.url.match(/\d+/g);
        this.projectId = ids[0];
    }

    if (
      this.router.url.includes("my-style") &&
      this.router.url.split("my-style/")[1]
    ) {
      this.quizId = this.router.url.split("my-style/")[1];
      this.getStyleReport(this.router.url.split("my-style/")[1]);
      this.currentPage = 7;
    } 

    this.selectedDept = this.api.quizCategory;
    if (this.projectId ) {
      this.api.getAllAppointments("", this.projectId).subscribe((resp) => {
        if (resp && resp.length > 0 && !this.quizId) {
          this.selectedDept = resp[0].room;
          if (resp[0].style_quiz_id ) {
            this.currentPage = 7;
            this.quizId =  resp[0].style_quiz_id;
              this.getStyleReport(this.quizId)
          } else{
            let obj = {
              department: this.selectedDept,
              category: null,
            };
            this.api.createStyleQuiz(obj).subscribe((x: any) => {
              this.quizId = x.quiz_id;
              this.updateBookingTask();
              this.getStyleReport(this.quizId);
              if (resp[0].style_quiz_id ) {
              
              
              } else {
                this.currentPage = 3;
                this.getGenomeData();
              
              }
            });
          }
        } else{
          let obj = {
            department: this.selectedDept,
            category: null,
          };
          this.api.createStyleQuiz(obj).subscribe((x: any) => {
            this.quizId = x.quiz_id;
            this.updateBookingTask();
            this.getStyleReport(this.quizId);
            if (resp[0].style_quiz_id ) {
            
            
            } else {
              this.currentPage = 3;
              this.getGenomeData();
           
            }
          });
        }
      });
    }
  }
  }

  updateBookingTask() {
    const data = {
      project_id: this.projectId,
      tasks_style: "1",
      style_quiz_id: this.quizId,
    };
    this.api.updateBookingTask(data).subscribe((res) => {});
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
  }
  getQuizCategories() {
    this.api.getQuizCategories().subscribe((res: any) => {
      this.quizDepartments = res;
      if (this.selectedDept) {
        let i = this.quizDepartments.findIndex(
          (item) =>
            item.room.toLowerCase() === this.selectedDept.toLowerCase() ||
            item.dept_name.toLowerCase() === this.selectedDept.toLowerCase()
        );
        this.quizCategories = this.quizDepartments[i].categories;
        if (i >= 0 && !this.loc.path().includes("/design/projects")) {
          this.currentPage = 3;
        }
      }
    });
  }
  loadChart() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: "doughnut",
      data: {
        labels: this.subStylesList.map((item) => item.style_name),
        datasets: [
          {
            label: "",
            data: this.subStylesList.map((item) => item.ratio * 100),
            backgroundColor: this.subStylesList.map((item) => item.style_color),
            hoverOffset: 4,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
      },
    });
    this.createCustomLegends();
  }
  createCustomLegends() {
    const legendContainer = document.createElement("div");
    legendContainer.className = "custom-legends";

    this.chart.data.datasets.forEach((dataset, index) => {
      const legendItem = document.createElement("div");
      legendItem.className = "legend";

      const legendColor = document.createElement("div");
      legendColor.className = "legend-color";
      legendColor.style.backgroundColor = dataset.backgroundColor;

      const legendLabel = document.createElement("div");
      legendLabel.className = "legend-label";
      legendLabel.innerHTML = dataset.label;

      legendItem.appendChild(legendColor);
      legendItem.appendChild(legendLabel);
      legendContainer.appendChild(legendItem);
    });

    this.elementRef.nativeElement.appendChild(legendContainer);
  }
  loadMore() {
    this.currentSetOption = this.currentSetOption + 1;
    this.shownImages = this.getRandomImagesFromGenomes();
    // this.selectedImages = [];
    if (this.shownImages.length > 0) {
      let randomArr = this.shuffleArray(this.shownImages);
      const chunkSize = Math.ceil(randomArr.length / 3);

      let col1 = randomArr.slice(0, chunkSize);
      let col2 = randomArr.slice(chunkSize, 2 * chunkSize);
      let col3 = randomArr.slice(2 * chunkSize);

      if (this.currentPage === 3) {
        let a1 = this.page3Col1List.concat(...col1);
        let a2 = this.page3Col2List.concat(...col2);
        let a3 = this.page3Col3List.concat(...col3);

        this.page3Col1List = a1;
        this.page3Col2List = a2;
        this.page3Col3List = a3;
      } else if (this.currentPage === 4) {
        let a1 = this.page4Col1List.concat(...col1);
        let a2 = this.page4Col2List.concat(...col2);
        let a3 = this.page4Col3List.concat(...col3);

        this.page4Col1List = a1;
        this.page4Col2List = a2;
        this.page4Col3List = a3;
      } else if (this.currentPage === 5) {
        let a1 = this.page5Col1List.concat(...col1);
        let a2 = this.page5Col2List.concat(...col2);
        let a3 = this.page5Col3List.concat(...col3);

        this.page5Col1List = a1;
        this.page5Col2List = a2;
        this.page5Col3List = a3;
      }

      this.saveQuizViewedGenomes(this.currentPage === 1 ? "A" : "B");
      if (this.currentSetOption === 3) {
        this.showLoadMore = false;
      }
    }
  }
  createStyleQuiz() {
    let obj = {
      department: this.selectedDept,
      category: null,
      // category: this.selectedCategory,
      conversation_id:""
    };
    this.api.createStyleQuiz(obj).subscribe((res: any) => {
      this.quizId = res.quiz_id;
    });
  }
  selectImage(i, col) {
    let selectionLimit = this.currentPage === 5 ? 5 : 3;
    let firstCol =
      this.currentPage === 3
        ? this.page3Col1List
        : this.currentPage === 4
        ? this.page4Col1List
        : this.page5Col1List;
    let secondCol =
      this.currentPage === 3
        ? this.page3Col2List
        : this.currentPage === 4
        ? this.page4Col2List
        : this.page5Col2List;
    let thirdCol =
      this.currentPage === 3
        ? this.page3Col3List
        : this.currentPage === 4
        ? this.page4Col3List
        : this.page5Col3List;

    let selectedImagesPage =
      this.currentPage === 3
        ? this.firstSelectedImages
        : this.currentPage === 4
        ? this.secondSelectedImages
        : this.thirdSelectedImages;
    let name =
      this.currentPage === 3
        ? "firstSelectedImages"
        : this.currentPage === 4
        ? "secondSelectedImages"
        : "thirdSelectedImages";

    if (col === 1) {
      if (!firstCol[i].checked && selectedImagesPage.length < selectionLimit) {
        firstCol[i].checked = true;
      } else {
        firstCol[i].checked = false;
      }
      if (firstCol[i].checked) {
        selectedImagesPage.push(firstCol[i]);
      } else {
        selectedImagesPage = selectedImagesPage.filter(
          (item) => item.id !== firstCol[i].id
        );
        // this[name] = selectedImagesPage;
      }
    } else if (col === 2) {
      if (!secondCol[i].checked && selectedImagesPage.length < selectionLimit) {
        secondCol[i].checked = true;
      } else {
        secondCol[i].checked = false;
      }

      if (secondCol[i].checked) {
        selectedImagesPage.push(secondCol[i]);
      } else {
        selectedImagesPage = selectedImagesPage.filter(
          (item) => item.id !== secondCol[i].id
        );

        // this[name] = selectedImagesPage;
      }
    } else if (col === 3) {
      if (!thirdCol[i].checked && selectedImagesPage.length < selectionLimit) {
        thirdCol[i].checked = true;
      } else {
        thirdCol[i].checked = false;
      }

      if (thirdCol[i].checked) {
        selectedImagesPage.push(thirdCol[i]);
      } else {
        selectedImagesPage = selectedImagesPage.filter(
          (item) => item.id !== thirdCol[i].id
        );

        // this[name] = selectedImagesPage;
      }
    }
    this[name] = selectedImagesPage;
  }
  refreshImages() {
    // write a code for refreh random images that should not show the aleady shown images
    // this.saveQuizSelectedGenomes();
    let obj = {
      quiz_id: "",
      selection: [],

      is_conversation : false,
      conversation_id:''
    };
    let set =
      this.currentPage === 3 ? "A" : this.currentPage === 4 ? "B" : "final";

      // let set =
      // this.currentPage === 3 ? "A" : this.currentPage === 4 ? "final" : "";

    obj["quiz_id"] = this.quizId;

    obj["selection"] = [];
    let selImages =
      this.currentPage === 3
        ? this.firstSelectedImages
        : this.currentPage === 4
        ? this.secondSelectedImages
        : this.thirdSelectedImages;
    obj["selection"].push({
      selection_set: set,
      images: selImages.map((item) => item.id).join(","),
    });

    this.isNextClicked = true;
    this.noOfTimesNextClicked++;
    if (this.noOfTimesNextClicked === 1) {
      this.page1 = this.firstSelectedImages;
    } else if (this.noOfTimesNextClicked === 2) {
      this.page2 = this.secondSelectedImages;
    }

    if (this.currentPage === 5) {
      this.currentPage =7// 6;
    }
    if (this.currentPage === 4) {
      this.currentPage = 5;
    }
    if (this.currentPage < 5) {
      if (this.page1.length === 1) {
        this.currentPage = 4;
        this.currentSetOption = 1;
      } else {
        this.currentPage = 5;
        this.isPage4Skipped = true;
      }

      if (
        this.page1.length === 1 &&
        this.page2.length === 1 &&
        this.page1[0].genomeIndex === this.page2[0].genomeIndex
      ) {
        this.getStyleReport();
        this.isPage5Skipped = true;
      }
      if (
        this.page1.length === 1 &&
        this.page2.length === 1 &&
        this.page1[0].genomeIndex !== this.page2[0].genomeIndex
      ) {
        this.currentPage = 5;
        this.isPage4Skipped = true;
      }
    }
    this.saveQuizSelectedGenomes(obj);
  }
  getStyleReport(qId?) {
    this.api.getStyleReport(qId ? qId : this.quizId).subscribe(
      (res: any) => {
        if (res && res.genome && res.genome.length > 0) {
          this.currentPage = 7;
          if (this.loc.path().includes("/design/project")) {
            if (!this.loc.path().includes("/style-report")) {
              this.router.navigate([
                `design/projects/${this.projectId}/style-report`,
              ]);
            }
          } else {
            this.router.navigate([
              `style-quiz/my-style/${res.quiz_id}`,
            ]);
          }
          this.genomeName = res.genome[0].genome_name;
          this.genomeDescription = res.genome[0].genome_description;
          this.genomeImage = res.genome[0].genome_image;
          this.subStylesList = res.substyles;
          if (qId) {
            setTimeout(() => {
              this.loadChart();
            }, 1000);
          }
        } else {
          if (this.loc.path().includes("/design/projects")) {
            this.router.navigate([
              `design/projects/${this.projectId}/style-intake`,
            ]);
          } else {
            this.router.navigate(["style-quiz"]);
          }
        }
      },
      () => {
        if (this.loc.path().includes("/design/projects")) {
          this.router.navigate([
            `design/projects/${this.projectId}/style-intake`,
          ]);
        } else {
          this.router.navigate(["style-quiz"]);
        }
      }
    );
  }

    getRandomImagesFromGenomes() {
      const randomImages = [];

      if (this.currentPage === 5) {
        const pageLength = this.page1.length;
        let count = 3;
        if (pageLength === 1) {
          count = 24;
          for (let i = 0; i < 24; i++) {
            let index = this.page1[0].genomeIndex;
            const randomBedroomImage = this.getRandomBedroomImage(
              this.genomesList[index],
              index,
              this.genomesList[index].genome_value
            );
            if (randomBedroomImage) {
              randomImages.push({ ...randomBedroomImage, checked: false });
            }
          }
        } else if (pageLength === 2) {
          count = 12;
          for (let i = 0; i < this.page1.length; i++) {
            for (let j = 0; j < 12; j++) {
              let index = this.page1[i].genomeIndex;
              const randomBedroomImage = this.getRandomBedroomImage(
                this.genomesList[index],
                index,
                this.genomesList[index].genome_value
              );
              if (randomBedroomImage) {
                randomImages.push({ ...randomBedroomImage, checked: false });
              }
            }
          }
        } else if (pageLength === 3) {
          count = 8;
          for (let i = 0; i < this.page1.length; i++) {
            for (let j = 0; j < 8; j++) {
              let index = this.page1[i].genomeIndex;
              const randomBedroomImage = this.getRandomBedroomImage(
                this.genomesList[index],
                index,
                this.genomesList[index].genome_value
              );
              if (randomBedroomImage) {
                randomImages.push({ ...randomBedroomImage, checked: false });
              }
            }
          }
        }
        return randomImages;
      } else {
        this.genomesList.forEach((genome, i) => {
          const randomBedroomImage = this.getRandomBedroomImage(
            genome,
            i,
            genome.genome_value
          );
          if (randomBedroomImage) {
            randomImages.push({ ...randomBedroomImage, checked: false });
          }
        });
        return randomImages;
      }
    }

  getRandomBedroomImage(genome, i, genomeName) {
    console.log(this.selectedDept, "type");
    if (this.selectedDept) {
      const bedroomGallery = genome.gallery.find(
        (room) => room.room.toLowerCase() === this.selectedDept.toLowerCase()
      );
      if (bedroomGallery && bedroomGallery.gallery.length > 0) {
        return this.getRandomImg(bedroomGallery.gallery, i, genomeName);
      } else {
        return this.getRandomLivingImage(genome, i, genomeName);
      }
    } else {
      let allImages = [];
      allImages = genome.gallery.flatMap((room) => room.gallery);
      return this.getRandomImg(allImages, i, genomeName);
    }
  }
  getRandomLivingImage(genome, i, genomeName) {
    const bedroomGallery = genome.gallery.find(
      (room) => room.room.toLowerCase() === "living"
    );
    if (bedroomGallery && bedroomGallery.gallery.length > 0) {
      return this.getRandomImg(bedroomGallery.gallery, i, genomeName);
    }

    return null;
  }

  getRandomImg(allImages, i, genomeName) {
    this.randomImageWithoutType = null;
    if (allImages && allImages.length > 0) {
      let ran = Math.floor(Math.random() * allImages.length);
      if (allImages.some((item) => !item["alreadyShown"])) {
        if (allImages[ran] && allImages[ran]["alreadyShown"] === true) {
          this.getRandomImg(allImages, i, genomeName);
        } else {
          this.randomImageWithoutType = allImages[ran];
          this.randomImageWithoutType["alreadyShown"] = true;

          this.randomImageWithoutType["genome_value"] = genomeName;
          this.randomImageWithoutType["genomeIndex"] = i;
        }
      }
    }
    return this.randomImageWithoutType;
  }

  saveQuizViewedGenomes(set) {
    let obj = {
      quiz_id: "",
      selection: []
    };
    obj["quiz_id"] = this.quizId;
    obj["selection"] = [];

    obj["selection"].push({
      selection_set: set,
      selection_set_option: this.currentSetOption,
      images: this.shownImages.map((item) => item.id).join(","),
    });
    this.api.saveQuizViewedGenomes(obj).subscribe((res: any) => {});
  }
  saveQuizSelectedGenomes(obj) {
    this.api.saveQuizSelectedGenomes(obj).subscribe((res: any) => {
      if (this.currentPage === 7) { //6
        this.getStyleReport(this.quizId);
      } else {
        this.goNext();
      }
    });
  }
  goNext() {
    this.shownImages = this.getRandomImagesFromGenomes();
    // this.selectedImages = [];
    if (this.shownImages.length > 0) {
      let randomArr = this.shuffleArray(this.shownImages);
      // this.col1List = [];
      // this.col2List = [];
      // this.col3List = [];
      let firstCol =
        this.currentPage === 3
          ? this.page3Col1List
          : this.currentPage === 4
          ? this.page4Col1List
          : this.page5Col1List;
      let secondCol =
        this.currentPage === 3
          ? this.page3Col2List
          : this.currentPage === 4
          ? this.page4Col2List
          : this.page5Col2List;
      let thirdCol =
        this.currentPage === 3
          ? this.page3Col3List
          : this.currentPage === 4
          ? this.page4Col3List
          : this.page5Col3List;

      for (let i = 0; i < randomArr.length; i++) {
        if (i % 3 == 0) {
          firstCol.push(randomArr[i]);
        } else if (i % 3 === 1) {
          secondCol.push(randomArr[i]);
        } else {
          thirdCol.push(randomArr[i]);
        }
      }
    } else {
      this.limitReached = true;
    }
    if (this.currentPage === 4) {
      this.saveQuizViewedGenomes("B");
    }
  }
  shuffleArray<T>(array: T[]): T[] {
    // Fisher-Yates shuffle algorithm
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  selectCard(name, i) {
  
    if (this.currentPage === 1) {
      this.selectedDept = name;
      this.api.quizCategory = name;
      this.quizCategories = this.quizDepartments[i].categories;
      this.currentPage = 3;

      this.createStyleQuiz();
      this.getGenomeData();
    }
  }

  getGenomeData() {
    this.api.getGenomeData().subscribe((res: any) => {
      this.genomesList = res.genomes;

      this.shownImages = this.getRandomImagesFromGenomes();
      let randomArr = this.shuffleArray(this.shownImages);
      // this.col1List = [];
      // this.col2List = [];
      // this.col3List = [];
      // for (let i = 0; i < randomArr.length; i++) {
      //   if (i % 3 == 0) {
      //     this.col1List.push(randomArr[i]);
      //   } else if (i % 3 === 1) {
      //     this.col2List.push(randomArr[i]);
      //   } else {
      //     this.col3List.push(randomArr[i]);
      //   }
      // }
      this.page3Col1List = [];
      this.page3Col2List = [];
      this.page3Col3List = [];
      this.page4Col1List = [];
      this.page4Col2List = [];
      this.page4Col3List = [];
      this.page5Col1List = [];
      this.page5Col2List = [];
      this.page5Col3List = [];
      this.firstSelectedImages = [];
      this.secondSelectedImages = [];
      this.thirdSelectedImages = [];

      let firstCol =
        this.currentPage === 3
          ? this.page3Col1List
          : this.currentPage === 4
          ? this.page4Col1List
          : this.page5Col1List;
      let secondCol =
        this.currentPage === 3
          ? this.page3Col2List
          : this.currentPage === 4
          ? this.page4Col2List
          : this.page5Col2List;
      let thirdCol =
        this.currentPage === 3
          ? this.page3Col3List
          : this.currentPage === 4
          ? this.page4Col3List
          : this.page5Col3List;

      for (let i = 0; i < randomArr.length; i++) {
        if (i % 3 == 0) {
          firstCol.push(randomArr[i]);
        } else if (i % 3 === 1) {
          secondCol.push(randomArr[i]);
        } else {
          thirdCol.push(randomArr[i]);
        }
      }
      this.saveQuizViewedGenomes("A");
      this.showLoadMore = true;
    });
  } 

  goToPage8() {
    if (this.isLoggedIn) {
    this.saveDesignBookings();
    let user =JSON.parse( localStorage.getItem('user'));

      let obj = {
        email: user.email
      };
      this.api.saveQuizMail(obj).subscribe((res: any) => {
        this.api.updateQuizUser(this.quizId).subscribe((res: any) => {});
      });
    } else { 
      this.api.quizId = this.quizId;
      this.router.navigate(['design/book/sign-up']);
    }
  }


  saveDesignBookings(){
    let user =JSON.parse( localStorage.getItem('user'));
    if(user){

    let data = {
      email: user.email,
      first_name: user.first_name,
      last_name:user.last_name,
      phone: "",
      room: this.selectedDept,
      zipcode: "",
      quiz_id: this.quizId
    };
    this.api.saveDesignBookings(data).subscribe((res:any)=>{
      if(res && res.status){
        this.projectId = res.booking_id;
        this.api.quizId = this.quizId;
        this.router.navigate([`/design/book/project-details/${this.projectId}`])
      }
    })
  }
  }
  Back() {
    if (this.currentPage === 4) {
      this.noOfTimesNextClicked = 1;
    }
    if (this.currentPage === 5) {
      this.noOfTimesNextClicked = 2;
      if (this.isPage4Skipped) {
        this.currentPage = 3;
        this.isPage4Skipped = false;
        this.noOfTimesNextClicked = 0;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    } else if (this.currentPage === 3) {
      this.currentPage = 1;
    
    } else {
 

        this.currentPage = this.currentPage - 1;
  
    } 
  }
  get firstCol() {
    return this.currentPage === 3
      ? this.page3Col1List
      : this.currentPage === 4
      ? this.page4Col1List
      : this.page5Col1List;
  }
  get secondCol() {
    return this.currentPage === 3
      ? this.page3Col2List
      : this.currentPage === 4
      ? this.page4Col2List
      : this.page5Col2List;
  }
  get thirdCol() {
    return this.currentPage === 3
      ? this.page3Col3List
      : this.currentPage === 4
      ? this.page4Col3List
      : this.page5Col3List;
  }
  get getSelectedImgesLength() {
    return this.currentPage === 3
      ? this.firstSelectedImages.length
      : this.currentPage === 4
      ? this.secondSelectedImages.length
      : this.thirdSelectedImages.length;
  }
}
