import {Injectable} from '@angular/core';

declare const fbq;

@Injectable({
    providedIn: 'root'
})
export class PixelService {

    constructor() {
    }

    public trackAddToCart(product: any) {
        const options = {
            contents: [
                {
                    id: product.sku,
                    quantity: product.quantity
                }
            ],
            currency: 'USD',
            value: product.price,
            content_type: 'product'
        };
        fbq('track', 'AddToCart', options);
    }

    public trackPurchase(order: any) {
        const products = order.mail_data.order.products;
        const contents = products.map(product => {
            return {
              id: product.sku,
              qunatity: product.count
            };
        })
        const options = {
          currency: "USD",
          value: order.amount,
          content_type: "product",
          contents
        };
        fbq('track', 'Purchase', options);
    }

    public trackInitiateCheckout() {
        fbq('track', 'InitiateCheckout');
    }

    public trackViewContent(product) {
        fbq("track", "ViewContent", {
          content_name: product.name,
          content_category:product.department_info.length>0? product.department_info[0].category_url:'',
          content_ids: [product.sku],
          content_type: "product",
        //   value: Number(product.is_price.split("-")[0]),
          value: Number(product.min_price),
          currency: "USD",
        });
    }
}

