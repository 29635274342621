import {IProductPayload} from './../../../shared/models';
import {Component, Input, OnInit} from '@angular/core';
import {WishlistSnackbarService} from '../../../shared/services/wishlist-service/wishlist-snackbar.service';
import { CookieService } from 'ngx-cookie-service';

import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { MatDialogUtilsService } from 'src/app/shared/services/matDialogUtils/matDialogUtils.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-product-mobile',
    templateUrl: './product-mobile.component.html',
    styleUrls: ['./product-mobile.component.less']
})
export class ProductMobileComponent implements OnInit {
    @Input() product: IProductPayload;
    @Input() productsInRow: number;

    isVariationImageVisible = false;
    priceObject = {
        isPrice: 0,
        wasPrice: 0
    };
    isDiscounted = false;
    isRange = false;
    isStrikeRange: boolean;
    minPrice: number;
    maxPrice: number;
    wasMinPrice: number;
    wasMaxPrice: number;
    user: any;

    constructor(
        private apiService: ApiService,
        private matDialogUtils: MatDialogUtilsService,
        private snackBarService: WishlistSnackbarService,
        private cookie: CookieService,
        public utils: UtilsService,
        private router:Router
    ) {
    }

    ngOnInit() {
        this.formatPriceText(this.product);
        this.user = JSON.parse(localStorage.getItem("user")); 
    }

    goToPreviewPage(){
        if(this.product.displayable == 'preview' && this.user.user_type == 0 ){
        this.router.navigate([`/product/${this.product.sku}/preview`]);
        }
    }

    formatPriceText(product) {
        const {min_price, max_price, min_was_price,max_was_price} = this.product;
        this.minPrice = min_price;
        this.maxPrice = max_price;
        this.wasMinPrice = min_was_price;
        this.wasMaxPrice = max_was_price;
        if(min_price !=  max_price){
            this.isRange = true;  
        } else {
            this.isRange =false;
        }
        
        if(min_price != min_was_price || max_price != max_was_price){
            this.isStrikeRange = true;  
        } else {
            this.isStrikeRange = false;  
        }

        if((this.minPrice < this.wasMinPrice || this.maxPrice<this.wasMaxPrice)){
            this.isDiscounted = true;
        } else {
            this.isDiscounted = false;  
        }
    //     if(product.was_price){
    //     let {is_price, was_price} = product;
    //     if (is_price.includes('-')) {
    //         this.isRange = true;
    //     }
    //     is_price =is_price.includes('-')? is_price.split('-'):is_price;
    //     was_price = was_price.includes('-')?was_price.split('-'):was_price;
    //     this.priceObject.isPrice =typeof(is_price)=='string'?  Number(is_price):Number(is_price[0]) ;
    //     this.isDiscounted = was_price &&
    //     (typeof(was_price)=='string'?  Number(was_price):was_price[0]) > (typeof(is_price)=='string'?  Number(is_price):is_price[0]);
    //     if (this.isDiscounted) {
    //         this.priceObject.wasPrice =typeof(was_price)=='string'?  Number(was_price):Number(was_price[0]); 
    //     }
    // } else {
    //     let {is_price, max_was_price} = product; 
    //     if (is_price.includes('-')) {
    //         this.isRange = true;
    //     }
    //     this.priceObject.isPrice = +   (is_price.includes('-')?Number( is_price.split('-')[0]):Number(is_price));
    //     this.isDiscounted = max_was_price && Number(max_was_price) > Number((is_price.includes('-')? Number(is_price.split('-')[0]):Number(is_price)));
    //     if (this.isDiscounted) {
    //         this.priceObject.wasPrice = Number(max_was_price);
    //     } 
    // }
    }

    wishlistProduct(sku, mark, event) {
        event.stopPropagation();
        const user = JSON.parse(localStorage.getItem('user'));
        const neverShow = localStorage.getItem('login-prompt-never-show');
        const token = this.cookie.get('token');
        if (!token) {
            // trigger signup window
            this.utils.openSignupDialog(false, true);
            return;
        }
        this.apiService
            .wishlistProduct(sku, mark, true)
            .subscribe((payload: any) => {
                this.product.wishlisted = mark;
                if (mark) {
                    if (user.user_type === 0 && !neverShow) {
                        this.matDialogUtils.openWishlistLoginPromptModal(this.product, true);
                    } else {
                        this.snackBarService.addToWishlist(sku);
                    }
                } else {
                    this.snackBarService.removeIfExistsProduct(sku);
                }
            });
    }

    renderPrice(price: number): number | string {
        const quotient = Math.floor(price);
        const remainder = Number((price - quotient).toPrecision(2));
        if (remainder === 0) {
            return quotient;
        } else {
            return price.toFixed(2);
        }
    }
}
