import { Component, Input, OnInit } from '@angular/core';
import { EnvService } from '../../../../shared/services/env.service';

@Component({
  selector: 'app-ls-difference',
  templateUrl: './ls-difference.component.html',
  styleUrls: ['./ls-difference.component.less']
})
export class LsDifferenceComponent implements OnInit {
  @Input() isHandset = false;
  constructor(public env: EnvService) { }

  ngOnInit() {
  }

}
