import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.less']
})
export class TimelineComponent implements OnInit,AfterViewInit {
  @Input()  isHandset = false;
items:any;
steps =[{header:'Discovery',content:'We will scope out your design needs and style. Schedule a complimentary consultation and tell us about your project.',icon:'assets/image/008-messenger.png'},
{header:'Courtship',content:'We will pair you with an accredited designer. You will receive a design schematic with floor plans and furniture to scale.',icon:'assets/image/009-partnership-handshake.png'},
{header:'Design Development',content:'We will review the design with you. We\'ll fine-tune your design based on your style, budget and timeline.',icon:'assets/image/007-interior-design-1.png'},
{header:'Project Management',content:'Final execution of the approved plans. Curate and checkout with your personalized shopping list across brands all in one place.',icon:'assets/image/010-loading.png'},
{header:'Delivery',content:'Our concierge team will help you navigate post order needs. From tracking to installation logistics, we will handle all the details.',icon:'assets/image/011-delivery-truck-1.png'}]
  constructor() { }

  ngOnInit() {
    // this.wf();

 
  }
ngAfterViewInit(): void {
  this.items = document.querySelectorAll(".timeline li");
  this.isElementInViewport(this.items[0]);
}

  // check if an element is in viewport
  // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
   isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

   callbackFunc() {
    for (var i = 0; i < this.items.length; i++) {
      if (this.isElementInViewport(this.items[i])) {
        this.items[i].classList.add("in-view");
        this.items[i].classList.add("point");
      }
    }
  }
  @HostListener('window:load', ['$event']) chload($event: Event){
    this.callbackFunc();
  }
  @HostListener('window:resize', ['$event']) chkresize($event: Event){
    this.callbackFunc();
  }
  @HostListener('window:scroll', ['$event']) chkscroll($event: Event){
    this.callbackFunc();
  }
  

}
