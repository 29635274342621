export const environment = {
    production: false,
    APP_NAME: 'Seeded',
    JSON_BASE_HREF: 'assets/resources/',
    ADMIN_API_BASE_HREF: 'https://staging.seededhome.com/api/admin/',
    API_BASE_HREF: 'https://staging.seededhome.com/api/',
    BASE_HREF: 'https://staging.seededhome.com',
    SITE_URL: 'https://staging.seededhome.com',
    ES_API_BASE_HREF: 'https://seededhome.com:9200/',
    LIVE_SITE: true,
    GOOGLE_LINK: 'https://www.seededhome.com/redirect/google',
    FACEBOOK_LINK: 'https://www.seededhome.com/redirect/facebook',
    BOARD_BASE_HREF: 'board',
    STRIPE_API_KEY: 'pk_test_51OGPIMEgDNqBlTkXIakU0UulhoSa97RS3dlKI0sAYA9Cd2AACW4nlkRsryNAIn7ydhVlxPIn9OoCUWYuCUZSiQRZ00O1x4D6uv',
    CLIENT_ID: 5,
    CLIENT_SECRET: 'hlmZpdScfvjJr5ggHQRAo9UJvdSMH2izxwpksfuz',
    ASSET_BASE_URL: 'https://s3.us-east-2.amazonaws.com/lazysuzy-images/',
    CONTACT_EMAIL: 'hello@seededhome.com',
    APP_URL: 'https://seededhome.com',  
};
