import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/services";

import * as JSZip from "jszip";

@Component({
  selector: "app-view-files",
  templateUrl: "./view-files.component.html",
  styleUrls: ["./view-files.component.less"],
})
export class ViewFilesComponent implements OnInit {
  @Input() isHandset: boolean;
  @Input() designData: any;
  imageResponse: any[] = [];
  firstCol: any[] = [];
  secondCol: any[] = [];
  thirdCol: any[] = [];

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    // this.designData.intakeimage = [
    //   {
    //     image:
    //       "https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg",
    //   },
    //   {
    //     image:
    //       "https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg",
    //   },
    //   // {
    //   //   image:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg'
    //   // },
    //   // {
    //   //   image:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg'
    //   // },
    //   // {
    //   //   image:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg'
    //   // },
    //   // {
    //   //   image:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg'
    //   // },
    //   // {
    //   //   image:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg'
    //   // },
    //   // {
    //   //   image:'https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/170874/client_submission/Ql0DK9fSeV2pSRmbA5mZiVLSLPRPlXXjwusftUnA.jpg'
    //   // }
    // ];

    // console.log(this.designData.intakeimage.length);
    let intakeImages = JSON.parse(JSON.stringify(this.designData.intakeimage));
    
    for (let i = 0; i < intakeImages.length; i++) {
      if (i % 3 == 0) {
        this.firstCol.push(intakeImages[i]);
      } else if (i % 3 === 1) {
        this.secondCol.push(intakeImages[i]);
      } else {
        this.thirdCol.push(intakeImages[i]);
      }
    }
  }

  showEditPage() {}
  download() {
    this.designData.intakeimage.forEach((f) => {
      if (f.image) {
        const imageUrl =
          `/${this.designData.project_id}/client_submission/` +
          f.image.split(`/${this.designData.project_id}/client_submission/`)[1];
        this.getImages(
          f.image,
          f.image.split(`/${this.designData.project_id}/client_submission/`)[1],
          this.designData.intakeimage
        );
      }
    });
  }
  getImages(data: any, name: string, fullData: any[]) {
    this.api.getImageBlob(data).then((res) => {
      this.imageResponse.push({ name: name, content: res });
      if (fullData.length === this.imageResponse.length) {
        this.downloadZip();
      }
    });
  }
  async downloadZip() {
    const files = this.imageResponse;

    const zip = new JSZip();

    for (const file of files) {
      zip.file(file.name, file.content);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `${this.designData.project_id}_documents.zip`;
    link.click();
  }
}
