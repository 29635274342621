import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SearchProductComponent } from './search-product.component';
import { RouterModule } from '@angular/router';

const DECLARATIONS = [SearchProductComponent];

const MODULES = [CommonModule, MatIconModule,RouterModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES,RouterModule],
  exports: [...DECLARATIONS, ...MODULES]
})
export class SearchProductModule {}
