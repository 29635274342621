import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IUpgradeProduct} from '../../../../shared/models/product-detail-payload.interface';

@Component({
    selector: 'app-product-upgrades',
    templateUrl: './product-upgrades.component.html',
    styleUrls: ['./product-upgrades.component.less']
})
export class ProductUpgradesComponent implements OnInit {

    @Input() product: IUpgradeProduct;
    @Output() cartClick = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    cartClickEvent() {
        this.cartClick.emit();
    }
}
