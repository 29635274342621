import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {IAllCategory} from '../../../shared/models/all-department.interface';
import {ApiService, SeoService} from './../../../shared/services';
import {ActivatedRoute, Router} from '@angular/router';
import MetaData from '../../../shared/services/seo/meta-data-model';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.less']
})
export class CategoriesComponent implements OnInit {
    routeSubscription: Subscription;
    categorySubscription: Subscription;
    department: string;
    categories: IAllCategory[];
    departments: any;

    constructor(
        private apiService: ApiService,
        private router: Router, 
        private seoService: SeoService, 
    ) {
    }

    ngOnInit(): void {
        this.getParams();
       
    }

    onDestroy(): void {
        // this.routeSubscription.unsubscribe();
        this.categorySubscription.unsubscribe();
    }

    getParams(): void {
        const urlParams: string[] = this.router.url.split('/').slice(1);
        if(urlParams.length === 2){ 
            this.department = urlParams[1];
            // this.gteDepartments();
        } else if(urlParams.length === 3){
            this.department = urlParams[2];
            this.getCategories();
        } 
    }
gteDepartments(){
    this.departments = []; 
    this.apiService.browseRoom().subscribe((res: any) => { 
        this.departments = [];
      let xDept  =  res.all_departments.find((val) => val.class_url.toLowerCase().includes(this.department.toLowerCase()));
      if(xDept){
        this.departments = xDept.departments.filter(f=>f.displayable===1);
      }    
      });
}

    getCategories() {
        this.categorySubscription = this.apiService
        .getCategories(this.department)
        .subscribe((payload: any) => {
            if(payload){
            this.categories = payload.categories;
            this.setMetaTags(payload);
            }
        }); 
    }

    goToCategory(link){ 
        this.router.navigate([`/products/${this.department}/${link.split('/')[1]}`]);
        this.categorySubscription = this.apiService
        .getCategories(link.split('/')[1])
        .subscribe((payload: any) => {
          
            this.departments = [];
            this.categories = payload.categories;
            this.setMetaTags(payload);
        }); 
    }

    setMetaTags(department) {
        const metaData: MetaData = {};
        metaData.title = `Search from hundreds of ${department.department_long} products of top brands at once.`;
        metaData.description = `Search from hundreds of ${department.department_long} products of top brands at once.Add to your room designs with your own design boards.`;
        metaData.image = department.department_image;
        this.seoService.setMetaTags(metaData);
    }
}
