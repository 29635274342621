import { Component, Input, OnInit } from '@angular/core';
import { ICollectionData } from 'src/app/shared/models/product-collection.interface';

@Component({
  selector: 'app-product-collection-mobile',
  templateUrl: './product-collection-mobile.component.html',
  styleUrls: ['./product-collection-mobile.component.less']
})
export class ProductCollectionMobileComponent implements OnInit {

  @Input() product:ICollectionData;
    @Input() pageKey = '';  
    showCollectionGroup = false;
    previousItem: string;
    count = 0;
    user: any;

    constructor( 
    ) {
    }

    ngOnInit(): void {  
        this.user = JSON.parse(localStorage.getItem("user"));  
    }

    onDestroy(): void { 
    }
 

    getQueryParams() {
        if (this.pageKey) {
            return {
                pkey: this.pageKey
            };
        } else {
            return {};
        }
    }
  
    counter(type: string) {
        if(type=='soft'){
            return new Array(+this.product.beta_data_firmness);
        } else {
            return new Array(5 - +this.product.beta_data_firmness);
        }
    }
    
    showCollection(currentItem: string) { 
        if (currentItem == this.previousItem && this.count == 1) {

            this.showCollectionGroup = true;
            this.count = 0
        } else {
            this.showCollectionGroup = false;
            currentItem == this.previousItem ? this.count++ : false;
        }

        if (currentItem !== this.previousItem && this.count == 0) {

            this.showCollectionGroup = true;
            this.count = 0
        } else { 
            this.count > 0 ? this.showCollectionGroup = false : false;
        }
        this.previousItem = currentItem;
    }
}

