import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core"; 
import { Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";
import Chart from "chart.js";
import { FormControl, Validators } from "@angular/forms";
import { EnvService } from "src/app/shared/services/env.service";
import { CookieService } from "ngx-cookie-service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ChatService } from "src/app/shared/services/api/chat.service";

@Component({
  selector: 'app-chat-genomes',
  templateUrl: './chat-genomes.component.html',
  styleUrls: ['./chat-genomes.component.less']
})
export class ChatGenomesComponent implements OnInit {

   // selectedImages = [];

  @Input() conversation_id: string = "";
   firstSelectedImages = [];
   secondSelectedImages = [];
   thirdSelectedImages = [];
   genomesList: any[] = [];
   shownImages = [];
   type: string;
   // col1List:any[] = [];
   // col2List:any[] = [];
   // col3List: any[] = [];
 
   page3Col1List: any[] = [];
   page3Col2List: any[] = [];
   page3Col3List: any[] = [];
 
   page4Col1List: any[] = [];
   page4Col2List: any[] = [];
   page4Col3List: any[] = [];
 
   page5Col1List: any[] = [];
   page5Col2List: any[] = [];
   page5Col3List: any[] = [];
   deviceHeight: number;
   alreadyShownImages: any[] = [];
   isNextClicked = false;
   limitReached = false;
   noOfTimesNextClicked: number = 0;
   randomImageWithoutType: any;
   deviceWidth: number;
   @Input() quizId: string = "";
   page1: any[] = [];
   page2: any[] = [];
   currentPage = 3;
   genomeName: string;
   genomeDescription: string;
   genomeImage: string;
   subStylesList: any[] = [];
   @ViewChild("chart", { static: false })
   private chartRef: ElementRef;
   private chart: Chart;
   currentSetOption: number = 1;
   showLoadMore: boolean;
   bottomScrolled: boolean;
   selectedDept: string;
   email = new FormControl("", [Validators.required, Validators.email]);
   password = new FormControl("", [Validators.required]);
   shoppingList: any[] = [
     { name: "My Primary Residence", value: "primary" },
     { name: "My Vacation Home", value: "vacation" },
     { name: "My Rental Property", value: "rental" },
     { name: "Someone Else", value: "agent" },
   ];
   homeList: any[] = [
     { name: "Urban House", image: "", value: "urban_home" },
     { name: "Apartment", value: "apartment", image: "" },
     { name: "Suburban House", value: "suburban_home", image: "" },
     { name: "Farmhouse/Lodge", image: "", value: "country" },
   ];
   houseHoldList: any[] = [
     { name: "Just Myself", image: "", value: "single" },
     { name: "Two- Three", image: "", value: "small" },
     { name: "More Than Three", image: "", value: "large" },
   ];
   peopleList: any[] = [
     { name: "Kids", image: "", selected: false },
     { name: "Pets", image: "", selected: false },
     { name: "None", image: "", selected: false },
   ];
   mattersList: any[] = [
     { name: "Style / Aesthetic", value: "style" },
     { name: "Durability", value: "durable" },
     { name: "Functional Usability", value: "functional" },
     { name: "All are equally important to me", value: "all" },
   ];
   stageList: any[] = [
     { name: "Empty Room", image: "", value: "early" },
     { name: "Partially There", image: "", value: "midway" },
     { name: "Touch Ups/Refresh", image: "", value: "late" },
   ];
 
   location = "";
   home_type = "";
   people = "";
   household = "";
   priority = "";
   stage = "";
   emailAvailable: boolean;
   isPage5Skipped: boolean;
   isPage4Skipped: boolean;
   logoPath: string;
   showAuth: boolean = true;
   quizCategory: any;
   projectId: any;
   onInitLoaded: boolean; 
   userId: any;
   @Output() styleReport = new EventEmitter();
   @Output() msgFromGenome = new EventEmitter();
   @Output() showLoader = new EventEmitter();
   genomePage1: any[] = [];
   genomePage2: any[] = [];
   genomePage3: any[] = [];
 
   constructor(
     private api: ApiService,
     private router: Router,
     private elementRef: ElementRef,
     private env: EnvService,
     private cookie: CookieService,
     private chatService: ChatService
   ) {
     this.logoPath = this.env.logoPathBlack;
 
     this.homeList = [
       {
         name: "Urban House",
         image: env.assetBaseUrl + "site/quiz/034-duplex.png",
         value: "urban_home",
       },
       {
         name: "Apartment",
         value: "apartment",
         image: env.assetBaseUrl + "site/quiz/035-apartment.png",
       },
       {
         name: "Suburban House",
         value: "suburban_home",
         image: env.assetBaseUrl + "site/quiz/036-house.png",
       },
       {
         name: "Farmhouse/Lodge",
         image: env.assetBaseUrl + "site/quiz/037-farmhouse.png",
         value: "country",
       },
     ];
     this.houseHoldList = [
       {
         name: "Just Myself",
         image: env.assetBaseUrl + "site/quiz/038-user.png",
         value: "single",
       },
       {
         name: "Two- Three",
         image: env.assetBaseUrl + "site/quiz/039-couple-users-silhouette.png",
         value: "small",
       },
       {
         name: "More Than Three",
         image: env.assetBaseUrl + "site/quiz/040-large-family.png",
         value: "large",
       },
     ];
     this.peopleList = [
       {
         name: "Kids",
         image: env.assetBaseUrl + "site/quiz/041-children.png",
         selected: false,
       },
       {
         name: "Pets",
         image: env.assetBaseUrl + "site/quiz/042-pet.png",
         selected: false,
       },
       { name: "None", image: "", selected: false },
     ];
     this.stageList = [
       {
         name: "Empty Room",
         image: env.assetBaseUrl + "site/quiz/043-floor.png",
         value: "early",
       },
       {
         name: "Partially There",
         image: env.assetBaseUrl + "site/quiz/045-hotel.png",
         value: "midway",
       },
       {
         name: "Touch Ups/Refresh",
         image: env.assetBaseUrl + "site/quiz/044-room.png",
         value: "late",
       },
     ];
   }
 
   get isLoggedIn(): boolean {
     return (
       JSON.parse(localStorage.getItem("user")) &&
       JSON.parse(localStorage.getItem("user")).email.length > 0
     );
   }
 
   ngOnInit() {
    
     document.body.style.setProperty("background-color", "white", "important");
 
     this.userId = JSON.parse(localStorage.getItem("user")).id;
 
     this.selectedDept = this.chatService.quizCategory;
     if (this.selectedDept) {
     this.currentPage = 3;
     if(this.chatService.shuffledImages && this.chatService.shuffledImages.length>0){
      this.formImgColumns(this.chatService.shuffledImages);
    } else {
    this.getGenomeData();
    }
     }
     this.hideIntercomIcon();
     this.deviceHeight = window.innerHeight;
     this.deviceWidth = window.innerWidth;
     this.onInitLoaded = true;
   }
 
   updateBookingTask() {
     const data = {
       project_id: this.projectId,
       tasks_style: "1",
       style_quiz_id: this.quizId,
     };
     this.api.updateBookingTask(data).subscribe((res) => {});
   }
 
   hideIntercomIcon() {
     const interval = setInterval(() => {
       const intercom = document.getElementsByClassName(
         "intercom-lightweight-app"
       )[0];
       if (intercom) {
         intercom.classList.add("dn");
         clearInterval(interval);
       }
     }, 500);
   }
 
   loadChart() {
     this.chart = new Chart(this.chartRef.nativeElement, {
       type: "doughnut",
       data: {
         labels: this.subStylesList.map((item) => item.style_name),
         datasets: [
           {
             label: "",
             data: this.subStylesList.map((item) => item.ratio * 100),
             backgroundColor: this.subStylesList.map((item) => item.style_color),
             hoverOffset: 4,
           },
         ],
       },
       options: {
         responsive: true,
         legend: {
           display: false,
         },
       },
     });
     this.createCustomLegends();
   }
   createCustomLegends() {
     const legendContainer = document.createElement("div");
     legendContainer.className = "custom-legends";
 
     this.chart.data.datasets.forEach((dataset, index) => {
       const legendItem = document.createElement("div");
       legendItem.className = "legend";
 
       const legendColor = document.createElement("div");
       legendColor.className = "legend-color";
       legendColor.style.backgroundColor = dataset.backgroundColor;
 
       const legendLabel = document.createElement("div");
       legendLabel.className = "legend-label";
       legendLabel.innerHTML = dataset.label;
 
       legendItem.appendChild(legendColor);
       legendItem.appendChild(legendLabel);
       legendContainer.appendChild(legendItem);
     });
 
     this.elementRef.nativeElement.appendChild(legendContainer);
   }
   backGenome() {
     if (this.currentGenomePage >= 2) {
       this.currentGenomePage--;
     }
   }
   next() {
     // this.currentGenomePage++;
   }
   currentGenomePage = 1;
   loadMore() {
    this.currentSetOption = this.currentSetOption + 1;
    this.shownImages = this.getRandomImagesFromGenomes();
    // this.selectedImages = [];
    if (this.shownImages.length > 0) {
      let randomArr = this.shuffleArray(this.shownImages);
      const chunkSize = Math.ceil(randomArr.length / 3);

      let col1 = randomArr.slice(0, chunkSize);
      let col2 = randomArr.slice(chunkSize, 2 * chunkSize);
      let col3 = randomArr.slice(2 * chunkSize);

      if (this.currentPage === 3) {
        let a1 = this.page3Col1List.concat(...col1);
        let a2 = this.page3Col2List.concat(...col2);
        let a3 = this.page3Col3List.concat(...col3);

        this.page3Col1List = a1;
        this.page3Col2List = a2;
        this.page3Col3List = a3;
      } else if (this.currentPage === 4) {
        let a1 = this.page4Col1List.concat(...col1);
        let a2 = this.page4Col2List.concat(...col2);
        let a3 = this.page4Col3List.concat(...col3);

        this.page4Col1List = a1;
        this.page4Col2List = a2;
        this.page4Col3List = a3;
      } else if (this.currentPage === 5) {
        let a1 = this.page5Col1List.concat(...col1);
        let a2 = this.page5Col2List.concat(...col2);
        let a3 = this.page5Col3List.concat(...col3);

        this.page5Col1List = a1;
        this.page5Col2List = a2;
        this.page5Col3List = a3;
      }

      this.saveQuizViewedGenomes(this.currentPage === 1 ? "A" : "B");
      if (this.currentSetOption === 3) {
        this.showLoadMore = false;
      }
    }
  }
 
   selectImage(i, col) {
    let selectionLimit = this.currentPage === 5 ? 5 : 3;
    let firstCol =
      this.currentPage === 3
        ? this.page3Col1List
        : this.currentPage === 4
        ? this.page4Col1List
        : this.page5Col1List;
    let secondCol =
      this.currentPage === 3
        ? this.page3Col2List
        : this.currentPage === 4
        ? this.page4Col2List
        : this.page5Col2List;
    let thirdCol =
      this.currentPage === 3
        ? this.page3Col3List
        : this.currentPage === 4
        ? this.page4Col3List
        : this.page5Col3List;

    let selectedImagesPage =
      this.currentPage === 3
        ? this.firstSelectedImages
        : this.currentPage === 4
        ? this.secondSelectedImages
        : this.thirdSelectedImages;
    let name =
      this.currentPage === 3
        ? "firstSelectedImages"
        : this.currentPage === 4
        ? "secondSelectedImages"
        : "thirdSelectedImages";

    if (col === 1) {
      if (!firstCol[i].checked && selectedImagesPage.length < selectionLimit) {
        firstCol[i].checked = true;
      } else {
        firstCol[i].checked = false;
      }
      if (firstCol[i].checked) {
        selectedImagesPage.push(firstCol[i]);
      } else {
        selectedImagesPage = selectedImagesPage.filter(
          (item) => item.id !== firstCol[i].id
        );
        // this[name] = selectedImagesPage;
      }
    } else if (col === 2) {
      if (!secondCol[i].checked && selectedImagesPage.length < selectionLimit) {
        secondCol[i].checked = true;
      } else {
        secondCol[i].checked = false;
      }

      if (secondCol[i].checked) {
        selectedImagesPage.push(secondCol[i]);
      } else {
        selectedImagesPage = selectedImagesPage.filter(
          (item) => item.id !== secondCol[i].id
        );

        // this[name] = selectedImagesPage;
      }
    } else if (col === 3) {
      if (!thirdCol[i].checked && selectedImagesPage.length < selectionLimit) {
        thirdCol[i].checked = true;
      } else {
        thirdCol[i].checked = false;
      }

      if (thirdCol[i].checked) {
        selectedImagesPage.push(thirdCol[i]);
      } else {
        selectedImagesPage = selectedImagesPage.filter(
          (item) => item.id !== thirdCol[i].id
        );

        // this[name] = selectedImagesPage;
      }
    }
    this[name] = selectedImagesPage;
  }
  refreshImages() { 
    // this.saveQuizSelectedGenomes();
    let obj = {
      quiz_id: "",
      selection: [],
      is_conversation : true,
      conversation_id:this.conversation_id
    };
    let set =
      this.currentPage === 3 ? "A" : this.currentPage === 4 ? "B" : "final";
 

    obj["quiz_id"] = this.quizId;

    obj["selection"] = [];
    let selImages =
      this.currentPage === 3
        ? this.firstSelectedImages
        : this.currentPage === 4
        ? this.secondSelectedImages
        : this.thirdSelectedImages;
    obj["selection"].push({
      selection_set: set,
      images: selImages.map((item) => item.id).join(","),
    });

    this.isNextClicked = true;
    this.noOfTimesNextClicked++;
    if (this.noOfTimesNextClicked === 1) {
      this.page1 = this.firstSelectedImages;
    } else if (this.noOfTimesNextClicked === 2) {
      this.page2 = this.secondSelectedImages;
    }

    if (this.currentPage === 5) {
      this.currentPage =7// 6;
    }
    if (this.currentPage === 4) {
      this.currentPage = 5;
    }
    if (this.currentPage < 5) {
      if (this.page1.length === 1) {
        this.currentPage = 4;
        this.currentSetOption = 1;
      } else {
        this.currentPage = 5;
        this.isPage4Skipped = true;
      }

      if (
        this.page1.length === 1 &&
        this.page2.length === 1 &&
        this.page1[0].genomeIndex === this.page2[0].genomeIndex
      ) {
        this.getStyleReport();
        this.isPage5Skipped = true;
      }
      if (
        this.page1.length === 1 &&
        this.page2.length === 1 &&
        this.page1[0].genomeIndex !== this.page2[0].genomeIndex
      ) {
        this.currentPage = 5;
        this.isPage4Skipped = true;
      }
    }
    this.saveQuizSelectedGenomes(obj);
  }
   getStyleReport() {
     this.api.getStyleReport(this.quizId).subscribe(
       (res: any) => {
         if (res && res.genome && res.genome.length > 0) {
           this.styleReport.emit(res);
           this.genomeName = res.genome[0].genome_name;
           this.genomeDescription = res.genome[0].genome_description;
           this.genomeImage = res.genome[0].genome_image;
           this.subStylesList = res.substyles;
 
           setTimeout(() => {
            //  this.loadChart();
           }, 1000);
         }
       },
       () => {}
     );
   }
 
  
   getRandomImagesFromGenomes() {
    const randomImages = [];

    if (this.currentPage === 5) {
      const pageLength = this.page1.length;
      let count = 3;
      if (pageLength === 1) {
        count = 24;
        for (let i = 0; i < 24; i++) {
          let index = this.page1[0].genomeIndex;
          const randomBedroomImage = this.getRandomBedroomImage(
            this.genomesList[index],
            index,
            this.genomesList[index].genome_value
          );
          if (randomBedroomImage) {
            randomImages.push({ ...randomBedroomImage, checked: false });
          }
        }
      } else if (pageLength === 2) {
        count = 12;
        for (let i = 0; i < this.page1.length; i++) {
          for (let j = 0; j < 12; j++) {
            let index = this.page1[i].genomeIndex;
            const randomBedroomImage = this.getRandomBedroomImage(
              this.genomesList[index],
              index,
              this.genomesList[index].genome_value
            );
            if (randomBedroomImage) {
              randomImages.push({ ...randomBedroomImage, checked: false });
            }
          }
        }
      } else if (pageLength === 3) {
        count = 8;
        for (let i = 0; i < this.page1.length; i++) {
          for (let j = 0; j < 8; j++) {
            let index = this.page1[i].genomeIndex;
            const randomBedroomImage = this.getRandomBedroomImage(
              this.genomesList[index],
              index,
              this.genomesList[index].genome_value
            );
            if (randomBedroomImage) {
              randomImages.push({ ...randomBedroomImage, checked: false });
            }
          }
        }
      }
      return randomImages;
    } else {
      this.genomesList.forEach((genome, i) => {
        const randomBedroomImage = this.getRandomBedroomImage(
          genome,
          i,
          genome.genome_value
        );
        if (randomBedroomImage) {
          randomImages.push({ ...randomBedroomImage, checked: false });
        }
      });
      return randomImages;
    }
  }

   getRandomBedroomImage(genome, i, genomeName) {
     console.log(this.selectedDept, "type");
     if (this.selectedDept) {
       const bedroomGallery = genome.gallery.find(
         (room) => room.room.toLowerCase() === this.selectedDept.toLowerCase()
       );
       if (bedroomGallery && bedroomGallery.gallery.length > 0) {
         return this.getRandomImg(bedroomGallery.gallery, i, genomeName);
       } else {
         return this.getRandomLivingImage(genome, i, genomeName);
       }
     } else {
       let allImages = [];
       allImages = genome.gallery.flatMap((room) => room.gallery);
       return this.getRandomImg(allImages, i, genomeName);
     }
   }
   getRandomLivingImage(genome, i, genomeName) {
     const bedroomGallery = genome.gallery.find(
       (room) => room.room.toLowerCase() === "living"
     );
     if (bedroomGallery && bedroomGallery.gallery.length > 0) {
       return this.getRandomImg(bedroomGallery.gallery, i, genomeName);
     }
 
     return null;
   }
 
   getRandomImg(allImages, i, genomeName) {
     this.randomImageWithoutType = null;
     if (allImages && allImages.length > 0) {
       let ran = Math.floor(Math.random() * allImages.length);
       if (allImages.some((item) => !item["alreadyShown"])) {
         if (allImages[ran] && allImages[ran]["alreadyShown"] === true) {
           this.getRandomImg(allImages, i, genomeName);
         } else {
           this.randomImageWithoutType = allImages[ran];
           this.randomImageWithoutType["alreadyShown"] = true;
 
           this.randomImageWithoutType["genome_value"] = genomeName;
           this.randomImageWithoutType["genomeIndex"] = i;
         }
       }
     }
     return this.randomImageWithoutType;
   }
 
   saveQuizViewedGenomes(set) {
     let obj = {
       quiz_id: "",
       selection: [],
     };
     obj["quiz_id"] = this.quizId;
     obj["selection"] = []; 
     obj["selection"].push({
       selection_set: set,
       selection_set_option: this.currentSetOption,
       images: this.shownImages.map((item) => item.id).join(","),
     });
     this.api.saveQuizViewedGenomes(obj).subscribe((res: any) => {});
   }
   saveQuizSelectedGenomes(obj) {
     if (obj.selection[0].selection_set === "final") {
       this.showLoader.emit(true);
     }
     this.api.saveQuizSelectedGenomes(obj).subscribe((res: any) => {
       if (obj.selection[0].selection_set === "final") {
         this.msgFromGenome.emit(res);
       }
       if (this.currentPage === 7) {
         this.getStyleReport();
       } else {
         this.goNext();
       }
     });
   }
   goNext() {
    this.shownImages = this.getRandomImagesFromGenomes();
    // this.selectedImages = [];
    if (this.shownImages.length > 0) {
      let randomArr = this.shuffleArray(this.shownImages);
      // this.col1List = [];
      // this.col2List = [];
      // this.col3List = [];
      let firstCol =
        this.currentPage === 3
          ? this.page3Col1List
          : this.currentPage === 4
          ? this.page4Col1List
          : this.page5Col1List;
      let secondCol =
        this.currentPage === 3
          ? this.page3Col2List
          : this.currentPage === 4
          ? this.page4Col2List
          : this.page5Col2List;
      let thirdCol =
        this.currentPage === 3
          ? this.page3Col3List
          : this.currentPage === 4
          ? this.page4Col3List
          : this.page5Col3List;

      for (let i = 0; i < randomArr.length; i++) {
        if (i % 3 == 0) {
          firstCol.push(randomArr[i]);
        } else if (i % 3 === 1) {
          secondCol.push(randomArr[i]);
        } else {
          thirdCol.push(randomArr[i]);
        }
      }
    } else {
      this.limitReached = true;
    }
    if (this.currentPage === 4) {
      this.saveQuizViewedGenomes("B");
    }
  }
   shuffleArray<T>(array: T[]): T[] {
     // Fisher-Yates shuffle algorithm
     for (let i = array.length - 1; i > 0; i--) {
       const j = Math.floor(Math.random() * (i + 1));
       [array[i], array[j]] = [array[j], array[i]];
     }
     return array;
   }
 
   getGenomeData() {
    this.api.getGenomeData().subscribe((res: any) => {
      this.genomesList = res.genomes;

      this.shownImages = this.getRandomImagesFromGenomes();
      this.chatService.shuffledImages = this.shuffleArray(this.shownImages);
      let randomArr = JSON.parse(JSON.stringify( this.chatService.shuffledImages))
      this.formImgColumns(randomArr)
    });
  }

  formImgColumns(randomArr){
    this.page3Col1List = [];
    this.page3Col2List = [];
    this.page3Col3List = [];
    this.page4Col1List = [];
    this.page4Col2List = [];
    this.page4Col3List = [];
    this.page5Col1List = [];
    this.page5Col2List = [];
    this.page5Col3List = [];
    this.firstSelectedImages = [];
    this.secondSelectedImages = [];
    this.thirdSelectedImages = [];

    let firstCol =
      this.currentPage === 3
        ? this.page3Col1List
        : this.currentPage === 4
        ? this.page4Col1List
        : this.page5Col1List;
    let secondCol =
      this.currentPage === 3
        ? this.page3Col2List
        : this.currentPage === 4
        ? this.page4Col2List
        : this.page5Col2List;
    let thirdCol =
      this.currentPage === 3
        ? this.page3Col3List
        : this.currentPage === 4
        ? this.page4Col3List
        : this.page5Col3List;

    for (let i = 0; i < randomArr.length; i++) {
      if (i % 3 == 0) {
        firstCol.push(randomArr[i]);
      } else if (i % 3 === 1) {
        secondCol.push(randomArr[i]);
      } else {
        thirdCol.push(randomArr[i]);
      }
    }
    this.saveQuizViewedGenomes("A");
    this.showLoadMore = true;
  }
  
   Back() {
    if (this.currentPage === 4) {
      this.noOfTimesNextClicked = 1;
    }
    if (this.currentPage === 5) {
      this.noOfTimesNextClicked = 2;
      if (this.isPage4Skipped) {
        this.currentPage = 3;
        this.isPage4Skipped = false;
        this.noOfTimesNextClicked = 0;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    } else if (this.currentPage === 9) {
      if (this.isLoggedIn) {
        this.currentPage = 7;
      } else {
        this.currentPage = 8;
      }
    } else {
      this.currentPage = this.currentPage - 1;
    }
  }
   get firstCol() {
    return this.currentPage === 3
      ? this.page3Col1List
      : this.currentPage === 4
      ? this.page4Col1List
      : this.page5Col1List;
  }
  get secondCol() {
    return this.currentPage === 3
      ? this.page3Col2List
      : this.currentPage === 4
      ? this.page4Col2List
      : this.page5Col2List;
  }
  get thirdCol() {
    return this.currentPage === 3
      ? this.page3Col3List
      : this.currentPage === 4
      ? this.page4Col3List
      : this.page5Col3List;
  }
  get getSelectedImgesLength() {
    return this.currentPage === 3
      ? this.firstSelectedImages.length
      : this.currentPage === 4
      ? this.secondSelectedImages.length
      : this.thirdSelectedImages.length;
  }
 }
 