import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, Subject } from "rxjs"; // RxJS 6 syntax
import { HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { environment as env } from "src/environments/environment";
import { HttpService } from "../http/http.service";
import {
  IDepartment,
  IProductPayload,
  IProductsPayload,
  ISearchProductsPayload,
} from "./../../models";
import { map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  quizData: any;
  checkedStyleList: any[];
  productList: any[];
  collectionProductList: any[];
  dupProductList: any[];
  dupCollectionProductList: any[];
  toggle: string;
  brandList: any[];
  styleList: any[];
  sortTypeList: any;
  sortType: string;
  gptData: any;
  drawerOpened: boolean;
  blackSaleBanner: any[] = [];
  quizCategory: any;
  isSideNavOpened: boolean;
    dialodClosed$ = new Subject();
  query: any;
  onInitLoaded: boolean;
  onInitLoadedMob:boolean;
  projectId: any;
  quizId: any;
  constructor(
    private httpService: HttpService,
    private cookie: CookieService // private utils: UtilsService
  ) {}

  compareAndUpdate(arr1: any[], arr2: any[]): any[] {
    return arr2.map(newObj => {
      const matchingOldObj = arr1.find(oldObj => oldObj.key === newObj.key);
      if (matchingOldObj  && newObj.key!== "size" && newObj.key!== "price" && newObj.key!== "diameter" && newObj.key!== "width" && newObj.key!== "height" && newObj.key!== "length" && newObj.key!== "depth") {
          const newValue = newObj.value.map(newItem => {
              const matchingOldItem = matchingOldObj.value.find(oldItem => oldItem.name === newItem.name);
              return { ...newItem, checked: (matchingOldItem&& matchingOldItem.enabled) ? matchingOldItem.checked : false };
          });
       
          return { ...newObj, value: newValue ,opened: matchingOldObj.opened};
      } else {
        if(newObj.key!== "size" && newObj.key!== "price" && newObj.key!== "diameter" && newObj.key!== "width" && newObj.key!== "height" && newObj.key!== "length" && newObj.key!== "depth"){
          const newValue = newObj.value.map(newItem => ({ ...newItem, checked: false }));
          return { ...newObj, value: newValue };
        }   
        return newObj;
      }
  });
}

 pairKeyValues(input: string): any[] {
  const pairs = input.split(';');
  const result: any[] = [];

  pairs.forEach(pair => {
      const [key, valuesString] = pair.split(':');
      if (!valuesString) {
        return;
    }
      const values = valuesString.split(',');
      result.push({ key: key, value: values });
  });

  return result;
}



  getDesignProfile(): Observable<any> {
    const url = `${env.API_BASE_HREF}designerProfile`;
    return this.httpService.get(url);
  }
  acuityavaibletime(date: string, desId: string): Observable<any> {
    const url = `${env.API_BASE_HREF}acuityavailabletime?date=${date}&designerId=${desId}`;
    return this.httpService.get(url);
  }
  saveDesignAppointment(data: any): Observable<any> {
    const url = `${env.API_BASE_HREF}save_designAppointment`;
    return this.httpService.post(url, data);
  }
  getApppointment(date, designerId, data): Observable<any> {
    const url = `${env.API_BASE_HREF}appointments?date=${date}&firstName=${data.firstName}&lastName=${data.lastName}&email=${data.email}&phone=${data.phone}&designerId=${designerId}`;
    return this.httpService.get(url);
  }
  getUserByMail(email): Observable<any> {
    const url = `${env.API_BASE_HREF}get_userbymail?email=${email}`;
    return this.httpService.get(url);
  }
  updateDesignUser(data): Observable<any> {
    const url = `${env.API_BASE_HREF}update_designuser`;
    return this.httpService.post(url, data);
  }
  getAllAppointments(email, booking_id,userId?): Observable<any> {
    if (email) {
      const url = `${env.API_BASE_HREF}get_designProject?emailid=${email}`;
      return this.httpService.get(url);
    } else if (booking_id) {
      const url = `${env.API_BASE_HREF}get_designProject?booking_id=${booking_id}`;
      return this.httpService.get(url);
    } else if (userId) {
      const url = `${env.API_BASE_HREF}get_designProject?user_id=${userId}`;
      return this.httpService.get(url);
    }  else {
      const url = `${env.API_BASE_HREF}get_designProject`;
      return this.httpService.get(url);
    }
    // return of([
    //   {
    //     "project_id": 487470,
    //     "user_id": 2849,
    //     "firstName": "ramsey",
    //     "lastName": "43",
    //     "email": "ramsey@443.com",
    //     "phone": "1",
    //     "room": "bedroom",
    //     "zip_code": 21,
    //     "status": "open",
    //     "designer_id": null,
    //     "tasks_summary": true,
    //     "tasks_upload": true,
    //     "tasks_style": false,
    //     "tasks_vision": false,
    //     "designer": null,
    //     "intakeimage": [
    //       {
    //         "id": 15,
    //         "image": "https://s3.us-east-2.amazonaws.com/lazysuzy-images/images/design/projects/487470/client_submission/c4cQuzX2pnWUfkLzq7sRcGnU62MxQxuCHecQ6cSi.jpg",
    //         "comment": null
    //       }
    //     ],
    //     "style_reports": [
    //       822
    //     ],
    //     "appointments": null
    //   }
    // ])
  }

  getProjectAdmin( booking_id): Observable<any> {
    const url = `${env.API_BASE_HREF}get_designProject_admin?booking_id=${booking_id}`;
    return this.httpService.get(url);
    // if (email) {
    //   const url = `${env.API_BASE_HREF}get_designProject?emailid=${email}`;
    //   return this.httpService.get(url);
    // } else if (booking_id) {
    
    // } else if (userId) {
    //   const url = `${env.API_BASE_HREF}get_designProject?user_id=${userId}`;
    //   return this.httpService.get(url);
    // }  else {
    //   const url = `${env.API_BASE_HREF}get_designProject`;
    //   return this.httpService.get(url);
    // }
  }

  getNewArrivals(filters = "", page = 0): Observable<IProductsPayload> {
    const endpoint = `products/all`;
    const url = `${env.API_BASE_HREF}${endpoint}?new=true`;
    return this.httpService.get(url);
  }

  getTopDeals(filters = "", page = 0): Observable<IProductsPayload> {
    const endpoint = `products/all`;
    const url = `${env.API_BASE_HREF}${endpoint}?sale=true`;
    return this.httpService.get(url);
  }

  getBlowOutDeals() {
    const dealEndpoint = `v1/blowout-deals`;
    const url = `${env.API_BASE_HREF}${dealEndpoint}`;
    return this.httpService.get(url);
  }

  getTrendingProducts(): Observable<IProductsPayload> {
    const endPoint = `trending`;
    const url = `${env.API_BASE_HREF}${endPoint}`;
    return this.httpService.get(url);
  }

  getBestSellers(filters = "", page = 0): Observable<IProductsPayload> {
    const endpoint = `products/all`;
    const url = `${env.API_BASE_HREF}${endpoint}?bestseller=true`;
    return this.httpService.get(url);
  }

  getEmail(email = "", url = "") {
    const endpoint = "subscribe";
    const path = `${env.API_BASE_HREF}${endpoint}?email=${email}&url=${url}`;
    return this.httpService.get(path);
  }

  getBrands(): Observable<IProductPayload> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.cookie.get("token")}`,
    });
    const url = `${env.API_BASE_HREF}brand`;
    return this.httpService.get(url, headers);
  }

  browseRoom() {
    const endpoint = "all-departments-v1";
    const url = `${env.API_BASE_HREF}${endpoint}?home=true`;
    return this.httpService.get(url);
  }

  bannerData() {
    const endpoint = "banners";
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  getAllProducts(
    trend: string = "",
    total: number,
    filters = "",
    sortType = "",
    page = 0,
    keyword_url = ""
  ): Observable<IProductsPayload> {
    const endpoint = `products/all`;
    let url = "";
    if (trend !== "") {
      url = `${env.API_BASE_HREF}${endpoint}?${trend}=true&limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${page}`;
    } else {
      url = `${env.API_BASE_HREF}${endpoint}?limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${page}&keyword_url=${keyword_url}`;
    }
    return this.httpService.get(url);
  }

  getAllProductsUsingKeyword(
    keyword: string,
    total: number,
    filters = "",
    sortType = "",
    page = 0
  ) {
    let url = "";
    if (keyword !== "sale" && keyword !== "new" && keyword !== "all") {
      url = `${env.API_BASE_HREF}products/all?keyword_url=${keyword}&limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${page}`;
    } else {
      url = `${env.API_BASE_HREF}products/${keyword}?limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${page}`;
    }
    return this.httpService.get(url);
  }

  getAllProductsWithBrand(
    brand: string,
    trend: string = "",
    total: number,
    filters = "",
    sortType = "",
    page = 0,
    keyword_url = ""
  ): Observable<IProductsPayload> {
    let endpoint = `products/all`;
    if (brand) {
      endpoint = `products/brand/${brand}`;
    }
    let url = "";
    if (trend !== "") {
      url = `${env.API_BASE_HREF}${endpoint}?${trend}=true&limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${page}`;
    } else {
      url = `${env.API_BASE_HREF}${endpoint}?limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${page}&keyword_url=${keyword_url}`;
    }
    return this.httpService.get(url);
  }

  getMultiplePageAllProducts(
    trend: string,
    total: number,
    filters = "",
    sortType = "",
    page: number,
    brand: string = ""
  ): Observable<any> {
    const httpCalls = [];
    let endpoint;
    if (brand) {
      endpoint = `products/brand/${brand}`;
    } else {
      endpoint = `products/all`;
    }
    for (let i = 0; i <= page; i++) {
      let url = "";
      if (trend) {
        url = `${env.API_BASE_HREF}${endpoint}?${trend}=true&limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${i}`;
      } else {
        url = `${env.API_BASE_HREF}${endpoint}?limit=${total}&filters=${filters}&sort_type=${sortType}&pageno=${i}`;
      }

      httpCalls.push(this.httpService.get(url));
    }
    return forkJoin(httpCalls);
  }

  getProducts(
    department: string,
    category: string,
    filters = "",
    sortType = "",
    page = 0,
    isSort: boolean = true
  ): Observable<IProductsPayload> {
    let endpoint;
    if(category==""){
    endpoint = `products/${department}`;
    } else {
     endpoint =
      department !== ""
        ? `products/${department}/${category}`
        : `products/${category}`;
    }
    let url = "";
    if (isSort) {
      url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&sort_type=${sortType}&pageno=${page}`;
    } else {
      url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&pageno=${page}`;
    }
    // const url= 'https://staging.seededhome.com/api/products/custom/602';
    return this.httpService.get(url);
    // return this.httpService.get('https://staging.seededhome.com/api/products/rugs?filters=&sort_type=recommended&pageno=0');
  }
  getCollectionProducts(
    department: string,
    category: string,
    filters = "",
    sortType = "",
    page = 0
  ): Promise<any> {
    const endpoint = `living-products/${department}/${category}`;
    const url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&sort_type=${sortType}&pageno=${page}`;
    return this.httpService.get(url).toPromise();
  }
  getFilteredData(
    category: string,
    filters = "",
    sortType = "",
    keyword_url = ""
  ): Observable<Record<string, any>> {
    let endpoint = "";
    let url = "";
    if (category !== "sale" && category !== "new" && category !== "all" && !category.includes('/')) {
      endpoint = `filter-data/all`;
      url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&sort_type=${sortType}&keyword_url=${category}`;
    } else {
    // if (category.includes("all")) {
    //   let sliced = category.split("/")[1];
    //   if (sliced !== "") {
    //     endpoint = `filter-data/all?${sliced}=true`;
    //     url = `${env.API_BASE_HREF}${endpoint}&filters=${filters}&sort_type=${sortType}&keyword_url=${keyword_url}`;
    //   } else {
    //     endpoint = `filter-data/all?`;
    //     url = `${env.API_BASE_HREF}${endpoint}filters=${filters}&sort_type=${sortType}&keyword_url=${keyword_url}`;
    //   }
    // } else {
    endpoint = `filter-data/${category}`;
    url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&sort_type=${sortType}&keyword_url=${keyword_url}`;
    }
    // }
    return this.httpService.get<Record<string, any>>(url);
  }

  getFilteredDataForBrand(
    brandName: string,
    category: string,
    filters = "",
    sortType = "",
    keyword_url = ""
  ) {
    let endpoint = "";
    let url = "";
    if (brandName) {
      endpoint = `filter-data/brand/${brandName}`;
      url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&sort_type=${sortType}&keyword_url=${keyword_url}`;
    } else if (category.includes("all")) {
      let sliced = category.split("/")[1];
      if (sliced !== "") {
        endpoint = `filter-data/all?${sliced}=true`;
        url = `${env.API_BASE_HREF}${endpoint}&filters=${filters}&sort_type=${sortType}&keyword_url=${keyword_url}`;
      } else {
        endpoint = `filter-data/all?`;
        url = `${env.API_BASE_HREF}${endpoint}filters=${filters}&sort_type=${sortType}&keyword_url=${keyword_url}`;
      }
    } else {
      endpoint = `filter-data/${category}`;
      url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&sort_type=${sortType}&keyword_url=${keyword_url}`;
    }
    return this.httpService.get(url);
  }
  getFilterDataCustom(quizId) {
    const url = `${env.API_BASE_HREF}filter-data/custom/${quizId}`;
    return this.httpService.get(url);
  }
  getBrandData(brandName: string): Observable<any> {
    let endpoint = `brand`;
    if (brandName !== "") {
      endpoint = `brand/${brandName}`;
    }
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  getMultiplePageProducts(
    department: string,
    category: string,
    filters = "",
    sortType = "",
    page: number,
    isSort: boolean = true
  ): Observable<any> {
    const httpCalls = [];
    const endpoint = `products/${department}/${category}`;
    for (let i = 0; i <= page; i++) {
      let url = "";
      if (isSort) {
        url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&sort_type=${sortType}&pageno=${i}`;
      } else {
        url = `${env.API_BASE_HREF}${endpoint}?filters=${filters}&pageno=${i}`;
      }
      httpCalls.push(this.httpService.get(url));
    }
    return forkJoin(httpCalls);
  }

  getProduct(id: string): Observable<any> {
    const endpoint = `product/${id}`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);

  }

  getProductSelection(id: string): Observable<any> {
    const endpoint = `product_selection/${id}`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
 
  }

  getAllBrandNames(): Observable<any> {
    const endpoint = `brand`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  getAllDepartments(brandFilter: string = ""): Observable<IDepartment> {
    let endpoint = `all-departments-v1`;
    if (brandFilter !== "" && brandFilter !== undefined) {
      endpoint = `all-departments-v1?brands=${brandFilter}`;
    }
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  getBrowseTabData(
    id: string,
    appliedFilters: string,
    pageNo
  ): Observable<any> {
    const endpoint = `products/all`;
    const url = `${
      env.API_BASE_HREF
    }${endpoint}?filters=${appliedFilters};category:${id}&sort_type=&pageno=${
      pageNo || 0
    }&limit=24&board-view=true`;
    return this.httpService.get(url);
  }

  getSearchProducts(
    search: string,
    pageNo: number
  ): Observable<ISearchProductsPayload> {
    const endpoint = `products/_search`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    // const url = `${env.ES_API_BASE_HREF}${endpoint}?source=${searchQuery}&source_content_type=application%2Fjson`;
    const url = `${env.API_BASE_HREF}search-keywords?q=${search}&page=${pageNo}`;
    return this.httpService.get(url, headers);
  }

  getWishlistProducts(isBoard): Observable<IProductsPayload> {
    const endpoint = `wishlist`;
    const url = `${env.API_BASE_HREF}${endpoint}${
      isBoard ? "?board-view=true" : ""
    }`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.cookie.get("token")}`,
    });
    return this.httpService.get(url, headers);
  }

  getCategories(department: string): Observable<ISearchProductsPayload> {
    const endpoint = `categories/${department}`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  login(data) {
    const endpoint = `login`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get("token")}`,
    });
    return this.httpService.post(url, data, headers);
  }

  subscription(URL, email): Observable<string> {
    const endpoint = `subscribe`;
    const url = `${env.API_BASE_HREF}${endpoint}?url=${URL}&email=${email}`;
    return this.httpService.get(url);
  }
  newsLetterSubmit(email): Observable<string> {
    const endpoint = `createpromo?type=NewSub`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, {});
  }

  wishlistProduct(sku, mark, isHandset: boolean) {
    let endpoint;
    if (mark) {
      endpoint = `mark/favourite/${sku}`;
    } else {
      endpoint = `unmark/favourite/${sku}`;
    }
    const token = this.cookie.get("token");
    // if (!token) {
    //     // trigger signup window
    //     this.utils.openSignupDialog(isHandset, true);
    //     return;
    // }
    const url = `${env.API_BASE_HREF}${endpoint}`;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    return this.httpService.get(url, headers);
  }

  signup(data) {
    const endpoint = `register`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get("token")}`,
    });
    return this.httpService.post(url, data, headers);
  }

  signout() {
    const endpoint = `logout`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get("token")}`,
    });
    return this.httpService.get(url, headers);
  }

  keepAlive() {
    const endpoint = `user/keepalive`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get("token")}`,
    });
    return this.httpService.get(url, headers);
  }

  getPosts(): Observable<any[]> {
    return this.httpService.get<any[]>(
      "https://psimonmyway.com/wp-json/wp/v2/posts?_embed",
      {
        params: {
          per_page: "6",
        },
      }
    );
  }

  getPostById(id): Observable<any[]> {
    return this.httpService.get<any[]>(
      `https://psimonmyway.com/wp-json/wp/v2/posts/${id}`
    );
  }

  getAuthToken(accessToken, provider) {
    const endpoint = `oauth/token`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    const data = {
      access_token: accessToken,
      client_id: env.CLIENT_ID,
      client_secret: env.CLIENT_SECRET,
      grant_type: "social",
      provider,
    };
    return this.httpService.post(url, data);
  }

  addCartProduct(data) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const endpoint = "cart/add";
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }

  removeCartProduct(data) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const endpoint = "cart/remove";
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }

  getCartProduct(hasState, state, hasPromo?, promoCode?) {
    let endpoint = hasState ? `cart?state_code=${state}` : "cart";
    endpoint = hasPromo ? `${endpoint}&promo=${promoCode}` : endpoint;
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url, headers);
  }
  getCartProductsFromDashboard(hasState, state) {
    let endpoint = hasState
      ? `cart_servicerep?state_code=${state}`
      : "cart_servicerep";
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url, headers);
  }

  getAllDepartmentsBoard(): Observable<IDepartment> {
    const endpoint = `all-departments-v1`;
    const url = `${env.API_BASE_HREF}${endpoint}?board-view=true`;
    return this.httpService.get(url);
  }

  getAllBoards(payload): Observable<IProductPayload> {
    const url = `${env.API_BASE_HREF}board`;
    return this.httpService.post(url, {
      operation: "select",
      entity: "board",
      data: 5,
      Buser_id: 1,
    });
  }

  createOrder(data) {
    const url = `${env.API_BASE_HREF}create_order`;
    return this.httpService.post(url, data);
  }
designBookCustomerCreate(data){
  const url = `${env.API_BASE_HREF}design_customer_create_paypal`;
  return this.httpService.post(url, data);
}
  

  captureOrder(data) {
    const url = `${env.API_BASE_HREF}paypal_capture_order`;
    return this.httpService.post(url, data);
  }

  paymentCharge(data) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const endpoint = `payment_charge`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }

  paymentChargeBooking(data) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const endpoint = `design_payment_charge`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }

  getOrderSuccessData(orderId) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const endpoint = `order?order_id=${orderId}`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url, headers);
  }

  userUpdate(data) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const endpoint = `user/update`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }

  sendPasswordResetLink(data) {
    const endpoint = `password/create`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data);
  }

  validateResetPasswordToken(token) {
    const endpoint = `password/find/${token}`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  setNewPassword(data) {
    const endpoint = `password/reset`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data);
  }

  updateProfile(data) {
    const endpoint = "user/details/update";
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }

  getProfile() {
    const endpoint = "get-user";
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url, headers);
  }

  fetchDisplayProfile(username) {
    const endpoint = `user/details?username=${username}`;
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url, headers);
  }

  getUserBoards(username) {
    const endpoint = `board?username=${username}`;
    const token = this.cookie.get("token");
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  likeBoard(boardId, like) {
    const apiAction = like ? "like" : "unlike";
    const endpoint = `board/${apiAction}/${boardId}`;
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, headers);
  }

  getCollections() {
    const url = `${env.API_BASE_HREF}collections`;
    return this.httpService.get(url);
  }

  getCollectionData(collection) {
    const url = `${env.API_BASE_HREF}collection?collection=${collection}`;
    return this.httpService.get(url);
  }

  getDeals() {
    const url = `${env.API_BASE_HREF}deals`;
    return this.httpService.get(url);
  }

  getRecentProducts() {
    const recentProductsUrl = `sku-history`;
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${recentProductsUrl}`;
    return this.httpService.get(url, headers);
  }

  submitReview(data) {
    const endpoint = "review";
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }

  getProductReviews(id: string, limit) {
    // const limit = 6;
    const endpoint = `review/getreview-${id}/${limit}`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  // Mark Review Helpful
  markReviewHelpful(userId: number, reviewId: number) {
    const data = {
      user_id: userId,
      review_id: reviewId,
    };
    const endpoint = `mark-helpful`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data);
  }

  // Report Review
  reportReview(userId: number, reviewId: number) {
    const data = {
      user_id: userId,
      review_id: reviewId,
    };
    const endpoint = `mark-reported`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data);
  }

  // Get Full Review List
  getFullReviewList(sku, pageNo, sortType: string = "") {
    const endpoint = sortType
      ? `allreviews/${sku}?sort_type=${sortType}&pageno=${pageNo}`
      : `allreviews/${sku}?pageno=${pageNo}`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.get(url);
  }

  // getOtherPeopleProducts()
  getOtherPeopleProducts(sku) {
    const endPoint = `other-views/userrelated-${sku}`;
    const url = `${env.API_BASE_HREF}${endPoint}`;
    return this.httpService.get(url);
  }

  // Api to get Order Details
  getOrderDetails(orderNumber, zipCode) {
    const endPoint =
      orderNumber && zipCode
        ? `order_status?orderid=${orderNumber}&zipcode=${zipCode}`
        : `order_status`;
    const url = `${env.API_BASE_HREF}${endPoint}`;
    return this.httpService.get(url);
  }

  saveCheckoutEmail(emailId = "") {
    if (emailId) {
      const endpoint = "save_checkout";
      const token = this.cookie.get("token");
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      const data = {
        emailid: emailId,
      };
      const url = `${env.API_BASE_HREF}${endpoint}`;
      return this.httpService.post(url, data, headers);
    }
  }

  getCollectionsCount() {
    const endPoint = "get_all_collection";
    const url = `${env.API_BASE_HREF}${endPoint}`;
    return this.httpService.get(url);
  }

  getProductShippingStatus(zipcode, productSku, site: string): Promise<any> {
    const token = this.cookie.get("token");
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    let zipCodeCheckUrl = "";
    if (site === "westelm") {
      headers = new HttpHeaders({
        Accept: "application/json",
        "Accept-Encoding": "gzip, deflate",
        Authorization: `Bearer ${token}`,
      });
      zipCodeCheckUrl = `${env.API_BASE_HREF}scraper/westelm`;
    } else if (site === "cb2") {
      zipCodeCheckUrl = `${env.API_BASE_HREF}scraper/cb2`;
    } else if (site == "cab") {
      zipCodeCheckUrl = `${env.API_BASE_HREF}scraper/cnb`;
    } else {
      zipCodeCheckUrl = `${env.API_BASE_HREF}get_shipzip_status`;
    }
    const url=  `${zipCodeCheckUrl}?sku=${productSku}&zipcode=${zipcode}`;;
    return this.httpService.get(url, headers).toPromise();
  }

  getTestimonials() {
    const endPoint = "testimonials?sortby=serial&sorttype=asc";
    const url = `${env.API_BASE_HREF}${endPoint}`;
    return this.httpService.get(url);
  }
  getShipzipStatus(zipcode, productSku) {
    const url = `${env.API_BASE_HREF}get_shipzip_status?sku=${productSku}&zipcode=${zipcode}`;
    return this.httpService.get(url);
  }
  getProductFinder(pageNo: number) {
    const url = `${env.API_BASE_HREF}product_finder/${pageNo}`;
    return this.httpService.get(url);
  }
  getSizingData(data: any) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}size_range_finder`;
    return this.httpService.post(url, data, headers);
  }
  getPricingData(data: any) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}price_range_finder`;
    return this.httpService.post(url, data, headers);
  }
  getShapeData(data: any) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}shape_finder`;
    return this.httpService.post(url, data, headers);
  }
  getQuizResLeftPanel(id: string) {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const data = { ls_id: id };
    const url = `${env.API_BASE_HREF}left_panel`;
    return this.httpService.post(url, data, headers);
  }
  searchProductFinder(data: any): Promise<any> {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}search_finder`;
    return this.httpService.post(url, data, headers).toPromise();
  }
  searchProductFinderCollection(data: any): Promise<any> {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}search_finder_collection`;
    return this.httpService.post(url, data, headers).toPromise();
  }

  saveQuizData(data: any): Observable<any> {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}save_quiz_sofa`;
    return this.httpService.post(url, data, headers);
  }
  getWareHouseLocations(productSku) {
    const url = `${env.API_BASE_HREF}warehouse_locations?productid=${productSku}`;
    return this.httpService.get(url);
  } 
  saveEmailTemplateSchedule(data: any): Observable<any> {
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}newsletter_mailer`;
    return this.httpService.post(url, data, headers);
  }
  getEmailTemplateSchedule(): Observable<any> {
    const url = `${env.API_BASE_HREF}get_email_data`;
    return this.httpService.get(url);
  }
  getAllDesignBookings(status,designer,timePeriod) {
    const url = `${env.API_BASE_HREF}alldesignbooking?status=${status}&designerid=${designer}&timePeriod=${timePeriod}`;
    return this.httpService.get(url);
  }
  getPickupLogs(sortBy,sortType,filter:string) {
    const url = `${env.API_BASE_HREF}pickup_log?sortby=${sortBy}&sorttype=${sortType}&filters=${filter}`;
    return this.httpService.get(url);
  }
  getOrderCourier(){
    const url = `${env.API_BASE_HREF}get_order_courier`;
    return this.httpService.get(url);
  }
  
  sendDesignBookingMail(data) {
    const url = `${env.API_BASE_HREF}designbookingmail`;
    return this.httpService.post(url, data);
  }

  cancelEmailTemplateSchedule(id: any): Observable<any> {
    const url = `${env.API_BASE_HREF}cancel_batch`;
    return this.httpService.post(url, { id: id });
  }
  getProdImgBoard(sku: string): Observable<any> {
    const url = `${env.API_BASE_HREF}product_imageBoard/${sku}`;
    return this.httpService.get(url);
  }
  getDesignImages(id) {
    const url = `${env.API_BASE_HREF}getdesign_image?project_id=${id}`;
    return this.httpService.get(url);
  }
  cancelAppointment(id: any): Observable<any> {
    const url = `${env.API_BASE_HREF}cancelappoinment`;
    return this.httpService.post(url, { id: id });
  }
  tradeDashboard(hasFilter): Observable<any> {
    let url = "";
    if (hasFilter) {
      url = `${env.API_BASE_HREF}tradeDashboard?${hasFilter}`;
    } else {
      url = `${env.API_BASE_HREF}tradeDashboard`;
    }
    return this.httpService.get(url);
  }
  getInvoiceDates(): Observable<any> {
    const url = `${env.API_BASE_HREF}optionsInvoiceDate`;
    return this.httpService.get(url);
  }
  getOptionsAssignedTo(): Observable<any> {
    const url = `${env.API_BASE_HREF}optionsAssignedTo`;
    return this.httpService.get(url);
  }
  getTradeStatus(): Observable<any> {
    const url = `${env.API_BASE_HREF}tradeStatus`;
    return this.httpService.get(url);
  }
  save_keyword() {
    const url = `${env.API_BASE_HREF}save_keyword`;
    return this.httpService.post(url, {});
  }
  savePackaging(data: any) {
    const url = `${env.API_BASE_HREF}save_packaging`;
    return this.httpService.post(url, data);
  }
  getPackaging(sku) {
    const url = `${env.API_BASE_HREF}get_packaging/${sku}`;
    return this.httpService.get(url);
  }
  saveRoomDesign(data: FormData) {
    const endpoint = `save_roomdesign`;
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data, headers);
  }
  getEditPrdFilter() {
    const url = `${env.ADMIN_API_BASE_HREF}edit-products-filter`;
    return this.httpService.get(url);
  }
  postPaymentOnServer(data: any) {
    const endpoint = `gpt_payment`;
    const url = `${env.API_BASE_HREF}${endpoint}`;
    return this.httpService.post(url, data);
  }
  getAvailableCredits() {
    const url = `${env.API_BASE_HREF}get_user_credits`;
    return this.httpService.get(url);
  }
  userLikeUnlike(data: any) {
    const url = `${env.API_BASE_HREF}gpt_rendering_like`;
    return this.httpService.post(url, data);
  }

  getNewProductsListing(
    page: number,
    vendor: string[],
    vendor_category: string[],
    status: string[],
    availability: string[],
    sortBy: string,
    sortType: string
  ) {
    const vendorFilter = vendor.toString();
    const vendorCategoryFilter = vendor_category.toString();
    const statusFilter = status.toString();
    const availabilityFilter = availability.toString();
    let url ='';
    if(sortBy && sortType){
      url = `${env.ADMIN_API_BASE_HREF}new-products-listing?sortby=${sortBy}&sorttype=${sortType}&filters=product_status:${availabilityFilter};site_name:${vendorFilter};status:${statusFilter};product_category:${vendorCategoryFilter};&pageno=${page}`;
    } else {
     url = `${env.ADMIN_API_BASE_HREF}new-products-listing?filters=product_status:${availabilityFilter};site_name:${vendorFilter};status:${statusFilter};product_category:${vendorCategoryFilter};&pageno=${page}`;
    }
    return this.httpService.get(url);
  }
  getAllProductsListing(
    page: number,
    filters: string,
    sortBy: string,
    sortType: string
  ) {
    let url=''
    if(sortBy && sortType){
      url = `${env.ADMIN_API_BASE_HREF}products-listing?sortby=${sortBy}&sorttype=${sortType}&filters=${filters}&pageno=${page}`;

    } else {
     url = `${env.ADMIN_API_BASE_HREF}products-listing?&filters=${filters}&pageno=${page}`;
    }
    return this.httpService.get(url);
  }
  updateStatusProductsListing(data: any) {
    const url = `${env.ADMIN_API_BASE_HREF}new-products/update-status`;
    return this.httpService.post(url, data);
  }

  getAllBrandList() {
    const url = `${env.API_BASE_HREF}brand_new`;
    return this.httpService.get(url);
  }

  getAllCategoryList() {
    const url = `${env.API_BASE_HREF}departments_new`;
    return this.httpService.get(url);
  }
  getPredictionProducts(data, filters: string, pageNo) {
    let tailUrl = `room=${data.room}&class=${data.class}`;
    // if (data.color) {
    //   tailUrl = `${tailUrl}&color=${data.color}`;
    // }
    // if (data.shape) {
    //   tailUrl = `${tailUrl}&shape=${data.shape}`;
    // }
    const url = `${env.API_BASE_HREF}get_class_products?${tailUrl}&filters=${filters}&pageno=${pageNo}`;
    // const url = 'https://staging.seededhome.com/api/get_class_products?room=livingroom&class=side%20table&color=&shape=&style=midcentury&pageno=0';
    return this.httpService.get(url);
  }
  getFilteredProducts(data, filters) {
    let tailUrl = `room=${data.room}&class=${data.class}`;
    const url = `${env.API_BASE_HREF}filter-data?${tailUrl}&filters=${filters}&sort_type=recommended`;
    // const url = `${env.API_BASE_HREF}filter-data?room=livingroom&class=side%20table&filters=style:midcentury;color:white;shape:round;&sort_type=recommended`;
    return this.httpService.get(url);
  }
  getImageClassification(img) {
    const url = `${env.API_BASE_HREF}get_imageclassification?image=${img}`;
    return this.httpService.get(url);
  }
  getImageRetrive(img) {
    const url = `${env.API_BASE_HREF}get_imageretrieval?image=${img}`;
    return this.httpService.get(url);
  }
  getGptRenderings(pageNo) {
    const url = `${env.API_BASE_HREF}get_renderimagelist?sortby=likes&sorttype=asc&pageno=${pageNo}`;
    return this.httpService.get(url);
  }
  getGptRendersByUser(pageNo) {
    const url = `${env.API_BASE_HREF}get_renderimagelist_byuser?pageno=${pageNo}`;
    return this.httpService.get(url);
  }
  getPinBoardList(img_id, user_id) {
    const url = `${env.API_BASE_HREF}get_pinboardlist?image_id=${img_id}&user_id=${user_id}`;
    return this.httpService.get(url);
  }
  savePinBoard(data) {
    const url = `${env.API_BASE_HREF}save_pinboard`;
    return this.httpService.post(url, data);
  }
  insertCredits() {
    const url = `${env.API_BASE_HREF}insert_user_credit`;
    return this.httpService.post(url, {});
  }
  getGenomeData() {
    const url = `${env.API_BASE_HREF}genomeslist`;
    return this.httpService.get(url);
    // return this.httpService.get('/assets/genome_list.json');
  }
  saveQuizViewedGenomes(data) {
    const url = `${env.API_BASE_HREF}quiz_viewed_genomes`;
    return this.httpService.post(url, data);
  }
  saveQuizSelectedGenomes(data) {
    const url = `${env.API_BASE_HREF}quiz_selected_genomes`;
    return this.httpService.post(url, data);
  }
  createStyleQuiz(data) {
    const url = `${env.API_BASE_HREF}create_style_quiz`;
    return this.httpService.post(url, data);
  }
  getStyleReport(quiz_id: any) {
    // https://staging.seededhome.com/api/style_report/{quiz_id}
    const url = `${env.API_BASE_HREF}style_report/${quiz_id}`;
    return this.httpService.get(url);
  }
  getQuizCategories() {
    const url = `${env.API_BASE_HREF}quiz_categories`;
    return this.httpService.get(url);
  }
  getStyleProductList(quizId) {
    // quizId='895'
    const url = `${env.API_BASE_HREF}style_productlist/${quizId}`;
    return this.httpService.get(url);
  }
  saveQuizSelectedProducts(data) {
    const url = `${env.API_BASE_HREF}quiz_selected_products`;
    return this.httpService.post(url, data);
  }
  saveQuizLifestyle(data) {
    const url = `${env.API_BASE_HREF}quiz_lifestyle`;
    return this.httpService.post(url, data);
  }
  updateQuizUser(quizId) {
    const url = `${env.API_BASE_HREF}update_quizuser`;
    return this.httpService.post(url, { quiz_id: quizId });
  }
  saveQuizMail(data) {
    const url = `${env.API_BASE_HREF}save_quiz_email`;
    return this.httpService.post(url, data);
  }
  saveDesignBookings(data) {
    const url = `${env.API_BASE_HREF}design_bookings`;
    return this.httpService.post(url, data);
  }
  designPayment() {
    const url = `${env.API_BASE_HREF}design_payment`;
    return this.httpService.post(url, {});
  }
  customerCreate(data: any) {
    const url = `${env.API_BASE_HREF}design_customer_create`;
    return this.httpService.post(url, data);
  }
  getProductPageTitle(quizId) {
    const url = `${env.API_BASE_HREF}name/${quizId}`;
    return this.httpService.get(url);
  }
  savePageTitle(data) {
    const url = `${env.API_BASE_HREF}/udpate-name`;
    return this.httpService.post(url, data);
  }
  getProjectIntakeDropdowns(){
    const url = `${env.API_BASE_HREF}design_intake_dropdown`;
    return this.httpService.get(url);
  }
  saveDesignIntake(data){
    const url = `${env.API_BASE_HREF}/savedesign_intake`;
    return this.httpService.post(url, data); 
  }
  saveDesignImages(data){
    const url = `${env.API_BASE_HREF}savedesign_image`;
    const token = this.cookie.get("token");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.httpService.post(url, data,headers); 
  }
  getDesignIntake(projectId){
    const url = `${env.API_BASE_HREF}design_intake?project_id=${projectId}`;
    return this.httpService.get(url);
  }
  getImageBlob(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to fetch image (status ${response.status})`);
          }
          return response.blob();
        })
        .then(blob => resolve(blob))
        .catch(error => reject(error));
    });
  }
  getProjectDropdown(){
    const url = `${env.API_BASE_HREF}design_project_list`;
    return this.httpService.get(url);
  }
  saveToProject(data){
    const url = `${env.API_BASE_HREF}design_product_add`;
    return this.httpService.post(url, data);
  }
  updateBookingTask(data){
    const url = `${env.API_BASE_HREF}update_bookingtask`;
    return this.httpService.post(url, data);
  }
  getDesignStausList(){
    const url = `${env.ADMIN_API_BASE_HREF}/dashboard/design_code`;
    return this.httpService.get(url);
  }
  updateDesignStatus(data){
    const url = `${env.API_BASE_HREF}save_designStatus`;
    return this.httpService.post(url, data);
  }
  getProductsByProjectId(id,page){
    // id=548624
    const url = `${env.API_BASE_HREF}get_design_product_list/${id}?pageno=${page}`;
    return this.httpService.get(url);
  }
  removeProduct(data){
    const url = `${env.API_BASE_HREF}design_product_remove`;
    return this.httpService.post(url, data);
  }
  getLandingDesignImages() {
    const url = `${env.API_BASE_HREF}designImages`;
    return this.httpService.get(url);
  }

  aiSubscribe(email:string){
    const url = `${env.API_BASE_HREF}subscribe?url=AI&email=${email}`;
    return this.httpService.get(url);
  }
  createPromo(){
    const url = `${env.API_BASE_HREF}createpromo?type=NewSub`;
    return this.httpService.post(url,{});
  }
}
