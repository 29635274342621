import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrdAvailStatusDialogComponent } from '../prd-avail-status-dialog/prd-avail-status-dialog.component';
import { ApiService } from 'src/app/shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.less']
})
export class AdminDashboardComponent implements OnInit {
  userName: string = '';
  userType: number = 0;

  constructor(private dialog: MatDialog,private router:Router, private apiService: ApiService) { }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.userName = user.first_name;
    this.userType = user.user_type;
  }
  openPrdAvailDialog() {
    this.router.navigate(['/admin/dashboard/cb-inventory'])
    // this.dialog.open(PrdAvailStatusDialogComponent, {
    //   disableClose: true
    // })
  }
  callKeyword() {
    this.apiService.save_keyword().subscribe((res) => {
    })
  }
}
