import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router'; 

const DECLARATIONS = [FooterComponent];

const MODULES = [
  CommonModule,
  RouterModule,
  MatIconModule,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class FooterModule {}
