import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnvService {
 public contactMail = environment.CONTACT_EMAIL;
 public appName = environment.APP_NAME;
 public appUrl = environment.APP_URL;
 public assetBaseUrl = environment.ASSET_BASE_URL;
 public siteUrl = environment.SITE_URL;
 public fbLink = 'https://www.facebook.com/SeededHome';
 public instaLink = 'https://www.instagram.com/seededhome/';
 public logoPathWhite = `${this.assetBaseUrl}site/assets/logo_full_white.png`;
 public logoPathBlack = `${this.assetBaseUrl}site/assets/logo_full_dark.png`;

  constructor() {}
}
