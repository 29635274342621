import {Component, Inject, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import { ApiService } from 'src/app/shared/services/api/api.service'; 
import { EventEmitterService } from 'src/app/shared/services/events/event-emitter.service';
import { MatDialogUtilsService } from 'src/app/shared/services/matDialogUtils/matDialogUtils.service';
import { EnvService } from 'src/app/shared/services/env.service';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.less']
})
export class SigninComponent implements OnInit {
    isHandset: boolean;
    userCookie: string;
    user: any;
    error = false;
    errorMsg: string;
    email = '';
    password = '';
    thanksMsg = false;

    returnUrl: string;
    bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
        Breakpoints.Handset
    );
    bpSubscription: Subscription;

    constructor(
        private apiService: ApiService, 
        private router: Router,
        private eventService: EventEmitterService,
        private breakpointObserver: BreakpointObserver,
        private matDialogUtils: MatDialogUtilsService,
        @Inject(MAT_DIALOG_DATA) public data,
        public env:EnvService
    ) {
    }

    ngOnInit() {
        this.bpSubscription = this.bpObserver.subscribe(
            (handset: BreakpointState) => {
                this.isHandset = handset.matches;
            }
        );
        if (this.data.shouldRedirect) {
            this.returnUrl = this.data.returnUrl;
        }
    }

    onDestroy(): void {
        this.bpSubscription.unsubscribe();
    }

    validateForm(email, password) {
        this.error = false;
        if (!email) {
            this.error = true;
            this.errorMsg = 'Email cannot be blank';
        } else if (!password) {
            this.error = true;
            this.errorMsg = 'Password cannot be blank';
        } else if (password.length < 8) {
            this.error = true;
            this.errorMsg = 'Password must contain 8 characters';
        }
        return !this.error;
    }

    handleError(payload: any) {
        this.error = true;
        this.errorMsg = payload.error;
    }

    login(event, email, password) {
        event.preventDefault();
        if (this.validateForm(email, password)) {
            const formData: any = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            this.apiService.login(formData).subscribe(
                (payload: any) => {
                    if (payload.success) {
                        this.error = false;
                        this.eventService.fetchUser(payload.success.token, payload.user);
                        if (this.data.shouldRedirect) {
                            this.router.navigateByUrl(this.returnUrl);
                        }
                        this.matDialogUtils.closeDialogs();
                        this.apiService.dialodClosed$.next(true)
                    } else {
                        this.handleError(payload);
                    }
                },
                (payload: any) => this.handleError(payload.error)
            );
        }
    }

    socialSignIn(platform) {
        this.eventService.socialSignIn(platform);
    }

    openSignupDialog() {
        this.matDialogUtils.openSignupDialog(this.isHandset, this.data.isClose);
    }

    closeDialogs() {
        this.matDialogUtils.closeDialogs();
        this.matDialogUtils.closeClicked = true;
    }
}
