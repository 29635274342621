import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pstTime'
})
export class PstTimePipe extends DatePipe implements PipeTransform {
  transform(date: any, format?: any): any {
      let dateObj: Date = typeof date == 'string' ? new Date(date) : date;
      if(dateObj){
      let offset = 7 * 60 * 60 * 1000;
      dateObj.setTime(dateObj.getTime() - offset);
      return super.transform(dateObj, format, null, null) ;
      }
      return '';
    }
  }