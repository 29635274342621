import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {  MatButtonModule } from '@angular/material/button';
import { ProductFilterMobileComponent } from './product-filter-mobile.component';
import { Ng5SliderModule } from 'ng5-slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SharedModule} from '../../../shared/shared.module';

const DECLARATIONS = [ProductFilterMobileComponent];
const MODULES = [CommonModule, MatIconModule, MatButtonModule, Ng5SliderModule, MatCheckboxModule];


@NgModule({
  declarations: [...DECLARATIONS],
    imports: [
        ...MODULES,
        SharedModule
    ],
  exports: [...DECLARATIONS, ...MODULES]
})
export class ProductFilterMobileModule { }
