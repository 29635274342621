import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, UtilsService } from 'src/app/shared/services';
import { EnvService } from 'src/app/shared/services/env.service';

@Component({
  selector: 'app-booking-paypal',
  templateUrl: './booking-paypal.component.html',
  styleUrls: ['./booking-paypal.component.less']
})
export class BookingPaypalComponent implements OnInit {
 
 
  @Input() isHandset = false;
  
   deviceHeight: number;
   deviceWidth: number; 
   logoPath = ""; 
   amount: number;   
   projectId:string;
   bookForm:any;

   constructor(
    private api:ApiService,
     private router: Router,
     private env:EnvService,
     private utils:UtilsService,
     private actRouter:ActivatedRoute
   ) {
    this.getDesignPayment(); 
   }
  
   ngOnInit(): void {
     this.logoPath = this.env.logoPathBlack;
     window.scrollTo(0, 0);
     this.hideIntercomIcon();
     this.utils.isHandset().subscribe((handset) => {
       this.isHandset = handset.matches;
       if (!this.isHandset) {
       }
     });
     this.projectId = this.actRouter.snapshot.params.bookingId
 
     this.deviceHeight = window.innerHeight;
     this.deviceWidth = window.innerWidth;

   }
   get isLoggedIn(): boolean {
     return (
       JSON.parse(localStorage.getItem("user")) &&
       JSON.parse(localStorage.getItem("user")).email.length > 0
     );
   }
 
   hideIntercomIcon() {
     const interval = setInterval(() => {
       const intercom = document.getElementsByClassName(
         "intercom-lightweight-app"
       )[0];
       const intercomApp = document.getElementsByClassName("intercom-app")[0];
 
       if (intercom) {
         intercom.classList.add("dn");
         clearInterval(interval);
       }
       if (intercomApp) {
         intercomApp.classList.add("dn");
         clearInterval(interval);
       }
     }, 1000);
   }
   ngAfterViewInit(): void {
     this.hideIntercomIcon();
   }
      
   getDesignPayment() {
     this.api.designPayment().subscribe((res: any) => {
     
       this.amount = res.amount / 100;
     });
   } 
 
   execPayment(event) {
    let user = JSON.parse(localStorage.getItem("user"));
     let data = {
       f_Name: user.firstName,
       l_Name:user.lastName,
       email: user.email,
       payment_id: event.payment_id,
       payer_id: event.payer_id,
       status: event.status,
     };
      
     this.api.customerCreate(data).subscribe((res: any) => {
       if (res) {
        // this.router.navigate([`/design/projects/${this.projectId}/dashboard`]);
        this.router.navigate([`/design/projects/${this.projectId}/landing`]);
       }
     });
   }
  
 }
 