import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-curated-collection',
  templateUrl: './curated-collection.component.html',
  styleUrls: ['./curated-collection.component.less']
})
export class CuratedCollectionComponent implements OnInit {
  @Input() isHandset = false;
  @Input() content: any[]=['From traditional classics to new age contemporary, our designers thoughtfully source products from top brands representing generational quality and conscious sustainability.',
'Partner with a designer to tackle bigger projects and unlock access to exclusive discounts or shop for your home at your own pace with our self checkout option.'];

@Input() contentMob: any[]=[' From traditional classics to new age contemporary, our designers thoughtfully source products from top brands representing generational quality and conscious sustainability.'];
  constructor() { }

  ngOnInit() {
  }

}
