import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReviewFormComponent } from 'src/app/feature/components/reviewForm/reviewForm.component';

import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReviewDialogService {
  productDialog: any;
  private payload: any;

  constructor(  public dialog: MatDialog,
    private location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,) { }
  openMyReviewDialog(modal) {
    this.productDialog = this.dialog.open(ReviewFormComponent, {
        width: '80%',
        height: '100%',
        data: {modal},
        panelClass: 'product-details-dialog-container'
    });
    this.productDialog.afterOpened().subscribe((result) => {
        this.location.go(`product/review/${modal.sku}`, '', this.location.getState());
    });
    this.productDialog.afterClosed().subscribe((result) => {

        this.payload = null;
        const params = {...this.activeRoute.snapshot.queryParams};
        // this.productDialog = undefined;
        if (params.modal_sku) {
            //  delete params.modal_sku;
            //  this.router.navigate([], { queryParams: params });
            this.router.navigate(['/product/', modal.sku]);
        } else {
            //this.location.back();
            this.router.navigateByUrl(`/product/${modal.sku}`);
        }

    });


}

openMyReviewDialog_bkup(modal) {
    this.productDialog = this.dialog.open(ReviewFormComponent, {
        width: '80%',
        height: '100%',
        data: {modal},
        panelClass: 'product-details-dialog-container'
    });
    this.productDialog.afterOpened().subscribe((result) => {
        this.location.go(`product/review/${modal.sku}`, '', this.location.getState());
    });
    this.productDialog.afterClosed().subscribe((result) => {
        this.payload = null;
        window.location.href = './product/' + modal.sku;
        const params = {...this.activeRoute.snapshot.queryParams};
        this.productDialog = undefined;
        if (params.modal_sku) {
            delete params.modal_sku;
            this.router.navigate([''], {queryParams: params});
        } else {
            this.location.back();
        }
    });
}

setProduct(payload) {
  this.payload = payload;
}
}
