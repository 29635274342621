import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.less']
})
export class ThankYouComponent implements OnInit {
  isHandset: boolean;
  projectId: string;

  constructor(private utilService:UtilsService,private router:Router,
    private actRouter: ActivatedRoute,

  ) {
    this.projectId = this.actRouter.snapshot.params.id;

   }

  ngOnInit(): void {
   this.utilService.isHandset().subscribe(resp=>{
    this.isHandset = resp.matches
   });
  }
  goToRoom(){
    this.router.navigate([`/room-profile/${this.projectId}/inspiration`])
  }
}
