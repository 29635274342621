import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ApiService } from "src/app/shared/services";
import { ChatService } from "src/app/shared/services/api/chat.service";

@Component({
  selector: "app-cb-inventory",
  templateUrl: "./cb-inventory.component.html",
  styleUrls: ["./cb-inventory.component.less"],
})
export class CbInventoryComponent implements OnInit {
  innerWidth: number;
  statusList: any[] = [];
  sku: string;
  brand: string;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset = false;
  constructor(private api: ApiService, private chatService: ChatService, private breakpointObserver: BreakpointObserver) {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }
  search() {
    if (this.sku) {
      this.chatService.getProductBySku(this.sku).then((data) => {
        if (data && data.length > 0) {
          this.brand = data[0].brand;
        }
      });
      this.api.getWareHouseLocations(this.sku).subscribe((resp: any) => {
        resp.map((m) => {
          m.status = "Pending";
          m.retryAttempts = 1;
        });
        this.statusList = resp;
        this.statusList.forEach((f) => {
          f.status = "Updating...";
          this.getStatus(f);
        });
      });
    }
  }
  async getStatus(data: any) {
    await this.api
      .getProductShippingStatus(data.zipcode, this.sku, this.brand)
      .then((resp) => {
        if (resp.onlineAvailableMessage) {
          data.status = resp.onlineAvailableMessage;
        } else {
          if (data.retryAttempts < 5) {
            this.getStatus(data);
            data.retryAttempts = data.retryAttempts + 1;
          } else {
            data.status = "Not Found";
          }
        }
      });
  }
}
