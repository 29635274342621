import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { CookieService } from "ngx-cookie-service";
import { environment as env } from "../../../../environments/environment";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PromoCodeService {
  baseUrl = `${env.API_BASE_HREF}cart`;

  constructor(private http: HttpService, private cookie: CookieService) {}

  getPromoCodeProducts(urlParams, type?) {
    if (type === "neworder") {
      this.baseUrl = `${env.API_BASE_HREF}cart_servicerep`;
    } else {
      this.baseUrl = `${env.API_BASE_HREF}cart`;
    }
    const url = `${this.baseUrl}?${urlParams}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.cookie.get("token")}`,
    });
    return this.http.get(url, headers);
  }
}
