import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlModule } from 'ngx-owl-carousel';
import { RouterModule } from '@angular/router';
import {
  MatButtonModule 
} from '@angular/material/button'; 
import { 
  MatFormFieldModule, 
} from '@angular/material/form-field';
import { 
  MatInputModule,  
} from '@angular/material/input';
import { 
  MatIconModule, 
} from '@angular/material/icon';

import { environment as env } from 'src/environments/environment';
import { MaterialModule } from 'src/app/shared/material-module';
import { CarouselModule } from 'primeng/carousel';
import { DesignComponent } from './design.component';
import { DesignMobileComponent } from './design-mobile/design-mobile.component';
import { DesignRoutingModule } from './design-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../core/components/auth/auth.module';
import { CuratedCollectionComponent } from '../feature/containers/landing/curated-collection/curated-collection.component';
import { GptComponent } from './gpt/gpt.component';
import { FormsModule } from '@angular/forms';
import { ShoppableImageComponent } from './shoppable-image/shoppable-image.component';
import { BuyCreditsModalComponent } from './gpt/buy-credits-modal/buy-credits-modal.component';
import { NgxStripeModule } from 'ngx-stripe';
import { RenderComponent } from './gpt/render/render.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GptMobileComponent } from './gpt/gpt-mobile/gpt-mobile.component';
import { FooterModule } from '../core/components';
import { MatCommonModule } from '@angular/material/core';
import { ImageComparisonComponent } from './image-comparison/image-comparison.component';
const DECLARATIONS = [DesignComponent, DesignMobileComponent, CuratedCollectionComponent];

const MODULES = [
  CommonModule,
  RouterModule,
  DesignRoutingModule,
  OwlModule,
  CarouselModule,
  SharedModule,
  AuthModule,
  FormsModule,
  MatButtonModule,
  MatCommonModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MaterialModule,
  InfiniteScrollModule,
  // MatMenuModule,
  
  FooterModule,
];

@NgModule({
  declarations: [...DECLARATIONS, GptComponent, ShoppableImageComponent, BuyCreditsModalComponent, RenderComponent, GptMobileComponent, ImageComparisonComponent],
  imports: [...MODULES, NgxStripeModule.forRoot(env.STRIPE_API_KEY)],
  entryComponents: [BuyCreditsModalComponent],
  exports: [...DECLARATIONS, ...MODULES],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class DesignModule { }
