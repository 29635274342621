import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-design-sign-up",
  templateUrl: "./design-sign-up.component.html",
  styleUrls: ["./design-sign-up.component.less"],
})
export class DesignSignUpComponent implements OnInit, AfterViewInit {
  isHandset: any;
  logoPath: string;
  email = new FormControl("", [Validators.required, Validators.email]);
  password = new FormControl("", [Validators.required]);
  quizId: string;

  emailAvailable: boolean;
  showAuth: boolean;
  projectId: any;
  selectedDept: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private env: EnvService,
    private router: Router,
    private cookie: CookieService,
    private api: ApiService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
      });

    this.logoPath = this.env.logoPathBlack;
  }

  ngOnInit(): void {
    this.selectedDept = this.api.quizCategory;
    this.quizId = this.api.quizId;
  }
  
  ngAfterViewInit(): void {
    this.hideIntercomIcon();
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
  }

  goToLifeStyle() {
    if (this.email.valid) {
      this.api.getUserByMail(this.email.value).subscribe((res: any) => {
        if (res.status) {
          this.emailAvailable = true;
        } else {
          let obj = {
            email: this.email.value,
          };
          this.api.saveQuizMail(obj).subscribe((res: any) => {
            if (res.user && res.user.first_name === "") {
              res.user.first_name = res.user.email.split("@")[0];
              res.user.name = res.user.email.split("@")[0];
            }
            this.updateUser(res);
            this.emailAvailable = false;
            this.api.updateQuizUser(this.quizId).subscribe((res: any) => {});
          });
        }
      });
    }
    // this.currentPage = 10;
  }
  signIn() {
    let obj = {
      email: this.email.value,
      password: this.password.value,
    };
    this.api.login(obj).subscribe((res: any) => {
      this.updateUser(res);
      this.api
        .saveQuizMail({ email: this.email.value })
        .subscribe((res: any) => {
          this.api.updateQuizUser(this.quizId).subscribe((res: any) => {});
        });
    });
  }
  expiredDate = new Date();
  updateUser(payload) {
    this.showAuth = false;
    this.expiredDate.setMonth(this.expiredDate.getMonth() + 6);
    this.cookie.set("token", `${payload.success.token}`, this.expiredDate, "/");
    localStorage.setItem("user", JSON.stringify(payload.user));
    this.saveDesignBookings();
    setTimeout(() => {
      this.showAuth = true;
    }, 1000);
  }

  saveDesignBookings() {
    this.selectedDept = this.api.quizCategory;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let data = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: "",
        room: this.selectedDept,
        zipcode: "",
        quiz_id: this.quizId,
      };
      this.api.saveDesignBookings(data).subscribe((res: any) => {
        if (res && res.status) {
          this.projectId = res.booking_id;
          this.api.quizId = this.quizId;
          this.router.navigate([
            `/design/book/project-details/${this.projectId}`,
          ]);
        }
      });
    }
  }

  Back() {
    this.router.navigate([`/style-quiz/my-style/${this.quizId}`]);
  }

  get deviceHeight() {
    return window.innerHeight;
  }
}
