import {Component, Input, OnInit} from '@angular/core';
import {ApiService, EventEmitterService, MatDialogUtilsService, UtilsService} from './../../../shared/services';
import {Router} from '@angular/router';
import {Carousel} from 'primeng/carousel';
import {IProductsPayload} from 'src/app/shared/models';
import {Subscription} from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-new-arrivals',
    templateUrl: './new-arrivals.component.html',
    styleUrls: ['./new-arrivals.component.less']
})
export class NewArrivalsComponent implements OnInit {
    newProducts: any[]=[];
    responsiveOptions: any;
    Math:Math=Math;
    showLoader = false;
    eventSubscription: Subscription; 
    recentOptions = {
        autoWidth: false,
        loop: true,
        margin: 10,
        items: 1.3,
        center: false,
        dots: false,
        pagination: false,
    };
    @Input() isHandset = false;

    constructor(
        private apiService: ApiService,
        private router: Router,
        private utilsService: UtilsService,
        private eventEmitterService: EventEmitterService,
        private matDialogUtils: MatDialogUtilsService
    ) {
        this.responsiveOptions = [
            {
              breakpoint: '1024px',
              numVisible: 3,
              numScroll: 1
            },
            {
              breakpoint: '768px',
              numVisible: 2,
              numScroll: 1
            },
            {
                breakpoint: '600px',
                numVisible: 1.5,
                numScroll: 1
            }
          ]; 
    }

    ngOnInit() {
        this.eventSubscription = this.eventEmitterService.userChangeEvent
            .asObservable()
            .subscribe((user) => {
                this.getNewArrivals();
            });
    }

    onDestroy(): void {
        this.eventSubscription.unsubscribe();
    }

    getNewArrivals(): void {
        // this.showLoader = true;
        // this.apiService.getNewArrivals().subscribe((res: IProductsPayload) => {
        //     this.newProducts = res.products;
        //     this.showLoader = false;
        // });
        this.showLoader = true;
        this.apiService.getNewArrivals().pipe(first()).subscribe((res:any) => {
          this.newProducts = res.products;
          console.log(this.newProducts,'np')
          this.showLoader = false;
        });
    }

    seeAll() {
        this.router.navigateByUrl('/products/all?new=true');
    }

    openDialog(sku) {
        this.router.navigateByUrl(`/product/${sku}`);
    }

    handleEvtProductCarousal(e) {
        this.openDialog(e);
    }
}
