import { Component, OnInit } from '@angular/core';
import { EnvService } from '../../../shared/services/env.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.less']
})
export class TermsOfServiceComponent implements OnInit {

  constructor(public env: EnvService) { }

  ngOnInit() {
  }

}
