import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-no-quiz-data-dialog',
  templateUrl: './no-quiz-data-dialog.component.html',
  styleUrls: ['./no-quiz-data-dialog.component.less']
})
export class NoQuizDataDialogComponent implements OnInit {

  constructor(private dialog:MatDialogRef<any>) { }

  ngOnInit() {
  }
  seeSofas(){
    this.dialog.close('sofas');
  }
  retake(){
    this.dialog.close('retake');
  }
}
