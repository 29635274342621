import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-faq-help',
  templateUrl: './shop-faq-help.component.html',
  styleUrls: ['./shop-faq-help.component.less']
})
export class ShopFaqHelpComponent implements OnInit {
  @Input() isHandset = false;
  constructor() { }

  ngOnInit() {
  }

  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      "Hi there! I see you would like to chat with us."
    );
  }
}
