import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../../../core/core.module';
import {
    RoomModule,
    // BrowseByRoomModule,
    TopDealsModule
} from '../../components';
import {LandingComponent} from './landing.component';
import {CarouselModule} from 'primeng/carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NewArrivalsModule} from '../../components';
import {BestSellersModule} from '../../components';
import {EmailSubscriptionModule} from '../../components/email-subscription/email-subscription.module';
import {FlashSaleComponent} from './flash-sale/flash-sale-desktop/flash-sale/flash-sale.component';
import {FlashSaleProductComponent} from './flash-sale/flash-sale-desktop/flash-sale-product/flash-sale-product.component';
import {FlashSaleMobileComponent} from './flash-sale/flash-sale-mobile/flash-sale-mobile/flash-sale-mobile.component';
import {FlashSaleMobileProductComponent} from './flash-sale/flash-sale-mobile/flash-sale-mobile-product/flash-sale-mobile-product.component';
import {FlashSaleMobileProductHorizontalComponent} from './flash-sale/flash-sale-mobile/flash-sale-mobile-product-horizontal/flash-sale-mobile-product-horizontal.component';
import { AboutSmallComponent } from './about-small/about-small.component';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';
import { ShopThisLookComponent } from './shop-this-look/shop-this-look.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LsDifferenceComponent } from './ls-difference/ls-difference.component';
import { ShopFaqHelpComponent } from './shop-faq-help/shop-faq-help.component';
import { ShopComponent } from './shop/shop.component';
import { SharedModule } from 'src/app/shared/shared.module';

const DECLARATIONS = [LandingComponent];

const MODULES = [
    CommonModule,
    CoreModule,
    RoomModule,
    // BrowseByRoomModule,
    NewArrivalsModule,
    FormsModule,
    TopDealsModule,
    EmailSubscriptionModule,
    BestSellersModule,
    ReactiveFormsModule,
    SharedModule
];

@NgModule({
    declarations: [
        ...DECLARATIONS,
        FlashSaleComponent,
        FlashSaleProductComponent,
        FlashSaleMobileComponent,
        FlashSaleMobileProductComponent,
        FlashSaleMobileProductHorizontalComponent,
        AboutSmallComponent,
        DeliveryDetailsComponent,
        ShopThisLookComponent,
        TimelineComponent,
        LsDifferenceComponent,
        ShopComponent,
    ],
    imports: [...MODULES, CarouselModule],
    exports: [...DECLARATIONS, ...MODULES],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LandingModule {
}
