export const SCROLL_ICON_SHOW_DURATION = 5000;
export const STATE_LIST = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    countryCode:'USA'
  },

  {
    name: 'American Samoa',
    abbreviation: 'AS',
    countryCode:'USA'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    countryCode:'USA'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    countryCode:'USA'

  },
  {
    name: 'Alberta',
    abbreviation: 'Alberta',
    countryCode:'CN'
  },
  {
    name: 'Ontario',
    abbreviation: 'Ontario',
    countryCode:'CN'
  },
  {
    name: 'Quebec',
    abbreviation: 'Quebec',
    countryCode:'CN'
  },
  {
    name: 'New Brunswick',
    abbreviation: 'New Brunswick',
    countryCode:'CN'
  },
  {
    name: 'Nova Scotia',
    abbreviation: 'Nova Scotia',
    countryCode:'CN'
  },
  {
    name: 'British Columbia',
    abbreviation:'British Columbia',
    countryCode:'CN'

  },
  {
    name: 'California',
    abbreviation: 'CA',
    countryCode:'USA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    countryCode:'USA'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    countryCode:'USA'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    countryCode:'USA'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
    countryCode:'USA'
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
    countryCode:'USA'
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    countryCode:'USA'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    countryCode:'USA'
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
    countryCode:'USA'
  },

  {
    name: 'Idaho',
    abbreviation: 'ID',
    countryCode:'USA'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    countryCode:'USA'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    countryCode:'USA'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    countryCode:'USA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    countryCode:'USA'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    countryCode:'USA'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    countryCode:'USA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    countryCode:'USA'
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
    countryCode:'USA'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    countryCode:'USA'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    countryCode:'USA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    countryCode:'USA'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    countryCode:'USA'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    countryCode:'USA'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    countryCode:'USA'
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    countryCode:'USA'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    countryCode:'USA'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    countryCode:'USA'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    countryCode:'USA'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    countryCode:'USA'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    countryCode:'USA'
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    countryCode:'USA'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    countryCode:'USA'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    countryCode:'USA'
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
    countryCode:'USA'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    countryCode:'USA'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    countryCode:'USA'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    countryCode:'USA'
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
    countryCode:'USA'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    countryCode:'USA'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
    countryCode:'USA'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    countryCode:'USA'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    countryCode:'USA'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    countryCode:'USA'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    countryCode:'USA'
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    countryCode:'USA'
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    countryCode:'USA'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    countryCode:'USA'
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
    countryCode:'USA'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    countryCode:'USA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    countryCode:'USA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    countryCode:'USA'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    countryCode:'USA'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    countryCode:'USA'
  }
];
export const COUNTRY_LIST = [
  {
    name: 'USA',
    abbreviation: 'USA'
  },
  {
    name: 'Canada',
    abbreviation: 'Canada'

  }
];
