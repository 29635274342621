import { I } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Options } from 'ng5-slider';
import { MaterialFilter } from '../product-filters/product-filters.component';

@Component({
    selector: 'app-product-filter-mobile',
    templateUrl: './product-filter-mobile.component.html',
    styleUrls: ['./product-filter-mobile.component.less']
})
export class ProductFilterMobileComponent implements OnInit, OnChanges {
    @Output() setMobileToggle = new EventEmitter<any>();
    @Output() setFilters = new EventEmitter<any>();
    @Input() productFilters: any;
    @Input() totalCount: number;
    @Input() isProductFetching: boolean;
    @Input() isBrandPage = false;
    @Input() isCollectionPage = false;
    objectKeys = Object.keys;
    isClearAllVisible = false;
    displayDimensionFilter = [];
    activeFilters = {
        brand: [],
        collection: [],
        price_from: 0,
        price_to: 0,
        type: [],
        class: [],
        shape: [],
        seating: [],
        color: [],
        height_from: 0,
        height_to: 0,
        width_from: 0,
        width_to: 0,
        length_from: 0,
        length_to: 0,
        diameter_from: 0,
        diameter_to: 0,
        square_from: 0,
        square_to: 0,
        depth_from: 0,
        depth_to: 0,
        country: [],
        designer: [],
        fabric: [],
        material: [],
        style:[]
    };
    dimensionFilters = [
        'height',
        'width',
        'length',
        'diameter',
        'square',
        'depth'
    ];
    dimensionFilterValueStore = [
        'height_from',
        'height_to',
        'width_from',
        'width_to',
        'length_from',
        'length_to',
        'diameter_from',
        'diameter_to',
        'square_from',
        'square_to',
        'depth_from',
        'depth_to',
    ];
    isPriceChanged = false;
    isSizeChanged = false;
    priceFilter = {
        from: 100,
        to: 600,
        min: 100,
        max: 600
    };
    silderOptions: Options = {
        floor: 10,
        ceil: 500,
        translate: (value: number) => {
            return '$' + value;
        }
    };
    activeTab = '';
    activeFilterTabData: any;
    clearOptionVisible = {
        // brand: false,
        color: false,
        type: false,
        price: true,
        size: false,
    };
    tempFilters: string;

    @Output() emitPrdFilters = new EventEmitter<any>();

    constructor(private activeRoute: ActivatedRoute) {
    }

    ngOnInit() {
        if (this.isCollectionPage) {
            this.activeTab = 'price';
        }
        if(this.productFilters && this.productFilters.size){
            this.activeTab = this.productFilters[Object.keys(this.productFilters)[0]] !== null ? Object.keys(this.productFilters)[0] : Object.keys(this.productFilters)[1] ;
            if(this.productFilters.size.width){
            this.productFilters['width'] = this.productFilters.size.width;
            }
            if(this.productFilters.size.height){
            this.productFilters['height'] = this.productFilters.size.height;
            }
            if(this.productFilters.size.length){
            this.productFilters['length'] = this.productFilters.size.length;
            }
            if(this.productFilters.size.depth){
            this.productFilters['depth'] = this.productFilters.size.depth;
            }
        }
        console.log(this.productFilters);
        this.selectTab(this.activeTab);
        this.activeRoute.queryParams.subscribe((params) => {
            this.isClearAllVisible = params.filters !== '';
        });
        
        this.populateDimensionFilters();
    }

    populateDimensionFilters() {
        this.displayDimensionFilter = [];
        for (const filter in this.productFilters) {
            if (this.isDimensionFilter(filter)) {
                this.displayDimensionFilter.push(this.productFilters[filter]);
            }
        }
    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnChanges(change: any) {
        if (change.productFilters !== undefined) {
            this.productFilters = change.productFilters.currentValue;
            if(this.productFilters && this.productFilters.size){

                this.activeTab = this.productFilters[Object.keys(this.productFilters)[0]] !== null ? Object.keys(this.productFilters)[0] : Object.keys(this.productFilters)[1] ;

                if(this.productFilters.size.width){
                this.productFilters['width'] = this.productFilters.size.width;
                }
                if(this.productFilters.size.height){
                this.productFilters['height'] = this.productFilters.size.height;
                }
                if(this.productFilters.size.length){
                this.productFilters['length'] = this.productFilters.size.length;
                }
                if(this.productFilters.size.depth){
                this.productFilters['depth'] = this.productFilters.size.depth;
                }
            }

        console.log(this.productFilters);
            this.selectTab(this.activeTab);
            this.populateDimensionFilters();
            if (this.productFilters && !this.isPriceChanged) {
                this.priceFilter = this.productFilters.price;
                if (this.isCollectionPage) {
                    this.activeFilters.collection = this.productFilters.collection;
                } else {
                    delete this.activeFilters.collection;
                    delete this.productFilters.collection;
                }
                this.clearEmptyFilters();
                this.activeFilters.price_from = this.priceFilter.min;
                this.activeFilters.price_to = this.priceFilter.max;
                // this.activeFilters.brand = this.productFilters.brand
                //     .filter((brand) => brand.checked)
                //     .map((brand) => brand.value);
                if(this.productFilters.type){
                this.activeFilters.type = this.productFilters.type
                    .filter((type) => type.checked)
                    .map((type) => type.value);
                }
                if(this.productFilters.color){
                this.activeFilters.color = this.productFilters.color
                    .filter((color) => color.checked)
                    .map((color) => color.value);
                }
                if (this.productFilters.seating) {
                    this.activeFilters.seating = this.productFilters.seating
                        .filter((seating) => seating.checked)
                        .map((seating) => seating.value);
                }
                if (this.productFilters.shape) {
                    this.activeFilters.shape = this.productFilters.shape
                        .filter((shape) => shape.checked)
                        .map((shape) => shape.value);
                }
                if (this.productFilters.class) {
                    this.activeFilters.class = this.productFilters.class
                        .filter((c) => c.checked)
                        .map((c) => c.value);
                }
                if (this.productFilters.country) {
                    this.activeFilters.country = this.productFilters.country
                        .filter((mfgCountry) => mfgCountry.checked)
                        .map((mfgCountry) => mfgCountry.value);
                }
                if (this.productFilters.designer) {
                    this.activeFilters.designer = this.productFilters.designer
                        .filter((designer) => designer.checked)
                        .map((designer) => designer.value);
                }
                if (this.productFilters.fabric) {
                    this.activeFilters.fabric = this.productFilters.fabric
                        .filter((fabric) => fabric.checked)
                        .map((fabric) => fabric.value);
                }
                if (this.productFilters.material) {
                    const keys = Object.keys(this.productFilters.material);
                    let newData = [];
                    keys.forEach(f => {
                        newData.push({ name: f, values: this.productFilters.material[f] })
                    })
                    this.productFilters.material = newData;

                    this.activeFilters.material = this.productFilters.material
                        .filter((material) => material.checked)
                        .map((material) => material.value);
                }

                if (this.productFilters.height && this.productFilters.height[0]) {
                    const filter = this.productFilters.height[0];
                    if (Math.round(filter.min) !== Math.round(filter.from) ||
                        Math.round(filter.max) !== Math.round(filter.to)) {
                        const from = `${filter.name.toLowerCase()}_from`;
                        const to = `${filter.name.toLowerCase()}_to`;
                        this.activeFilters[from] = filter.from;
                        this.activeFilters[to] = filter.to;
                        this.isSizeChanged = true;
                    }
                }
                if (this.productFilters.width && this.productFilters.width[0]) {
                    const filter = this.productFilters.width[0];
                    if (Math.round(filter.min) !== Math.round(filter.from) ||
                        Math.round(filter.max) !== Math.round(filter.to)) {
                        const from = `${filter.name.toLowerCase()}_from`;
                        const to = `${filter.name.toLowerCase()}_to`;
                        this.activeFilters[from] = filter.from;
                        this.activeFilters[to] = filter.to;
                        this.isSizeChanged = true;
                    }
                }
                if (this.productFilters.diameter && this.productFilters.diameter[0]) {
                    const filter = this.productFilters.diameter[0];
                    if (Math.round(filter.min) !== Math.round(filter.from) ||
                        Math.round(filter.max) !== Math.round(filter.to)) {
                        const from = `${filter.name.toLowerCase()}_from`;
                        const to = `${filter.name.toLowerCase()}_to`;
                        this.activeFilters[from] = filter.from;
                        this.activeFilters[to] = filter.to;
                        this.isSizeChanged = true;
                    }
                }
                if (this.productFilters.square && this.productFilters.square[0]) {
                    const filter = this.productFilters.square[0];
                    if (Math.round(filter.min) !== Math.round(filter.from) ||
                        Math.round(filter.max) !== Math.round(filter.to)) {
                        const from = `${filter.name.toLowerCase()}_from`;
                        const to = `${filter.name.toLowerCase()}_to`;
                        this.activeFilters[from] = filter.from;
                        this.activeFilters[to] = filter.to;
                        this.isSizeChanged = true;
                    }
                }
                if (this.productFilters.depth && this.productFilters.depth[0]) {
                    const filter = this.productFilters.depth[0];
                    if (Math.round(filter.min) !== Math.round(filter.from) ||
                        Math.round(filter.max) !== Math.round(filter.to)) {
                        const from = `${filter.name.toLowerCase()}_from`;
                        const to = `${filter.name.toLowerCase()}_to`;
                        this.activeFilters[from] = filter.from;
                        this.activeFilters[to] = filter.to;
                        this.isSizeChanged = true;
                    }
                }
            }

            if (
                Math.round(this.productFilters.price.from) === Math.round(this.productFilters.price.min) &&
                Math.round(this.productFilters.price.to) === Math.round(this.productFilters.price.max)
            ) {
                this.isPriceChanged = false;
                if (this.activeFilters.price_from) {
                    delete this.activeFilters.price_from;
                }
                if (this.activeFilters.price_to) {
                    delete this.activeFilters.price_to;
                }
            } else {
                this.isPriceChanged = true;
                this.priceFilter = this.productFilters.price;
            }
        }
    }

    onCheckChange(event, filter: string, materialOption?: any) {
        const option = event.source.value;
        if (event.source.checked && filter !== 'material') {
            this.activeFilters[filter].push(option);
        } else if (filter == 'material') {
            if (event.source.checked) {
                this.productFilters.material.forEach(f => {
                    if (materialOption.name == f.name) {
                        const val = f.values.find(d => d.value == event.source.value.value);
                        if (val) {
                            val.checked = true;
                            // val.enabled = true;
                            this.activeFilters[filter].push(option.value);
                        }
                    }
                })

            } else {
                const optionsArr = this.activeFilters[filter].filter(
                    (value: string) => value !== option.value
                );
                this.activeFilters[filter] = optionsArr;
            }
        } else {
            const optionsArr = this.activeFilters[filter].filter(
                (value: string) => value !== option
            );
            this.activeFilters[filter] = optionsArr;
        }
        if (
            this.priceFilter.min === this.productFilters.price.from &&
            this.priceFilter.max === this.productFilters.price.to
        ) {
            delete this.activeFilters.price_from;
            delete this.activeFilters.price_to;
            this.isPriceChanged = false;
        } else {
            this.isPriceChanged = true;
        }

        this.buildAndSetFilters();
    }

    onDimensionFilterUpdate(event, filter) {
        const values = event;
        const maxValueString = `${filter}_to`;
        const minValueString = `${filter}_from`;
        this.activeFilters[maxValueString] = values.maxValue;
        this.activeFilters[minValueString] = values.minValue;
        this.isSizeChanged = true;
        this.clearOptionVisible.size = true;
        this.buildAndSetFilters();
    }

    buildAndSetFilters(clearFlag?: boolean) { 
        this.tempFilters = '';

        this.emitPrdFilters.emit({pf:this.productFilters,tab:this.activeTab});
        for (const [filter, options] of Object.entries(this.activeFilters)) {
            if ((filter === 'price_from' || filter === 'price_to') && this.isPriceChanged) {
                this.tempFilters += `${filter}:${options};`;
            } else {
                if (Array.isArray(options)) {
                    this.tempFilters += options.length ? `${filter}:${options};` : ``;
                } else if (options !== 0) {
                    this.tempFilters += `${filter}:${options};`;
                }
            }
        }
        // if(clearFlag){
        this.setFilters.emit(this.tempFilters);
        // }

        this.isClearAllVisible = this.tempFilters !== '';
        return this.tempFilters;
    }

    selectFilter(d, name) {
        d.checked = !d.checked;
        
        // if (this.productFilters[name].filter((f) => f.checked).length > 0) {
        //   if (!this.filteredElements.includes(name)) {
        //     this.filteredElements.push(name);
        //   }
        // } else {
        //   this.filteredElements = this.filteredElements.filter((f) => f != name);
        // }
    
        // if (name === "class") {
        //   this.getFilterData(true);
        // }
        // this.iscurrentFilterApplied = true;
      }

    clearFilters() {
        let collection = this.activeFilters.collection;
        // let brand = this.activeFilters.brand;
        // if (!this.isBrandPage) {
        //     brand = [];
        // }
        if (!this.isCollectionPage) {
            collection = [];
        }
        this.activeFilters = {
            brand:[],
            collection,
            price_from: 0,
            price_to: 0,
            type: [],
            class: [],
            shape: [],
            seating: [],
            color: [],
            height_from: 0,
            height_to: 0,
            width_from: 0,
            width_to: 0,
            length_from: 0,
            length_to: 0,
            diameter_from: 0,
            diameter_to: 0,
            square_from: 0,
            square_to: 0,
            depth_from: 0,
            depth_to: 0,
            country: [],
            designer: [],
            fabric: [],
            material: [],
            style:[]
        };
        this.clearOptionVisible = {
            // brand: false,
            color: false,
            type: false,
            price: true,
            size: false
        };
        delete this.activeFilters.price_from;
        delete this.activeFilters.price_to;
        this.isPriceChanged = false;
        this.buildAndSetFilters(true);
    }

    onPriceChange(event) {
        this.activeFilters.price_from = event.minValue;
        this.activeFilters.price_to = event.maxValue;
        this.isPriceChanged = true;
        this.buildAndSetFilters();
    }
    // applyFilters(){
    //     this.setFilters.emit(this.tempFilters); 
    // }

    closeFilters() {
        this.setMobileToggle.emit();
    }

    /**
     * Check if filter is valid to be shown.
     * Important: The Order of checking here matters, don't change
     * @param filter- Filter to be checked
     */
    checkValidFilter(filter): boolean {
        if (filter === 'price' || filter == 'material') {
            return true;
        }
        if (this.isDimensionFilter(filter) || this.isCollectionFilter(filter)) {
            return false;
        }
        return this.productFilters[filter] && this.productFilters[filter].length !== 0;
    }

    renderFilterName(filter): string {
        if(filter == 'class'){
            return 'CATEGORY'
        }
        return filter.replace('_', ' ').toUpperCase();
    }

    selectTab(filter) {
        this.activeTab = filter;
        if (filter !== 'price' && filter !== 'size') {
            this.showClearBtn(
                this.productFilters[filter].filter(this.ifChecked),
                filter
            );
            if (filter === 'class') {
                this.activeFilterTabData = this.productFilters[filter].filter(value => value.enabled);

            } else if (filter === 'material') {
                this.activeFilterTabData = this.productFilters[filter];
                console.log(this.productFilters[filter], 'tests')
            } else {
                console.log(this.productFilters[filter], 'tests')
                this.activeFilterTabData = this.productFilters[filter].filter(
                    this.checkEnabled
                );
            }
        } else {
            this.activeFilterTabData = null;
        }
    }

    disableTab(filter) {
        if (filter !== 'price' && filter !== 'class' && filter !== 'material' && filter !== 'size') {
            return this.productFilters[filter].filter(this.checkEnabled).length === 0;
        } else {
            return false;
        }
    }

    checkEnabled(data) {
        return data.count > 0;
    }

    ifChecked(data) {
        return data.checked;
    }

    showClearBtn(data, filter) {
        this.clearOptionVisible[filter] = data.length > 0;
    }

    clearBtn(activeFilter) {
        if (activeFilter === 'price') {
            this.activeFilters.price_from = 0;
            this.activeFilters.price_to = 0;
            delete this.activeFilters.price_from;
            delete this.activeFilters.price_to;
            this.isPriceChanged = false;
        } else if (activeFilter === 'size') {
            console.log(activeFilter);
        } else {
            this.activeFilters[activeFilter] = [];
            this.clearOptionVisible[activeFilter] = false;
        }
        this.buildAndSetFilters();
    }

    isDimensionFilter(filter: string): boolean {
        return this.dimensionFilters.includes(filter);
    }

    isArray(value): boolean {
        return Array.isArray(value);
    }


    isCollectionFilter(filter) {
        if (this.isCollectionPage && filter === 'brand') {
            return false;
        }
        return filter === 'collection';
    }

    clearEmptyFilters() {
        for (const productFiltersKey in this.productFilters) {
            if (!this.dimensionFilters.includes(productFiltersKey) &&
                productFiltersKey !== 'price' &&
                productFiltersKey !== 'material' &&
                productFiltersKey !== 'class'
                && productFiltersKey !== 'size'
                && productFiltersKey !== 'width'
                && productFiltersKey !== 'height'
                && productFiltersKey !== 'length'
                && productFiltersKey !== 'depth'
            ) {
                this.productFilters[productFiltersKey] = this.productFilters[productFiltersKey] ? this.productFilters[productFiltersKey]
                    .filter(value => value.count > 0) : [];
            } else if (productFiltersKey == 'material') {
                const newVal: MaterialFilter[] = [];
                // this.productFilters.material && this.productFilters.material.forEach(f => {
                //     f.values.forEach((fi, index) => {
                //         if (fi.enabled && fi.count > 0) {
                //             const ind = newVal.findIndex(fn => fn.name == f.name);
                //             if (ind >= 0) {
                //                 newVal[ind].values.push(fi);
                //             } else {
                //                 newVal.push({ name: f.name, values: [fi] });
                //             }

                //         }
                //     })

                // })
                // this.productFilters.material = newVal;
            }
        }
    }
}
