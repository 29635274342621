import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartComponent } from './cart.component';
import { PaymentModule } from '../../components';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { InactiveItemsDialogComponent } from './inactive-items-dialog/inactive-items-dialog.component';

const DECLARATIONS = [CartComponent];

const MODULES = [CommonModule, PaymentModule, RouterModule,SharedModule];

@NgModule({
  declarations: [...DECLARATIONS, InactiveItemsDialogComponent],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
  entryComponents:[InactiveItemsDialogComponent]
})
export class CartModule {}
