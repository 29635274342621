import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ISearchProduct} from '../../../shared/models';
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {ApiService, MatDialogUtilsService} from 'src/app/shared/services';

@Component({
    selector: 'app-search-product',
    templateUrl: './search-product.component.html',
    styleUrls: ['./search-product.component.less']
})
export class SearchProductComponent implements OnInit {
    @Input() product: ISearchProduct;
    @Input() productsInRow = 0;
    @Input() pageKey = '';
    starIcons: string[] = [];
    bpSubscription: Subscription;
    isHandset: boolean;
    bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
        Breakpoints.Handset
    );
    priceObject = {
        isPrice: 0,
        wasPrice: 0
    };
    isDiscounted = false;
    isRange = false;
    minPrice: number;
    maxPrice: number;
    wasMinPrice: number;
    wasMaxPrice: number;
    Math: Math = Math;

    constructor(
        public dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private apiService: ApiService,
        private router: Router,
        private matDialogUtils: MatDialogUtilsService
    ) {
    }

    ngOnInit(): void {
        this.setRating();
        this.bpSubscription = this.bpObserver.subscribe(
            (handset: BreakpointState) => {
                this.isHandset = handset.matches;
            }
        );
        this.formatPriceText(this.product);
    }

    onDestroy(): void {
        this.bpSubscription.unsubscribe();
    }

    setRating(): void {
        let starCount = Math.round(parseFloat(this.product.rating) * 2) / 2;
        while (starCount > 0.5) {
            this.starIcons.push('star');
            starCount -= 1;
        }
        if (starCount && this.starIcons.length < 5) {
            this.starIcons.push('star_half');
        } else if (this.starIcons.length < 5) {
            while (this.starIcons.length < 5) {
                this.starIcons.push('star_outline');
            }
        }
    }

    openDialog(): void {
        this.router.navigateByUrl(`/product/${this.product.product_sku}`);
        this.router.navigate(['/product', this.product.product_sku], {
            queryParams: this.getQueryParams()
        });
    }

    getQueryParams() {
        if (this.pageKey) {
            return {
                pkey: this.pageKey
            };
        } else {
            return {};
        }
    }

    wishlistProduct(sku, mark, event) {
        event.stopPropagation();
        this.apiService
            .wishlistProduct(sku, mark, this.isHandset)
            .subscribe((payload: any) => {
                // this.product.wishlisted = mark;
            });
    }

    formatPriceText(product) {
     

        if(product.was_price){
            const {max_price, min_price, was_price} = product;
            if (max_price !== min_price) {
                this.isRange = true;
            }
            this.priceObject.isPrice = Number(min_price);
            this.isDiscounted = was_price && (was_price > min_price);
            if (this.isDiscounted) {
                this.priceObject.wasPrice = was_price;
            }
        } else {
            // let {is_price, max_was_price} = product;
            // // is_price =is_price.includes('-')? is_price.split('-'):is_price;
            // // was_price = was_price.includes('-')?was_price.split('-'):'';
            // if (is_price.includes('-')) {
            //     this.isRange = true;
            // }
            // this.priceObject.isPrice = +   (is_price.includes('-')?Number( is_price.split('-')[0]):Number(is_price));
            // this.isDiscounted = max_was_price && Number(max_was_price) > Number((is_price.includes('-')? Number(is_price.split('-')[0]):Number(is_price)));
            // if (this.isDiscounted) {
            //     this.priceObject.wasPrice = Number(max_was_price);
            // } 
            const {min_price, max_price, min_was_price,max_was_price} = this.product;
            this.minPrice = min_price;
            this.maxPrice = max_price;
            this.wasMinPrice = min_was_price;
            this.wasMaxPrice = max_was_price;
        }
    }

    renderPrice(price: number): number | string {
        const quotient = Math.floor(price);
        const remainder = Number((price - quotient).toPrecision(2));
        if (remainder === 0) {
            return quotient;
        } else {
            return price.toFixed(2);
        }
    }
}
