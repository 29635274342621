import {
  NavDesktopModule,
  FooterModule,
  SearchBarModule,
  NavMobileModule,
  AboutusModule,
  PrivacyPolicyModule,
  TermsOfServiceModule,
  FooterMobileModule,
  BrandFooterModule,
  BannerModule
} from './components';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './components/auth/auth.module';
 
import { DesignModule } from '../design/design.module';
import { BookingPaypalComponent } from './components/booking-paypal/booking-paypal.component';

const DECLARATIONS = [];

const MODULES = [
  CommonModule,
  NavDesktopModule,
  NavMobileModule,
  FooterModule,
  SearchBarModule,
  AboutusModule,
  PrivacyPolicyModule,
  TermsOfServiceModule,
  FooterMobileModule,
  BrandFooterModule,
  BannerModule,
  AuthModule,
  SearchBarModule,
  DesignModule
];

@NgModule({
  declarations: [...DECLARATIONS,  BookingPaypalComponent],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES]
})
export class CoreModule {}
