import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { OwlModule } from 'ngx-owl-carousel';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuizOfferBannerComponent } from './quiz-offer-banner/quiz-offer-banner.component';
import { ExpertDesignerSectionModule } from '../expert-designer-section/expert-designer-section.module';
import { FormsModule } from '@angular/forms';

const DECLARATIONS = [BannerComponent,QuizOfferBannerComponent];

const MODULES = [
  CommonModule,
  OwlModule,
  RouterModule,
  CarouselModule,
  FormsModule,
  SharedModule,
  ExpertDesignerSectionModule
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES]
})
export class BannerModule {}
