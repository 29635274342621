import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { ApiService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";
@Component({
  selector: "app-design",
  templateUrl: "./design.component.html",
  styleUrls: ["./design.component.less"],
})
export class DesignComponent implements OnInit {
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  @ViewChild("shop", { static: false }) shop: ElementRef<any>;
  @ViewChild("design", { static: false }) design: ElementRef<any>;
  @ViewChild("target", { static: false }) target: ElementRef<any>;
  ph = `Try "White Chair"`;
  bpSubscription: Subscription;
  isHandset = false;
  lsData = [
    { benefit: "Accredited Design Professionals", ls: 1, discount: 0 },
    { benefit: "Curated luxury desginer brands", ls: 1, discount: 0 },
    { benefit: "Realistic 3D models to scale", ls: 1, discount: 0 },
    { benefit: "Seamless integrated ordering ", ls: 1, discount: 0 },
    { benefit: "Discounted pricing on furniture", ls: 1, discount: 0 },
    { benefit: "Complimentary white glove delivery", ls: 1, discount: 0 },
  ];
  slideIndex = 1;
  profileList: any[] = [];

  isShop = true;


  carousalOptions = {
    autoWidth: false,
    loop: true,
    margin: 10,
    items: 3.3,
    center: false,
    dots: false,
    pagination: false,
  };
  carousalOptions1: any;
  isLoggedIn: boolean;
  public images = ["/assets/image/c1.jpg", "/assets/image/c2.jpg"];
  constructor(
    private breakpointObserver: BreakpointObserver,
    private apiService: ApiService,
    private router: Router,
    public env: EnvService
  ) { 
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }
  ngOnInit() {
    // this.getDesigners();
    this.getLandingDesignImages();
    this.isLoggedIn = JSON.parse(localStorage.getItem("user")).email.length > 0;
  }
  getLandingDesignImages() {
    this.apiService.getLandingDesignImages().subscribe((resp:any) => {
      // this.designImagesList = resp;
    });
  }
  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      "Hi there! I see you would like to chat with a design consultant."
    );
  }
  getDesigners() {
    this.apiService.getDesignProfile().subscribe((res: any) => {
      this.profileList = res;
    });
  }
  goTOCons() {
    // this.router.navigate(["/design/book/SydneyLedden"]);
    // var my_element = document.getElementById("consult");

    // my_element.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    //   inline: "nearest",
    // });
  }
  setLink(link) {
    let width, height, left;
    let parentLeft;
    if (link === 0) {
      link = this.isShop ? 1 : 2;
    }

    if (link === 1) {
      this.isShop = true;
      parentLeft =
        this.shop.nativeElement.parentElement.getBoundingClientRect().left;
      width = this.shop.nativeElement.getBoundingClientRect().width;
      height = this.shop.nativeElement.getBoundingClientRect().height;
      left = this.shop.nativeElement.getBoundingClientRect().left - parentLeft;
      this.shop.nativeElement.classList.add("active-nav-link");
      this.design.nativeElement.classList.remove("active-nav-link");
    } else {
      this.isShop = false;
      parentLeft =
        this.design.nativeElement.parentElement.getBoundingClientRect().left;
      width = this.design.nativeElement.getBoundingClientRect().width;
      height = this.design.nativeElement.getBoundingClientRect().height;
      left =
        this.design.nativeElement.getBoundingClientRect().left - parentLeft;
      this.shop.nativeElement.classList.remove("active-nav-link");
      this.design.nativeElement.classList.add("active-nav-link");
    }
    this.target.nativeElement.style.width = `${width}px`;
    this.target.nativeElement.style.height = `${height}px`;
    this.target.nativeElement.style.left = `${left}px`;
    // this.target.nativeElement.style.top = `${top}px`;
    this.target.nativeElement.style.transform = "none";
  }
  goToDesigner(p) {
    this.router.navigate(["/design/book/" + p.designerId]);
  }
}
