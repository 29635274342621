import { Component, OnInit } from '@angular/core';
import { EnvService } from '../../../shared/services/env.service'; 

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.less']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(public env: EnvService) { }

  ngOnInit() {
  }

}
