import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Ng5SliderModule } from 'ng5-slider';
import { ProductFiltersComponent } from './product-filters.component';
import { MatIconModule } from '@angular/material/icon';
import { DimensionsFilterComponent } from './dimensions-filter/dimensions-filter.component';
import {SharedModule} from '../../../shared/shared.module';
import {NgxSliderModule} from '@angular-slider/ngx-slider';  
import { FilterIconFactoryComponent } from './icon-factory/filter-icon-factory.component';
const DECLARATIONS = [ProductFiltersComponent,FilterIconFactoryComponent];

const MODULES = [
  CommonModule,
  MatMenuModule,
  MatCheckboxModule,
  Ng5SliderModule,
  MatIconModule,
];

@NgModule({
  declarations: [...DECLARATIONS, DimensionsFilterComponent,FilterIconFactoryComponent],
  imports: [...MODULES, NgxSliderModule, SharedModule],
  exports: [...DECLARATIONS, ...MODULES]
})
export class ProductFiltersModule {}
