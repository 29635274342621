import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// This is imported in shared module.
@Component({
  selector: 'app-cancel-confirmation-dialog',
  templateUrl: './cancel-confirmation-dialog.component.html',
  styleUrls: ['./cancel-confirmation-dialog.component.less']
})
export class CancelConfirmationDialogComponent implements OnInit {

  constructor(   @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
