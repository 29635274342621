import { Component, OnInit } from '@angular/core';
import {
  IProductsPayload,
  ISearchProduct,
  ISearchProductsPayload
} from './../../../shared/models';
import { ApiService } from './../../../shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {
  BreakpointState,
  Breakpoints,
  BreakpointObserver
} from '@angular/cdk/layout';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
  query: string;
  iPageNo: number;
  iLimit: number;
  productsSubscription: Subscription;
  products: ISearchProduct[];
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  totalCount = 0;
  bpSubscription: Subscription;
  isHandset: boolean;
  productsInRow = 2;
  isProductFetching = false;
  isBoardApi = false;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.query = this.route.snapshot.queryParamMap.get('query');
    this.getQueryResult();
    this.route.queryParams.subscribe((params) => {
      this.query = params.query || '';
      this.getQueryResult();
    });

    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }

  onDestroy(): void {
    this.productsSubscription.unsubscribe();
    this.bpSubscription.unsubscribe();
  }

  getQueryResult(): void {
    this.iPageNo = 0;
    this.iLimit = 24;
    const queryString = JSON.stringify({
      from: this.iPageNo * this.iLimit,
      size: this.iLimit,
      query: {
        match: {
          name: {
            query: this.query + '*'
          }
        }
      }
    });

    this.productsSubscription = this.apiService
      .getSearchProducts(this.query,this.iPageNo)
      .subscribe((payload: any) => {
        if(payload.total>0){
        console.log(payload); 
        this.totalCount = payload.total
        this.products = payload.data;
        // payload: ISearchProductsPayload
        // const { hits } = payload.hits;
        // this.totalCount = payload.hits.total.value;
        // this.products = hits.map((hit: any) => hit._source);
      } else {
        this.totalCount=0;
        this.products=[];
      }
      });
  }

  onScroll() {
    if (this.isProductFetching) {
      return;
    }
    this.iPageNo += 1;
    this.isProductFetching = true;

    const queryString = JSON.stringify({
      from: this.iPageNo * this.iLimit,
      size: this.iLimit,
      query: {
        match: {
          name: {
            query: this.query + '*'
          }
        }
      }
    });
    // this.updateQueryString();

    this.productsSubscription = this.apiService
      .getSearchProducts(this.query,this.iPageNo)
      .subscribe((payload: any) => {

        if(payload.total>0){ 
        this.totalCount = payload.total;
        this.products =[...this.products,...payload.data] ;
        this.isProductFetching = false;
          // payload: ISearchProductsPayload
          // const { hits } = payload.hits;
          // this.totalCount = payload.hits.total.value;
          // this.products = hits.map((hit: any) => hit._source);
        } else {
          this.totalCount=0;
          this.products=[];
          this.isProductFetching = false;
        }
        // :ISearchProductsPayload
        // const { hits } = payload.hits;
        // this.products = [
        //   ...this.products,
        //   ...hits.map((hit: any) => hit._source)
        // ];
        
      });
  }


  updateQueryString(): void {
    let params = new HttpParams();
  
    if (this.iPageNo) {
        params = params.set('pageNo', this.iPageNo.toString());
    }
    this.location.replaceState(
        window.location.pathname +'?query='+this.query,
        params.toString(),
        this.location.getState()
    );
}

  toggleMobileView() {
    if (this.productsInRow === 3) {
      this.productsInRow = 1;
    } else {
      this.productsInRow += 1;
    }
  }
}
