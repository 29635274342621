import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProductDetailsRoutingModule} from './product-details-routing.module';
import {ProductDetailsContainerComponent} from './product-details-container/product-details-container.component';
import {ProductDetailsMobileModule, ProductModule} from '../../components';


@NgModule({
    declarations: [
        ProductDetailsContainerComponent
    ],
  imports: [
    CommonModule,
    ProductDetailsRoutingModule,
    ProductModule,
    ProductDetailsMobileModule
  ]
})
export class ProductDetailsModule {
}
