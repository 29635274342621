import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ApiService } from "../../services";
import { EnvService } from "../../services/env.service";
import { ChatService } from "../../services/api/chat.service";

@Component({
  selector: "app-products-carousal",
  templateUrl: "./products-carousal.component.html",
  styleUrls: ["./products-carousal.component.less"],
})
export class ProductsCarousalComponent implements OnInit,AfterViewInit {
  carousalOptions = {
    margin: 10,
    loop: true,
    items: 1.25,
    dots: true,
    pagination: true,
    // autoWidth: true,
    // stagePadding: 100,
    singleItem: true,
  };
  Math: Math = Math;
  bannerCarousalOptions = {
    items: 1,
    margin: 10,
    autoplay: false,
    autoplayHoverPause: false,
    dots: true,
    pagination: true,
    smartSpeed: 1000,
    loop: true,
    nav: true,
    navText: false,
  };

  images = [];
  @Input() data: any = [];
  @Input() items: any;
  @Output() updates: EventEmitter<any> = new EventEmitter();

  @Input() isBanner = false;
  @Input() isBoards = false;
  @Input() nameOfCarousal = "";
  @Input() pagex= "home";
  banner = new Banner();
  boardCarousalOptions: any;

  searchData = "";
  bannerData: any[]=[];
  email = "";
  EMAIL_REGEX =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    invalidEmail: boolean;
    message: any;
  currentPage: string;
  blackSaleBanner: any[]=[];
goodByeList :any []=[{header:'Say GoodBye to Endless Scrolling',
value:'Chat with our AI design concierge to curate personalized recommendations for specific furniture & decor products.'},
{
  header:'Say GoodBye to Designer Fees',
  value:'Partner with a designer on larger projects to bring your vision to life and create your perfect living space. '
},
{
  header:'Say GoodBye to Costly Regrets',
  value:'Shop stress free with our commitment to curating premium quality brands and offering free returns on eligible items.'
},
]
  slideIndex: number=1;

  faqList:any[]=[
    {
    ques:'What can I expect from the Seeded design process?',
    ans:`With our Full Service design process, you will be paired with a professional interior designer to design your living space.
     Through the design process, you will receive:`,
    showAns:false
  },
{
  ques:'Why do I have to take a quiz to shop products at Seeded?',
  ans:`Our AI powered style quiz is designed to extract your personal 
  style tastes and generate your personalized shopping feed. 
  Once you have completed the quiz, all shopping lists on our site are curated to 
  rank products matching your style the highest.`,
  showAns:false
},
{
  ques:'How much do your design services cost?',
  ans:`Our design service is offered completely complimentary without any fees 
  specifically associated to the design service.  `,
  showAns:false
},
{
  ques:'How do I get help with my furniture order after I have made a purchase?',
  ans:`Orders placed with Seeded are routed to sellers immediately and fulfilled directly 
  by the seller using in-house or outsourced logistics partners.
   Our concierge team monitors your orders throughout
   its journey and makes sure everything runs smoothly in the background.`,
  showAns:false
},

]
  featuredBrands: any[]=[];
  isToggleChecked = true;
  query:string = '';
  promptSearchList: any[]=[];
  categoriesList: any[]=[];
  constructor(private router: Router,private apiService:ApiService,public env: EnvService,
    public chatService: ChatService
  ) {
    this.currentPage = location.pathname;
  }

  ngOnInit() {
    if (this.pagex === "shop" || this.currentPage === "/") {
    this.apiService.bannerData().subscribe((res: any[]) => {
      this.bannerData = res.filter((value) => value.position === "primary");

      this.blackSaleBanner = res.filter((value) => value.position === "hero");
      this.apiService.blackSaleBanner = this.blackSaleBanner;
    });
  }
    if (
      location.href.includes(this.env.siteUrl) ||
      location.href.includes("localhost")
    ) {
      this.banner.image =
      this.env.assetBaseUrl+ "site/banners/test/landing-m-light.jpeg";
    } else {
      this.banner.image =
      this.env.assetBaseUrl+ "site/banners/landing-m-light.jpeg"; 
    }

    this.boardCarousalOptions = {
      margin: 10,
      loop: true,
      items: this.items,
      dots: true,
      dotsClass: "owl-dots pt3",
      pagination: true,
      // autoWidth: true,
      // stagePadding: 100,
      singleItem: true,
    };
    this.boardCarousalOptions = {
      ...this.boardCarousalOptions,
      ...{ items: this.items },
    }; 
  }
  getbrands() {
    this.apiService.getBrands().subscribe((res: any) => {
     let brands = res;
      brands = brands.filter((val) => {
        if (val.value !== "potterybarn") {
          return val;
        }
      });
      this.featuredBrands = brands.filter((_) => _.is_home_feature === 1);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // this.getbrands();
    this.getPromptsSearch();
    this.showSlides(this.slideIndex);
    }, 1000);
    document.addEventListener("DOMContentLoaded", function() {
      const textarea = document.getElementById("chatInput");
  
      textarea.addEventListener("input", function() {
          this.style.height = "auto";
          this.style.height = (this.scrollHeight - 20) + "px";
      });
  });
  }
  get envirnonment(): boolean {
    return (
      location.href.includes(this.env.siteUrl) ||
      location.href.includes("localhost")
    );
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: any) {
    if (
      changes.data &&
      changes.data.previousValue !== changes.data.currentValue
    ) {
      this.data = changes.data.currentValue;
    }
  }

  openDialog(sku) {
    this.updates.emit(sku);
  }

  getPreviewImagePath(preview) {
    if (preview) {
      return `${environment.BASE_HREF}${preview}`;
    } else {
      return "https://via.placeholder.com/500x400";
    }
  }

  openSearch(): void {
    if (this.searchData.length > 0) {
      this.router.navigateByUrl(`/search?query=${this.searchData}`).then(() => {
        this.searchData = "";
        // this.searchComplete.emit();
      });
    } else {
      // this.textChange = true;
    }
  }
  routeToSale() {
    // this.router.navigate(['/products/all'], { queryParams: { sale: true } });
    this.router.navigate(["/products/sale/cyber-week"]);
  }

  submit(data) {
    if (this.EMAIL_REGEX.test(data)) {
      this.invalidEmail = false;
      const url = window.location.href.split("?")[0];
      this.apiService.subscription(url, data).subscribe((response: any) => {
        this.message = response.message;
        if (!response.subscribed) {
          this.apiService
            .newsLetterSubmit(this.email)
            .subscribe((response: any) => {});
        }
        // if (payload.status === 'We already have your email. Thanks!') {
        //   this.subscriberExists = true;
        // } else if (payload.status === 'success') {
        //   this.showSuccessMsg = true;
        // }
      });
    } else {
      this.invalidEmail = true;
    }
  }
  navTo(url){
    window.open(url, "_self");
  }

  goToQuiz(){
    this.router.navigate(['/style-quiz']);
  }
  goToAppointments(){
    this.router.navigate(['/design']);
  }

  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
}
showSlides(n) {
    let i;
    let slides = document.getElementsByClassName(
        "pSlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (slides.length > 0) {
        let dots = document.getElementsByClassName("dotx");
        if (n > slides.length) {
            this.slideIndex = 1;
        }
        if (n < 1) {
            this.slideIndex = slides.length;
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active-dotx", "");
        }
        slides[this.slideIndex - 1].style.display = "block";
        dots[this.slideIndex - 1].className += " active-dotx";
    }
}
assignQuery() {
  if (this.isToggleChecked) {
    this.chatService.query = this.query;
    this.router.navigate(["/chat"]);
  } else { 
    const data = `?query=${this.query}`
    this.router.navigateByUrl(`/search${data}`).then(() => {
      this.query = "";
    });
  }
}
sampleChat(data) {
  localStorage.removeItem('recentChat');
  this.chatService.query = data;
  this.router.navigate(["/chat"]);
}
getPromptsSearch(){
  this.chatService.getPromptSearch().subscribe((resp:any[])=>{
    this.promptSearchList = resp.find(f=>f.type==='primary').prompt_details;
    this.categoriesList = resp.find(f=>f.type==='secondary').prompt_details;
  })
}
}

export class Banner {
  "name": "Fall 2022";
  "image": string;
  "link": string;
  "link_label": string;
  "banner_label": string;
  "font_hex": "#f0f0f0";
  "position": "primary";
  "rank": 1;
  "link_type": "full";
  assetBaseUrl = environment.ASSET_BASE_URL;;
  constructor() {
    this.image =
      this.assetBaseUrl+ "site/banners/landing-m-light.jpeg";
  }
  
}
