
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-mail-listing',
  templateUrl: './mail-listing.component.html',
  styleUrls: ['./mail-listing.component.less'],
  providers: [DatePipe]
})
export class MailListingComponent implements OnInit {
  mailingData: MailingData = new MailingData();
  scheduleList: any[] = [];
  time = '';
  isLive = false
  constructor(private api: ApiService, private datePipe: DatePipe, private snackbar: MatSnackBar) {
   }

  ngOnInit() {
    this.scheduleList.forEach(f => {
      f.isEdit = false;
    })
    this.get();
  }

  get() {
    this.api.getEmailTemplateSchedule().subscribe((res: any) => {
      this.scheduleList = res;
      this.scheduleList.forEach(f => {
        f.isEdit = false;
        f.template = f.email_template;
        f.schedule = this.getPstTime(f.schedule_date);
        f.time = f.schedule ? f.schedule.toLocaleTimeString().slice(0,-3) : '';
      })
    })
  }
  getPstTime(date:any){
    let dateObj: Date = typeof date == 'string' ? new Date(date) : date;
    if(dateObj){
    let offset = 7 * 60 * 60 * 1000;
    dateObj.setTime(dateObj.getTime() - offset);
    return dateObj;
  }
}
  schedule() {
    const sData = new MailingData();
    sData.name = this.mailingData.name;
    sData.template = this.mailingData.template;
    const mDate = (this.datePipe.transform(new Date(this.mailingData.date), 'yyyy/MM/dd')).concat(` ${this.time}:00`)

    let data = {
      name: sData.name,
      template: sData.template,
      schedule: this.getServerDate(mDate) ,
      is_test: !this.isLive
    } 
    this.api.saveEmailTemplateSchedule(data).subscribe(res => {
      console.log(res);
      this.snackbar.open('Schedule saved successfully', 'x');
      this.mailingData = new MailingData();
      this.get();
    })

  }
  saveFromTable(s) {

    const mDate = (this.datePipe.transform(new Date(s.date), 'yyyy/MM/dd')).concat(` ${s.time}:00`)

    let data = {
      name: s.name,
      template: s.template,
      schedule: this.getServerDate(mDate)
    }
    this.api.saveEmailTemplateSchedule(data).subscribe(res => {
      s.isEdit = false;
    })

  }
  cancel(id) {
    this.api.cancelEmailTemplateSchedule(id).subscribe(res => {
      this.snackbar.open('Schedule cancelled successfully', 'x');
      this.get();
    })
  } 
  getColor(status: string) {

    if (status.toUpperCase() == 'DELIVERED') {
      return {
        'background-color': '#4daf50',
        color: 'white',
        height: '22px',
        'border-radius': '8px',
      };
    } else if (status.toUpperCase() == 'CANCELED') {
      return {
        'background-color': '#ed5f5a',
        color: 'white',
        height: '22px',
        'border-radius': '8px',
      };
    } else if (status.toUpperCase() == 'SCHEDULED') {
      return {
        'background-color': '#05a8f4',
        color: 'white',
        height: '22px',
        'border-radius': '8px',
      };
    }
  }
  getServerDate(date: any): any {
    const y = (new Date(date)).toISOString();
    return y;
  }
}
class MailingData {
  name: string;
  template: string;
  date: string;
}