import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../shared/services';
import { EnvService } from '../../../shared/services/env.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
    departments = [];
currentYear = new Date().getFullYear();
    constructor(
        private apiService: ApiService,
        public env:EnvService
    ) {
    }

    ngOnInit() {
        // this.loadDepartments();
    }

    // loadDepartments() {
    //     this.apiService.browseRoom().subscribe((res: any) => {  
    //         this.departments = [];
    //         res.all_departments.forEach((val) => {
    //             this.departments = [...this.departments, ...val.departments];
    //         });
    //         this.departments = this.departments.filter((val) => {
    //             if (val.department !== 'Bathroom' && val.department !== '' && val.displayable === 1) {
    //                 return val;
    //             }
    //         });
    //     });
    // }
    goBlog(){
        window.open("https://blog.seededhome.com","_self");
    }
}
