import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {ProductFiltersModule, ProductModule, SortTypeModule} from './../../components';
 
import {ProductMobileModule} from '../../components';
import {ProductFilterMobileModule} from '../../components/product-filter-mobile/product-filter-mobile.module';
import {ProductSortMobileModule} from '../../components/product-sort-mobile/product-sort-mobile.module';
import {BrandListModule} from '../../components/brand-list/brand-list.module';
import {CollectionListModule} from '../../components/collection-list/collection-list.module';
import { ProductKeywordMappingComponent } from './product-keyword-mapping.component';

const DECLARATIONS = [ProductKeywordMappingComponent];

const MODULES = [
    CommonModule,
    ProductModule,
    ProductFiltersModule,
    SortTypeModule,
    MatToolbarModule,
    MatIconModule,
    InfiniteScrollModule,
    ProductMobileModule,
    ProductFilterMobileModule,
    BrandListModule,
    ProductSortMobileModule
];

@NgModule({
    declarations: [...DECLARATIONS],
    imports: [...MODULES, CollectionListModule],
    exports: [...DECLARATIONS, ...MODULES]
})

export class ProductKeywordMappingModule {
}
