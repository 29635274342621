import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {BannerModule} from '../banner/banner.module';
import { ExpertDesignerSectionComponent } from './expert-designer-section.component';
const DECLARATIONS = [ExpertDesignerSectionComponent];

const MODULES = [
  CommonModule,
];

@NgModule({
  declarations: [...DECLARATIONS],
    imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES]
})
export class ExpertDesignerSectionModule {}
