import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
  styleUrls: ["./brands.component.less"],
})
export class BrandsComponent implements OnInit {
  brandsList: any[]=[];
  bottomBrandsList: any[]=[];
  constructor(private api: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.getBrandsList();
  }
  getBrandsList() {
    this.api.getBrands().subscribe((res:any) => {
      this.brandsList = res.filter(f=>f.feature ==1);
      this.bottomBrandsList = res.filter(f=>f.feature ==2);
      // this.brandsList.forEach(f=>{
      //   f.cover_image = 'https://s3.us-east-2.amazonaws.com/lazysuzy-images/sellers/ash/ash_about.png'
      // })
      console.log(res, "RES");
    });
  }
  goTobrand(b){
    this.router.navigate([`/brand/${b.value}`])
  }
}