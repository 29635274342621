import { LabelType, Options } from '@angular-slider/ngx-slider'; 
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { forkJoin } from 'rxjs'; 
import { ApiService } from '../../shared/services';
import { QuizData } from '../quiz-desktop/quiz-desktop.component';
import { environment } from 'src/environments/environment.staging';
import { EnvService } from '../../shared/services/env.service';

@Component({
  selector: 'app-result-filter',
  templateUrl: './result-filter.component.html',
  styleUrls: ['./result-filter.component.less']
})
export class ResultFilterComponent implements OnInit, OnChanges, OnDestroy {
  seatsCount = [2, 8];
  value: number = 2;
  highValue: number = 8;
  options: Options = {
    showTicksValues: true,
    floor: 2,
    ceil: 8,
  };

  assetBaseUrl = '';

  firmness = [1, 5];
  minFirmness: number = 1;
  maxFirmness: number = 5;
  firmnessoptions: Options = {
    showTicksValues: true,
    floor: 1,
    ceil: 5,
  };

  minWidth: number = 0;
  maxWidth: number = 0;
  widthoptions: Options = {
    floor: 0,
    ceil: 400,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "''";
        case LabelType.High:
          return value + "''";
        default:
          return value + "''";
      }
    }
  };

  minDepth: number = 0;
  maxDepth: number = 0;
  depthoptions: Options = {
    floor: 0,
    ceil: 400,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "''";
        case LabelType.High:
          return value + "''";
        default:
          return value + "''";
      }
    }
  };

  minHeight: number = 0;
  maxHeight: number = 0;
  heightoptions: Options = {
    floor: 0,
    ceil: 400,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "''";
        case LabelType.High:
          return value + "''";
        default:
          return value + "''";
      }
    }
  };

  minPrice: number = 0;
  maxPrice: number = 0;
  @Input() priceoptions: Options = {
    floor: 0,
    ceil: 400,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        case LabelType.High:
          return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        default:
          return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  };



  colorList: any[] = [];
  styleList: any[] = [];
  shapesList: any[] = [];
  filterData: QuizData;
  @Output() emitFilter = new EventEmitter();
  @Output() emitPrice = new EventEmitter();
  width: any[] = [];
  depth: any[] = [];
  height: any[] = [];
  price: any[] = [];
  featureSleeper: boolean = false;
  featureStorage: boolean = false;
  browseData: any[] = [];
  @Output() emitMoreFeature = new EventEmitter();
  originalPrice: any;
  @Output() priceOutput = new EventEmitter();

  constructor(private api: ApiService,public env: EnvService) { 
    this.assetBaseUrl = this.env.assetBaseUrl;
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  ngOnInit() {
    if (this.api.quizData) {
      this.filterData = this.api.quizData;
    } else {
      this.featureSleeper = false;
      this.featureStorage = false;
      this.filterData = JSON.parse(sessionStorage.getItem('quiz-result'));
      // this.filterData.firmness = '1,2,3,4,5';
      // this.filterData.firmness =  this.filterData.firmness;
      this.filterData.sort_type = 'recommended';

    }
    this.browseData
    let final = { ls_id: this.filterData.browse.join(',') }
    this.getASizingData(final);
    this.browseData = JSON.parse(JSON.stringify(this.filterData.browse));
    forkJoin([this.getFilter(3),
    this.getFilter(6),
    this.getFilter(7)]).subscribe(resp => {

      const num = this.filterData.seats.split(',').map(Number);
      this.seatsCount = [num[0], num[num.length - 1]];

      const comNum = this.filterData.firmness.split(',').map(Number);
      this.firmness = [comNum[0], comNum[comNum.length - 1]];


    });

  }
  changeFirmness() {
    const num = [];
    for (let i = +this.firmness[0]; i <= +this.firmness[1]; i++) {
      num.push(i);
    }
    this.filterData.firmness = num.join(',');
    this.emitData();
  }
  selectFeature(type: string) {
    const fd = this.browseData.join(',');
    // if (this.featureSleeper) { //201,202,203
    //   if (fd == '201,202,203') {
    //     this.filterData.browse = ['203']
    //   } else if (fd == '204,205,206') {
    //     this.filterData.browse = ['205']
    //   }else if (fd == '201,202,203,204,205,206') {
    //     this.filterData.browse = ['203','205']
    //   }
    // }  if (this.featureStorage) { //201,202,203
    //   if (fd == '204,205,206') {
    //     this.filterData.browse = ['206']
    //   }
    // }  if (this.featureSleeper && this.featureStorage) {
    //   if (fd == '201,202,203') {
    //     this.filterData.browse = ['203']
    //   } else if (fd == '204,205,206') {
    //     this.filterData.browse = ['205','206']
    //   } else if (fd == '201,202,203,204,205,206') {
    //     this.filterData.browse = ['203', '205','206']
    //   }
    // }

    if (this.featureSleeper) {
      this.filterData.browse = ['203', '205']
    }
    if (this.featureStorage) {
      this.filterData.browse = ['206']
    }
    if (this.featureSleeper && this.featureStorage) {
      this.filterData.browse = ['203', '205', '206']
    }
    if (!this.featureSleeper && !this.featureStorage) {
      this.filterData.browse = ['201', '202', '203', '204', '205', '206']
    }
    this.emitData();
    // if(type=='featureSleeper'){
    //   this.filterData.feature = ['203']
    // }
  }
  async getFilter(pageNo) {
    this.api.getProductFinder(pageNo).subscribe((resp: any) => {
      if (pageNo == 3) {
        this.api.getShapeData({ ls_id: this.filterData.browse.join(',') }).subscribe((resp: any) => {
          this.shapesList = resp.sort((a, b) => +a.rank - +b.rank);
          console.log(this.shapesList, 'sl')
        })

      } else if (pageNo == 6) {
        this.styleList = resp.sort((a, b) => +a.rank - +b.rank);
      } else if (pageNo == 7) {
        this.colorList = resp.sort((a, b) => +a.rank - +b.rank);
      }
    });
  }
  widthReset() {
    const dims = JSON.parse(localStorage.getItem('dims'));

    if (dims.width) {
      this.minWidth = dims.width[0];
      this.maxWidth = dims.width[1];
      this.widthoptions.floor = dims.width[dims.width.length - 2];
      this.widthoptions.ceil = dims.width[dims.width.length - 1];
      this.width[0] = this.minWidth
      this.width[1] = this.maxWidth;
    } else {
      this.minWidth = 0;
      this.maxWidth = 0;
      this.widthoptions.floor = 0;
      this.widthoptions.ceil = 0;
    }
    this.minWidth = this.width[0];
    this.maxWidth = this.width[1];
    this.assignSizing();
    this.emitData();
  }
  getASizingData(data: any) {

    const dims = JSON.parse(localStorage.getItem('dims'));

    if (dims.width) {
      this.minWidth = dims.width[dims.width.length - 2];
      this.maxWidth = dims.width[dims.width.length - 1];
      this.widthoptions.floor = dims.width[dims.width.length - 2];
      this.widthoptions.ceil = dims.width[dims.width.length - 1];
      this.width.push(this.minWidth);
      this.width.push(this.maxWidth);
    } else {
      this.minWidth = 0;
      this.maxWidth = 0;
      this.widthoptions.floor = 0;
      this.widthoptions.ceil = 0;
    }

    if (dims.height) {
      this.minHeight = dims.height[dims.height.length - 2];
      this.maxHeight = dims.height[dims.height.length - 1];
      this.heightoptions.floor = dims.height[dims.height.length - 2];
      this.heightoptions.ceil = dims.height[dims.height.length - 1];
      this.height.push(this.minHeight);
      this.height.push(this.maxHeight);
    } else {
      this.minHeight = 0;
      this.maxHeight = 0;
      this.heightoptions.floor = 0;
      this.heightoptions.ceil = 0;
    }

    if (dims.depth) {
      this.minDepth = dims.depth[dims.depth.length - 2];
      this.maxDepth = dims.depth[dims.depth.length - 1];
      this.depthoptions.floor = dims.depth[dims.depth.length - 2];
      this.depthoptions.ceil = dims.depth[dims.depth.length - 1];
      this.depth.push(this.minDepth);
      this.depth.push(this.maxDepth);
    } else {
      this.minDepth = 0;
      this.maxDepth = 0;
      this.depthoptions.floor = 0;
      this.depthoptions.ceil = 0;
    }
    // this
    this.width[0] = this.filterData.sizing['min-width'];
    this.width[1] = this.filterData.sizing['max-width'];

    this.depth[0] = this.filterData.sizing['min-depth'];
    this.depth[1] = this.filterData.sizing['max-depth'];

    this.height[0] = this.filterData.sizing['min-height'];
    this.height[1] = this.filterData.sizing['max-height'];

    if (this.price.length == 0) {


      this.api.getPricingData(data).subscribe((resp: any) => {
        // const dims = resp.dimension;
        const price = resp;
        if (price) {
          this.minPrice = Math.round(price.min / 10) * 10; //Math.round(price.min);
          this.maxPrice = Math.round(price.max / 10) * 10;// Math.round(price.max);
          this.priceoptions = {
            floor: this.minPrice,
            ceil: this.maxPrice,
            step: 10,
            translate: (value: number, label: LabelType): string => {
              switch (label) {
                case LabelType.Low:
                  return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                case LabelType.High:
                  return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                default:
                  return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            }
          }
          // this.price.push(this.minPrice);
          // this.price.push(this.maxPrice);
          this.price.push(this.filterData.price['min_price']);
          this.price.push(this.filterData.price['max_price']);
          this.priceOutput.emit(this.price);
          this.originalPrice = JSON.parse(JSON.stringify(this.price));
        }
      });
    } else {
      this.price = this.originalPrice;
    }
  }

  selectCard(type: string, filterType: string) {
    const index = this.filterData[filterType].findIndex(f => f == type);
    if (index > -1) {
      this.filterData[filterType].splice(index, 1)
    } else {
      this.filterData[filterType].push(type);
    }
    this.emitData();
  }
  emitData() {
    this.filterData.pageno = 0
    this.filterData.price_from = this.price[0];
    this.filterData.price_to = this.price[1];
    this.emitFilter.emit(this.filterData);
  }
  changeSeats() {
    setTimeout(() => {
      const num = [];
      for (let i = +this.seatsCount[0]; i <= +this.seatsCount[1]; i++) {
        num.push(i);
      }
      this.filterData.seats = num.join(',');
      this.emitData();
    }, 500);
  }
  changeWidth(e: any) {
    if (this.width) {
      this.minWidth = this.width[0];
      this.maxWidth = this.width[1];
      this.assignSizing();
      this.emitData();
    }
  }
  changeDepth(e: any) {
    if (this.depth) {
      this.minDepth = this.depth[0];
      this.maxDepth = this.depth[1];
      this.assignSizing();
      this.emitData();
    }

  }
  changeHeight(e: any) {
    if (this.height) {
      this.minHeight = this.height[0];
      this.maxHeight = this.height[1];
      this.assignSizing();
      this.emitData();
    }
  }
  changePrice() { 
         if (this.price) {
      this.emitPrice.emit(this.price)
    }  

  }
  assignSizing() {
    this.filterData.sizing = {
      "min-width": this.minWidth, "max-width": this.maxWidth,
      "min-depth": this.minDepth, "max-depth": this.maxDepth,
      "min-height": this.minHeight, "max-height": this.maxHeight
    }
  }
  colorSelect(type: string) {
    if (type == 'select') {
      // this.colorList.forEach(f => this.selectCard(f.filter_value, 'colors'));
      this.filterData['colors'] = [];
      this.colorList.forEach(f => {
        this.filterData['colors'].push(f.filter_value);
      })
    } else {
      this.filterData['colors'] = [];
    }
    this.emitData();
  }
  shapeSelect(type: string) {
    if (type == 'select') {
      // this.shapeList.forEach(f => this.selectCard(f.filter_value, 'shapes'));
      this.filterData['shapes'] = [];
      this.shapesList.forEach(f => {
        this.filterData['shapes'].push(f.shape);
      })
    } else {
      this.filterData['shapes'] = [];
    }
    this.emitData();
  }
  shapeReset(){
    this.filterData['shapes'] = JSON.parse(sessionStorage.getItem('quiz-result')).shapes;
    this.emitData();
    
  }
  priceReset(){
    this.minPrice = this.priceoptions.floor;
    this.maxPrice = this.priceoptions.ceil;
    this.price = this.originalPrice;

    this.priceOutput.emit(this.price);
  }
  resetWidthShapePrice(){
    this.filterData['shapes'] = JSON.parse(sessionStorage.getItem('quiz-result')).shapes;
    this.minPrice = this.priceoptions.floor;
    this.maxPrice = this.priceoptions.ceil;
    this.price = this.originalPrice;
    const dims = JSON.parse(localStorage.getItem('dims'));

    if (dims.width) {
      this.minWidth = dims.width[0];
      this.maxWidth = dims.width[1];
      this.widthoptions.floor = dims.width[dims.width.length - 2];
      this.widthoptions.ceil = dims.width[dims.width.length - 1];
      this.width[0] = this.minWidth
      this.width[1] = this.maxWidth;
    } else {
      this.minWidth = 0;
      this.maxWidth = 0;
      this.widthoptions.floor = 0;
      this.widthoptions.ceil = 0;
    }
    this.minWidth = this.width[0];
    this.maxWidth = this.width[1];

    this.priceOutput.emit(this.price);
  }
  getSelectionHighlight(type: string, filterType: string): string {
    // if(filterType=='shape'){
    const index = this.filterData[filterType].findIndex(f => f == type);
    if (index > -1) {
      return 'selected';
    } else {
      return 'inner-squ';
    }
    // } 
  }
  getColorSelectionHighlight(type: string): string {
    const index = this.filterData.colors.findIndex(f => f == type);
    if (index > -1) {
      return 'highlight-border';
    } else {
      return 'dot';
    }
  }
  resetFilter() {
    this.featureSleeper = false;
    this.featureStorage = false;

    this.ngOnInit();
    this.emitData();
  }
  ngOnDestroy(): void {
    this.api.quizData = this.filterData;
  }
}
