import { Component, DoCheck, Input, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Location } from "@angular/common";

@Component({
  selector: "app-dept-nav-bar",
  templateUrl: "./dept-nav-bar.component.html",
  styleUrls: ["./dept-nav-bar.component.less"],
})
export class DeptNavBarComponent implements OnInit {
  @Input() classList;
  windowWidth: number;
  filterArray: any[] = [];
  constructor(private cookie: CookieService,
    public location: Location) {}
  get cookieV() {
    const showNewsLetter =
    (this.location.path() === "" ||
      this.location.path().match(/shop/) !== null ||
      this.location.path().match(/aboutus/) !== null ||
      this.location.path().match(/product/) !== null) &&
    this.location.path().match(/edit-products/) == null &&
    this.location.path().match(/new-products/) == null &&
    this.location.path().match(/product-intake/) == null  &&
    this.location.path().match(/merchandising/) == null &&
    this.location.path().includes('/design/designer/products/all/') == null;
    
    if(showNewsLetter){
      return this.cookie.get("popupShow");
    } else {
      return true;
    }
  }
  ngOnInit() {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth > 1800 && this.windowWidth <= 6500) {
      this.windowWidth = 1800;
    }
    console.log(this.classList, "cl");

    const filteredArray = this.classList.flatMap((item) => {
      const filteredDepartments = item.departments.filter(
        (dept) => dept.displayable === 1
      );

      const filteredCategories = filteredDepartments.map((dept) => {
        const filteredCat = dept.categories.filter(
          (cat) => cat.displayable === 1
        );
        return { ...dept, categories: filteredCat };
      });

      return { ...item, departments: filteredCategories };
    });

    const data = filteredArray.filter((item) => item.departments.length > 0);

    this.filterArray = data;
  }
  fillCols(count) {
    if (count > 4) {
      let cols = [];
      let mod = count % 4;
      let add = 4 - mod;
      for (let i = 0; i < add; i++) {
        cols.push(i);
      }
      return cols;
    }
  }
  closeNav() {
    let hideElement = document.getElementsByClassName("department-cate");
    if (hideElement.length > 0) {
      hideElement[0].classList.remove("show");
      hideElement[0].classList.add("hide");

      setTimeout(() => {
        hideElement[0].classList.add("show");
      }, 1000);
    }
  }
}
