import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SCROLL_ICON_SHOW_DURATION } from 'src/app/shared/constants';
import { ApiService } from 'src/app/shared/services';
import { QuizData } from '../quiz-desktop/quiz-desktop.component';
import { ResultFilterMobileComponent } from '../result-filter-mobile/result-filter-mobile.component';
import { ResultFilterComponent } from '../result-filter/result-filter.component';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.less']
})
export class ResultPageComponent implements OnInit, OnDestroy {
  filterData: QuizData;
  toggle = 'All Products';
  // toggle = 'Collection';
  count = 0;
  collectionCount = 0;
  productCount = 0;
  sortType = 'asc';
  sortTypeList: any;
  collectionProductsList: any[] = [];
  dupCollectionProductsList: any[] = [];
  productsList: any[] = [];
  brandList: any[] = [];
  dupProductList: any[] = [];
  styleList: any[] = [];
  prdStyleList: any[] = [];
  collectionStyleList: any[] = [];
  prdBrandList: any[] = [];
  collectionBrandList: any[] = [];
  isProductFetching: boolean;

  terminateLoop: boolean;

  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset: boolean;
  checkedStyleList: any[] = [];
  progressValue: number = 0;
  apiCallEnd: boolean;
  isAPIEnd: boolean;
  isFilter: any;
  finalProductsList: any[] = [];
  finalCollectionProductsList: any[] = [];
  isFilterChanged: boolean = false;
  dupFinalProductsList: any[] = [];
  dupFinalCollectionProductsList: any[] = [];

  productsInRow = 2;

  featureSleeper: boolean = false;
  featureStorage: boolean = false;
  sustainablySourced: boolean = false;
  spaceSaving: boolean = false;
  handCrafted: boolean = false;
  familyFriendly: boolean = false;
  madeInUSA: boolean = false;
  defaultPrice: any;
  @ViewChild(ResultFilterComponent, { static: false }) result: ResultFilterComponent;
  @ViewChild(ResultFilterMobileComponent, { static: false }) resultMobile: ResultFilterMobileComponent;
  rmList = ['Width', 'Brand', 'Style', 'Shape', 'Price'];
  a = [{key:'Width',hasChange:false},{key:'Brand',hasChange:false},{key:'Style',hasChange:false},
  {key:'Shape',hasChange:false},{key:'Price',hasChange:false},{key:'Seat',hasChange:false}]
  moreFeatureList: any[] = [];
  filteredrmList: any;
  isBrandChanged: boolean;
  isStyleChanged: boolean;
  isPriceChanged: boolean;

  topPosToStartShowing = 300;
  fixFilterBar = 150;
  isIconShow = false;
  showBar = false;
  timeout: any;
  user: any;
  
  constructor(private api: ApiService,
    private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem("user"));  
    this.filteredrmList = JSON.parse(JSON.stringify(this.rmList));
    // 
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );

    if (this.api.quizData) {
      this.toggle = this.api.toggle;
      this.filterData = this.api.quizData;
      this.sortTypeList = this.api.sortTypeList;
      this.sortType = this.api.sortType;
      this.checkedStyleList = this.api.checkedStyleList;
      this.dupFinalCollectionProductsList = this.api.dupCollectionProductList;
      this.dupFinalProductsList = this.api.dupProductList;
      this.finalCollectionProductsList = this.api.collectionProductList;
      this.finalProductsList = this.api.productList;
      this.brandList = this.api.brandList;
      this.styleList = this.api.styleList;
      this.productCount = this.api.productList.length;
      this.collectionCount = this.api.collectionProductList.length;
      this.progressValue = 1000;
      this.terminateLoop = false;
      // if (this.toggle == 'All Products') {
      //   this.filterByBrandOrStyle();
      // } else {
      //   this.filterByBrandOrStyleCollection();
      // }

    } else {
      this.filterData = JSON.parse(sessionStorage.getItem('quiz-result'));
      this.checkedStyleList = JSON.parse(sessionStorage.getItem('quiz-result')).style;
      this.filterData.pageno = 0;
      this.getFilterData();
      this.isFilterChanged = true;
      this.filterData.sort_type = 'recommended';
      this.toggleBtn();
      this.sortType = 'recommended';
    }



  }
  toggleBtnUI() {
    if ((this.isFilterChanged || !this.terminateLoop) && this.toggle == 'Collection') {
      this.filterData.pageno = 0;
      this.styleList = [];
      this.brandList = [];

      this.collectionProductsList = [];
      this.dupCollectionProductsList = [];
      this.finalCollectionProductsList = [];
      this.dupFinalCollectionProductsList = [];
      this.progressValue = 0;
      this.collectionCount = 0;
      if (this.toggle === 'Collection') {
        this.searchProductFinderCollection();
      }
    } else {
      if (this.toggle == 'Collection') {
        this.sortType = 'recommended';
        this.filterInsideAPICollections();
      }
    }
    if ((this.isFilterChanged || !this.apiCallEnd) && this.toggle == 'All Products') {
      this.filterData.pageno = 0;
      this.styleList = [];
      this.brandList = [];
      this.progressValue = 0;
      this.productCount = 0;
      this.productsList = [];
      this.dupProductList = [];
      this.finalProductsList = [];
      this.dupFinalProductsList = [];
      this.searchProductFinder();
    } else {
      if (this.toggle == 'All Products') {
        this.sortType = 'recommended';
        this.filterInsideAPI();
      }
    }
  }
  toggleBtn() {

    if ((this.isFilterChanged || !this.terminateLoop) && this.toggle == 'Collection') {
      this.filterData.pageno = 0;
      this.styleList = [];
      this.brandList = [];
      this.collectionBrandList = [];
      this.collectionStyleList = [];
      this.collectionProductsList = [];
      this.dupCollectionProductsList = [];
      this.finalCollectionProductsList = [];
      this.dupFinalCollectionProductsList = [];
      this.progressValue = 0;
      this.collectionCount = 0;
      if (this.toggle === 'Collection') {
        this.searchProductFinderCollection();
      }
    } else {
      if (this.toggle == 'Collection') {
        this.sortType = 'recommended';
        this.filterInsideAPICollections();
      }
    }
    if ((this.isFilterChanged || !this.apiCallEnd) && this.toggle == 'All Products') {
      this.filterData.pageno = 0;
      this.styleList = [];
      this.brandList = [];
      this.prdBrandList = [];
      this.prdStyleList = [];
      this.progressValue = 0;
      this.productCount = 0;
      this.productsList = [];
      this.dupProductList = [];
      this.finalProductsList = [];
      this.dupFinalProductsList = [];
      this.searchProductFinder();
    } else {
      if (this.toggle == 'All Products') {

        this.sortType = 'recommended';
        this.filterInsideAPI();
      }
    }
  }
  emptyProducts() {
    if (this.toggle === 'Collection') {
      this.collectionProductsList = [];
      this.dupCollectionProductsList = [];
    } else {
      this.productsList = [];
      this.dupProductList = [];
    }
  }

  async searchProductFinderCollection() {
    this.isProductFetching = true;
    await this.api.searchProductFinderCollection(this.filterData).then((resp: any) => {
      if (resp) {

        if (!resp.collection) {
          this.terminateLoop = true;
        } else {
          this.terminateLoop = false;
        }
        this.isProductFetching = false;
        this.collectionProductsList = [...this.collectionProductsList, ...resp.collection];
        this.collectionProductsList.forEach(f => {
          f.collection_omega && f.collection_omega.forEach(e => {
            e.products && e.products.forEach(a => {
              a.is_price = a.min_price == a.max_price ? '' + a.min_price : a.min_price + '-' + a.max_price;
            })
          })
        })
        this.dupCollectionProductsList = [...this.dupCollectionProductsList, ...JSON.parse(JSON.stringify(this.collectionProductsList))];
        const currentCount = JSON.parse(JSON.stringify(this.collectionProductsList.length));
        this.progressValue = Math.round((currentCount / resp.total) * 100);
        this.filterInsideAPICollections()
        if (!this.terminateLoop) {
          if (this.toggle === 'Collection') {
            this.filterData.pageno = this.filterData.pageno + 1;
            this.searchProductFinderCollection();
          }
        } else {
          this.isProductFetching = false;
          this.isProductFetching = false;
          this.progressValue = 20000;
          this.collectionCount = this.finalCollectionProductsList.length;
        }
      } else {
        this.terminateLoop = true;
        this.isProductFetching = false;
        this.progressValue = 20000;
        this.collectionCount = this.finalCollectionProductsList.length;
      }
    })
  }

  filterInsideAPICollections() {


    this.collectionProductsList.forEach(a => {
      a.collection_omega && a.collection_omega.forEach(c => {
        c.products && c.products.forEach(f => {

          // this.productsList.forEach(f => {

          if (!this.collectionBrandList.some(s => s.name == f.brand_name) && f.brand_name) {
            this.collectionBrandList.push({ checked: true, name: f.brand_name, value: f.brand });
          }
          if (f.style && f.style.includes(',')) {
            const sty = f.style.split(',');
            sty.forEach(e => {
              if (!this.collectionStyleList.some(s => s.name == e.style) && e.style) {
                this.collectionStyleList.push({ checked: false, name: e.style });
              }
            });
          } else {
            if (!this.collectionStyleList.some(s => s.name == f.style) && f.style) {
              this.collectionStyleList.push({ checked: false, name: f.style });
            }
          }
        });
      });
    });

    this.collectionStyleList.forEach(f => {
      this.checkedStyleList.forEach(r => {
        if (r == f.name) {
          f.checked = true;
        }

      })
    })
    this.styleList = this.collectionStyleList;
    this.brandList = this.collectionBrandList;

    this.filterByBrandOrStyleCollection();
  }

  async searchProductFinder() {
    if (this.filterData.style) {
      delete this.filterData.style;
    }
    // if(this.filterData.firmness){
    //   delete this.filterData.firmness;
    // }
    this.isProductFetching = true;
    this.apiCallEnd = false;
    await this.api.searchProductFinder(this.filterData).then((resp: any) => {
      this.isProductFetching = false;
      if (resp.products && resp.products.length > 0) {
        this.productsList = [...this.productsList, ...resp.products];
        this.productsList = this.productsList.filter((v, i, a) => a.findIndex(v2 => ((v2.name === v.name) && v2.sku === v.sku)) === i);
        this.dupProductList = JSON.parse(JSON.stringify(this.productsList));

        const currentCount = JSON.parse(JSON.stringify(this.productsList.length));
        this.progressValue = Math.round((currentCount / resp.total) * 100);
        this.filterInsideAPI();

        if (resp.products && resp.products.length == 0) {
          // this.filterByBrandOrStyle();
        } else {
          if (this.toggle == 'All Products') {
            this.filterData.pageno = this.filterData.pageno + 1;
            this.searchProductFinder()
          }
        }
      } else {
        this.apiCallEnd = true; 
        this.progressValue = 20000;
        // this.count = resp.total;
      }
      if (this.apiCallEnd) {
        this.productCount = this.finalProductsList.length;
      }


    })
  }
  filterInsideAPI() {
    this.productsList.forEach(f => {

      if (!this.prdBrandList.some(s => s.name == f.brand_name) && f.brand_name) {
        this.prdBrandList.push({ checked: true, name: f.brand_name, value: f.brand });
      }
      if (f.style && f.style.includes(',')) {
        const sty = f.style.split(',');
        sty.forEach(e => {
          if (!this.prdStyleList.some(s => s.name == e.style) && e.style) {
            this.prdStyleList.push({ checked: false, name: e.style });
          }
        });
      } else {
        if (!this.prdStyleList.some(s => s.name == f.style) && f.style) {
          this.prdStyleList.push({ checked: false, name: f.style });
        }
      }
    });


    this.prdStyleList.forEach(f => {
      this.checkedStyleList&&  this.checkedStyleList.forEach(r => {
        if (r == f.name) {
          f.checked = true;
        }

      })
    })
    this.styleList = this.prdStyleList.sort((a, b) => a.name.localeCompare(b.name));
    this.brandList = this.prdBrandList.sort((a, b) => a.name.localeCompare(b.name));
    this.filterByBrandOrStyle();
  }

  filterByBrandOrStyle(type?: string) {
    // this.us
    const style = this.styleList.filter(f => f.checked);//name
    let brand = this.brandList.filter(s => s.checked);//value 

    if (this.toggle === 'All Products') {
      this.finalProductsList = [];

      let product = [];
      if (brand.length > 0) {

        this.dupProductList.forEach(r => {
          brand.forEach(f => {
            if (type == 'selectAllBrand' || this.brandList.length == brand.length) {
              if (style.length > 0) {
                style.forEach(f => {
                  if (type == 'selectAllStyle' || this.styleList.length == style.length) {
                    this.finalProductsList.push(r);
                  } else {
                    if (r.style && r.style.includes(f.name)) {
                      this.finalProductsList.push(r);
                    }
                  }
                })

              }
            } else {
              if (f.value == r.brand) {
                if (style.length > 0) {
                  style.forEach(f => {


                    if (type == 'selectAllStyle' || this.styleList.length == style.length) {
                      this.finalProductsList.push(r);
                    } else {
                      if (r.style && r.style.includes(f.name)) {
                        this.finalProductsList.push(r);
                      }
                    }
                  })
                }
              }
            }
          })
        })


      } else {
        this.productsList = [];
      }

      this.finalProductsList = this.finalProductsList.filter((v, i, a) => a.findIndex(v2 => ((v2.name === v.name) && v2.sku === v.sku)) === i);
      this.dupFinalProductsList = JSON.parse(JSON.stringify(this.finalProductsList));
      this.productCount = this.finalProductsList.length;
    } else {
      this.collectionProductsList = [];

      let product = [];
      if (brand.length > 0) {
        brand.forEach(f => {
          this.dupCollectionProductsList.forEach(r => {
            if (f.name == r.beta_data_brand) {
              product.push(r);
            }
          })
        })
        if (product.length == 0) {
          this.filterData.pageno = this.filterData.pageno + 1
          this.searchProductFinderCollection();
        }
      } else {
        this.collectionProductsList = [];
      }

      if (style.length > 0) {
        style.forEach(f => {
          product.forEach(r => {
            if (type == 'selectAllStyle') {
              if (r.beta_data_style && r.beta_data_style.includes(f.name)) {
                this.collectionProductsList.push(r);
              }
            } else {
              if ((r.beta_data_style == null || r.beta_data_style == undefined) || (r.beta_data_style && r.beta_data_style.includes(f.name))) {
                this.collectionProductsList.push(r);
              }
            }

          })
        })
        if (this.dupCollectionProductsList.length == 0) {
          this.filterData.pageno = this.filterData.pageno + 1
          this.searchProductFinderCollection();
        }

        this.collectionCount = this.collectionProductsList.length;
      } else {
        this.collectionProductsList = [];
      }
      this.collectionProductsList = this.collectionProductsList.filter((v, i, a) => a.findIndex(v2 => (v2.beta_data_value === v.beta_data_value)) === i);
      this.collectionCount = this.collectionProductsList.length;
    }
    this.filterByFeature(true);
    // console.log(newArr,'NEWARRR')
  }

  filterByBrandOrStyleCollection(type?: string) {
    // this.us
    const style = this.styleList.filter(f => f.checked);//name
    let brand = this.brandList.filter(s => s.checked);//value  
    this.finalCollectionProductsList = [];
    let product = [];
    if (brand.length > 0) {

      // this.dupProductList.forEach(r => {

      this.dupCollectionProductsList.forEach(a => {
        a.collection_omega && a.collection_omega.forEach(c => {
          c.products && c.products.forEach(r => {

            brand.forEach(f => {
              if (type == 'selectAllBrand' || this.brandList.length == brand.length) {
                if (style.length > 0) {
                  style.forEach(f => {
                    if (type == 'selectAllStyle' || this.styleList.length == style.length) {
                      this.finalCollectionProductsList.push(a);
                    } else {
                      if (r.style && r.style.includes(f.name)) {
                        this.finalCollectionProductsList.push(a);
                      }
                    }
                  })
                } else {
                  this.finalCollectionProductsList = [];
                }
              } else {
                if (f.value == r.brand) {
                  if (style.length > 0) {
                    style.forEach(f => {
                      if (type == 'selectAllStyle' || this.styleList.length == style.length) {
                        this.finalCollectionProductsList.push(a);
                      } else {
                        if (r.style && r.style.includes(f.name)) {
                          this.finalCollectionProductsList.push(a);
                        }
                      }
                    })
                  } else {
                    this.finalCollectionProductsList = [];
                  }
                }
              }
            })
          })
        })
      })
    } else {
      this.finalCollectionProductsList = [];
    }



    this.finalCollectionProductsList = this.finalCollectionProductsList.filter((v, i, a) => a.findIndex(v2 => ((v2.beta_data_value === v.beta_data_value) && v2.beta_data_name === v.beta_data_name)) === i);
    this.dupFinalCollectionProductsList = JSON.parse(JSON.stringify(this.finalCollectionProductsList));
    if (this.finalCollectionProductsList.length > 0) {

      this.filterByFeature(false);
    }
    this.collectionCount = this.finalCollectionProductsList.length;

  }

  filterByFeature(empty?: boolean) {
    const hc = this.handCrafted ? 1 : 0;
    const ss = this.spaceSaving ? 1 : 0;
    const sus = this.sustainablySourced ? 1 : 0;
    const ff = this.familyFriendly ? 1 : 0;
    let filter = [];
    filter.push({ key: 'mfg_country', value: this.madeInUSA });
    filter.push({ key: 'is_handmade', value: hc });
    filter.push({ key: 'is_space_saver', value: ss });
    filter.push({ key: 'is_sustainable', value: sus });
    filter.push({ key: 'is_family_friendly', value: ff });
    const aval = filter.filter(f => f.value == 1);
    if (this.toggle == 'Collection' && this.dupFinalCollectionProductsList) {
      if (empty) {
        this.finalCollectionProductsList = [];
      }

      if (!this.handCrafted && !this.spaceSaving && !this.sustainablySourced && !this.madeInUSA && !this.familyFriendly) {

        this.dupFinalCollectionProductsList.forEach(a => {
          a.collection_omega && a.collection_omega.forEach(c => {
            c.products && c.products.forEach(r => {
              this.finalCollectionProductsList.push(a);
            });
          });
        });
      } else {
        let selected = filter.filter(f => f.value == 1);
        if (selected.length == 1) {
          const data = filter.find(f => f.value);
          if (data.key !== 'mfg_country') {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r[selected[0].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          } else {

            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r.mfg_country && (this.madeInUSA ? r.mfg_country.toUpperCase() == 'USA' : r.mfg_country.toUpperCase() !== 'USA')) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          }

        } else if (selected.length == 2) {
          if (selected[0].key !== 'mfg_country') {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r[selected[1].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          } else {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r.mfg_country && (this.madeInUSA ? r.mfg_country.toUpperCase() == 'USA' : r.mfg_country.toUpperCase() !== 'USA') && r[selected[1].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          }
        } else if (selected.length == 3) {

          if (selected[0].key !== 'mfg_country') {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r[selected[0].key] == 1 && r[selected[1].key] == 1 && r[selected[2].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          } else {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r.mfg_country && (this.madeInUSA ? r.mfg_country.toUpperCase() == 'USA' : r.mfg_country.toUpperCase() !== 'USA') && r[selected[1].key] == 1 && r[selected[2].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          }

        } else if (selected.length == 4) {

          if (selected[0].key !== 'mfg_country') {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r[selected[0].key] == 1 && r[selected[1].key] == 1 && r[selected[2].key] == 1 && r[selected[3].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          } else {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r.mfg_country && (this.madeInUSA ? r.mfg_country.toUpperCase() == 'USA' : r.mfg_country.toUpperCase() !== 'USA') && r[selected[1].key] == 1 && r[selected[1].key] == 1 && r[selected[2].key] == 1 && r[selected[3].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          }


        } else if (selected.length == 5) {


          if (selected[0].key !== 'mfg_country') {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r[selected[0].key] == 1 && r[selected[1].key] == 1 && r[selected[2].key] == 1 && r[selected[3].key] == 1 && r[selected[4].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          } else {
            this.dupFinalCollectionProductsList.forEach(a => {
              a.collection_omega && a.collection_omega.forEach(c => {
                c.products && c.products.forEach(r => {
                  if (r.mfg_country && (this.madeInUSA ? r.mfg_country.toUpperCase() == 'USA' : r.mfg_country.toUpperCase() !== 'USA') && r[selected[1].key] == 1 && r[selected[1].key] == 1 && r[selected[2].key] == 1 && r[selected[3].key] == 1 && r[selected[4].key] == 1) {
                    this.finalCollectionProductsList.push(a);
                  }
                });
              });
            });
          }

        }
      }





      this.finalCollectionProductsList = this.finalCollectionProductsList.filter((v, i, a) => a.findIndex(v2 => ((v2.beta_data_value === v.beta_data_value) && v2.beta_data_name === v.beta_data_name)) === i);
      this.collectionCount = this.finalCollectionProductsList.length;
    } else {
      if (!this.handCrafted && !this.spaceSaving && !this.sustainablySourced && !this.madeInUSA && !this.familyFriendly) {
        this.finalProductsList = this.dupFinalProductsList
      } else {
        let selected = filter.filter(f => f.value == 1);
        if (selected.length == 1) {
          const data = filter.find(f => f.value);
          if (data.key !== 'mfg_country') {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f[data.key] == 1);
          } else {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f.mfg_country && (this.madeInUSA ? f.mfg_country.toUpperCase() == 'USA' : f.mfg_country.toUpperCase() !== 'USA'))
          }

        } else if (selected.length == 2) {
          if (selected[0].key !== 'mfg_country') {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f[selected[0].key] == 1 && f[selected[1].key] == 1);
          } else {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f.mfg_country && (this.madeInUSA ? f.mfg_country.toUpperCase() == 'USA' : f.mfg_country.toUpperCase() !== 'USA') && f[selected[1].key] == 1)
          }
        } else if (selected.length == 3) {
          if (selected[0].key !== 'mfg_country') {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f[selected[0].key] == 1 && f[selected[1].key] == 1 && f[selected[2].key] == 1);
          } else {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f.mfg_country && (this.madeInUSA ? f.mfg_country.toUpperCase() == 'USA' : f.mfg_country.toUpperCase() !== 'USA') && f[selected[1].key] == 1 && f[selected[2].key] == 1)
          }
        } else if (selected.length == 4) {
          if (selected[0].key !== 'mfg_country') {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f[selected[0].key] == 1 && f[selected[1].key] == 1 && f[selected[2].key] == 1 && f[selected[3].key] == 1);
          } else {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f.mfg_country && (this.madeInUSA ? f.mfg_country.toUpperCase() == 'USA' : f.mfg_country.toUpperCase() !== 'USA') && f[selected[1].key] == 1 && f[selected[2].key] == 1 && f[selected[3].key] == 1)
          }
        } else if (selected.length == 5) {
          if (selected[0].key !== 'mfg_country') {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f[selected[0].key] == 1 && f[selected[1].key] == 1 && f[selected[2].key] == 1 && f[selected[3].key] == 1 && f[selected[4].key] == 1);
          } else {
            this.finalProductsList = this.dupFinalProductsList.filter(f => f.mfg_country && (this.madeInUSA ? f.mfg_country.toUpperCase() == 'USA' : f.mfg_country.toUpperCase() !== 'USA') && f[selected[1].key] == 1 && f[selected[2].key] == 1 && f[selected[3].key] == 1 && f[selected[4].key] == 1)
          }
        }
      }
      this.productCount = this.finalProductsList.length;
    }
  }
  removeFeature(c: any, i: number) {
    this.moreFeatureList = this.moreFeatureList.splice(i, 1);
    this[c.ng] = false;
    this.selectMoreFeature()
  }
  removeOptions(c: any, i: number) {
    this.filteredrmList.splice(i, 1);
    if (c == 'Width') {
      if(this.result){

        this.result.widthReset();
      } else {

        this.resultMobile.widthReset();
      }
    } else if (c === 'Brand') {
      this.brandList.forEach(f => {
        f.checked = true
      });
      if (this.toggle === 'All Products') {
        this.filterByBrandOrStyle();
      } else {
        this.filterByBrandOrStyleCollection();
      }
    } else if (c === 'Style') {
      this.styleList.forEach(f => {
        this.checkedStyleList.forEach(r => {
          if (r == f.name) {
            f.checked = true;
          }

        })
      })
      if (this.toggle === 'All Products') {
        this.filterByBrandOrStyle();
      } else {
        this.filterByBrandOrStyleCollection();
      }
    } else if (c === 'Shape') {
      if(this.result){
        this.result.shapeReset();
      } else {
        this.resultMobile.shapeReset();
      } 
    } else if (c === 'Price') { 
      if(this.result){
        this.result.priceReset();
      } else {
        this.resultMobile.priceReset();
      } 
    }

  }
  clearAll(){
    this.filteredrmList=[];
    this.moreFeatureList=[];
    this.moreFeatureList = this.moreFeatureList.splice(0);
    this.handCrafted = false
    this.spaceSaving=false
    this.sustainablySourced=false
     this.madeInUSA=false
   this.familyFriendly=false;
    this.filteredrmList.splice(0);
    this.brandList.forEach(f => {
      f.checked = true
    });
    this.styleList.forEach(f => {
      this.checkedStyleList.forEach(r => {
        if (r == f.name) {
          f.checked = true;
        }
      })
    }) 
    if(this.result){
      this.result.resetWidthShapePrice();
    } else {
      this.resultMobile.resetWidthShapePrice();
    } 
    this.toggleBtn();
    this.rmList = ['Width', 'Brand', 'Style', 'Shape', 'Price'];
    this.filteredrmList  = ['Width', 'Brand', 'Style', 'Shape', 'Price'];
  }
  getSelectedBrandsCount(): number {
    return this.brandList.filter(f => f.checked).length;
  }
  getSelectedStylesCount(): number {
    return this.styleList.filter(f => f.checked).length;
  }
  brandSelect(type: string) {
    this.isBrandChanged = true;
    if (type == 'select') {
      this.brandList.forEach(f => f.checked = true);
      if (this.toggle == 'Collection') {
        this.filterByBrandOrStyleCollection('selectAllBrand');
      } else {
        this.filterByBrandOrStyle('selectAllBrand');
      }

    } else {
      this.brandList.forEach(f => f.checked = false);
      if (this.toggle == 'Collection') {
        this.filterByBrandOrStyleCollection();
      } else {
        this.filterByBrandOrStyle();
      }
    }

  }
  styleSelect(type: string) {
    this.isStyleChanged = true;
    if (type == 'select') {
      this.styleList.forEach(f => f.checked = true);
      if (this.toggle == 'Collection') {
        this.filterByBrandOrStyleCollection('selectAllStyle');
      } else {
        this.filterByBrandOrStyle('selectAllStyle');
      }
    } else {
      this.styleList.forEach(f => f.checked = false);
      if (this.toggle == 'Collection') {
        this.filterByBrandOrStyleCollection();
      } else {
        this.filterByBrandOrStyle();
      }
    }
  }
  filterProducts(event) {
    this.filterData = event;
    this.filterData.pageno = 0;
    if (this.toggle === 'Collection') {
      this.collectionProductsList = [];
      this.dupCollectionProductsList = [];
      this.finalCollectionProductsList = [];
    } else {
      this.productsList = [];
      this.dupProductList = [];
      this.finalProductsList = [];
    }
    this.isFilterChanged = true;
    this.toggleBtn();

  }
  filterByPrice(e: any) {
    console.log(e,'ee')
    this.isPriceChanged = true;
    this.filterData.pageno = 0;
    this.filterData.price_from = e[0];
    this.filterData.price_to = e[1];
    this.filterData.price.min_price = e[0];
    this.filterData.price.max_price = e[1];
    this.isFilterChanged = true;
    this.toggleBtn();
  }
  onSetSortType(e: any) {
    this.filterData.pageno = 0;
    this.sortType = e;
    this.filterData.sort_type = e;
    this.emptyProducts();
    this.isFilterChanged = true;
    this.filterData.price_from = this.filterData.price_from;
    this.filterData.price_to = this.filterData.price_to;
    this.toggleBtn();
  }

  selectMoreFeature(event?:any) {
    let data ={}
    if(event){
      data = event;
    this.handCrafted =event.is_handmade;
     this.spaceSaving =event.is_space_saver
     this.sustainablySourced =event.is_sustainable
      this.madeInUSA =event.mfg_country
     this.familyFriendly=event.is_family_friendly

    }else {
     data = {
      is_back_order: 1,
      is_handmade: this.handCrafted,
      is_space_saver: this.spaceSaving,
      is_sustainable: this.sustainablySourced,
      mfg_country: this.madeInUSA,
      is_family_friendly: this.familyFriendly
    }
  }
    let more = [];
    more.push({ key: 'is_handmade', value: this.handCrafted, label: 'Handcrafted', ng: 'handCrafted' },
      { key: 'is_space_saver', value: this.spaceSaving, label: 'Space saver', ng: 'spaceSaving' },
      { key: 'sustainablySourced', value: this.sustainablySourced, label: 'Sustainable Sourced', ng: 'sustainablySourced' },
      { key: 'mfg_country', value: this.madeInUSA, label: 'Made In USA', ng: 'madeInUSA' },
      { key: 'is_family_friendly', value: this.familyFriendly, label: 'Family Friendly', ng: 'familyFriendly' })
    this.moreFeatureList = more.filter(f => f.value);
    this.filterByFeature(true);

  }

  showContent(e: any) {
    this.isFilter = e;
  }
  checkFilterBS() {
    if (this.toggle == 'Collection') {
      this.filterByBrandOrStyleCollection();
    } else {
      this.filterByBrandOrStyle();
    }
  }

  getFilterData() { 
    this.api.getFilteredData('living/sectionals', '', this.sortType).subscribe((payload: any) => {
      this.sortTypeList = payload.sortType.filter(f => f.value !== 'popularity');
      this.styleList.forEach(f => { 
        this.checkedStyleList&& this.checkedStyleList.forEach(r => {
          if (r == f.name) {
            f.checked = true;
          }

        })
      })
    })
  }

  onScroll() {
    // if (this.isProductFetching) {
    //   return;
    // }
    // this.filterData.pageno += 1;
    // this.isProductFetching = true;
    // this.toggleBtn();
  }
  getDefaultPrice(e: any) {
    this.defaultPrice = e;
  }
  resetFilter() {
    this.result.ngOnInit();
    this.clearAll();
    this.sustainablySourced = false;
    this.spaceSaving = false;
    this.handCrafted = false;
    this.familyFriendly = false;
    this.madeInUSA = false;
    this.featureSleeper = false;
    this.featureStorage = false;
    this.filterData.pageno = 0
    this.filterData.price_from = this.defaultPrice[0];
    this.filterData.price_to = this.defaultPrice[1];
    this.isFilterChanged = true;
    this.filterData.browse = ['201', '202', '203', '204', '205', '206'];
    this.filterProducts(this.filterData);
  }

  @HostListener('window:scroll')
  checkScroll() {
      const scrollPosition =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
      this.isIconShow = scrollPosition >= this.topPosToStartShowing;
      this.showBar = scrollPosition >= this.fixFilterBar;
      const self = this;
      if (this.isIconShow) {
          if (this.timeout) {
              clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
              self.isIconShow = false;
          }, SCROLL_ICON_SHOW_DURATION);
      }
  }


  gotoTop() {
      window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
      });
  }
  ngOnDestroy(): void {
    this.api.quizData = this.filterData;
    this.api.checkedStyleList = this.checkedStyleList;
    this.api.dupProductList = this.dupFinalProductsList;
    this.api.dupCollectionProductList = this.dupFinalCollectionProductsList;
    this.api.productList = this.finalProductsList;
    this.api.collectionProductList = this.finalCollectionProductsList;
    this.api.brandList = this.brandList;
    this.api.styleList = this.styleList;
    this.api.toggle = this.toggle;
    this.api.sortTypeList = this.sortTypeList;
    this.api.sortType = this.sortType;
  }
}
