import {ElementRef, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MarkdownService} from 'ngx-markdown';
import {environment as env} from 'src/environments/environment';
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState,} from '@angular/cdk/layout';
import {formatCurrency, getLocaleCurrencySymbol} from '@angular/common';
import { SignupComponent } from 'src/app/core/components/signup/signup.component';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    signupRef: ElementRef;
    readonly userType = {
        // guest: 0,
        // default: 1,
        // admin: 90,
        // admin1: 70,
        
        guest: 0,
        registered: 1,
        verified: 2,
        adminFullSite: 90 ,
        adminDesign: 70,
    };

    bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
        Breakpoints.Handset
    );
    bpSubscription: Subscription;
    signInDialogRef: any;
    signUpDialogRef: any;
    showCatNav = true;

    constructor(
        public dialog: MatDialog,
        private markdownService: MarkdownService,
        private breakpointObserver: BreakpointObserver
    ) {
    }

    closeDialogs() {
        this.dialog.closeAll();
    }

    checkDataLength(data) {
        if(data){
        if (Array.isArray[data]) {
            return data.length > 0 && data[0].length > 0;
        }
        return data.length > 0;
    } else {
        return false;
    }
    }

    checkDimensionsLength(data) {
        return data.length > 0;
    }

    hasInventory(product) {
        if (product.in_inventory) {
            return (
                product.in_inventory && product.inventory_product_details.count > 0
            );
        } else {
            return false;
        }
    }

    openSignupDialog(isHandset = false, isClose = false) {
        const width = isHandset ? '100%' : '35%';
        // tslint:disable-next-line: no-unused-expression
        !isClose && this.dialog.closeAll();
        return this.dialog.open(SignupComponent, {
            width,
            data: {isClose},
            panelClass: 'auth-dialog-container',
            autoFocus: false,
        });
    }

    compileMarkdown(data, overview = false) {
        let compileData;
        if(data){
        if (!overview) {
            compileData = data.map((item) => `*   ${item.trim()}`);
        } else {
            compileData = data.map((item) => `${item.trim()}`);
        }
        let mergedData = '';
        for (const item of compileData) {
            mergedData = `${mergedData}${
                item.indexOf('</h6>') > -1 ? '\n' : ''
            }${item}\n${item.indexOf('</h6>') > -1 ? '\n' : ''}`;
        }
        return this.markdownService.compile(mergedData);
    } else{
        return '';
    }
    }

    compileMarkdownText(data) {
        return this.markdownService.compile(data);
    }

    updateBoardLike(board, like) {
        return (
            (board.is_liked = like),
                (board.like_count = like ? board.like_count + 1 : board.like_count - 1)
        );
    }

    updateProfileImageLink(picture) {
        return picture.includes('http') ? picture : env.BASE_HREF + picture;
    }

    formatPrice(price) {
        if (price) {
            const priceString = price.toString();
            let minRange;
            let maxRange;
            let result;
            const splitedPrice = priceString.split('-');
            minRange = parseFloat(splitedPrice[0]).toFixed(2);
            if (splitedPrice.length > 1) {
                maxRange = parseFloat(splitedPrice[1]).toFixed(2);
                result = minRange.toString() + ' - ' + maxRange.toString();
            } else {
                maxRange = null;
                result = minRange.toString();
            }
            return result;
        } else {
            return '0';
        }
    }

    formatPriceNew(price) {
        if (price) {
            const priceString = price.toString();
            let minRange;
            let maxRange;
            let result;
            const splitedPrice = priceString.split('-');
            minRange = this.parsePrice(splitedPrice[0]);
            if (splitedPrice.length > 1) {
                maxRange = this.parsePrice(splitedPrice[1]);
                result = minRange.toString() + ' - ' + maxRange.toString();
            } else {
                maxRange = null;
                result = minRange.toString();
            }
            return result;
        } else {
            return '0';
        }
    }

    formatPriceMobile(price, wasPrice) {
        if (price) {
            const priceString = price.toString();
            let minRange;
            let maxRange;
            let result;
            const splitedPrice = priceString.split('-');
            minRange = parseFloat(splitedPrice[0]).toFixed(2);
            if (splitedPrice.length > 1) {
                maxRange = parseFloat(splitedPrice[1]).toFixed(2);
                if (!wasPrice) {
                    result = `From ${this.parsePrice(minRange)}`;
                } else {
                    result = this.parsePrice(minRange);
                }

            } else {
                maxRange = null;
                result = this.parsePrice(minRange);
            }
            return result;
        } else {
            return;
        }
    }

    getPriceObject(product: any) {
        let isRanged, isDiscounted = false;
        let {min_price,max_price,min_was_price, max_was_price} = product;
        let isPriceString, wasPriceString;
        // min_price = min_price.split('-');
        // max_was_price = max_was_price.split('-');
        if (min_price != max_price) {
            isRanged = true;
            isPriceString = `${this.parsePrice(Number(min_price))} - ${this.parsePrice(Number(max_price))}`;
        } else {
            isPriceString = `${this.parsePrice(Number(min_price))}`;
        }
        isDiscounted = max_was_price && Number(max_was_price) > Number(min_price);
        if (isDiscounted) {
            if (isRanged) {
                wasPriceString = `${this.parsePrice(Number(min_was_price))} - ${this.parsePrice(Number(max_was_price))}`;
            } else {
                wasPriceString = `${this.parsePrice(Number(max_was_price))}`;
            }
        }
        return {isDiscounted, isRanged, isPriceString, wasPriceString};
    }

    updateLocalCart(quantityChange) {
        // tslint:disable-next-line: radix
        const localCartData = parseInt(localStorage.getItem('cart'));
        let quantity = quantityChange;
        if (typeof quantityChange === 'string') {
            // tslint:disable-next-line: radix
            quantity = parseInt(quantityChange);
        }
        const updateCartTotal = localCartData + quantity;
        localStorage.setItem('cart', updateCartTotal.toString());
    }

    isHandset(): Observable<BreakpointState> {
        return this.bpObserver;
    }

    hideIntercomIcon() {
        const interval = setInterval(() => {
          const intercom = document.getElementsByClassName(
            "intercom-lightweight-app"
          )[0];
          if (intercom) {
            intercom.classList.add("dn");
            clearInterval(interval);
          }
        }, 500);
    
        const intervalx = setInterval(() => {
          const intercomx = document.getElementsByClassName("intercom-app")[0];
          if (intercomx) {
            intercomx.classList.add("dn");
            clearInterval(intervalx);
          }
        }, 500);
      }

    parsePrice(price: number): string {
        const quotient = Math.floor(price);
        const remainder = Number((price - quotient).toPrecision(2));
        if (remainder === 0) {
            return this.formatUSCurrency(quotient, '1.0-0');
        } else {
            return this.formatUSCurrency(price, '1.2-2');
        }
    }

    formatUSCurrency(price: number, decimals) {
        return formatCurrency(price, 'en-US', getLocaleCurrencySymbol('en-US'), 'en-US', decimals);
    }

    getShippingDesc(product) {
        if (product.inventory_product_details) {
            return product.inventory_product_details.shipping_desc;
        }
        if (this.checkDataLength(product.variations)) {
            const variations = product.variations.filter(v => v.in_inventory);
            return variations[0].inventory_product_details.shipping_desc;
        }
    }
}
