export const addViaUrlResponse = {
  asset_id: 12,
  user_id: 1,
  name: 'sdsd',
  price: '12',
  brand: null,
  path: 'uploads/06FA523F-15BC-016F-1FA4-14737995AF00.png',
  transparent_path: null,
  is_private: 0,
  created_at: '2020-04-11 23:57:41',
  modified_at: '2020-04-11 23:57:41',
  is_active: 1
};
