import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subscription } from "rxjs";
import { ApiService } from "../../../shared/services"; 
import { EnvService } from "../../../shared/services/env.service";

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.less']
})
export class ProductPreviewComponent implements OnInit {
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  email= new FormControl('',Validators.required);
  password= new FormControl('',Validators.required);
  bpSubscription: Subscription;
  isHandset = false;
  emailAvailable: boolean;
  error: boolean;
  expiredDate = new Date();
  minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  currentPrd: any;
  product: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private router: Router,
    private actRouter:ActivatedRoute,
    private cookie:CookieService,
    private api:ApiService,
    public env:EnvService) {}
  ngOnInit() {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
    this.currentPrd = this.actRouter.snapshot.params.id;
    if(this.currentPrd){
      this.api.getProduct(this.currentPrd).subscribe((res)=>{ 
          this.product = res.product; 
      });
    }
 
  } 
 
  checkEmail() {
    if (this.email.valid) {
      this.api.getUserByMail(this.email.value).subscribe((res) => {
        if (res.status) {
          this.emailAvailable = true;
        } else {
          this.emailAvailable = false;
        }
      });
    }
  }
  decideFunc() {   
    if (this.emailAvailable) {
      this.login();
    } else {
      this.register();
    }
  }
  register() {
    if (this.email.valid && this.password.valid) {
      const formData: any = new FormData();
      formData.append("name", "");
      formData.append("email", this.email.value);
      formData.append("password", this.password.value);
      formData.append("c_password", this.password.value);
      this.api.signup(formData).subscribe(
        (res) => {
          this.updateUser(res);
          // this.snackbar.open("User registered successfully", "x");
          this.router.navigate([`/product/${this.currentPrd}`]);
        },
        (error: any) => {
          if (error.error.error.email) {
            this.error = true;

            this.login(); 

            return false;
          }
        }
      );
    } else{
      this.email.markAllAsTouched();
      this.password.markAllAsTouched();
    }
  }
  login() {
    if (this.email.valid && this.password.valid) {
      const user = {
        email: this.email.value,
        password: this.password.value,
      };
      this.api.login(user).subscribe((res) => {
        this.updateUser(res); 
        // this.snackbar.open("User logged in successfully", "x");
        this.router.navigate([`/product/${this.currentPrd}`]);
      });
    }else{
      this.email.markAllAsTouched();
      this.password.markAllAsTouched();
    }
  }
  updateUser(payload) {
    this.expiredDate.setMonth(this.expiredDate.getMonth() + 6);
    this.cookie.set("token", `${payload.success.token}`, this.expiredDate, "/");
    localStorage.setItem("user", JSON.stringify(payload.success.user));
    // this.user =
    //   typeof payload.user === "string"
    //     ? JSON.parse(payload.user)
    //     : payload.user;
  }

  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      ""
    );
  }
}