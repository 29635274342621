import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'; 
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {ApiService, EventEmitterService, SeoService} from 'src/app/shared/services';
import {ActivatedRoute, Router} from '@angular/router'; 
import AOS from "aos";
import { isPlatformBrowser } from '@angular/common';
import { Board } from 'src/app/modules/board/board';
import { boardRoutesNames } from 'src/app/modules/board/board.routes.names';
import { BoardService } from 'src/app/modules/board/board.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.less']
})
export class ShopComponent implements OnInit {

  newArrivals: any;
  newProducts: any;
  totalArrivals: any;
  topDeals: any;
  bestSellers: any;
  emailForm: FormGroup;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
      Breakpoints.Handset
  );

  bpSubscription: Subscription;
  isHandset = false;
  eventSubscription: Subscription;
  contentList = [
      'Design your space the way you want',
      'Mix and match various styles',
      'Easy drag & drop from our catalog',
      'Upload your own items',
      'Publish and share your work to inspire others'
  ];
  carousalOptions = {
      autoWidth: false,
      loop: false,
      items: 1,
      center: true,
      dots: false,
      nav: true,
      margin: 10,
      navText: ['', '<button class=\'right-icon\'><span class="fa fa-chevron-right right-arrow"></span></button>'],
      pagination: true,
      responsive: {
          0: {
              nav: false,
              items: 1,
          },
          1024: {
              items: 3,
              loop: false,
              center: false,
              margin: 10,
              nav: true,
          },
          1366: {
              items: 4,
              loop: false,
              center: false,
              margin: 10,
              nav: true,
          },
          1920: {
              items: 5,
              loop: false,
              center: false,
              margin: 10,
          },
      },
  };
  reviews :any[]=[];
  boardViewLink = boardRoutesNames.BOARD_VIEW;
  displayBoardPath = 'assets/image/board_display.webp';
  trustPilotStarsPath = 'assets/image/trustpilot_stars.png';
  trustPilotLogoPath = 'assets/image/trustpilot_logo.png';
  data: any;

  constructor(
      private apiService: ApiService,
      private formBuilder: FormBuilder,
      private breakpointObserver: BreakpointObserver,
      private eventEmitterService: EventEmitterService,
      private route: ActivatedRoute,
      private router: Router,
      private boardService: BoardService,
      private seoService: SeoService,
      @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.router.navigate(['/']);
    this.bpSubscription = this.bpObserver.subscribe(
        (handset: BreakpointState) => {
          this.isHandset = handset.matches;
        }
      );
  }

  ngOnInit() {
      this.seoService.setMetaTags({});
      if (isPlatformBrowser(this.platformId)) { 
          AOS.init(); 
      }
      
      this.eventSubscription = this.eventEmitterService.userChangeEvent
          .asObservable()
          .subscribe((user) => {
              this.emailForm = this.formBuilder.group({
                  email: ['', [Validators.required, Validators.email]]
              });
              this.bpSubscription = this.bpObserver.subscribe(
                  (handset: BreakpointState) => {
                      this.isHandset = handset.matches;
                  }
              );
          });
        //   this.getTestimonials();
  }
  getTestimonials() {
      this.apiService.getTestimonials().subscribe((res:any) => {
          this.reviews = res;
      });
  }

  onDestroy(): void {
      this.eventSubscription.unsubscribe();
  }

  onSubmit(value: any) {
      this.apiService.getEmail().subscribe((res) => {
      });
  }

  newBoard() {
      const board: Board = new Board();
      const newBoard = Object.assign({}, board);
      newBoard.title = 'Untitled Board';

      newBoard.type_privacy = newBoard.is_published = 0;

      // tslint:disable-next-line: no-shadowed-variable
      this.boardService.addBoard(newBoard).subscribe((board) => {
          this.router.navigate(
              [['/board', this.boardViewLink, board.uuid].join('/')],
              {
                  relativeTo: this.route,
                  state: {
                      justCreated: true
                  }
              }
          );
      });
  }
  openSearch(): void {
      if (this.data.length > 0) {
          this.router
              .navigateByUrl(`/search?query=${this.data}`)
              .then(() => {
                 this.data ='';
                  // this.searchComplete.emit();
              });
      } else {
          // this.textChange = true;
      }
  }
}
