import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {  Router } from "@angular/router";
import { ApiService } from "src/app/shared/services";

@Component({
  selector: "app-expert-designer-section",
  templateUrl: "./expert-designer-section.component.html",
  styleUrls: ["./expert-designer-section.component.less"],
})
export class ExpertDesignerSectionComponent implements OnInit, AfterViewInit,OnDestroy {
  slideIndex = 0;
  profileList: any[] = [];
  interval: any;
  constructor(private api: ApiService,private router:Router) {}

  ngOnInit() {
    this.api.getDesignProfile().subscribe((res: any) => {
      this.profileList = res;
      setTimeout(() => {
        this.showSlides(0);
      }, 0);

      this.interval = setInterval(() => {
        this.slideIndex <= this.profileList.length
          ? (this.slideIndex += 1)
          : (this.slideIndex = 1);
        this.showSlides(this.slideIndex);
      }, 3000);
    });
  }
  ngAfterViewInit(): void {}

  // Next/previous controls
  plusSlides(n: number) {
    this.showSlides((this.slideIndex += n));
  }
  navToDesign(){
    this.router.navigate(['/design'])
  } 

  showSlides(n) { 
    let i;
    let slides = document.getElementsByClassName(
      "mySlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this.slideIndex - 1].style.display = "block";
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
