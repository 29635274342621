import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  StripeService,
  StripeCardComponent,
  
} from "ngx-stripe";
import { Element as StripeElement } from 'ngx-stripe';
import { ApiService } from "src/app/shared/services";

@Component({
  selector: "app-buy-credits-modal",
  templateUrl: "./buy-credits-modal.component.html",
  styleUrls: ["./buy-credits-modal.component.less"],
})
export class BuyCreditsModalComponent implements OnInit {
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCvc: StripeElement;
  customerToken = null;
  customerIp = null;
  isHandset = false;
  selectedOption: number = 0; // Default selected option
  priceOptions: number[] = [5, 10];
  creditAmtOptions: number[] = [20, 50];
  firstName: string = "";
  lastName: string = "";
  userEmail: string = JSON.parse(localStorage.getItem("user")).email;
  errorMessage: string = "";
  cardComplete: boolean = false;
  paymentInProgress: boolean = false;
  paymentSuccess: boolean = false;

  @ViewChild(StripeCardComponent, { static: true }) card: StripeCardComponent;
  @ViewChild("stripeElement", { static: true }) stripeElement: ElementRef;

  constructor(
    private api: ApiService,
    private stripeService: StripeService,
    private dialogRef: MatDialogRef<BuyCreditsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  ngOnInit() {
    this.errorMessage = "";
  }
  selectOption(option: number) {
    this.selectedOption = option;
    this.paymentSuccess = false;
    this.firstName = ""; // Clear first name
    this.lastName = ""; // Clear last name
  }

  async initPayment() {
    this.errorMessage = "";
    this.paymentSuccess = false;
    this.paymentInProgress = true;
    const cardElement = this.card.element;
    const name =
      this.firstName +
      ' ' +
      this.lastName;
    this.stripeService
      // tslint:disable-next-line: no-unused-expression
      .createToken((this.cardNumber, this.cardExpiry, this.cardCvc), { name })
      .subscribe((result: any) => {
        this.customerToken = result.token.id;
        this.customerIp = result.token.client_ip;
      })
    const result = this.stripeService
      .createPaymentMethod("card", cardElement, {
        billing_details: {
          name: `${this.firstName} ${this.lastName}`,
        },
        metadata: {
          // Add any additional metadata properties here if needed
        },
      })
      .subscribe(async (paymentMethodRes) => {
        if (paymentMethodRes.error) {
          this.errorMessage = paymentMethodRes.error.message;
          this.paymentInProgress = false;
          return;
        } else if (this.selectedOption !== -1) {
          await this.initiatePaymentOnServer(
            this.firstName,
            this.lastName,
            this.userEmail,
            this.priceOptions[this.selectedOption],
            paymentMethodRes.paymentMethod.id,
            this.customerToken,
            this.customerIp
          );
        }
      });
  }

  async initiatePaymentOnServer(
    firstName,
    lastName,
    userEmail,
    amount,
    methodId,
    customerToken,
    customerIp
  ) {
    const data = {
      f_Name: firstName,
      l_Name: lastName,
      email: userEmail,
      amount: amount,
      credits: amount === 10 ? 50 : 20,
      method_id: methodId,
      customerToken: customerToken,
      customerIp: customerIp,
    };
    this.api.postPaymentOnServer(data).subscribe((res: any) => {
      if (res.erorr) {
        this.errorMessage = res.error.message;
      } else if (res.status == false) {
        this.errorMessage = res.message;
      } else {
        this.firstName = "";
        this.lastName = "";
        this.cardNumber = null;
        this.cardExpiry = null;
        this.cardCvc = null;
        this.paymentSuccess = true;
        this.customerToken = null;
        this.customerIp = null;
        this.dialogRef.close("success");
      }
      this.paymentInProgress = false;
    });
  }
}
